import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/solid";
import {
  dummyData,
  fbaInboundOptions,
  handleFilterMarketPlaces,
  handleGetAmazonIntegrations,
  inboundFormInitState,
  LIST_INBOUND_PLANS_TIME_IN_MS,
  planStatusesEnums,
} from "./FbaUtils";
import {
  fetchWarehouses,
  fetchCustomers,
  fetchIntegrations,
  createFbaInboundPlan,
  listFbaInboundPlans,
} from "#redux/FbaInbound/fbaInboundActions";
import InboundForm from "./InboundForm";
import FbaInboundTable from "./FbaInboundTable";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { AppStateContext } from "#contexts/appState";
import { resetFbaInboundIntegrations } from "#redux/FbaInbound/fbaInboundSlice";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import SlideOverPanel from "#components/common/SlideOverPanel";
import FbaConfirmationProducts from "./FbaConfirmationProducts";
import { useParams, useHistory } from "react-router-dom";
import FbaProductTableReadOnly from "./FbaProductTableReadOnly";

const FbaInbound = () => {
  const dispatch = useDispatch();
  const appState = useContext(AppStateContext);
  const notify = CustomNotification();
  const urlParams = useParams();
  const history = useHistory();

  const { warehouses, customers, integrations, createInboundPlan } =
    useSelector((state) => state.fbaInbound);

  // State for inbound form and inbound plan variables
  const [inboundForm, setInboundForm] = useState({ ...inboundFormInitState });

  const [inboundPlansVariables, setInboundPlansVariables] = useState({
    pageNumber: 1,
    perPage: 10,
    sort: "-updatedAt",
    filters: {
      customerIds: null,
      ids: null,
      warehouseIds: null,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [listInboundPlansData, setListInboundPlansData] = useState([]);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  // Fetch inbound plans with updated parameters
  const fetchPlans = (pageNumber = 1) => {
    if (pageNumber === 1) setListInboundPlansData([]); // Reset data on first page
    const variables = {
      listInboundPlansInput: { ...inboundPlansVariables, pageNumber },
    };
    dispatch(listFbaInboundPlans({ variables }));
    // }
  };

  const handleResetFetchPlans = () => {
    setCurrentPage(1);
    fetchPlans(1);
  };

  // Handle form submission for creating a new inbound plan
  const handleFormSubmit = () => {
    const { marketPlace, client, warehouse, sellerId } = inboundForm;
    const variables = {
      createInboundPlanInput: {
        marketplace: marketPlace,
        customer: client,
        warehouse,
        sellerId:
          (sellerId ? sellerId : integrations?.data?.[0]?.sellerId) || null,
      },
    };
    const successCallback = (data) => {
      notify.success(`${data?.message} with planId - ${data?.inboundPlanId}`);
      setCurrentPage(1);
      fetchPlans(1); // Refresh data
    };
    const errorCallback = (error) => {
      notify.error(error);
    };
    dispatch(
      createFbaInboundPlan({ variables, successCallback, errorCallback }),
    );
  };

  const handleHeaderWithArrow = (planStatusAfterActive) => {
    return planStatusAfterActive ? (
      <HeaderWithArrow
        headerTitle={
          <p className="flex items-center gap-2">
            <span className="text-gray-400">Inbound Plan ID</span>
            <span>{selectedPlan?.inboundPlanId}</span>
            <span>{">"}</span>
            <span>Plan Details</span>
          </p>
        }
        description={"Context text goes here"}
        isLearnMore={false}
        isArrow
        arrowAction={() => {
          setSlideOverOpen(false);
          history.push("/fbaInbound");
          // handleResetFetchPlans();
        }}
        mainClasses="mb-0"
      />
    ) : (
      <HeaderWithArrow
        headerTitle={
          <p className="flex items-center gap-2">
            {/* <span className="text-gray-400">Inbound Plan ID</span> */}
            <span>{selectedPlan?.inboundPlanId}</span>
            <span>{">"}</span>
            <span>Confirmation of Products and their Quantities</span>
          </p>
        }
        description={
          "This screen allows users to review and verify the selected products along with their quantities before proceeding, ensuring accuracy for shipment planning."
        }
        isLearnMore={false}
        isArrow
        arrowAction={() => {
          setSlideOverOpen(false);
          history.push("/fbaInbound");
          handleResetFetchPlans();
        }}
        mainClasses="mb-0"
      />
    );
  };

  const planStatusAfterActive =
    selectedPlan?.status === planStatusesEnums.ACTIVE.key ||
    selectedPlan?.status === planStatusesEnums.VOIDED.key ||
    selectedPlan?.status === planStatusesEnums.SHIPPED.key;

  // Set the selected plan from list based on URL params
  const handleGetSelectedPlan = (planId) => {
    const plan = listInboundPlansData.find((plan) => plan.id === planId);
    setSelectedPlan(plan);
  };

  // Fetch warehouses and customers initially
  useEffect(() => {
    dispatch(fetchWarehouses({}));
    dispatch(fetchCustomers({}));
  }, []);

  // Update filters in inboundPlansVariables when client or warehouse changes
  useEffect(() => {
    setCurrentPage(1);
    setInboundPlansVariables((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        customerIds: inboundForm.client ? [inboundForm.client] : null,
        warehouseIds: inboundForm.warehouse ? [inboundForm.warehouse] : null,
      },
    }));
  }, [inboundForm.client, inboundForm.warehouse]);

  // Fetch additional pages of inbound plans when page number changes
  useEffect(() => {
    appState.setAdminPageBgColor("bg-white");
    if (currentPage > 1) {
      fetchPlans(currentPage);
    }
  }, [currentPage]);

  // Fetch inbound plans when filters (excluding pageNumber) change
  useDebouncedEffect(
    () => {
      fetchPlans(1); // Fetch from the first page when filters change
    },
    LIST_INBOUND_PLANS_TIME_IN_MS,
    [
      inboundPlansVariables.filters.customerIds,
      inboundPlansVariables.filters.warehouseIds,
      inboundPlansVariables.sort,
    ],
  );

  // Reset integrations and fetch new ones when client or warehouse changes
  useDebouncedEffect(
    () => {
      dispatch(resetFbaInboundIntegrations());
      setInboundForm((form) => ({ ...form, marketPlace: "", sellerId: "" }));

      if (inboundForm.warehouse && inboundForm.client) {
        const variables = {
          filters: {
            customer: inboundForm.client,
            warehouse: inboundForm.warehouse,
          },
        };
        dispatch(fetchIntegrations({ variables }));
      }
    },
    "immediate",
    [inboundForm.client, inboundForm.warehouse],
  );

  // Manage SlideOverPanel visibility based on URL params
  useEffect(() => {
    if (urlParams.planId) {
      handleGetSelectedPlan(urlParams.planId);
    } else {
      setSlideOverOpen(false);
      setSelectedPlan({});
    }
  }, [urlParams.planId, listInboundPlansData]);

  useEffect(() => {
    if (selectedPlan && Object.keys(selectedPlan).length) {
      setSlideOverOpen(true);
    }
  }, [selectedPlan]);

  return (
    <>
      <div className="p-8 font-inter">
        <div className="mb-4 flex justify-between">
          <HeaderWithArrow
            headerTitle="Inbound FBA Plans"
            description="View and manage your FBA inbound plans. Track past and ongoing shipments, check product eligibility, and initiate new inbound plans for seamless inventory management."
            isLearnMore={false}
            isArrow={false}
          />
          <div className="flex items-start justify-end gap-6">
            <PrimaryButton
              height="2.8rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="primary"
              className="ml-6 mt-2 text-sm font-medium"
              onClick={handleFormSubmit}
              loading={createInboundPlan.isPending}
              disabled={false}>
              <PlusIcon className="mr-1 h-4 w-4 stroke-2 text-white" />
              Create an Inbound Plan
            </PrimaryButton>
          </div>
        </div>

        <div className="mb-4">
          <InboundForm
            inboundForm={inboundForm}
            setInboundForm={setInboundForm}
            fbaIntegrations={handleGetAmazonIntegrations(integrations?.data)}
            isIntegrationsLoading={integrations?.isPending}
            shouldSellerIdFieldVisible={
              appState?.tenant?.settings
                ?.multiAccountIntegrationSupportEnabled === true
            }
            formData={fbaInboundOptions(
              warehouses?.data || [],
              customers?.data || [],
              handleFilterMarketPlaces(
                inboundForm.sellerId,
                handleGetAmazonIntegrations(integrations?.data),
              )?.map((marketPlace) => ({
                name: marketPlace,
                id: marketPlace,
              })) || [],
              handleGetAmazonIntegrations(integrations?.data)?.map(
                (integration) => ({
                  id: integration?.sellerId,
                  name: integration?.sellerId,
                }),
              ),
              dummyData.shippingModeOptions,
            )}
          />
        </div>

        <div className="h-70v w-full">
          <FbaInboundTable
            listInboundPlansData={listInboundPlansData}
            setListInboundPlansData={setListInboundPlansData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={inboundPlansVariables?.perPage}
            fetchPlans={fetchPlans}
            setSlideOverOpen={setSlideOverOpen}
            setSelectedPlan={setSelectedPlan}
          />
        </div>
      </div>
      <SlideOverPanel
        open={slideOverOpen}
        isCrossIconVisible={false}
        title={
          <div className="flex justify-between p-2">
            {handleHeaderWithArrow(planStatusAfterActive)}
          </div>
        }
        setOpen={(value) => {
          if (!value) {
            history.push("/fbaInbound");
            if (!planStatusAfterActive) handleResetFetchPlans();
          }
          setSlideOverOpen(value);
        }}
        containerStyle={"max-w-6xl"}>
        {planStatusAfterActive ? (
          <FbaProductTableReadOnly inventoryData={selectedPlan?.items} />
        ) : (
          <FbaConfirmationProducts
            selectedPlan={selectedPlan}
            handleResetFetchPlans={handleResetFetchPlans}
            client={
              customers?.data?.find(
                (customer) => customer.id === selectedPlan?.customer,
              ) || {}
            }
          />
        )}
      </SlideOverPanel>
    </>
  );
};

export default FbaInbound;
