import { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import _ from "lodash";
import { SearchIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import { storeInLocalStorageForSalesOverview } from "#utils/catalogsAndAnalyticsUtils";
import AnalyticsTabs from "#components/catalogs/AnalyticsTabs";
import ProductDetails from "#components/catalogs/ProductDetails";
import { DEFAULT_SEARCH_FIELDS } from "#components/catalogs/catalogTabs/CatalogEnhancedSearch";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";

const defaultTab = "Sales Overview";
const Analytics = ({}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [shouldOpenModel, setShouldOpenModel] = useState(false);
  const [product, setProduct] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isProductSearch, setIsProductSearch] = useState(false);
  const [allVariantComponents, setAllVariantComponents] = useState([]);
  const [tabs, setTabs] = useState([
    { name: "Sales Overview", current: true },
    { name: "Pricing Overview", current: false },
  ]);
  const productsQuery = useQuery(GET_PRODUCTS);
  const isSearchRef = useRef(false);
  const searchModalRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let redirectBack = "/analytics";
  if (location?.state?.backPath === "/productPerformance") {
    redirectBack = "/productPerformance";
  }

  const salesOverviewData = JSON.parse(
    localStorage.getItem("salesOverviewData") || null,
  );
  const getSkuFromRotingState = location?.state?.sku;

  const searchFields = DEFAULT_SEARCH_FIELDS?.map((fieldObject) => {
    if (fieldObject?.value === "name" || fieldObject?.value === "sku")
      return { ...fieldObject, enabled: true };
    return fieldObject;
  });

  const getMatchedProducts = async (keyword) => {
    setIsProductSearch(true);
    const response = await productsQuery.fetchData({
      perPage: 25,
      pageNumber: 1,
      filters: {
        keyword: keyword,
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });
    setIsProductSearch(false);
    if (response?.data?.products) {
      const products =
        response.data.products?.entities?.filter((i) => !i.parentProduct) || [];
      if (products?.length > 0) {
        if (isSearchRef.current && salesOverviewData?.productSku) {
          const foundedProduct = products?.find(
            (product) => product?.sku === salesOverviewData?.productSku,
          );
          if (foundedProduct) {
            setProduct(foundedProduct);
            storeInLocalStorageForSalesOverview(
              foundedProduct.sku,
              "productSku",
            );
            storeInLocalStorageForSalesOverview(foundedProduct.id, "productId");
          }
        } else {
          setSearchResults(products);
        }
      }
    }
    if (response?.error) setSearchResults([]);
    isSearchRef.current = false;
  };

  const onSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct);
    storeInLocalStorageForSalesOverview(selectedProduct.sku, "productSku");
    storeInLocalStorageForSalesOverview(selectedProduct.id, "productId");
    setShouldOpenModel(false);
    let tabInLocalScope = [...tabs];
    const tabIndex = tabInLocalScope.findIndex(
      (tabObject) => tabObject.name === defaultTab,
    );
    if (tabIndex !== -1 && !tabInLocalScope[tabIndex].current) {
      tabInLocalScope = tabInLocalScope.map((tabObject) => {
        return {
          ...tabObject,
          current: tabObject.name === defaultTab,
        };
      });
      setTabs(tabInLocalScope);
    }
  };

  const searchFocusHandler = () => {
    if (searchResults?.length > 0) setShouldOpenModel(true);
  };

  const handleOutsideClick = (event) => {
    if (
      searchModalRef?.current &&
      !searchModalRef.current?.contains(event.target)
    ) {
      setShouldOpenModel(false);
    }
  };

  useEffect(() => {
    if (getSkuFromRotingState) {
      isSearchRef.current = true;
      if (!salesOverviewData?.productSkuFromRoutingState) {
        storeInLocalStorageForSalesOverview(true, "productSkuFromRoutingState");
        storeInLocalStorageForSalesOverview(
          getSkuFromRotingState,
          "productSku",
        );
        setSearchKeyword(getSkuFromRotingState);
      }
    }
  }, [getSkuFromRotingState]);

  useEffect(() => {
    if (searchKeyword.trim() === "") {
      if (product) {
        setProduct(null);
        setShouldOpenModel(false);
        setSearchResults([]);
        storeInLocalStorageForSalesOverview(null, "productId");
        storeInLocalStorageForSalesOverview(null, "productSku");
      }
    }
  }, [searchKeyword, salesOverviewData]);

  useDebouncedEffect(
    () => {
      if (searchKeyword && searchKeyword.trim() !== "") {
        getMatchedProducts(searchKeyword);
      }
    },
    500,
    [searchKeyword],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (salesOverviewData) {
      if (salesOverviewData?.productSku) {
        isSearchRef.current = true;
        setSearchKeyword(salesOverviewData?.productSku);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (
        !window.location.pathname.includes("/productRevenueByRegions") &&
        !window.location.pathname.includes("/productRevenueByCustomers")
      ) {
        localStorage.removeItem("salesOverviewData");
      }
    };
  }, []);

  return (
    <>
      <div className="min-h-screen flex-1 flex-col bg-white font-inter">
        <div className="hs-catalog-wrapper">
          <div className="relative flex items-center justify-between">
            <div className="mb-4 flex items-center gap-4">
              <Link
                className="flex items-center gap-1 text-gray-600 hover:text-gray-900"
                to={redirectBack}>
                <ArrowLeftIcon className="h-5 w-5" />
                {redirectBack === "/analytics"
                  ? `Back to Dashboard`
                  : "Back to All Products"}
              </Link>
              <h2 className="text-2xl font-semibold">Product Analytics</h2>
            </div>
            <div className="relative flex-col">
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-0 py-3 pl-10 font-inter text-lg text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                  placeholder="Search Products"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    setSearchResults([]);
                    if (e.target.value?.trim() !== "") setShouldOpenModel(true);
                    else setShouldOpenModel(false);
                  }}
                  onFocus={searchFocusHandler}
                  value={searchKeyword}
                />
              </div>
              {shouldOpenModel && searchKeyword && (
                <div
                  className="absolute right-0 z-20 mt-4 max-h-64 w-[320px] overflow-y-auto rounded-md border-2 border-[#DDDFE0] bg-white p-4"
                  ref={searchModalRef}>
                  <div className="flex-col">
                    {isProductSearch && (
                      <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                        <LoadingIndicator shouldShowOnPage={false} />
                      </div>
                    )}
                    {(!searchResults || searchResults.length === 0) &&
                    !isProductSearch ? (
                      <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                        <img
                          src={noSearchResultsIcon}
                          className="mx-auto h-48"
                        />
                        <div className="mt-4 text-lg font-semibold text-gray-600">
                          No Results To Display
                        </div>
                      </div>
                    ) : !isProductSearch && searchResults?.length > 0 ? (
                      searchResults?.map((product, index) => {
                        return (
                          <div
                            key={`search-analytics-product-${index}`}
                            className="mb-2 flex cursor-pointer items-center"
                            onClick={() => onSelectProduct(product)}>
                            {(product?.images?.[0]?.url ||
                              product?.images?.[0]?.display_url) && (
                              <div className="h-[66px] w-16 shrink-0">
                                <img
                                  className="h-[66px] w-16 rounded"
                                  src={
                                    product.images?.[0]?.display_url
                                      ? product.images?.[0]?.display_url
                                      : product.images?.[0]?.url
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                            <div className="ml-2 flex flex-col gap-1">
                              <div className="w-auto max-w-[200px] truncate text-base font-medium text-primaryAccent">
                                {product?.name}
                              </div>
                              <div className="w-auto max-w-[200px] truncate text-sm text-lightGray">
                                SKU: {product?.sku}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="py-4">
            <ProductDetails
              isLoading={isProductSearch}
              product={product}
              allVariantComponents={allVariantComponents}
              setAllVariantComponents={setAllVariantComponents}
            />
            <AnalyticsTabs
              searchKeyword={searchKeyword}
              isLoading={isProductSearch}
              product={product}
              allVariantComponents={allVariantComponents}
              tabs={tabs}
              setTabs={setTabs}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// @ts-ignore
export default Analytics;
