import CurrentPackBoxes from "#components/packer/CurrentPackBoxes";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import BoxContentDetails from "#newUiComponents/packer/BoxContentDetails";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import { useState, useEffect, useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import ShipmentDetailsAndPreview from "#newUiComponents/packer/ShipmentDetailsAndPreview";
import NewRateShopping from "#newUiComponents/packer/NewRateShopping";
import PackerRateShopping from "./PackerRateShopping";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import {
  CONFIRM_PALLET_INFORMATION,
  UPDATE_ORDER_CARRIER_INFO,
} from "#mutations/index";
import { FETCH_SPECIFIC_ORDER } from "#queries/index";
// import { UPDATE_ORDER_TRACKING_INFO } from "#mutations/index";
import { useQuery } from "#hooks/useQuery";
import _ from "lodash";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { isManyMooonsTenant } from "#utils/tenantCheck";
import { useFeatureFlags } from "#contexts/featureFlags";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
const ALERT_VISIBILITY_IN_MS = 3000;

export const selectedMethodEnums = {
  RATE_SHOPPING: "RateShopping",
  TRACKING_ID: "TrackingId",
};

const ConfirmBoxContents = ({
  currentBatch,
  weight,
  setWeight,
  order,
  restartActivity,
  pauseActivity,
  unpackItem,
  confirmBoxes,
  setWeightOfBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  setBoxDetails,
  boxDetails,
  transportMode,
  setTransportMode,
  tenant,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  print2DBarcode,
  isNewRateShoppingEnabled,
  downloadBoxLabels,
  printPackingLabels,
  downloadPackingLabel,
  downloadShippingLabel,
  checkFbaBoxWeightLimits,
  printShippingLabel,
  fetchSpecificOrderData,
  downloadBol,
  printBol,
  packingLabelsEnabled,
  isLabelGenerated,
  setIsLabelGenerated,
  confirmDropoff,
  getFbaLabelGenerationStatus,
  confirmTransportInformation,
  viewInvoiceLabels,
}) => {
  const confirmPalletInformationQuery = useQuery(CONFIRM_PALLET_INFORMATION);
  const updateCarrierInfoQuery = useQuery(UPDATE_ORDER_CARRIER_INFO);
  // const updateTrackingInfoQuery = useQuery(UPDATE_ORDER_TRACKING_INFO);
  const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
  const [rate, setRate] = useState(null);
  const appState = useContext(AppStateContext);
  const [showRateShoppingSlideOver, setShowRateShoppingSlideOver] =
    useState(false);
  const [selectedIncoterms, setSelectedIncoterms] = useState(null);
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
    useState(null);
  const [insuredValue, setInsuredValue] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(
    order.trackingNumber || "",
  );
  const [actionsMenuItems, setActionsMenuItems] = useState([]);

  const { bolUploadEnabled } = useFeatureFlags();

  let toAddress = null;
  if (order?.shipmentPlan?.ShipToAddress) {
    const shipToAddress = order.shipmentPlan.ShipToAddress;
    toAddress = {
      line1: shipToAddress.AddressLine1,
      line2: shipToAddress.AddressLine2,
      city: shipToAddress.City,
      name: shipToAddress.Name,
      country: shipToAddress.CountryCode,
      email: "",
      phone: "",
      postalCode: shipToAddress.PostalCode,
      state: shipToAddress.StateOrProvinceCode,
    };
  } else {
    toAddress = {
      line1: order?.shippingAddress?.line1,
      line2: order?.shippingAddress?.line2,
      city: order?.shippingAddress?.city,
      name: order?.shippingAddress?.name,
      country: order?.shippingAddress?.country,
      email: order?.shippingAddress?.email,
      phone: order?.shippingAddress?.phone,
      postalCode: order?.shippingAddress?.zip,
      state: order?.shippingAddress?.state,
    };
  }

  const groupedBoxes = _.groupBy(currentBatch.workingList, "boxName");

  const boxes = currentBatch.boxes.map((box) => ({
    length: box.length,
    height: box.height,
    width: box.width,
    weight: box.weight,
    name: box.name,
    items: groupedBoxes[box.name]?.map((item) => {
      return {
        sku: item.sku,
        fnSku: item.fnSku,
        quantity: item.quantity,
        bestByDate: item.bestByDate,
      };
    }),
  }));

  useEffect(() => {
    if (updateCarrierInfoQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (
      updateCarrierInfoQuery.data &&
      updateCarrierInfoQuery.data.updateCarrierInfo
    ) {
      appState.setAlert(
        updateCarrierInfoQuery.data.updateCarrierInfo.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      fetchSpecificOrder.fetchData({
        orderId: order.id,
      });
    }

    if (updateCarrierInfoQuery.error) {
      appState.setAlert(
        updateCarrierInfoQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    updateCarrierInfoQuery.loading,
    updateCarrierInfoQuery.error,
    updateCarrierInfoQuery.data,
  ]);

  useEffect(() => {
    if (confirmPalletInformationQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (confirmPalletInformationQuery.data) {
      appState.setAlert(
        confirmPalletInformationQuery.data.confirmPalletInformation.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (confirmPalletInformationQuery.error) {
      appState.setAlert(
        confirmPalletInformationQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    confirmPalletInformationQuery.loading,
    confirmPalletInformationQuery.error,
    confirmPalletInformationQuery.data,
  ]);

  useEffect(() => {
    setTrackingNumber(trackingNumber || order?.trackingNumber);
    setTransportMode(transportMode || order?.typeOfShipment || "SP");
    setSelectedIncoterms(selectedIncoterms || order?.incoterms);
    setSelectedInsuranceProvider(
      selectedInsuranceProvider || order?.insuranceProvider,
    );
    setInsuredValue(insuredValue || order?.insuredValue);
    const preSelectedRate = order?.preSelectedCarrierRate
      ? {
          selectedShipmentRateId: order?.preSelectedCarrierRate?.id,
          selectedShipmentSource: order?.preSelectedCarrierRate?.source,
          transportMode: order?.typeOfShipment,
          selectedCarrierRate: order?.preSelectedCarrierRate,
          carrier: `${order?.preSelectedCarrierRate?.carrier} - ${order?.preSelectedCarrierRate?.service}`,
        }
      : {};

    if (rate && Object.keys(rate).length > 0) {
      setRate(rate);
    } else if (
      order?.preSelectedCarrierRate &&
      Object.keys(order?.preSelectedCarrierRate).length > 0
    ) {
      setRate(preSelectedRate);
    } else {
      setRate(null);
    }

    if (
      order.preSelectedCarrierRate &&
      Object.keys(order.preSelectedCarrierRate).length &&
      order.typeOfShipment
    ) {
      setSelectedMethod(selectedMethodEnums.RATE_SHOPPING);
    } else if (order.trackingNumber) {
      setSelectedMethod(selectedMethodEnums.TRACKING_ID);
    }
  }, [order]);

  const onPalletSubmit = async (pallets, successCallBack) => {
    const palletInformationQueryResponse =
      await confirmPalletInformationQuery.fetchData({
        id: currentBatch.id,
        pallets: pallets,
        generateLabels: false,
      });
    if (palletInformationQueryResponse.data) successCallBack();
  };

  const onRateSelect = (selectedRate) => {
    setRate(selectedRate);

    const carrierSelectionData = {
      orderInfo: {
        id: order.id,
        selectedCarrierServiceId: selectedRate?.selectedShipmentRateId,
        selectedCarrierSource: selectedRate?.selectedShipmentSource,
        typeOfShipment: selectedRate?.transportMode,
        confirmedTypeOfShipment: true,
        carrier: selectedRate?.carrier,
      },
    };
    updateCarrierInfoQuery.fetchData(carrierSelectionData);
  };

  useEffect(() => {
    const returnArr = [];

    if (tenant?.typeOfCustomer?.includes("B2B") || bolUploadEnabled) {
      // DONE
      returnArr.push({
        name: "Download BOL",
        icon: "",
        onClick: () => downloadBol(order.id),
      });
      returnArr.push({
        name: "Print BOL",
        icon: "",
        onClick: () => printBol(order.id),
      });
    }

    if (packingLabelsEnabled && currentBatch?.boxes?.length > 0) {
      returnArr.push({
        name: "Print Box Label",
        icon: "",
        onClick: () => printPackingLabels(),
      });
      returnArr.push({
        name: "Download Box Label",
        icon: "",
        onClick: () => downloadPackingLabel(order.id),
      });
    }

    if (order?.invoiceUrls) {
      returnArr.push({
        title: "View Invoice Labels",
        icon: "",
        onClick: () => viewInvoiceLabels(order.invoiceUrls),
      });
    }

    setActionsMenuItems((prev) => [...prev, ...returnArr]);
  }, []);

  useEffect(() => {
    if (isLabelGenerated) {
      const returnArr = [];

      if (order.shippingLabel) {
        // DONE
        returnArr.push({
          name: "Download Shipping Label",
          icon: "",
          onClick: () => {
            downloadShippingLabel(order.id);
          },
        });
        returnArr.push({
          name: "Print Shipping Label",
          icon: "",
          onClick: () => {
            printShippingLabel(true);
          },
        });
      }

      setActionsMenuItems((prev) => [...prev, ...returnArr]);
    }
  }, [isLabelGenerated, order.shippingLabel]);

  useEffect(() => {
    if (!order?.transportFailed && isLabelGenerated) {
      if (order?.source === "FBA") {
        if (currentBatch.status === "AWAITING_LABEL") {
          getFbaLabelGenerationStatus();
        }
      } else {
        confirmTransportInformation();
      }
    }
  }, [isLabelGenerated]);

  const handleGetConfirmCtaText = () => {
    if (selectedMethod === selectedMethodEnums.RATE_SHOPPING) {
      if (rate?.selectedShipmentRateId) {
        return "Generate Labels";
      } else {
        return "Skip";
      }
    } else if (selectedMethod === selectedMethodEnums.TRACKING_ID) {
      if (trackingNumber || order?.trackingNumber) {
        return "Generate Labels";
      } else {
        return "Skip";
      }
    } else {
      return "Skip";
    }
  };

  const handleIsGenerateLabelsEnabled = () => {
    if (selectedMethod === selectedMethodEnums.RATE_SHOPPING) {
      return rate?.selectedShipmentRateId ? true : false;
    } else if (selectedMethod === selectedMethodEnums.TRACKING_ID) {
      return trackingNumber || order?.trackingNumber ? true : false;
    } else {
      return false;
    }
  };

  const validateConfirmBoxes = () => {
    if (order?.source === "FBA") {
      for (const box of boxes) {
        const validationResult = checkFbaBoxWeightLimits(
          box,
          currentBatch.workingList,
        );
        if (!validationResult.isValid) {
          return appState.setAlert(
            `Check box ${box.name} for weight limits. ${validationResult.message}`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
      }
    }
    confirmBoxes();
  };

  useEffect(() => {
    // automatically print shipping label
    if (isLabelGenerated) {
      if (
        tenant?.features?.packerRestartOptionsEnabled !== false &&
        !tenant?.settings?.activities?.packing?.disableAutomaticPrinting &&
        fetchSpecificOrderData
      ) {
        printShippingLabel();
      }
    }
  }, [isLabelGenerated, fetchSpecificOrderData]);

  useEffect(() => {
    return () => {
      setIsLabelGenerated(false);
    };
  }, []);

  return (
    <>
      {isLabelGenerated ? (
        <div className="px-12 pt-12">
          <CustomAlert
            type="info"
            message={
              "Editing data has been disabled now. If you wish to edit, please click on Restart Packing."
            }
          />
        </div>
      ) : null}

      {isNewRateShoppingEnabled ? (
        <div className="flex min-h-screen flex-col gap-4 overflow-hidden bg-white p-12 font-inter">
          <div className="mt-2 flex w-full justify-center bg-white">
            <BoxContentDetails
              currentBatch={currentBatch}
              order={order}
              weight={weight}
              setWeight={setWeight}
              setWeightOfBox={setWeightOfBox}
              setBoxDetails={setBoxDetails}
              boxDetails={boxDetails}
              unpackItem={unpackItem}
              handleFindAndPack={handleFindAndPack}
              isAddItemQuantityExceed={isAddItemQuantityExceed}
              isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
              itemsToUnPack={itemsToUnPack}
              setItemsToUnPack={setItemsToUnPack}
              handleFindAndUnpackItem={handleFindAndUnpackItem}
              print2DBarcode={print2DBarcode}
              isLabelGenerated={isLabelGenerated}
            />
          </div>
          <ShipmentDetailsAndPreview
            order={order}
            transportMode={transportMode}
            setTransportMode={setTransportMode}
            boxDetails={boxDetails}
            setShowRateShoppingSlideOver={() => {
              if (confirmBoxes()) setShowRateShoppingSlideOver(true);
            }}
            selectedIncoterms={selectedIncoterms}
            selectedInsuranceProvider={selectedInsuranceProvider}
            insuredValue={insuredValue}
            preSelectedCarrierRate={
              rate && Object.keys(rate).length > 0
                ? rate?.selectedCarrierRate
                : order.typeOfShipment && order.preSelectedCarrierRate
                  ? order.preSelectedCarrierRate
                  : {}
            }
            setSelectedIncoterms={setSelectedIncoterms}
            setSelectedInsuranceProvider={setSelectedInsuranceProvider}
            setInsuredValue={setInsuredValue}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            setTrackingNumber={setTrackingNumber}
            trackingNumber={trackingNumber}
            isLabelGenerated={isLabelGenerated}
          />
          <SlideOverPanel
            open={showRateShoppingSlideOver}
            setOpen={setShowRateShoppingSlideOver}
            containerStyle={"max-w-6xl"}
            isCrossIconVisible={false}
            title={
              <HeaderWithArrow
                headerTitle={
                  transportMode === "SP"
                    ? "Rate Shopping for Small Parcel"
                    : "Rate Shopping for LTL"
                }
                description=""
                arrowAction={() => setShowRateShoppingSlideOver(false)}
              />
            }>
            <NewRateShopping
              order={order}
              transportMode={transportMode}
              customer={order.customer}
              warehouse={order.warehouse}
              pallets={order.pallets}
              shippingAddress={toAddress}
              boxes={boxes}
              orderSource={order.source}
              storedTransportMode={order.typeOfShipment || ""}
              shipmentReference={order.orderId}
              onPalletSubmit={onPalletSubmit}
              onRateSelect={onRateSelect}
              rate={rate}
              preSelectedCarrierRate={
                rate && Object.keys(rate).length > 0
                  ? rate?.selectedCarrierRate
                  : order?.typeOfShipment === transportMode &&
                      order?.preSelectedCarrierRate
                    ? order.preSelectedCarrierRate
                    : null
              }
              tenant={tenant}
              validateAddress={order.validateAddress}
              carrierIntegration={order.carrierIntegration}
              selectedIncoterms={selectedIncoterms}
              selectedInsuranceProvider={selectedInsuranceProvider}
              insuredValue={insuredValue}
              setShowRateShoppingSlideOver={setShowRateShoppingSlideOver}
            />
          </SlideOverPanel>
          <div className="fixed bottom-0 left-0 right-0 flex items-center justify-end border-t border-gray-300 bg-white px-6 py-2 shadow-lg">
            <div className="flex items-center space-x-6">
              {actionsMenuItems.length > 0 ? (
                <CustomPopover
                  actions={actionsMenuItems}
                  defaultClasses=""
                  styles={{ bottom: "3rem" }}
                  bgColorVisible={false}
                  triggerElement={(open) => {
                    return (
                      <PrimaryButton
                        height="3rem"
                        width="7rem"
                        variant="secondary"
                        className="mt-2 border-none text-base font-medium"
                        onClick={() => {}}>
                        Actions{" "}
                        {open ? (
                          <ChevronDownIcon className="ml-1 h-6 w-6" />
                        ) : (
                          <ChevronUpIcon className="ml-1 h-6 w-6" />
                        )}
                      </PrimaryButton>
                    );
                  }}
                />
              ) : null}

              <PrimaryButton
                height="3rem"
                minWidth="7rem"
                maxWidth="15rem"
                variant="primary"
                className="ml-6 mt-2 text-lg font-medium"
                onClick={pauseActivity}
                disabled={isLabelGenerated}>
                Pause Packing
              </PrimaryButton>

              <PrimaryButton
                height="3rem"
                minWidth="7rem"
                maxWidth="15rem"
                variant="primary"
                className="ml-6 mt-2 text-lg font-medium"
                onClick={restartActivity}>
                Restart Packing
              </PrimaryButton>

              {!isLabelGenerated && (
                <PrimaryButton
                  height="3rem"
                  minWidth="7rem"
                  maxWidth="15rem"
                  variant="primary"
                  className="ml-6 mt-2 text-lg font-medium"
                  disabled={handleIsGenerateLabelsEnabled() === false}
                  onClick={() => {
                    if (confirmBoxes() && handleIsGenerateLabelsEnabled()) {
                      if (
                        selectedMethod === selectedMethodEnums.RATE_SHOPPING &&
                        rate &&
                        Object.keys(rate).length
                      ) {
                        const carrierSelectionData = {
                          orderInfo: {
                            id: order.id,
                            selectedCarrierServiceId:
                              rate?.selectedShipmentRateId,
                            selectedCarrierSource: rate?.selectedShipmentSource,
                            typeOfShipment:
                              rate?.transportMode || transportMode || "",
                            confirmedTypeOfShipment: true,
                            carrier: rate?.carrier,
                            incoterms: selectedIncoterms || null,
                            insuranceProvider:
                              selectedInsuranceProvider || null,
                            insuredValue: insuredValue || null,
                          },
                        };
                        updateCarrierInfoQuery.fetchData(carrierSelectionData);
                      } else if (
                        selectedMethod === "TrackingId" &&
                        (trackingNumber || order.trackingNumber)
                      ) {
                        const carrierSelectionData = {
                          orderInfo: {
                            id: order.id,
                            trackingNumber:
                              trackingNumber || order.trackingNumber,
                            bypassCarrierSelection: true,
                          },
                        };
                        updateCarrierInfoQuery.fetchData(carrierSelectionData);
                      }

                      setIsLabelGenerated(true);
                    }
                  }}>
                  Generate Labels
                </PrimaryButton>
              )}

              {isLabelGenerated && (
                <PrimaryButton
                  height="3rem"
                  minWidth="7rem"
                  maxWidth="15rem"
                  variant="primary"
                  className="ml-6 mt-2 text-lg font-medium"
                  onClick={confirmDropoff}>
                  Confirm
                </PrimaryButton>
              )}

              {!isLabelGenerated && (
                <PrimaryButton
                  height="3rem"
                  minWidth="7rem"
                  maxWidth="15rem"
                  variant="primary"
                  className="ml-6 mt-2 text-lg font-medium"
                  danger
                  onClick={() => {
                    appState.showConfirmation(
                      "Skip Rate Selection",
                      `Are you sure you want to skip rate selection?`,
                      async () => {
                        if (confirmBoxes()) {
                          const carrierSelectionData = {
                            orderInfo: {
                              id: order.id,
                              bypassCarrierSelection: true,
                            },
                          };
                          await updateCarrierInfoQuery.fetchData(
                            carrierSelectionData,
                          );

                          confirmDropoff();
                        }

                        appState.hideConfirmation();
                      },
                      appState.hideConfirmation,
                    );
                  }}>
                  Skip
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-20 flex w-full items-center justify-center">
            <div className="mt-24 flex space-x-4">
              <div className="max-h-xs rounded-2xl border bg-white shadow-lg">
                <CurrentPackBoxes
                  currentBatch={currentBatch}
                  order={order}
                  weight={weight}
                  setWeight={setWeight}
                  setWeightOfBox={setWeightOfBox}
                  unpackItem={unpackItem}
                  handleFindAndPack={handleFindAndPack}
                  isAddItemQuantityExceed={isAddItemQuantityExceed}
                  isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
                  itemsToUnPack={itemsToUnPack}
                  setItemsToUnPack={setItemsToUnPack}
                  handleFindAndUnpackItem={handleFindAndUnpackItem}
                  print2DBarcode={print2DBarcode}
                  checkFbaBoxWeightLimits={checkFbaBoxWeightLimits}
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex items-center justify-center space-x-4">
            <div
              className="w-64 cursor-pointer rounded-md bg-primaryAccent py-4 text-center text-2xl text-white"
              onClick={validateConfirmBoxes}>
              Confirm
            </div>
            <div
              className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={pauseActivity}>
              Pause Packing
            </div>
            <div
              className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={restartActivity}>
              Restart Packing
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ConfirmBoxContents;

const truncate = (str) => {
  return str && str.length > 100 ? str.substring(0, 100) + "..." : str;
};
