import { createApiThunk } from "#redux/ReduxUtils/graphqlApi";

import {
  GET_WAREHOUSES,
  GET_CUSTOMERS,
  GET_INTEGRATIONS,
  LIST_INBOUND_PLANS,
  DELETE_INBOUND_PLAN,
  GET_INVENTORY_FOR_RELEASE,
  GET_ELIGIBILITY_CHECK_RESULTS,
} from "#queries/index";
import {
  ACTIVATE_INBOUND_PLAN,
  CREATE_INBOUND_PLAN,
  SAVE_ORDER,
  START_ELIGIBILITY_CHECK,
} from "#mutations/index";

// Create thunks with custom data transformation
export const fetchWarehouses = createApiThunk(
  "fbaInbound/fetchWarehouses",
  GET_WAREHOUSES,
  (data) => data?.warehouses?.entities, // Transform to return only the "warehouses" field
);

export const fetchCustomers = createApiThunk(
  "fbaInbound/fetchCustomers",
  GET_CUSTOMERS,
  (data) => data?.customers?.entities, // Transform to return only the "customers" field
);

export const fetchIntegrations = createApiThunk(
  "fbaInbound/fetchIntegrations",
  GET_INTEGRATIONS,
  (data) => data?.integrations,
);

export const createFbaInboundPlan = createApiThunk(
  "fbaInbound/createFbaInboundPlan",
  CREATE_INBOUND_PLAN,
  (data) => data.createInboundPlan,
);

export const listFbaInboundPlans = createApiThunk(
  "fbaInbound/listFbaInboundPlans",
  LIST_INBOUND_PLANS,
  (data) => data?.listInboundPlans,
);

export const deleteInboundPlan = createApiThunk(
  "fbaInbound/deleteInboundPlan",
  DELETE_INBOUND_PLAN,
  (data) => data.deleteInboundPlan,
);

export const getInventoryForRelease = createApiThunk(
  "fbaInbound/getInventoryForRelease",
  GET_INVENTORY_FOR_RELEASE,
  (data) => data.getInventoryForRelease,
);

export const startEligibilityCheck = createApiThunk(
  "fbaInbound/startEligibilityCheck",
  START_ELIGIBILITY_CHECK,
  (data) => data.startEligibilityCheck,
);

export const getEligibilityCheckResults = createApiThunk(
  "fbaInbound/getEligibilityCheckResults",
  GET_ELIGIBILITY_CHECK_RESULTS,
  (data) => data.getEligibilityResults,
);

export const activateInboundPlan = createApiThunk(
  "fbaInbound/activateInboundPlan",
  ACTIVATE_INBOUND_PLAN,
  (data) => data.activateInboundPlan,
);

export const saveOrder = createApiThunk(
  "fbaInbound/saveOrder",
  SAVE_ORDER,
  (data) => data.saveOrder,
);
