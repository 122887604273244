import { useState, useRef, useEffect } from "react";
import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import _ from "lodash";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import ListViewProducts from "#components/catalogs/catalogTabs/ListViewProducts";
import GridViewProducts from "#components/catalogs/catalogTabs/GridViewProducts";
import ModalV3 from "#components/utils/ModalV3";
import DatePicker from "#newUiComponents/commons/DatePicker";
import SectionTabs from "./catalogTabs/SectionTabs";
import noFilterIcon from "#static/images/nofilter.png";

function truncate(str) {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
}

const ManageCatalogProducts = ({
  onListScroll,
  showListView,
  selectedCatalogProducts,
  getAllUnSelectedProduct,
  addRemoveProduct,
  actionsOnProduct,
  productCategories,
  isProductsLoading,
  keyword,
  selectedCategories,
  catalog,
  customerList,
  filters,
  fromAddEditCatalog = false,
  setCatalog = () => {},
  setAllVariantComponent = () => {},
  setShowLockInPriceInfo,
  calculateLockedInPrice = () => {},
  calculateMarginPrice = () => {},
}) => {
  const productStatusTab = [
    { name: "Selected Products", current: false },
    { name: "All Products", current: true },
  ];
  const [tabs, setTabs] = useState(productStatusTab);
  const [showLockedInPricePopUp, setShowLockedInPricePopUp] = useState(false);
  const [selectedMarginType, setSelectedMarginType] = useState("flat");
  const [selectedDiscountType, setSelectedDiscountType] = useState("flat");
  const [lockedPriceInfo, setLockedPriceInfo] = useState(null);
  const [productForLockedInPrice, setProductForLockedInPrice] = useState(null);
  const [collapsedForProduct, setCollapsedForProduct] = useState("");
  const [autoCurrentLockedInDate, setAutoCurrentLockedInDate] = useState(false);
  const refForInputChange = useRef(false);

  const isAllowedForLockedInDate = [
    lockedPriceInfo?.margin,
    lockedPriceInfo?.shippingHandling,
    lockedPriceInfo?.laborCost,
    lockedPriceInfo?.discount,
  ].some((value) => value !== null && value !== undefined && value !== "");
  const isValidLockedInPriceInfo = Boolean(
    isAllowedForLockedInDate && lockedPriceInfo?.lockedInDate,
  );

  useEffect(() => {
    if (refForInputChange?.current && productForLockedInPrice) {
      if (
        isAllowedForLockedInDate &&
        lockedPriceInfo &&
        !_.isEmpty(lockedPriceInfo)
      ) {
        const typeObject = {};
        if (lockedPriceInfo?.margin) {
          typeObject["marginType"] = selectedMarginType;
        }
        if (lockedPriceInfo?.discount) {
          typeObject["discountType"] = selectedDiscountType;
        }
        const modifiedLockedPrice = calculateLockedInPrice(
          productForLockedInPrice?.costPrice,
          { ...lockedPriceInfo, ...typeObject },
        );
        if (!_.isEqual(modifiedLockedPrice, lockedPriceInfo)) {
          setLockedPriceInfo({ ...modifiedLockedPrice });
        }
      } else {
        if (!_.isEmpty(lockedPriceInfo)) {
          setLockedPriceInfo({ ...lockedPriceInfo, lockedInPrice: "" });
        }
      }
      refForInputChange.current = false;
    }
  }, [
    lockedPriceInfo,
    refForInputChange,
    productForLockedInPrice,
    selectedMarginType,
    selectedDiscountType,
    isAllowedForLockedInDate,
  ]);

  useEffect(() => {
    if (!(showLockedInPricePopUp || collapsedForProduct)) return;

    let lockedInDate = "";

    if (isAllowedForLockedInDate) {
      lockedInDate = autoCurrentLockedInDate
        ? moment().format("DD-MM-YYYY")
        : productForLockedInPrice?.lockedPriceInfo?.lockedInDate ||
          moment().format("DD-MM-YYYY");
    }

    setLockedPriceInfo((prev) => ({
      ...prev,
      lockedInDate,
    }));
  }, [
    isAllowedForLockedInDate,
    productForLockedInPrice,
    collapsedForProduct,
    showLockedInPricePopUp,
    autoCurrentLockedInDate,
  ]);

  const onChangeLockPriceInfo = (e) => {
    setLockedPriceInfo({ ...lockedPriceInfo, [e.target.name]: e.target.value });
    refForInputChange.current = true;
  };

  const clearState = () => {
    showLockedInPricePopUp && setShowLockedInPricePopUp(false);
    lockedPriceInfo && setLockedPriceInfo(null);
    selectedDiscountType !== "flat" && setSelectedDiscountType("flat");
    selectedMarginType !== "flat" && setSelectedMarginType("flat");
    productForLockedInPrice && setProductForLockedInPrice(null);
    collapsedForProduct && setCollapsedForProduct("");
    setShowLockInPriceInfo(false);
    setAutoCurrentLockedInDate(false);
    if (refForInputChange?.current) refForInputChange.current = false;
  };

  const submitLockedInInfo = () => {
    const lockedPriceInfoObject = { ...lockedPriceInfo };
    const typeObject = {};
    if (lockedPriceInfoObject?.margin) {
      typeObject["marginType"] = selectedMarginType;
    }
    if (lockedPriceInfoObject?.discount) {
      typeObject["discountType"] = selectedDiscountType;
    }
    let selectedCatalogProductsData = [...selectedCatalogProducts];
    if (productForLockedInPrice) {
      const selectedProductIndx = selectedCatalogProductsData?.findIndex(
        (productObject) => productObject?.id === productForLockedInPrice?.id,
      );
      if (selectedProductIndx !== -1) {
        selectedCatalogProductsData[selectedProductIndx] = {
          ...selectedCatalogProductsData[selectedProductIndx],
          lockedPriceInfo: { ...lockedPriceInfoObject, ...typeObject },
        };
      }
    } else {
      selectedCatalogProductsData = selectedCatalogProductsData?.map(
        (productObject) => {
          const calculatedLockedPrice = calculateLockedInPrice(
            productObject?.costPrice,
            { ...lockedPriceInfoObject, ...typeObject },
          );
          return {
            ...productObject,
            lockedPriceInfo: {
              ...lockedPriceInfoObject,
              ...calculatedLockedPrice,
            },
          };
        },
      );
    }
    setCatalog({
      ...catalog,
      products: selectedCatalogProductsData,
    });
    clearState();
  };

  const setlockedInPriceForSingleProduct = (product, isFromList = false) => {
    setProductForLockedInPrice(product);
    if (product?.lockedPriceInfo) {
      setLockedPriceInfo({ ...product?.lockedPriceInfo });
      if (product?.lockedPriceInfo?.marginType === "percentage")
        setSelectedMarginType("percentage");
      if (product?.lockedPriceInfo?.discountType === "percentage")
        setSelectedDiscountType("percentage");
    }
    if (!isFromList) setShowLockedInPricePopUp(true);
    else setCollapsedForProduct(product?.id);
    setShowLockInPriceInfo(true);
  };

  const addRemoveProductHandler = (productId) => {
    if (productId) {
      const productIndex = selectedCatalogProducts?.findIndex(
        (productObject) => productObject?.id === productId,
      );
      if (productIndex !== -1) {
        const allSelectedCatalogProducts = [...selectedCatalogProducts];
        const { lockedPriceInfo, ...restProductData } =
          allSelectedCatalogProducts[productIndex];
        allSelectedCatalogProducts[productIndex] = { ...restProductData };
        setCatalog({
          ...catalog,
          products: allSelectedCatalogProducts,
        });
        clearState();
      }
    }
    addRemoveProduct(productId);
  };

  const lockedPriceInfoHandler = (value) => {
    if (refForInputChange?.current) refForInputChange.current = false;
    const typeObject = {};
    if (lockedPriceInfo?.margin) {
      typeObject["marginType"] = selectedMarginType;
    }
    if (lockedPriceInfo?.discount) {
      typeObject["discountType"] = selectedDiscountType;
    }
    const modifiedLockedPriceWithMargin = calculateMarginPrice(
      productForLockedInPrice?.costPrice,
      { ...lockedPriceInfo, ...typeObject, lockedInPrice: value },
    );
    setLockedPriceInfo({ ...modifiedLockedPriceWithMargin });
  };

  const LockedInPriceInfo = (
    <div className="max-w-content flex flex-col space-y-4">
      <div className="flex gap-4">
        <div className="flex w-[50%] flex-col">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Margin
          </label>
          <div className="flex">
            <div className="margin-option-dropdown w-[70px]">
              <AutocompleteDropdownV2
                options={[
                  { name: "$", value: "flat" },
                  { name: "%", value: "percentage" },
                ]}
                labelKey="name"
                valueKey="value"
                onChange={(value) => {
                  setSelectedMarginType(value);
                  onChangeLockPriceInfo({
                    target: {
                      name: "margin",
                      value: "",
                    },
                  });
                  setAutoCurrentLockedInDate(true);
                }}
                value={selectedMarginType}
                searchable={false}
                id="search"
              />
            </div>
            <input
              type="number"
              className="h-[41px] w-full rounded-br-lg rounded-tr-lg border-gray-300 pr-8 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              step="0.1"
              value={lockedPriceInfo?.margin}
              onChange={(e) => {
                if (
                  Object.is(Number(e.target.value), -0) ||
                  (selectedMarginType === "percentage" &&
                    (Number(e.target.value) < -100 ||
                      Number(e.target.value) > 100))
                ) {
                  return false;
                }
                onChangeLockPriceInfo({
                  target: {
                    name: "margin",
                    value: e.target.value,
                  },
                });
                setAutoCurrentLockedInDate(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "+" || e.key == "e") {
                  e.preventDefault();
                }
              }}
              min={selectedMarginType === "percentage" ? "-100" : ""}
              max={selectedMarginType === "percentage" ? "100" : ""}></input>
          </div>
        </div>
        <div className="flex w-[50%] flex-col">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Shipping & Handling
          </label>
          <div className="flex">
            <div className="flex w-[70px] items-center justify-center rounded-l border">
              $
            </div>
            <input
              type="number"
              className="w-full rounded-br-lg rounded-tr-lg border-gray-300 pr-8 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              step="0.1"
              min="0"
              value={lockedPriceInfo?.shippingHandling}
              onChange={(e) => {
                onChangeLockPriceInfo({
                  target: {
                    name: "shippingHandling",
                    value: e.target.value,
                  },
                });
                setAutoCurrentLockedInDate(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "+" || e.key == "e") {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex w-[50%] flex-col">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Labor Cost
          </label>
          <div className="flex">
            <div className="flex w-[70px] items-center justify-center rounded-l border">
              $
            </div>
            <input
              type="number"
              className="w-full rounded-br-lg rounded-tr-lg border-gray-300 pr-8 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              step="0.1"
              min="0"
              max="100"
              value={lockedPriceInfo?.laborCost}
              onChange={(e) => {
                onChangeLockPriceInfo({
                  target: {
                    name: "laborCost",
                    value: e.target.value,
                  },
                });
                setAutoCurrentLockedInDate(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "+" || e.key == "e") {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="flex w-[50%] flex-col">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Discount
          </label>
          <div className="flex">
            <div className="margin-option-dropdown w-[70px]">
              <AutocompleteDropdownV2
                options={[
                  { name: "$", value: "flat" },
                  { name: "%", value: "percentage" },
                ]}
                labelKey="name"
                valueKey="value"
                onChange={(value) => {
                  setSelectedDiscountType(value);
                  onChangeLockPriceInfo({
                    target: {
                      name: "discount",
                      value: "",
                    },
                  });
                  setAutoCurrentLockedInDate(true);
                }}
                value={selectedDiscountType}
                searchable={false}
                id="search"
              />
            </div>
            <input
              type="number"
              className="h-[41px] w-full rounded-br-lg rounded-tr-lg border-gray-300 pr-8 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              step="0.1"
              value={lockedPriceInfo?.discount}
              onChange={(e) => {
                if (
                  selectedDiscountType === "percentage" &&
                  Number(e.target.value) > 100
                )
                  return false;
                onChangeLockPriceInfo({
                  target: {
                    name: "discount",
                    value: e.target.value,
                  },
                });
                setAutoCurrentLockedInDate(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "+" || e.key == "e") {
                  e.preventDefault();
                }
              }}
              min="0"
              max={selectedDiscountType === "percentage" ? "100" : ""}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        {productForLockedInPrice && (
          <div className="flex w-[50%] flex-col">
            <label className="mb-1 block text-sm font-medium text-gray-700">
              Locked In Price
            </label>
            <div className="flex gap-2">
              <input
                type="number"
                className="h-[41px] w-full rounded-lg border-gray-300 pr-8 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-200"
                step="1"
                value={
                  lockedPriceInfo?.lockedInPrice === undefined
                    ? ""
                    : lockedPriceInfo?.lockedInPrice
                }
                onChange={(e) => {
                  if (Number(e.target.value) < 0) {
                    return false;
                  }
                  lockedPriceInfoHandler(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "+" || e.key == "e") {
                    e.preventDefault();
                  }
                }}
                min={0}></input>
            </div>
          </div>
        )}
        <div
          className={`flex ${productForLockedInPrice ? "w-[50%]" : "w-full"} flex-col`}>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Lock In Date
          </label>
          <DatePicker
            label=""
            infoText="Select Lock In Date"
            value={lockedPriceInfo?.lockedInDate}
            onChange={(date, _) => {
              onChangeLockPriceInfo({
                target: {
                  name: "lockedInDate",
                  value: date,
                },
              });
            }}
            format="DD-MM-YYYY"
            disabledDate={(current) => !current.isSame(moment(), "day")}
            error=""
            parentClasses="mb-4"
            labelClasses="text-sm font-medium text-gray-700 lock-in-date-picker"
            disabled={!isAllowedForLockedInDate}
          />
        </div>
      </div>
      <div className="flex flex-1 items-end justify-end space-x-2">
        <button
          className="cursor-pointer whitespace-nowrap rounded-lg px-4 py-2 text-gray-600 hover:text-gray-800"
          onClick={clearState}>
          Cancel
        </button>
        <button
          className="cursor-pointer whitespace-nowrap rounded-lg bg-primaryAccent px-4 py-2 text-white hover:bg-primaryAccent disabled:cursor-not-allowed disabled:bg-gray-400"
          onClick={submitLockedInInfo}
          disabled={!isValidLockedInPriceInfo}>
          {`Apply to ${!productForLockedInPrice ? "All Products" : "Product"}`}
        </button>
      </div>
    </div>
  );

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    const currentTabInfo = {};
    if (currentTab === "Selected Products") {
      currentTabInfo.onListScroll = null;
      currentTabInfo.products = selectedCatalogProducts;
      currentTabInfo.selectedProducts = [...selectedCatalogProducts]?.map(
        (productObj) => productObj.id,
      );
    } else {
      currentTabInfo.onListScroll = onListScroll;
      currentTabInfo.products = getAllUnSelectedProduct;
      currentTabInfo.selectedProducts = [];
    }
    return currentTabInfo.products.length > 0 ? (
      <div
        onScroll={currentTabInfo.onListScroll}
        className="h-96 overflow-y-scroll">
        {showListView ? (
          <ListViewProducts
            products={currentTabInfo.products}
            selectProduct={addRemoveProductHandler}
            selectedProducts={currentTabInfo.selectedProducts}
            allRowsSelected={false}
            selectAllRows={() => {}}
            getCatalog={() => {}}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={false}
            selectAllDisabled={false}
            isRemoveSelectAllCheckBox={true}
            showSalesAndCostPrice={true}
            customerList={customerList}
            filters={filters}
            fromAddEditCatalog={fromAddEditCatalog}
            setlockedInPriceForSingleProduct={(product, isFromList) => {
              clearState();
              setlockedInPriceForSingleProduct(product, isFromList);
            }}
            collapsedForProduct={collapsedForProduct}
            collapsedLockedInPriceInfo={clearState}
            LockedInPriceInfo={LockedInPriceInfo}
            setAllVariantComponent={setAllVariantComponent}
            openCatalogAddEditModal={catalog}
          />
        ) : (
          <GridViewProducts
            products={currentTabInfo.products}
            selectProduct={addRemoveProductHandler}
            selectedProducts={currentTabInfo.selectedProducts}
            allRowsSelected={false}
            selectAllRows={() => {}}
            getCatalog={() => {}}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={false}
            selectAllDisabled={true}
            isRemoveSelectAllCheckBox={true}
            showSalesAndCostPrice={true}
            filters={filters}
            fromAddEditCatalog={fromAddEditCatalog}
            setlockedInPriceForSingleProduct={(product, isFromList) => {
              clearState();
              setlockedInPriceForSingleProduct(product, isFromList);
            }}
            setAllVariantComponent={setAllVariantComponent}
            openCatalogAddEditModal={catalog}
          />
        )}
      </div>
    ) : (
      <>
        {isProductsLoading && (
          <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 pt-8 text-center">
            <LoadingIndicator shouldShowOnPage={false} />
          </div>
        )}
        <div className="flex-col items-center justify-center p-16 text-center">
          <img src={noFilterIcon} className="mx-auto w-64" />
          <div className="mt-4 text-center font-inter text-2xl font-semibold text-black">
            {currentTab === "Selected Products"
              ? "There is no any selected product"
              : "No products available"}
          </div>
          <div className="mt-4 text-center font-inter text-2xl font-normal text-[#717679]">
            {currentTab === "Selected Products"
              ? "Select product from all products tab"
              : "Add products by using the search and product category above"}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!tabs.find((tabObject) => tabObject.name === "All Products")?.current) {
      setTabs(
        [...tabs].map((t) => {
          t.current = t.name === "All Products";
          return t;
        }),
      );
    }
  }, [keyword, selectedCategories]);

  useEffect(() => {
    if (catalog?.id && catalog?.products?.length > 0) {
      if (
        !tabs.find((tabObject) => tabObject.name === "Selected Products")
          ?.current
      ) {
        setTabs(
          [...tabs].map((t) => {
            t.current = t.name === "Selected Products";
            return t;
          }),
        );
      }
    }
  }, [catalog?.id]);

  return (
    <>
      <div className="mt-2 h-full flex-col">
        <div className="flex items-center justify-between">
          <SectionTabs tabs={tabs} setTabs={setTabs} />
          {selectedCatalogProducts?.length > 0 &&
            tabs.find((tab) => tab.current).name === "Selected Products" && (
              <button
                className="flex items-center space-x-1 text-sm text-primaryAccent hover:text-primaryAccent"
                onClick={() => {
                  clearState();
                  setShowLockedInPricePopUp(true);
                }}>
                <span>Set Locked In Price for All Products</span>
                <InformationCircleIcon className="h-5 w-5 cursor-pointer text-primaryAccent hover:text-primaryAccent" />
              </button>
            )}
        </div>
        <div className="h-full">{renderTabContent()}</div>
      </div>
      <ModalV3
        isOpen={showLockedInPricePopUp}
        onClose={() => {
          clearState();
          setShowLockedInPricePopUp(false);
        }}
        title="Set Locked In Price for All Products">
        <div className="min-w-3xl modalV3 p-4">
          <div className="flex items-center justify-between pb-4 font-inter">
            <span className="font-inter text-2xl font-bold text-[#454A4F]">
              {`Set Locked In Price for ${productForLockedInPrice ? truncate(productForLockedInPrice?.name) : "All Products"}`}
            </span>
            <XIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => setShowLockedInPricePopUp(false)}
            />
          </div>
          {LockedInPriceInfo}
        </div>
      </ModalV3>
    </>
  );
};

export default ManageCatalogProducts;
