import React, { useEffect } from "react";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import CheckBox from "#newUiComponents/commons/CheckBox";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import CustomNotification from "#newUiComponents/commons/CustomNotification";

const FbaProductRow = ({
  record,
  setFilteredInventory,
  setSelectedInventoryIds,
  selectedInventoryIds,
}) => {
  const notify = CustomNotification();
  let rowClasses = "bg-white";
  let errorIcon = null;

  // Access restriction details from the record
  const { restrictionDetails } = record;
  const isEligible = restrictionDetails ? restrictionDetails?.isEligible : true;
  const errorMessages = restrictionDetails
    ? restrictionDetails?.errors?.filter((error) => error.severity === "ERROR")
    : [];

  // If the product is not eligible and has an error message, display the first one
  if (!isEligible && errorMessages?.length > 0) {
    rowClasses = "bg-red-50"; // Red background to indicate an error
    const errorMessage = errorMessages[0].details;
    errorIcon = (
      <Tooltip title={errorMessage}>
        <ExclamationIcon className="ml-2 h-6 w-6 text-red-500" />
      </Tooltip>
    );
  }

  const columnClasses = `whitespace-nowrap px-6 py-6 text-base text-gray-600`;

  // Automatically deselect ineligible items from selectedInventoryIds
  useEffect(() => {
    if (!isEligible) {
      setSelectedInventoryIds((prevIds) =>
        prevIds.filter((id) => id !== record.id),
      );
    }
  }, [isEligible, record.id, setSelectedInventoryIds]);

  const handleExpirationDateChange = (selectedDate) => {
    const selectedOption = record.expirationDates.find(
      (date) => date.expirationDate === selectedDate,
    );

    setFilteredInventory((prevInventory) =>
      prevInventory.map((item) =>
        item.id === record.id
          ? {
              ...item,
              selectedExpirationDate: selectedDate,
              selectedQuantity: selectedOption ? selectedOption.quantity : 0,
              unitsToShip: selectedOption ? selectedOption.quantity : 0,
            }
          : item,
      ),
    );
  };

  const handleUnitsToShipChange = (e) => {
    let unitsToShip = e.target.value === "" ? "" : parseInt(e.target.value, 10);

    // Enforce max limit based on selectedQuantity
    if (unitsToShip > record.selectedQuantity) {
      unitsToShip = record.selectedQuantity;
      notify.error(`Cannot exceed available units: ${record.selectedQuantity}`);
    }

    setFilteredInventory((prevInventory) =>
      prevInventory.map((item) =>
        item.id === record.id ? { ...item, unitsToShip } : item,
      ),
    );
  };

  return (
    <tr className={`${rowClasses} hover:bg-gray-100`}>
      <td
        className={`${columnClasses} sticky left-0 z-auto bg-white shadow-md`}>
        <CheckBox
          checked={selectedInventoryIds.includes(record.id)}
          disabled={!isEligible} // Disable checkbox if item is not eligible
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedInventoryIds([...selectedInventoryIds, record.id]);
            } else {
              setSelectedInventoryIds(
                selectedInventoryIds.filter((id) => id !== record.id),
              );
            }
          }}
        />
      </td>
      <td className={columnClasses}>
        <div className="flex items-center space-x-2">
          <img
            src={record.image || FALLBACK_IMAGE_URL}
            alt={record.name}
            className="h-10 w-10 rounded"
          />
          <div>
            <Tooltip title={record.name}>
              <p
                className="max-w-xs truncate font-semibold"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {record.name}
              </p>
            </Tooltip>
            <p className="text-sm text-gray-500">SKU: {record.sku || "-"}</p>
            <p className="text-sm text-gray-500">ASIN: {record.asin || "-"}</p>
          </div>
        </div>
      </td>
      <td className={columnClasses}>
        <AutoCompleteSingleSelect
          options={
            record.expirationDates.length > 0
              ? record.expirationDates.map((date) => ({
                  label: date.expirationDate,
                  value: date.expirationDate,
                  quantity: date.quantity,
                }))
              : [{ label: "-", value: "-", quantity: 0 }]
          }
          value={record.selectedExpirationDate || ""}
          shouldDeselect={false}
          onChange={handleExpirationDateChange}
          labelKey="label"
          valueKey="value"
          placeholder="Exp Date"
          labelText=""
          parentClasses="w-48"
        />
      </td>
      <td className={columnClasses}>
        <CustomBadge
          color="green"
          label={
            record.selectedQuantity ? record.selectedQuantity.toString() : "0"
          }
          textSize="text-xs"
          className="px-2 py-1"
        />
      </td>
      <td className={columnClasses}>
        <div className="flex items-center">
          <input
            type="number"
            value={record.unitsToShip ?? ""}
            disabled={!isEligible}
            min={0}
            onChange={handleUnitsToShipChange}
            className="w-16 rounded border border-gray-300 px-2"
          />
          {errorIcon && <div className="ml-2">{errorIcon}</div>}
        </div>
      </td>
    </tr>
  );
};

export default FbaProductRow;
