import React, { useEffect, useContext } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { dummyInboundPlans, planStatusesEnums } from "./FbaUtils";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
import { useDispatch, useSelector } from "react-redux";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import { DotsVerticalIcon, TrashIcon } from "@heroicons/react/outline";
import { Dropdown } from "antd";
import { deleteInboundPlan } from "#redux/FbaInbound/fbaInboundActions";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { useHistory } from "react-router-dom";
import { AppStateContext } from "#contexts/appState";

const FbaInboundTable = ({
  listInboundPlansData,
  setListInboundPlansData,
  searchTerm,
  setSearchTerm,
  setCurrentPage,
  currentPage,
  fetchPlans,
  pageSize,
  setSelectedPlan,
}) => {
  const { listInboundPlans } = useSelector((state) => state.fbaInbound);
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);

  const columns = [
    {
      key: "inboundPlanId",
      title: "Inbound Plan ID",
      isSort: true,
      render: (id, row) => (
        <span
          onClick={() => {
            history.push("/fbaInbound/" + row.id);
          }}
          className="cursor-pointer font-semibold text-primaryAccent">
          {id}
        </span>
      ),
    },
    {
      key: "id",
      title: "Amazon Inbound Plan ID",
      isSort: true,
      render: (_, row) => {
        return <span>{row?.inboundDetails?.amazonInboundPlanId || "-"}</span>;
      },
    },
    {
      key: "createdAt",
      title: "Creation Date",
      isSort: false,
      render: (date) => {
        const formattedDate = dayjs(date).format("MMM Do YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      key: "status",
      title: "Status",
      isSort: true,
      render: (status) => {
        let badgeProps;
        switch (status) {
          case planStatusesEnums.ACTIVE.key:
            badgeProps = {
              color: "green",
              label: planStatusesEnums.ACTIVE.label,
            };
            break;
          case planStatusesEnums.VOIDED.key:
            badgeProps = {
              color: "red",
              label: planStatusesEnums.VOIDED.label,
            };
            break;
          case planStatusesEnums.SHIPPED.key:
            badgeProps = {
              color: "blue",
              label: planStatusesEnums.SHIPPED.label,
            };
            break;
          case planStatusesEnums.DRAFT.key:
            badgeProps = {
              color: "gray",
              label: planStatusesEnums.DRAFT.label,
            };
            break;
          case planStatusesEnums.ELIGIBILITY_CHECK_PENDING.key:
            badgeProps = {
              color: "yellow",
              label: planStatusesEnums.ELIGIBILITY_CHECK_PENDING.label,
            };
            break;
          case planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.key:
            badgeProps = {
              color: "blue",
              label: planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.label,
            };
            break;
          default:
            badgeProps = { color: "gray", label: "Unknown Status" };
        }
        return (
          <CustomBadge
            {...badgeProps}
            textSize="text-xs"
            className="px-2 py-1"
          />
        );
      },
    },
    {
      key: "totalProducts",
      title: "Total Products",
      isSort: false,
      render: (_, row) => {
        const totalProducts = row?.items?.length;
        return (
          <span className="rounded-full bg-blue-100 px-3 py-1 text-xs text-blue-600">
            {totalProducts}
          </span>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, row) => (
        <Dropdown
          trigger={["hover"]}
          menu={{
            items: handleGetActionsOnTable(row),
          }}
          overlayClassName="z-5"
          disabled={handleGetActionsOnTable(row).length === 0}
          dropdownRender={({ props }) => {
            return (
              <div
                style={{ width: "10rem" }}
                key={row.id}
                className="rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
                {props.items.map((item) => (
                  <div
                    key={item.key}
                    onClick={item.onClick}
                    className="cursor-pointer p-3 hover:bg-hoverHighlight hover:text-primaryAccent">
                    {item.label}
                  </div>
                ))}
              </div>
            );
          }}
          placement="topRight"
          arrow>
          <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
        </Dropdown>
      ),
    },
  ];

  const handleLoadingStateOfTable = () => {
    if (
      (listInboundPlans?.isPending && listInboundPlansData.length) ||
      !listInboundPlans?.isPending
    ) {
      return listInboundPlansData;
    } else if (listInboundPlans?.isPending && !listInboundPlansData.length) {
      return null;
    } else {
      return [];
    }
  };

  const handleDeletePlan = (plan) => {
    appState.showNewConfirmation(
      "Confirm",
      "Are you sure you want to delete this inbound plan ?",
      () => {
        const variables = {
          id: plan.id,
        };
        const successCallback = (data) => {
          notify.success(data?.message || "deleted successfully");
          setCurrentPage(1);
          fetchPlans(1);
        };
        const errorCallback = (error) => {
          notify.error(error);
        };
        dispatch(
          deleteInboundPlan({ variables, successCallback, errorCallback }),
        );
      },
      appState.hideConfirmation,
    );
  };
  const handleGetActionsOnTable = (plan) => {
    return [
      {
        key: 1,
        label: "Delete Plan",
        icon: <TrashIcon />,
        onClick: () => {
          handleDeletePlan(plan);
        },
      },
    ];
  };

  useEffect(() => {
    if (listInboundPlans.isFulfilled) {
      setListInboundPlansData((prevData) => {
        const existingIds = new Set(prevData.map((item) => item.id));
        const uniqueNewEntities = listInboundPlans?.data?.entities.filter(
          (item) => !existingIds.has(item.id),
        );
        return [...prevData, ...uniqueNewEntities];
      });
    }
  }, [listInboundPlans]);

  return (
    <CustomTable
      columns={columns}
      data={handleLoadingStateOfTable()}
      onSort={(key, direction) => {
        console.log("Sort by:", key, direction);
      }}
      isSearchable
      isFilters
      isPagination
      isLoadMore
      isInfiniteScroll
      loadingData={listInboundPlans?.isPending}
      currentPage={currentPage}
      setCurrentPage={(page) => setCurrentPage(page)}
      pageSize={pageSize}
      searchTerm={searchTerm}
      //   noResultsText={list}
      setPageSize={() => {}}
      onChangeSearchTerm={setSearchTerm}
      totalDataCount={listInboundPlans?.data?.metaData?.total}
      pageSizeOptions={["5", "10", "20"]}
      totalDataCountLable="Total Plans: "
    />
  );
};

export default FbaInboundTable;
