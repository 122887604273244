const isDelmarTenant = (subdomain) => {
  return ["delmar", "delmarca", "delmartest"].includes(subdomain);
};

const isProprepTenant = (subdomain) => {
  return ["proprep"].includes(subdomain);
};

const isFeatherRiverColdTenant = (subdomain) => {
  return ["featherrivercold"].includes(subdomain);
};

const isManyMoonsTenant = (subdomain) => {
  return ["manymoons"].includes(subdomain);
};

const isVanEngelenTenant = (subdomain) => {
  return ["vanengelen", "vetest"].includes(subdomain);
};

const isWaseTenant = (subdomain) => {
  return ["wase"].includes(subdomain);
};

const isOntelTenant = (subdomain) => {
  return ["ontel-test", "ontel"].includes(subdomain);
};

const isValidTenantForPickingBatchLabelGenerationFunctionality = (
  subdomain,
) => {
  return ["ontel-test", "ontel", "napaevino", "nev-test"].includes(subdomain);
};

// Add More tenants in future if required.

export {
  isDelmarTenant,
  isProprepTenant,
  isFeatherRiverColdTenant,
  isManyMoonsTenant,
  isVanEngelenTenant,
  isWaseTenant,
  isOntelTenant,
  isValidTenantForPickingBatchLabelGenerationFunctionality,
};
