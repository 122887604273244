import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const DATE_RANGES = {
  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
  Yesterday: [
    dayjs().subtract(1, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ],
  "Last 7 Days": [
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last 30 Days": [
    dayjs().subtract(30, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Previous Month": [
    dayjs().subtract(1, "month").startOf("month"),
    dayjs().subtract(1, "month").endOf("month"),
  ],
};

const ALL_DATE_RANGES = {
  ...DATE_RANGES,
  "Last 90 Days": [
    dayjs().subtract(90, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last 180 Days": [
    dayjs().subtract(180, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last Year": [
    dayjs().subtract(1, "year").startOf("day"),
    dayjs().endOf("day"),
  ],
};

delete ALL_DATE_RANGES["Previous Month"];
export const ANALYTICS_DATE_RANGES = ALL_DATE_RANGES;

export const getDateInDDMMYYYYFormate = (selectedTimePeriod) => {
  return [
    dayjs(selectedTimePeriod[0]).format("DD-MM-YYYY"),
    dayjs(selectedTimePeriod[1]).format("DD-MM-YYYY"),
  ];
};

export const getNumberOfDaysDifference = (fromDate, toDate) => {
  const startDate = dayjs(fromDate, "DD-MM-YYYY");
  const endDate = dayjs(toDate, "DD-MM-YYYY");
  return endDate.diff(startDate, "day");
};

export const getComparePeriodDate = (fromToDate, period) => {
  const startDate = dayjs(fromToDate[0], "DD-MM-YYYY");
  const endDate = dayjs(fromToDate[1], "DD-MM-YYYY");
  if (period === "Previous Period") {
    const daysDifference = getNumberOfDaysDifference(startDate, endDate);
    return [
      startDate.subtract(daysDifference + 1, "days").format("DD-MM-YYYY"),
      endDate.subtract(daysDifference + 1, "days").format("DD-MM-YYYY"),
    ];
  }
  return [
    startDate.subtract(1, "year").format("DD-MM-YYYY"),
    endDate.subtract(1, "year").format("DD-MM-YYYY"),
  ];
};

export const getDateBasedOnComparePeriod = (givenDate, daysDiff, period) => {
  const date = dayjs(dayjs(givenDate).format("DD-MM-YYYY"), "DD-MM-YYYY");
  if (period === "Previous Period") {
    return date.subtract(daysDiff + 1, "days").format("YYYY-MM-DD");
  }
  return date.subtract(1, "year").format("YYYY-MM-DD");
};

export const getStartDateWithHhMmSsFormate = (date) =>
  dayjs(date, "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD HH:mm:ss");

export const getEndDateWithHhMmSsFormate = (date) =>
  dayjs(date, "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");
