import { Skeleton } from "antd";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import noFilterIcon from "#static/images/nofilter.png";
import { getValue } from "#utils/catalogsAndAnalyticsUtils";

const SalesOrderRevenueTable = ({
  columns = [],
  data = [],
  isInfiniteLoading = true,
  pageNumber = 0,
  pageSize = 5,
  totalDataCount = 0,
  infiniteLoaderRef = null,
  isLoading = false,
  noDataFoundMessage = "No Data Available",
}) => {
  return (
    <div
      style={{ height: "calc(70vh - 56px)" }}
      className="relative w-full overflow-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="sticky top-0 z-5 bg-gray-50 shadow-sm">
          <tr>
            {columns?.map((column, index) => {
              return (
                <th
                  className={
                    "px-6 py-6 text-left text-base font-normal capitalize tracking-wider text-[#717679]"
                  }
                  key={`regions-revenue-${index}`}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full divide-y divide-gray-200 bg-white">
          {data?.length > 0 ? (
            <>
              {data.map((dataObject, index) => {
                const totalRevenue = getValue(dataObject?.totalRevenue);
                const orderVolume = getValue(dataObject?.orderVolume);
                const orderFrequency = getValue(dataObject?.orderFrequency);
                return (
                  <tr
                    className="relative hover:bg-gray-50"
                    key={`product-performance-${dataObject?.id}-${index}`}>
                    <td className="whitespace-nowrap px-6 py-6 text-base text-gray-600">
                      <div className="max-w-sm truncate text-base font-semibold text-gray-600">
                        {dataObject?.["cityName"] ||
                          dataObject?.["customerName"]}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`$${totalRevenue}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`${orderVolume}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`${orderFrequency}`}</div>
                    </td>
                  </tr>
                );
              })}
              {isInfiniteLoading &&
                data &&
                totalDataCount > pageNumber * pageSize && (
                  <tr>
                    <td colSpan={"100%"}>
                      <div
                        ref={infiniteLoaderRef}
                        className="absolute left-0 right-0 items-center justify-center">
                        <LoadingIndicator shouldShowOnPage={false} />
                      </div>
                    </td>
                  </tr>
                )}
            </>
          ) : !isLoading ? (
            <tr>
              <td colSpan={"100%"}>
                <div className="flex-col items-center justify-center p-2 text-center">
                  <img src={noFilterIcon} className="mx-auto w-32" />
                  <div className="mt-4 text-center text-xl font-semibold text-gray-600">
                    {noDataFoundMessage}
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            isLoading &&
            !data?.length &&
            Array.from({ length: pageSize }).map((_, index) => (
              <tr key={`tr-${index}`} className="hover:bg-gray-50">
                {columns.map((column, indx) => (
                  <td
                    key={`td-${indx}`}
                    className={`whitespace-nowrap px-6 py-6 text-base text-gray-900`}>
                    <Skeleton.Input active />
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SalesOrderRevenueTable;
