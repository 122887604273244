import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment-timezone";

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center",
  },
  Title: {
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#f3f3f3",
    fontWeight: "bold",
  },
  tableCol: {
    flexGrow: 1,
    flexBasis: "16.66%",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    top: 20,
    right: 20,
    textAlign: "right",
  },
  qcInformationContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  qcInformationTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 20,
  },
  qcInformation: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  qcInformationRow: {
    display: "flex",
    gap: 10,
    flexGrow: 1,
  },
});

const QCInformation = () => {
  return (
    <View style={styles.qcInformationContainer}>
      <Text style={styles.qcInformationTitle}>QC Information:</Text>

      <View style={styles.qcInformation}>
        <View style={styles.qcInformationRow}>
          <Text>Checked by: ____________</Text>
          <Text>Picked by: ____________</Text>
          <Text>Loaded by: ____________</Text>
          <Text>Shipped by: ____________</Text>
        </View>
        <View style={styles.qcInformationRow}>
          <Text>Start time: ____________</Text>
          <Text>End time: ____________</Text>
          <Text># of employees: ____________</Text>
          <Text># of leads: ____________</Text>
        </View>
      </View>
    </View>
  );
};

// Component to generate the PDF content
const PickListPdf = ({ batch, pickingScanningDisabled }) => {
  const { warehouse, customer, workingList } = batch;

  const formattedData = workingList.map((item) => ({
    binLocation: item.binLocation,
    sku: item.sku,
    skuName: item.name,
    lotId: item.lotId,
    expirationDate: item.bestByDate
      ? moment(item.bestByDate).format("YYYY-MM-DD")
      : null,
    skuQty: item.quantity,
    skuUOM: item.formFactor,
  }));

  return (
    <Document>
      <Page style={styles.page} size="A4">
        {/* Header section */}
        <View style={{ marginBottom: 10 }}>
          <Text style={styles.header}>Pick List</Text>
          <Text>
            <Text style={styles.Title}>Order IDs: </Text>
            {batch.attributes.humanReadableOrderIds.join(", ")}
          </Text>
        </View>

        <View fixed>
          <View style={{ marginBottom: 10 }}>
            <Text>
              <Text style={styles.Title}>BatchID: </Text>
              {batch.id}
            </Text>
            <Text>
              <Text style={styles.Title}>Warehouse: </Text>
              {warehouse}
            </Text>
            <Text>
              <Text style={styles.Title}>Customer: </Text>
              {customer}
            </Text>
            <Text>
              <Text style={styles.Title}>Number of orders: </Text>
              {batch.attributes.humanReadableOrderIds.length}
            </Text>

            {batch.user && (
              <Text>
                <Text style={styles.Title}>Picker: </Text>
                {batch.user}
              </Text>
            )}
          </View>
        </View>

        {/* Fixed Table Header Section */}
        <View fixed>
          {/* Table Header */}
          <View style={[styles.tableRow, styles.tableHeader]} wrap={false}>
            <Text style={[styles.tableCol, styles.tableCell]}>
              Bin Location
            </Text>
            <Text style={[styles.tableCol, styles.tableCell]}>SkuID (UOM)</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>
              Product Name
            </Text>
            <Text style={[styles.tableCol, styles.tableCell]}>
              Item Identifier
            </Text>
            <Text style={[styles.tableCol, styles.tableCell]}>QTY</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>
              Pick Tracker
            </Text>
          </View>
        </View>

        {/* Table Body */}
        <View style={styles.table}>
          {formattedData.map((row, index) => (
            <View style={styles.tableRow} key={index} wrap={false}>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row.binLocation}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row.sku} {` (${row.skuUOM})`}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row.skuName}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row.lotId && (
                  <>
                    <Text style={styles.Title}>lotId: </Text>
                    {row.lotId}
                    {"\n"}
                  </>
                )}
                {row.expirationDate && (
                  <>
                    <Text style={styles.Title}>Expiry Date: </Text>
                    {row.expirationDate}
                  </>
                )}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row.skuQty}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}></Text>
            </View>
          ))}
        </View>

        {/* Add page number */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        {/* QC Information - for Ontel only */}
        {pickingScanningDisabled && <QCInformation />}
      </Page>
    </Document>
  );
};

// Process the batch data
const processBatchData = (
  pickingBatch,
  warehousesList,
  customersList,
  usersMap,
) => {
  if (!pickingBatch.workingList) {
    // Create workingList from items if it doesn't exist
    const itemList = pickingBatch.items.map((item) => ({
      id: item.id,
      sku: item.sku,
      name: item.productName,
      binLocation: item.binLocation,
      quantity: item.quantity,
      attributes: item.attributes,
      formFactor: item.formFactor,
      lotId: item.lotId,
      bestByDate: item.bestByDate,
    }));
    const groupedItems = _.groupBy(
      itemList,
      (item) => `${item.sku}-${item.binLocation}`,
    );
    const mergedItems = Object.values(groupedItems).map((group) => {
      const firstItem = group[0];
      const totalQuantity = _.sumBy(group, "quantity");
      return {
        ...firstItem,
        quantity: totalQuantity,
      };
    });
    pickingBatch.workingList = mergedItems;
  }

  // Replace the IDs with the actual names
  const warehouseId = pickingBatch?.warehouse?.[0];
  pickingBatch.warehouse = warehousesList.find(
    (item) => item.id === warehouseId,
  )?.name;

  const customersName = pickingBatch.customer.map((customerId) => {
    return customersList.find((item) => item.id === customerId)?.name;
  });
  pickingBatch.customer = customersName.join(", ");

  pickingBatch.user = usersMap[pickingBatch.user]?.name;

  return pickingBatch;
};

// Function to download the PDF
export const downloadPickListPdf = async (
  batch,
  warehousesList,
  customersList,
  usersMap,
  pickingScanningDisabled = false,
) => {
  processBatchData(batch, warehousesList, customersList, usersMap);
  const blob = await pdf(
    <PickListPdf
      batch={batch}
      pickingScanningDisabled={pickingScanningDisabled}
    />,
  ).toBlob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `PickList-${batch.id}.pdf`;
  link.click();
};
