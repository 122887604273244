import React, { useState, useCallback, useEffect } from "react";
import { Popover } from "antd";
import { SearchIcon } from "@heroicons/react/outline";
import _ from "lodash";

const BoxTypePopover = ({
  boxTypes = [],
  onSelectBox,
  isOpen,
  setIsOpen,
  trigger,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setDebouncedSearchTerm(value);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchTerm);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  const filteredBoxTypes = boxTypes.filter((box) =>
    box.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
  );

  const content = (
    <div className="w-64">
      {/* Search input */}
      <div className="mb-3 flex items-center rounded-md border border-gray-300 px-3 py-2">
        <SearchIcon className="h-4 w-4 text-gray-400" />
        <input
          type="text"
          placeholder="Search box types..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="ml-2 w-full border-none p-0 text-sm focus:outline-none focus:ring-0"
        />
      </div>

      {/* Box types list */}
      <div className="max-h-64 overflow-y-auto">
        {(
          [...filteredBoxTypes, { name: "Add Custom", isCustom: true }] || [
            { name: "Add Custom", isCustom: true },
          ]
        ).map((boxType) => (
          <div
            key={boxType.name}
            className="cursor-pointer rounded-md px-3 py-2 hover:bg-gray-100"
            onClick={() => {
              onSelectBox(boxType);
              setSearchTerm("");
              setDebouncedSearchTerm("");
              setIsOpen(false);
            }}>
            <p>{boxType.isCustom ? boxType.name : `${boxType.name}`}</p>
            {!boxType.isCustom && (
              <p className="mt-2">{`${boxType.length} * ${boxType.width} * ${boxType.height} (in), ${boxType.weight} lbs`}</p>
            )}
          </div>
        ))}
        {filteredBoxTypes.length === 0 && (
          <div className="px-3 py-2 text-sm text-gray-500">
            No box types found
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      placement="bottom">
      {trigger}
    </Popover>
  );
};

export default BoxTypePopover;
