import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  ANALYTICS_DATE_RANGES,
  getComparePeriodDate,
  getDateInDDMMYYYYFormate,
  getNumberOfDaysDifference,
} from "#utils/dateRanges";
import { analyticsCategoriescolumns } from "#constants/analytics";
import CategoriesAnalyticsTable from "#components/catalogs/catalogTabs/CategoriesAnalyticsTable";
import CustomPagination from "#newUiComponents/commons/CustomPagination";

const CategoriesPerformance = () => {
  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const defaultSelectedDate = timePeriods[3];
  const getStartEndDate = getDateInDDMMYYYYFormate(
    ANALYTICS_DATE_RANGES[defaultSelectedDate],
  );
  let getDaysDiff = getNumberOfDaysDifference(
    getStartEndDate[0],
    getStartEndDate[1],
  );
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  if (analyticsData?.startEndDate) {
    getDaysDiff = getNumberOfDaysDifference(
      analyticsData.startEndDate[0],
      analyticsData.startEndDate[1],
    );
  }
  const getStartAndEndDate = analyticsData?.startEndDate || getStartEndDate;
  const getSelectedColumn =
    analyticsData?.categoriesPerformance?.selectedColumn ||
    analyticsCategoriescolumns[1];
  const getDataOrder = analyticsData?.categoriesPerformance?.toggle || false;
  const redirectBack = "/analytics";
  const categoryData = [
    {
      name: "Rings",
      revenue: 300000,
      orderVolume: 5000,
      products: [
        {
          name: "Diamond Solitaire Ring",
          sku: "RING-001",
          revenue: 150000,
          orderVolume: 5000,
        },
        {
          name: "Pearl Strand Necklace",
          sku: "NECK-001",
          revenue: 120000,
          orderVolume: 3000,
        },
        {
          name: "Tennis Bracelet",
          sku: "BRAC-001",
          revenue: 100000,
          orderVolume: 4000,
        },
        {
          name: "Sapphire Drop Earrings",
          sku: "EAR-001",
          revenue: 80000,
          orderVolume: 2000,
        },
      ],
    },
    {
      name: "Necklaces",

      revenue: 250000,
      orderVolume: 4000,
    },
    {
      name: "Bracelets",
      revenue: 200000,
      orderVolume: 3000,
    },
    {
      name: "Earrings",
      revenue: 180000,
      orderVolume: 2000,
    },
  ];
  const onPageChangeHandler = () => {};

  const selectColumnHandler = () => {};

  useEffect(() => {
    return () => {
      if (
        !window.location.pathname.includes("/categoriesPerformance") &&
        !window.location.pathname.includes("/analytics")
      ) {
        localStorage.removeItem("analyticsData");
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="mx-auto max-w-7xl">
        <div className="mb-6 flex items-center gap-4">
          <Link
            className="flex items-center gap-1 text-gray-600 hover:text-gray-900"
            to={redirectBack}>
            <ArrowLeftIcon className="h-5 w-5" />
            {`Back to Dashboard`}
          </Link>
          <h1 className="text-2xl font-bold text-gray-900">All Categories</h1>
        </div>
        <div className="rounded-lg bg-white shadow">
          <div className="rounded-lg bg-white p-6 shadow">
            <div className="mb-4 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <h2 className="text-lg font-semibold">
                  Category Level Performance
                </h2>
              </div>
            </div>
            <CategoriesAnalyticsTable
              data={categoryData}
              selectColumnHandler={selectColumnHandler}
              daysDifference={(getDaysDiff && Number(getDaysDiff)) || 0}
            />
          </div>
          <div className="product-performance-pagination flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <CustomPagination
              showDataCountOnly={false}
              currentPage={1}
              pageSize={2}
              totalDataCount={categoryData.length}
              onPageChange={(page) => onPageChangeHandler(page)}
              currentDataCount={2 || 0}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPerformance;
