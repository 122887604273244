import PropTypes from "prop-types";
import RateShopping from "#components/rateShopping/RateShopping";
import { useState } from "react";
import Toggle from "#components/utils/Toggle";

/**
 * Divides a list of order items into boxes based on a maximum quantity per box.
 *
 * @param {Array} orderItems - An array of order items, where each item is an object containing order details.
 * @param {number} maxQuantityPerBox - The maximum quantity of items that can be placed in a single box.
 * @returns {Array} An array of boxes, where each box is represented as an array of order items.
 */
const divideProductsIntoBoxes = (orderItems, maxQuantityPerBox) => {
  const boxes = [];
  let currentBox = [];

  for (const orderItem of orderItems) {
    let remainingQuantity = orderItem?.formFactors?.[0].quantity || 0;

    while (remainingQuantity > 0) {
      if (currentBox.length === 0) {
        // Start a new box if the current one is empty
        currentBox.push({
          sku: orderItem.sku,
          fnSku: orderItem.fnSku,
          quantity: Math.min(remainingQuantity, maxQuantityPerBox),
          bestByDate: null,
        });
        remainingQuantity -= Math.min(remainingQuantity, maxQuantityPerBox);
      } else {
        // Check if the current box has space for more of the same orderItem or a new orderItem
        const currentBoxQuantity = currentBox.reduce(
          (total, item) => total + item.quantity,
          0,
        );

        if (currentBoxQuantity + remainingQuantity <= maxQuantityPerBox) {
          // Add the remaining quantity of the same orderItem to the current box
          currentBox.push({
            sku: orderItem.sku,
            fnSku: orderItem.fnSku,
            quantity: remainingQuantity,
            bestByDate: null,
          });
          remainingQuantity = 0;
        } else {
          // Close the current box and start a new one
          boxes.push([...currentBox]);
          currentBox = [];
        }
      }
    }
  }

  // Add the last box, if not empty
  if (currentBox.length > 0) {
    boxes.push([...currentBox]);
  }

  return boxes;
};

const OrderRateShopping = ({
  order,
  tenant,
  nonEditableFieldsOfOrder,
  onChangeDropdown,
  onChange,
  setSelectedOrder,
  setIsFetchingRates,
  showTrackingNumberInput = false,
}) => {
  const [selectedRate, setSelectedRate] = useState(null);
  const [alreadyHaveCarrierInfo, setAlreadyHaveCarrierInfo] = useState(false);

  let toAddress = null;
  if (order?.shipmentPlan?.ShipToAddress) {
    const shipToAddress = order.shipmentPlan.ShipToAddress;
    toAddress = {
      line1: shipToAddress.AddressLine1,
      line2: shipToAddress.AddressLine2,
      city: shipToAddress.City,
      name: shipToAddress.Name,
      country: shipToAddress.CountryCode,
      email: "",
      phone: "",
      postalCode: shipToAddress.PostalCode,
      state: shipToAddress.StateOrProvinceCode,
    };
  } else {
    toAddress = {
      line1: order?.shippingAddress?.line1,
      line2: order?.shippingAddress?.line2,
      city: order?.shippingAddress?.city,
      name: order?.shippingAddress?.name,
      country: order?.shippingAddress?.country,
      email: order?.shippingAddress?.email,
      phone: order?.shippingAddress?.phone,
      postalCode: order?.shippingAddress?.zip,
      state: order?.shippingAddress?.state,
    };
  }

  // Calculate total box count from selectedBoxes with boxCount property
  const totalBoxCount = tenant?.settings?.isOutboundPlanningEnabled
    ? order?.selectedBoxes?.reduce((acc, box) => {
        return acc + (box.boxCount || 1);
      }, 0)
    : order?.estimatedBoxes?.reduce((acc, estimatedBox) => {
        return acc + estimatedBox.boxCount;
      }, 0);

  const totalProductQuantity = order?.orderLineItems?.reduce(
    (totalQty, orderLineItem) => {
      return totalQty + (orderLineItem?.formFactors?.[0].quantity || 0);
    },
    0,
  );

  const maxItemsPerBox = Math.ceil(totalProductQuantity / (totalBoxCount || 1));

  const itemsInBoxes = divideProductsIntoBoxes(
    order?.orderLineItems || [],
    maxItemsPerBox,
  );

  const getFormattedBoxesFromPackPlan = () => {
    return (order?.selectedBoxes || [])?.map((box, index) => {
      // Account for boxCount if it exists
      return {
        // Access dimensions from the boxDimension object
        length: box.boxDimension?.length,
        width: box.boxDimension?.width,
        height: box.boxDimension?.height,
        // Calculate weight per box
        weight: box.totalWeight || 0,
        name: box.boxName || `Box-${boxIndex + 1}`,
        items:
          box.items?.map((item) => {
            return {
              sku: item.sku,
              quantity: item.quantity,
            };
          }) || [],
      };
    });
  };

  const boxes = tenant?.settings?.isOutboundPlanningEnabled
    ? getFormattedBoxesFromPackPlan()
    : [];

  let boxIndex = 0;
  // Process selectedBoxes in case of pack plan
  if (!tenant?.settings?.isOutboundPlanningEnabled) {
    order?.estimatedBoxes?.map((estimatedBox, index) => {
      for (
        let boxCountIndex = 0;
        boxCountIndex < (estimatedBox?.boxCount || 0);
        boxCountIndex++
      ) {
        if (itemsInBoxes[boxIndex]) {
          boxes.push({
            length: estimatedBox.length,
            width: estimatedBox.width,
            height: estimatedBox.height,
            weight:
              Math.round(
                (estimatedBox.totalWeight / estimatedBox?.boxCount) * 100,
              ) / 100,
            name: `Box - ${boxIndex + 1}`,
            items: itemsInBoxes[boxIndex] || [],
          });
          boxIndex++;
        }
      }
    });
  }

  const onRateSelect = (selectedRate) => {
    setSelectedRate(selectedRate);
    const selectedCarrierRate = selectedRate?.selectedCarrierRate;
    setSelectedOrder({
      ...order,
      selectedCarrierRateData: {
        preSelectedCarrierRate: selectedCarrierRate && {
          carrier: selectedCarrierRate?.carrier,
          currency: selectedCarrierRate?.currency,
          estimated_delivery_date: selectedCarrierRate?.estimated_delivery_date,
          estimated_delivery_days: selectedCarrierRate?.estimated_delivery_days,
          id: selectedCarrierRate?.id,
          price: selectedCarrierRate?.price,
          service: selectedCarrierRate?.service,
          source: selectedCarrierRate?.source,
          type: selectedCarrierRate?.type,
        },
        typeOfShipment: selectedRate?.transportMode,
        carrier: selectedRate?.carrier?.split("-")[0]?.trim(),
        carrierService: selectedRate?.carrier?.split("-")[1]?.trim(),
      },
    });
  };

  return (
    <div className="flex-colpx-2 space-y-4 p-2">
      <h1 className="mb-4 text-xl font-bold">Carrier Details</h1>
      <div className="mb-5 flex">
        {showTrackingNumberInput === true ? (
          <>
            <div className="grow">
              <div className="inline-block cursor-pointer">
                <input
                  name="carrier-source"
                  type="radio"
                  id="rate-shopping-choice"
                  className="mr-4 cursor-pointer text-2C7695"
                  checked={!alreadyHaveCarrierInfo}
                  onChange={() => setAlreadyHaveCarrierInfo(false)}
                />
                <label
                  className="cursor-pointer"
                  htmlFor="rate-shopping-choice">
                  Select Carrier
                </label>
              </div>
            </div>
            <div className="grow">
              <div className="inline-block cursor-pointer">
                <input
                  name="carrier-source"
                  type="radio"
                  id="custom-carrier-choice"
                  className="mr-4 cursor-pointer text-2C7695"
                  checked={alreadyHaveCarrierInfo}
                  onChange={() => {
                    onRateSelect(null);
                    setAlreadyHaveCarrierInfo(true);
                  }}
                />
                <label
                  className="cursor-pointer"
                  htmlFor="custom-carrier-choice">
                  Already have carrier selected
                </label>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {(!alreadyHaveCarrierInfo && (
        <>
          <div className="flex h-40v w-full justify-center overflow-scroll border-2">
            <div className="mx-auto w-full rounded-lg border-solid bg-white px-10 py-5">
              <RateShopping
                customer={order.customer}
                warehouse={order.warehouse}
                shippingAddress={toAddress}
                boxes={boxes}
                orderSource={order.source}
                storedTransportMode={order.typeOfShipment}
                shipmentReference={order.orderId}
                onRateSelect={onRateSelect}
                selectedRate={selectedRate}
                showLTLRates={["FBA"].includes(order.source)}
                preSelectedCarrierRate={order.preSelectedCarrierRate}
                tenant={tenant}
                setIsFetchingRates={setIsFetchingRates}
                validateAddress={order.toValidAddress}
                carrierIntegration={order.carrierIntegration}
              />
            </div>
          </div>
          {["uat", "tosprep"].includes(tenant?.subdomain) && (
            <div>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <div className="text-lg">Is Insurance Required</div>
                  <Toggle
                    enabled={order.insuranceRequired}
                    setEnabled={(e) => {
                      onChangeDropdown("insuranceRequired", e);
                    }}
                    disabled={nonEditableFieldsOfOrder.includes(
                      "insuranceRequired",
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )) || (
        <>
          <div className="p-4">
            <label className="text-sm">Tracking Number</label>
            <input
              type="text"
              name="trackingNumber"
              className={`border-1 block w-full border-2C7695 bg-transparent p-2 font-montserrat text-lg placeholder-gray-400 focus:outline-none`}
              value={order.trackingNumber}
              onChange={onChange}
            />
            <label className="pl-3 text-sm text-gray-500">
              Select this option if you already have a selected a shipping
              carrier, the tracking number can be found in the invoice.
            </label>
          </div>
        </>
      )}
    </div>
  );
};

OrderRateShopping.propTypes = {
  order: PropTypes.shape({
    warehouse: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    selectedBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        boxName: PropTypes.string,
        boxDimension: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
          unit: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
          PropTypes.shape({
            sku: PropTypes.string,
            quantity: PropTypes.number,
          }),
        ),
        totalWeight: PropTypes.number,
        weightUnit: PropTypes.string,
        boxCount: PropTypes.number,
      }),
    ),
    shippingAddress: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
  }),
  tenant: PropTypes.shape({
    subdomain: PropTypes.string,
  }),
  nonEditableFieldsOfOrder: PropTypes.arrayOf(PropTypes.string),
  onChangeDropdown: PropTypes.func,
  onChange: PropTypes.func,
  setIsFetchingRates: PropTypes.func,
  showTrackingNumberInput: PropTypes.bool.isRequired,
};

export default OrderRateShopping;
