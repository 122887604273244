import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import CustomMultiSelect from "#newUiComponents/commons/CustomMultiSelect";

const PackerOrderListView = ({
  orders,
  checkOrderById,
  filters = {},
  setFilters,
  submitFilters,
  ordersData,
  checkPagination,
  sortingStations,
}) => {
  const checkForInboundPlanId = (orders || []).some(
    (order) => order.inboundPlanId,
  );
  const columns = [
    {
      key: "orderId",
      title: "Order ID",
      render: (orderId) => (
        <span className="cursor-pointer font-semibold text-primaryAccent">
          {orderId}
        </span>
      ),
    },
    checkForInboundPlanId && {
      key: "inboundPlanId",
      title: "Inbound Plan Id",
      render: (planId) => (
        <span
          onClick={() => {
            window.open(`/fbaInbound/${planId}`, "_blank");
          }}
          className="cursor-pointer font-semibold text-primaryAccent underline">
          {planId}
        </span>
      ),
    },
    // {
    //   key: "inboundPlanId",
    //   title: "Amazon Inbound Plan ID",
    //   render: (inboundPlanId) => <span>{inboundPlanId}</span>,
    // },
    {
      key: "source",
      title: "Source",
    },
    {
      key: "orderDate",
      title: "Order Date and Time",
      render: (orderDate) =>
        orderDate ? moment(orderDate).format("MMM DD, YYYY - hh:mm A") : "N/A",
    },
    sortingStations?.length > 0
      ? {
          key: "batchAttributes.requestedDropoffSortingStation",
          title: "Sorting Station",
          render: (sortingStation) => <span>{sortingStation ?? "-"}</span>,
        }
      : null,
    {
      key: "action",
      title: "Work on this",
      render: (_, row) => {
        const isDisabled =
          row.inboundPlanId &&
          (row.source === "Hopstack" || row.source === "HOPSTACK_FBA") &&
          row.subStatus === "PICKING_COMPLETED";
        const icon = (
          <ArrowCircleRightIcon
            className={`h-6 w-6 cursor-${isDisabled ? "not-allowed" : "pointer"} ${isDisabled ? "text-gray-300" : "text-primaryAccent"}`}
            onClick={() => !isDisabled && checkOrderById(row.orderId)}
          />
        );

        return isDisabled ? (
          <Tooltip title="This is an FBA order. Prepping needs to be completed.">
            {icon}
          </Tooltip>
        ) : (
          icon
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="mx-auto h-70v w-full p-4">
      {/* Sorting Station Filter */}
      {sortingStations?.length > 0 && (
        <div className="mb-4">
          <CustomMultiSelect
            options={sortingStations.map((station) => ({
              id: station.name,
              name: station.name,
            }))}
            selectedValues={filters.sortingStations ?? []}
            onChange={(selected) => {
              setFilters(
                {
                  ...filters,
                  sortingStations: selected?.map((val) => val.id) || null,
                },
                ordersData.perPage,
                1,
                true,
              );
            }}
            placeholder="Filter by Sorting Station"
            maxDisplayTags={5}
            className="w-64"
          />
        </div>
      )}

      {/* Table */}
      <div className="h-full">
        <CustomTable
          columns={columns}
          data={orders}
          isSearchable
          isFilters={false}
          isPagination
          totalDataCount={ordersData.total}
          setPageSize={(size) => {
            setFilters({ ...filters }, size, 1, true);
          }}
          currentPage={ordersData.pageNumber}
          pageSize={ordersData.perPage}
          searchTerm={filters.keyword || ""}
          setCurrentPage={(page) => checkPagination(page)}
          pageSizeOptions={[10, 15, 25, 50]}
          onSort={(key, direction) => console.log(`Sorted ${key} ${direction}`)}
          onChangeSearchTerm={(searchTerm) => {
            if (searchTerm === "") {
              setFilters(
                {
                  ...filters,
                  keyword: searchTerm,
                },
                ordersData.perPage,
                ordersData.pageNumber,
                true,
              );
              return;
            }
            setFilters({
              ...filters,
              keyword: searchTerm,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") submitFilters();
          }}
        />
      </div>
    </div>
  );
};

PackerOrderListView.propTypes = {
  orders: PropTypes.array.isRequired,
  checkOrderById: PropTypes.func.isRequired,
  filters: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  submitFilters: PropTypes.func.isRequired,
  ordersData: PropTypes.shape({
    total: PropTypes.number,
    perPage: PropTypes.number,
    pageNumber: PropTypes.number,
  }).isRequired,
  checkPagination: PropTypes.func.isRequired,
};

export default PackerOrderListView;
