import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import { GET_CATALOGS_ANALYTICS_CUSTOMERS_REVENUE } from "#queries/index";
import {
  getPercentageDetail,
  getUnixTimeStamp,
  getValue,
} from "#utils/catalogsAndAnalyticsUtils";
import {
  getEndDateWithHhMmSsFormate,
  getStartDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import CommonTable from "#components/common/CommonTable";
import { analyticsCustomerscolumns } from "#constants/analytics";
import noFilterIcon from "#static/images/nofilter.png";

const CustomersByRevenue = ({
  isProductLoading = false,
  product = null,
  startEndDate,
  selectedComparePeriods,
  comparePeriodDate,
}) => {
  const getCatalogAnalyticsCustomersRevenueQuery = useQuery(
    GET_CATALOGS_ANALYTICS_CUSTOMERS_REVENUE,
  );
  const appState = useContext(AppStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customersByRevenue, setCustomersByRevenue] = useState(null);

  const getCatalogAnalyticsCustomersRevenue = async () => {
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (product?.sku) {
      if (startEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(startEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(startEndDate[1]),
        );
      }
      if (selectedComparePeriods) {
        comparePeriodData.comparePeriod = true;
        comparePeriodData["compareStartEndDate"] = [
          getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
          getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
        ];
      }
      if (customersByRevenue) setCustomersByRevenue(null);
      setIsLoading(true);
      try {
        const response =
          await getCatalogAnalyticsCustomersRevenueQuery.fetchData({
            perPage: 5,
            pageNumber: 1,
            filters: {
              productId: product.id,
              sku: product.sku,
              customer: [],
              startEndDate: selectedStartEndDate,
              sortType: "top",
              performanceType: "revenue",
              ...comparePeriodData,
            },
          });
        if (response?.data?.catalogAnalyticsCustomerRevenue) {
          setCustomersByRevenue(response.data.catalogAnalyticsCustomerRevenue);
        }
      } catch (e) {
        appState.setAlert(
          `error while fetching customers revenue: ${e?.message}`,
          "error",
          5000,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (product && startEndDate?.length > 0) {
      getCatalogAnalyticsCustomersRevenue();
    }
  }, [startEndDate, selectedComparePeriods, product]);

  useEffect(() => {
    if (!product) {
      setCustomersByRevenue(null);
    }
  }, [product]);

  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-3">
          <h2 className="text-[17px] font-semibold">
            Top 5 Customers by Revenue
          </h2>
        </div>
        <Link
          className="flex items-center gap-1 text-sm font-semibold text-primaryAccent hover:text-primaryAccent"
          to="/productRevenueByCustomers">
          View More
          <ArrowRightIcon className="h-4 w-4" style={{ strokeWidth: 3 }} />
        </Link>
      </div>
      <div className="customer-by-revenue-table mt-5 w-full">
        <CommonTable headers={analyticsCustomerscolumns}>
          <tbody className="divide-y divide-gray-200 bg-white font-inter">
            {customersByRevenue?.primaryCustomerAnalytics?.length > 0 ? (
              customersByRevenue?.primaryCustomerAnalytics.map(
                (dataObject, index) => {
                  const totalRevenue = getValue(dataObject?.totalRevenue);
                  const orderVolume = getValue(dataObject?.orderVolume);
                  const orderFrequency = getValue(dataObject?.orderFrequency);
                  let comparedTotalRevenue = 0;
                  let comparedOrderVolume = 0;
                  let comparedOrderFrequency = 0;
                  const getPercentageDetailObject = {
                    totalRevenue: {
                      profit: 0.0,
                      isLoss: false,
                    },
                    orderVolume: {
                      profit: 0.0,
                      isLoss: false,
                    },
                    orderFrequency: {
                      profit: 0.0,
                      isLoss: false,
                    },
                  };
                  if (selectedComparePeriods) {
                    let compareProductObject = null;
                    if (
                      customersByRevenue?.comparedCustomerAnalytics?.length > 0
                    ) {
                      compareProductObject =
                        customersByRevenue?.comparedCustomerAnalytics?.find(
                          (compareDataObject) =>
                            compareDataObject?.customerName ===
                            dataObject?.customerName,
                        );
                    }
                    comparedTotalRevenue = getValue(
                      compareProductObject?.totalRevenue,
                    );
                    getPercentageDetailObject.totalRevenue =
                      getPercentageDetail(
                        totalRevenue,
                        comparedTotalRevenue,
                        true,
                      );
                    comparedOrderVolume = getValue(
                      compareProductObject?.orderVolume,
                    );
                    getPercentageDetailObject.orderVolume = getPercentageDetail(
                      orderVolume,
                      comparedOrderVolume,
                      true,
                    );
                    comparedOrderFrequency = getValue(
                      compareProductObject?.orderFrequency,
                    );
                    getPercentageDetailObject.orderFrequency =
                      getPercentageDetail(
                        orderFrequency,
                        comparedOrderFrequency,
                        true,
                      );
                  }
                  const totalRevenueProfit =
                    getPercentageDetailObject?.totalRevenue?.profit;
                  const totalOrderVolume =
                    getPercentageDetailObject?.orderVolume?.profit;
                  const totalOrderFrequency =
                    getPercentageDetailObject?.orderFrequency?.profit;
                  return (
                    <tr
                      key={`customers-${index}`}
                      className="cursor-pointer hover:bg-gray-100">
                      <td className="whitespace-nowrap px-6 py-4">
                        <div className="text-base font-semibold text-gray-600">
                          {dataObject?.customerName}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                        <div>{totalRevenue}</div>
                        {selectedComparePeriods && (
                          <div className="mt-1 flex items-center gap-2">
                            <span className="text-xs text-gray-600">{`vs $${comparedTotalRevenue}`}</span>
                            <span
                              className={`text-xs font-medium ${
                                getPercentageDetailObject?.totalRevenue?.isLoss
                                  ? "text-red-600"
                                  : totalRevenueProfit === "NA"
                                    ? "text-gray-600"
                                    : "text-green-600"
                              }`}>{`${getPercentageDetailObject?.totalRevenue?.isLoss || totalRevenueProfit === "NA" ? "" : "+"}${totalRevenueProfit}${totalRevenueProfit !== "NA" ? "%" : ""}`}</span>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                        <div>{orderVolume}</div>
                        {selectedComparePeriods && (
                          <div className="mt-1 flex items-center gap-2">
                            <span className="text-xs text-gray-600">{`vs ${comparedOrderVolume}`}</span>
                            <span
                              className={`text-xs font-medium ${
                                getPercentageDetailObject?.orderVolume?.isLoss
                                  ? "text-red-600"
                                  : totalOrderVolume === "NA"
                                    ? "text-gray-600"
                                    : "text-green-600"
                              }`}>{`${getPercentageDetailObject?.orderVolume?.isLoss || totalOrderVolume === "NA" ? "" : "+"}${totalOrderVolume}${totalOrderVolume !== "NA" ? "%" : ""}`}</span>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                        <div>{orderFrequency}</div>
                        {selectedComparePeriods && (
                          <div className="mt-1 flex items-center gap-2">
                            <span className="text-xs text-gray-600">{`vs ${comparedOrderFrequency}`}</span>
                            <span
                              className={`text-xs font-medium ${
                                getPercentageDetailObject?.orderFrequency
                                  ?.isLoss
                                  ? "text-red-600"
                                  : totalOrderFrequency === "NA"
                                    ? "text-gray-600"
                                    : "text-green-600"
                              }`}>{`${getPercentageDetailObject?.orderFrequency?.isLoss || totalOrderFrequency === "NA" ? "" : "+"}${totalOrderFrequency}${totalOrderFrequency !== "NA" ? "%" : ""}`}</span>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                },
              )
            ) : !isLoading && !isProductLoading ? (
              <tr>
                <td colSpan={"100%"}>
                  <div className="flex-col items-center justify-center p-2 text-center">
                    <img src={noFilterIcon} className="mx-auto w-32" />
                    <div className="mt-4 text-center text-xl font-semibold text-gray-600">
                      {"No Customers Found"}
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={"100%"}>
                  <div className="px-6 py-3 text-left text-sm font-medium tracking-wider text-[#717679]">
                    Loading...
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </CommonTable>
      </div>
    </div>
  );
};

export default CustomersByRevenue;
