// store.js
import { configureStore } from "@reduxjs/toolkit";
import fbaInboundReducer from "./FbaInbound/fbaInboundSlice";

const store = configureStore({
  reducer: {
    fbaInbound: fbaInboundReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
