import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_PICKER_BATCH,
  GET_NEW_PICKER_BATCH,
  GET_TOTES,
  GET_WORKFLOW_BY_ATTRIBUTES,
  GET_SKU,
  GET_USERS,
  FETCH_SPECIFIC_BATCH,
  GET_PICKING_BATCHES,
} from "#queries";
import {
  SCAN_PICKER_BARCODE,
  CONFIRM_PICK_ITEM,
  CONFIRM_PICKER_DROPOFF,
  SKIP_PICK_ITEM,
  CONFIRM_PICK_ITEMS,
  SET_EXECUTED_PICKING_PLAN,
  SKIP_PICKER_TOTE,
} from "#mutations";
import _, { set } from "lodash";
import { AppStateContext } from "#contexts/appState";
import { downloadPickListPdf } from "#components/pickings/PickListPdf";
import { AuthContext } from "#contexts/auth";

const ALERT_TIMEOUT_IN_MS = 5000;

const withOrderPickerLogic = (WrappedComponent) => {
  return (props) => {
    // state declarations
    const [customer, setCustomer] = useState(undefined);
    const [workflow, setWorkflow] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [loadingFirstTime, setLoadingFirstTime] = useState(true);
    const [displayScan, setDisplayScan] = useState(null);
    const [batchfilters, setBatchfilters] = useState({});

    // context declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // query declarations
    const confirmPickItemQuery = useQuery(CONFIRM_PICK_ITEM);
    const confirmDropoffQuery = useQuery(CONFIRM_PICKER_DROPOFF);
    const totesQuery = useQuery(GET_TOTES);
    const currentBatchQuery = useQuery(GET_CURRENT_PICKER_BATCH);
    const getCurrentBatchQuery = useQuery(GET_CURRENT_PICKER_BATCH);
    const getWorkflowByAttributesQuery = useQuery(GET_WORKFLOW_BY_ATTRIBUTES);
    const skipItemQuery = useQuery(SKIP_PICK_ITEM);
    const getNewBatchQuery = useQuery(GET_NEW_PICKER_BATCH);
    const scanBarcodeQuery = useQuery(SCAN_PICKER_BARCODE);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const confirmAllItemsPickedQuery = useQuery(CONFIRM_PICK_ITEMS);
    const fetchBatchForPickListPDF = useQuery(FETCH_SPECIFIC_BATCH);
    const usersQuery = useQuery(GET_USERS);
    const pickingBatchesQuery = useQuery(GET_PICKING_BATCHES);
    const setExecutedPickingPlanQuery = useQuery(SET_EXECUTED_PICKING_PLAN);
    const skipToteQuery = useQuery(SKIP_PICKER_TOTE);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [batchesData, setBatchesData] = useState({
      batches: [],
      total: 0,
      pageNumber: 1,
      perPage: 25,
      filters: {
        status: ["UNPROCESSED"],
      },
    });

    const pickingScanningDisabled =
      appState.tenant?.settings?.activities?.picking?.scanningDisabled || false;

    const [usersMap, setUsersMap] = useState({});

    useEffect(() => {
      usersQuery.fetchData({
        perPage: 1000,
        paginated: false,
      });
    }, []);

    useEffect(() => {
      if (currentItem?.executedPickingPlan) {
        const executedPickingPlanOnBatch =
          currentBatch.currentItem.executedPickingPlan;
        const executedPickingPlanOnItem = currentItem.executedPickingPlan;
        if (!_.isEqual(executedPickingPlanOnBatch, executedPickingPlanOnItem)) {
          updateExecutedPickingPlan();
        }
      }
    }, [currentItem?.executedPickingPlan]);

    const updateExecutedPickingPlan = async () => {
      appState.setLoading();
      const response = await setExecutedPickingPlanQuery.fetchData({
        id: currentBatch.id,
        executedPickingPlan: currentItem.executedPickingPlan,
      });
      if (response.data.setExecutedPickingPlan.message) {
        appState.setAlert(
          response.data.setExecutedPickingPlan.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.removeLoading();
      }

      if (setExecutedPickingPlanQuery.error) {
        appState.setAlert(
          setExecutedPickingPlanQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.removeLoading();
      }
    };

    useEffect(() => {
      if (usersQuery.data?.users?.entities) {
        let usersData = usersQuery.data.users.entities;
        let usersDataMap = {};
        usersData.forEach((user) => {
          usersDataMap[`${user.id}`] = user;
        });
        setUsersMap(usersDataMap);
      }
    }, [usersQuery.data]);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      totesQuery.fetchData();
      getWorkflowByAttributesQuery.fetchData({
        attributes: { name: "Picking" },
      });
    }, []);

    useEffect(() => {
      if (auth && auth.user && auth.user.warehousesList) {
        const warehouses = auth.user.warehousesList;
        if (warehouses.length === 1) {
          setBatchfilters({
            warehouse: { value: warehouses.map((i) => i.id)[0] },
          });

          if (pickingScanningDisabled) {
            const updatedFilters = {
              ...batchesData.filters,
              warehouse: warehouses.map((i) => i.id)[0],
            };
            setBatchesData({
              ...batchesData,
              filters: updatedFilters,
            });
            searchPickingBatches(updatedFilters);
          }
        }
      }
    }, []);

    useEffect(() => {
      if (getWorkflowByAttributesQuery.data) {
        setWorkflow(getWorkflowByAttributesQuery.data.getWorkflowByAttributes);
      }

      if (getWorkflowByAttributesQuery.error) {
        setWorkflow(null);
      }

      if (getWorkflowByAttributesQuery.loading) {
        appState.setLoading();
      }
    }, [
      getWorkflowByAttributesQuery.loading,
      getWorkflowByAttributesQuery.error,
      getWorkflowByAttributesQuery.data,
    ]);

    useEffect(() => {
      currentBatchQuery.fetchData();
    }, []);

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      }
      if (
        currentBatchQuery.data &&
        currentBatchQuery.data.getCurrentPickerBatch
      ) {
        handleSetBatchDetails(currentBatchQuery.data.getCurrentPickerBatch);
        appState.removeLoading();
      }

      if (currentBatchQuery.error) {
        setCurrentBatch(null);
        appState.removeLoading();
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);

    // get picking batches
    useEffect(() => {
      if (pickingBatchesQuery.loading) {
        appState.setLoading();
      }
      if (pickingBatchesQuery.data) {
        appState.removeLoading();
        setBatchesData({
          ...batchesData,
          batches: pickingBatchesQuery.data.getPickingBatches.entities,
          total: pickingBatchesQuery.data.getPickingBatches.total,
        });
      }
      if (pickingBatchesQuery.error) {
        appState.setAlert(pickingBatchesQuery.error.message, "error", 5000);
        appState.removeLoading();
      }
    }, [
      pickingBatchesQuery.loading,
      pickingBatchesQuery.data,
      pickingBatchesQuery.error,
    ]);

    // get new batch query
    useEffect(() => {
      if (getNewBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          getNewBatchQuery.data &&
          getNewBatchQuery.data.getNewPickerBatch.message
        ) {
          handleSetBatchDetails(getNewBatchQuery.data.getNewPickerBatch.batch);
        } else if (getNewBatchQuery.error) {
          appState.setAlert(getNewBatchQuery.error.message, "error", 5000);
        }
        appState.removeLoading();
      }
    }, [
      getNewBatchQuery.loading,
      getNewBatchQuery.data,
      getNewBatchQuery.error,
    ]);

    useEffect(() => {
      if (confirmPickItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmPickItemQuery.data &&
          confirmPickItemQuery.data.confirmPickItem.message
        ) {
          appState.setAlert(
            confirmPickItemQuery.data.confirmPickItem.message,
            "success",
            5000,
          );
          // currentBatchQuery.fetchData();
          handleSetBatchDetails(
            confirmPickItemQuery.data.confirmPickItem.batch,
          );
        }
        appState.removeLoading();
      }

      if (confirmPickItemQuery.error) {
        appState.setAlert(confirmPickItemQuery.error.message, "error", 5000);
        currentBatchQuery.fetchData();
      }
    }, [
      confirmPickItemQuery.loading,
      confirmPickItemQuery.data,
      confirmPickItemQuery.error,
    ]);

    useEffect(() => {
      if (confirmAllItemsPickedQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmAllItemsPickedQuery.data &&
          confirmAllItemsPickedQuery.data.confirmPickItems.message
        ) {
          appState.setAlert(
            confirmAllItemsPickedQuery.data.confirmPickItems.message,
            "success",
            5000,
          );
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }

      if (confirmAllItemsPickedQuery.error) {
        appState.setAlert(
          confirmAllItemsPickedQuery.error.message,
          "error",
          5000,
        );
        currentBatchQuery.fetchData();
      }
    }, [
      confirmAllItemsPickedQuery.loading,
      confirmAllItemsPickedQuery.data,
      confirmAllItemsPickedQuery.error,
    ]);

    useEffect(() => {
      if (confirmDropoffQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffQuery.data &&
          confirmDropoffQuery.data.confirmPickerDropoff.message
        ) {
          appState.setAlert(
            confirmDropoffQuery.data.confirmPickerDropoff.message,
            "success",
            5000,
          );
          currentBatchQuery.fetchData();
          if (pickingScanningDisabled) {
            searchPickingBatches();
          }
          setLoadingFirstTime(false);
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffQuery.loading,
      confirmDropoffQuery.data,
      confirmDropoffQuery.error,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanPickerBarcode &&
          scanBarcodeQuery.data.scanPickerBarcode.message
        ) {
          appState.setAlert(
            scanBarcodeQuery.data.scanPickerBarcode.message,
            "success",
            4000,
          );
          handleSetBatchDetails(scanBarcodeQuery.data.scanPickerBarcode.batch);
          // currentBatchQuery.fetchData();
        } else if (scanBarcodeQuery.error) {
          appState.setAlert(scanBarcodeQuery.error.message, "error", 5000);
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    useEffect(() => {
      if (skipItemQuery.data) {
        appState.setAlert(
          skipItemQuery.data.skipPickItem.message,
          "success",
          5000,
        );
        // currentBatchQuery.fetchData();
        handleSetBatchDetails(skipItemQuery.data.skipPickItem.batch);
      }

      if (skipItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (skipItemQuery.error) {
        appState.setAlert(skipItemQuery.error.message, "error", 5000);
        currentBatchQuery.fetchData();
      }
    }, [skipItemQuery.loading, skipItemQuery.data, skipItemQuery.error]);

    const onChangeScannedQuantity = (qty) => {
      if (qty <= currentItem.quantity && qty >= 0) {
        setCurrentItem({ ...currentItem, scannedSkus: parseInt(qty) });
      } else {
        appState.setAlert("Please enter correct quantity", "error", 5000);
      }
    };

    const onChangePickedQuantityOfItem = (qty, itemId) => {
      const item = currentBatch.workingList.find((item) => item.id === itemId);

      if (!qty) {
        qty = 0;
      }
      if (item.quantity >= parseInt(qty) && qty >= 0) {
        item.pickedQuantity = parseInt(qty);
        setCurrentBatch({
          ...currentBatch,
        });
      } else if (qty < 0) {
        appState.setAlert("Picked quantity can not be negative", "error", 2000);
      } else {
        appState.setAlert(
          `You cannot pick more than ${item.quantity} for this item`,
          "error",
          2000,
        );
        return;
      }
    };

    const simulateTote = () => {
      const totes = totesQuery.data?.totes?.entities;

      if (!totes || totes.length === 0) {
        appState.setAlert("No totes found", "error", 5000);
        return;
      }
      let activity = "PICKING";
      if (currentBatch && currentBatch.workflow === "Pick by order") {
        activity = "ORDER";
      }
      let items = totesQuery.data?.totes?.entities
        .filter((item) => item.toteType === activity)
        .map((item) => item.barcode);
      if (currentBatch.workflow === "Pick + Sort") {
        items = totesQuery.data?.totes?.entities
          .filter(
            (item) =>
              item.toteType === activity &&
              item.subTotes &&
              item.subTotes.length > 0,
          )
          .map((item) => item.barcode);
      }
      const item = items[Math.floor(Math.random() * items.length)];
      return scanBarcodeQuery.fetchData({ code: item });
    };

    const onSubmitCustomer = (selectedCustomer) => {
      setCustomer(selectedCustomer.value);
    };

    const onSkipCustomer = () => {
      setCustomer(null);
    };

    /**
     * Retrieves a new batch based on the current batch filters.
     * It checks certain conditions, that includes warehouse to be mandatory
     * and sets the appropriate alert if necessary.
     *
     * @function
     */
    const getNewBatch = () => {
      const data = {};
      if (batchfilters?.customer?.value) {
        data.customer = batchfilters.customer.value;
      }
      if (!batchfilters?.warehouse?.value) {
        appState.setAlert("Please select the Warehouse", "error", 5000);
        return;
      } else {
        data.warehouse = batchfilters.warehouse.value;
      }
      getNewBatchQuery.fetchData(data);
    };

    /**
     * Updates the batch filters with a given field and value.
     *
     * @function
     * @param {string} field - The name of the filter to change.
     * @param {string|number|boolean|Object} value - The value to set for the specified filter.
     */
    const onChangeDropdown = (field, value) => {
      const filters = {
        ...batchfilters,
      };

      filters[field] = value;
      setBatchfilters({ ...filters });
    };

    const skipItem = () => {
      skipItemQuery.fetchData({ id: currentBatch.id });
    };

    const confirmPickItem = () => {
      const totalExpectedQuantity = currentItem.quantity;
      const pickedQuantity = currentItem.executedPickingPlan
        ? currentItem.executedPickingPlan.reduce(
            (acc, item) => acc + item.pickedQuantity,
            0,
          )
        : currentItem.scannedSkus;

      if (
        (currentItem.skuConfirmationNeeded ||
          currentItem.executedPickingPlan) &&
        pickedQuantity !== totalExpectedQuantity
      ) {
        appState.showConfirmation(
          "Are you sure?",
          `Scanned and order quantities don't match. Picked: ${pickedQuantity}, Expected: ${totalExpectedQuantity}. Do you wish to proceed?`,
          () => {
            confirmPickItemQuery.fetchData({
              id: currentBatch.id,
              item: {
                id: currentItem.id,
                quantity: totalExpectedQuantity,
                pickedQuantity: pickedQuantity,
                scannedSkus: currentItem.scannedSkus,
                executedPickingPlan: currentItem.executedPickingPlan,
              },
              boxName:
                currentBatch.boxes &&
                currentBatch.boxes[currentBatch.boxes.length - 1]
                  ? currentBatch.boxes[currentBatch.boxes.length - 1].name
                  : null,
              weight: 1,
            });
            appState.hideConfirmation();
          },
          appState.hideConfirmation,
        );
      } else {
        confirmPickItemQuery.fetchData({
          id: currentBatch.id,
          item: {
            id: currentItem.id,
            quantity: currentItem.quantity,
            pickedQuantity: currentItem.pickedQuantity,
            scannedSkus: currentItem.scannedSkus,
          },
          boxName:
            currentBatch.boxes &&
            currentBatch.boxes[currentBatch.boxes.length - 1]
              ? currentBatch.boxes[currentBatch.boxes.length - 1].name
              : null,
          weight: 1,
        });
      }
    };

    const confirmAllItems = () => {
      const items = currentBatch.workingList.map((item) => {
        return {
          id: item.id,
          quantity: item.quantity,
          pickedQuantity: item.pickedQuantity,
        };
      });

      if (items.some((item) => item.quantity !== item.pickedQuantity)) {
        appState.showConfirmation(
          "Are you sure?",
          "Scanned and order quantities don't match.",
          () => {
            confirmAllItemsPickedQuery.fetchData({
              id: currentBatch.id,
              items,
            });
            appState.hideConfirmation();
          },
          appState.hideConfirmation,
        );
      } else {
        confirmAllItemsPickedQuery.fetchData({
          id: currentBatch.id,
          items,
        });
      }
    };

    const confirmDropoff = () => {
      confirmDropoffQuery.fetchData({
        id: currentBatch.id,
      });
    };

    const confirmDropoffWithConfirmation = () => {
      appState.showConfirmation(
        "Confirm",
        "Have you dropped off this batch at prep?",
        () => {
          confirmDropoffQuery.fetchData({
            id: currentBatch.id,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const handleSetBatchDetails = async (batch, error = null) => {
      if (error) {
        setCurrentBatch(null);
        return;
      }

      if (appState?.tenant?.settings?.softAllocationOnBatching) {
        if (
          (batch.tote || batch.skippedTote) &&
          batch.currentItem &&
          !batch.currentItem.pickingPlan
        ) {
          const response = await getCurrentBatchQuery.fetchData();
          if (response.data.getCurrentPickerBatch) {
            if (response.data.getCurrentPickerBatch?.currentItem?.pickingPlan) {
              batch.currentItem.pickingPlan =
                response.data.getCurrentPickerBatch.currentItem.pickingPlan;
            }
          }
        }
      }

      setCurrentBatch({
        ...batch,
        attributes: batch.attributes && {
          ...(currentBatch?.attributes ?? {}),
          ...batch.attributes,
          orders: currentBatch?.attributes?.orders || batch?.attributes?.orders,
        },
      });
      setCurrentItem(batch.currentItem);
      setDisplayScan(false);

      if (batch.customer?.length > 0) {
        setCustomer(batch.customer[0]);
      } else {
        setCustomer(batch.customer);
      }

      if (batch.currentItem?.productId) {
        getCurrentProductQuery.fetchData({ id: batch.currentItem.productId });
      }
    };

    const scanBarcode = (e) => {
      const warehouse = currentBatch
        ? currentBatch.warehouse[0]
        : batchfilters.warehouse.value;

      scanBarcodeQuery.fetchData({ code: e, warehouse });
    };

    const onClickDownloadPickList = async (batchId) => {
      const pickingBatch = (
        await fetchBatchForPickListPDF.fetchData({
          batchId,
        })
      ).data.batch;
      await downloadPickListPdf(
        pickingBatch,
        auth.user.warehousesList,
        auth.user.customersList,
        usersMap,
      );
    };

    const checkPagination = (direction) => {
      let pageNumber = batchesData.pageNumber;
      if (direction === "forward") {
        pageNumber += 1;
      } else {
        pageNumber -= 1;
      }
      setBatchesData({
        ...batchesData,
        pageNumber,
      });
      return pickingBatchesQuery.fetchData({
        perPage: batchesData.perPage,
        filters: batchesData.filters,
        pageNumber,
      });
    };

    const searchPickingBatches = (filters) => {
      pickingBatchesQuery.fetchData({
        perPage: batchesData.perPage,
        pageNumber: batchesData.pageNumber,
        filters: filters || batchesData.filters,
      });
    };

    const workOnBatch = (batchId, warehouseId) => {
      getNewBatchQuery.fetchData({ batchId, warehouse: warehouseId });
    };

    const skipTote = () => {
      appState.showConfirmation(
        `Please confirm`,
        `Are you sure you want to skip the tote for this batch?`,
        () => {
          skipToteQuery.fetchData();
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        "Confirm",
        "Cancel",
      );
    };

    useEffect(() => {
      if (skipToteQuery.data) {
        appState.setAlert(
          skipToteQuery.data.skipTote.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        const { batch } = skipToteQuery.data.skipTote;
        handleSetBatchDetails(batch);
      }

      if (skipToteQuery.error) {
        appState.setAlert(
          skipToteQuery.error.message,
          "danger",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (skipToteQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [skipToteQuery.data, skipToteQuery.error, skipToteQuery.loading]);

    return (
      <WrappedComponent
        currentItem={currentItem}
        currentBatch={currentBatch}
        getNewBatch={getNewBatch}
        scanBarcode={scanBarcode}
        confirmPickItem={confirmPickItem}
        confirmDropoff={confirmDropoff}
        loading={currentBatchQuery.loading}
        loadingFirstTime={loadingFirstTime}
        simulateTote={simulateTote}
        workflow={workflow}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        setCustomer={setCustomer}
        onSubmitCustomer={onSubmitCustomer}
        onSkipCustomer={onSkipCustomer}
        confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
        subdomain={appState.subdomain}
        skipItem={skipItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
        displayScan={displayScan}
        setDisplayScan={setDisplayScan}
        batchfilters={batchfilters}
        onChangeDropdown={onChangeDropdown}
        currentProduct={currentProduct}
        // START - For scanning is disabled and picking batches list view
        pickingScanningDisabled={pickingScanningDisabled}
        confirmAllItems={confirmAllItems}
        onChangePickedQuantityOfItem={onChangePickedQuantityOfItem}
        onClickDownloadPickList={onClickDownloadPickList}
        usersMap={usersMap}
        checkPagination={checkPagination}
        batchesData={batchesData}
        setBatchesDataFilters={(filters) =>
          setBatchesData({
            ...batchesData,
            filters: {
              ...batchesData.filters,
              ...filters,
            },
          })
        }
        searchBatches={searchPickingBatches}
        workOnBatch={workOnBatch}
        tenant={appState.tenant}
        setCurrentItem={setCurrentItem}
        skipTote={
          appState.tenant?.settings?.activities?.picking?.allowToteSkip
            ? skipTote
            : null
        }
        {...props}
      />
    );
  };
};

export default withOrderPickerLogic;
