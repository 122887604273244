// fbaInboundSlice.js
import promiseState from "#redux/ReduxUtils/reduxReducerHelper";
import { createSlice } from "@reduxjs/toolkit";
import {
  activateInboundPlan,
  createFbaInboundPlan,
  deleteInboundPlan,
  fetchCustomers,
  fetchIntegrations,
  fetchWarehouses,
  getEligibilityCheckResults,
  getInventoryForRelease,
  listFbaInboundPlans,
  saveOrder,
  startEligibilityCheck,
} from "./fbaInboundActions";

const stateKeysEnums = {
  WAREHOUSES: "warehouses",
  CUSTOMERS: "customers",
  INTEGRATIONS: "integrations",
  CREATE_INBOUND_PLAN: "createInboundPlan",
  LIST_INBOUND_PLANS: "listInboundPlans",
  DELETE_INBOUND_PLAN: "deleteInboundPlan",
  INVENTORY_FOR_RELEASE: "inventoryForRelease",
  ELIGIBILITY_CHECK: "eligibilityCheck",
  ELIGIBILITY_CHECK_RESULTS: "eligibilityCheckResults",
  ACTIVATE_INBOUND_PLAN_RESULT: "activateInboundPlanResult",
  SAVE_ORDER_RESULT: "saveOrderResult",
};

const initialState = {
  [stateKeysEnums.WAREHOUSES]: { ...promiseState(false, false, false, []) },
  [stateKeysEnums.CUSTOMERS]: { ...promiseState(false, false, false, []) },
  [stateKeysEnums.INTEGRATIONS]: { ...promiseState(false, false, false, []) },
  [stateKeysEnums.CREATE_INBOUND_PLAN]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.LIST_INBOUND_PLANS]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.DELETE_INBOUND_PLAN]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.INVENTORY_FOR_RELEASE]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.ELIGIBILITY_CHECK]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.ELIGIBILITY_CHECK_RESULTS]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.UPDATE_INBOUND_PLAN_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.SAVE_ORDER_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
};

// Helper function to add promise state handlers
const addPromiseHandlers = (builder, action, stateProp) => {
  builder
    .addCase(action.pending, (state) => {
      state[stateProp] = { ...promiseState(true, false, false, []) };
    })
    .addCase(action.fulfilled, (state, action) => {
      state[stateProp] = {
        ...promiseState(false, true, false, action.payload),
      };
    })
    .addCase(action.rejected, (state, action) => {
      state[stateProp] = {
        ...promiseState(false, false, true, action.payload),
      };
    });
};

const fbaInboundSlice = createSlice({
  name: "fbaInbound",
  initialState,
  reducers: {
    resetFbaInboundState: (state) => {
      // Reset each key in the state to its initial value
      Object.keys(initialState).forEach((key) => {
        state[key] = { ...initialState[key] };
      });
    },
    resetFbaInboundIntegrations: (state) => {
      state[stateKeysEnums.INTEGRATIONS] = {
        ...promiseState(false, false, false, []),
      };
    },
    resetEligibilityCheckResults: (state) => {
      state[stateKeysEnums.ELIGIBILITY_CHECK_RESULTS] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetSaveOrderResult: (state) => {
      state[stateKeysEnums.SAVE_ORDER_RESULT] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetUpdateInboundPlanResult: (state) => {
      state[stateKeysEnums.ACTIVATE_INBOUND_PLAN_RESULT] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetInventoryForRelease: (state) => {
      state[stateKeysEnums.INVENTORY_FOR_RELEASE] = {
        ...promiseState(false, false, false, []),
      };
    },
  },
  extraReducers: (builder) => {
    addPromiseHandlers(builder, fetchWarehouses, stateKeysEnums.WAREHOUSES);
    addPromiseHandlers(builder, fetchCustomers, stateKeysEnums.CUSTOMERS);
    addPromiseHandlers(builder, fetchIntegrations, stateKeysEnums.INTEGRATIONS);
    addPromiseHandlers(
      builder,
      createFbaInboundPlan,
      stateKeysEnums.CREATE_INBOUND_PLAN,
    );
    addPromiseHandlers(
      builder,
      listFbaInboundPlans,
      stateKeysEnums.LIST_INBOUND_PLANS,
    );
    addPromiseHandlers(
      builder,
      deleteInboundPlan,
      stateKeysEnums.DELETE_INBOUND_PLAN,
    );
    addPromiseHandlers(
      builder,
      getInventoryForRelease,
      stateKeysEnums.INVENTORY_FOR_RELEASE,
    );
    addPromiseHandlers(
      builder,
      startEligibilityCheck,
      stateKeysEnums.ELIGIBILITY_CHECK,
    );
    addPromiseHandlers(
      builder,
      getEligibilityCheckResults,
      stateKeysEnums.ELIGIBILITY_CHECK_RESULTS,
    );
    addPromiseHandlers(
      builder,
      activateInboundPlan,
      stateKeysEnums.ACTIVATE_INBOUND_PLAN_RESULT,
    );
    addPromiseHandlers(builder, saveOrder, stateKeysEnums.SAVE_ORDER_RESULT);
  },
});

export const {
  resetFbaInboundIntegrations,
  resetFbaInboundState,
  resetEligibilityCheckResults,
  resetSaveOrderResult,
  resetUpdateInboundPlanResult,
  resetInventoryForRelease,
} = fbaInboundSlice.actions;

export default fbaInboundSlice.reducer;
