import { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import { CheckIcon, RefreshIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuditTrailTab = ({
  order,
  getAudit,
  selectedAuditUsersInfo,
  isLoading,
  setIsLoading,
  hasFetched,
  setHasFetched,
  auditData,
  setAuditData,
  isRefreshing,
  setIsRefreshing,
}) => {
  const fetchAuditData = useCallback(
    async (isRefresh = false) => {
      if (!order?.id) return;

      // Only show loading on first fetch
      if (!hasFetched && !isRefresh) {
        setIsLoading(true);
      }
      if (isRefresh) {
        setIsRefreshing(true);
      }

      try {
        const data = await getAudit(order);
        if (Array.isArray(data)) {
          setAuditData(data);
          setHasFetched(true);
        } else {
          console.error("Received invalid audit data format:", data);
        }
      } catch (error) {
        console.error("Error fetching audit data:", error);
      } finally {
        setIsLoading(false);
        setIsRefreshing(false);
      }
    },
    [order, hasFetched],
  );

  // Initial fetch
  useEffect(() => {
    if (!hasFetched && order?.id) {
      fetchAuditData();
    }
  }, [order, hasFetched]);

  const handleRefresh = () => {
    fetchAuditData(true);
  };

  const RefreshButton = ({ className = "" }) => (
    <button
      onClick={handleRefresh}
      disabled={isRefreshing}
      className={`flex h-10 w-10 items-center justify-center rounded-full ${className} ${
        isRefreshing
          ? "bg-gray-200 text-gray-500"
          : "bg-primaryAccent text-white transition-transform duration-300 hover:rotate-180 hover:bg-opacity-90"
      }`}
      title="Refresh Audit Trail">
      {isRefreshing ? (
        <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent" />
      ) : (
        <RefreshIcon className="h-5 w-5" />
      )}
    </button>
  );

  // Content with refresh button
  const Content = () => (
    <>
      {auditData && auditData.length > 0 ? (
        <div className="space-y-6">
          <nav aria-label="Progress">
            <ol role="list" className="overflow-hidden">
              {auditData.map((entry, index) => (
                <li
                  key={index}
                  className={classNames(
                    index !== auditData.length - 1 ? "pb-10" : "",
                    "relative",
                  )}>
                  {entry.status ? (
                    <>
                      {index !== auditData.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-1 bg-F4C261"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <span className="flex h-9 items-center">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primaryAccent">
                            <CheckIcon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="ml-4 flex min-w-0 flex-col">
                          <span className="text-sm text-gray-600">Status</span>
                          <span className="text-sm font-semibold uppercase tracking-wide">
                            {entry.status}
                          </span>
                          {entry.notes && entry.notes.length > 0 && (
                            <>
                              {entry.notes.map((note, noteIndex) => (
                                <div
                                  key={noteIndex}
                                  className="text-sm font-semibold uppercase tracking-wide text-FC8862">
                                  {note}
                                </div>
                              ))}
                            </>
                          )}
                          {entry.userId && (
                            <span className="text-sm text-gray-600">
                              Performed by:{" "}
                              {selectedAuditUsersInfo?.filter(
                                (user) => user.id === entry.userId,
                              )[0]?.username ?? ""}
                            </span>
                          )}
                          <span className="text-sm font-normal uppercase tracking-wide text-gray-500">
                            {moment(entry.createdAt).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {index !== auditData.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <span
                          className="flex h-9 items-center"
                          aria-hidden="true">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                          </span>
                        </span>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      ) : (
        <div className="text-center">
          <p className="text-gray-600">
            No audit data available for this order.
          </p>
        </div>
      )}
    </>
  );

  if (isLoading && !auditData.length) {
    return (
      <div className="flex items-center justify-center py-12">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-primaryAccent border-t-transparent"></div>
        <span className="ml-3 text-primaryAccent">Loading audit trail...</span>
      </div>
    );
  }

  return (
    <div className="relative py-6">
      <div className="absolute left-1/2 top-6 -translate-x-1/2">
        <RefreshButton />
      </div>
      <div className="mt-16">
        <Content />
      </div>
    </div>
  );
};

export default AuditTrailTab;
