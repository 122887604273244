// SPECIFIC TO DELMAR. THEY USE THE PAPER FORMAT TO DETERMINE THE PRINTER.
export const PAPER_FORMAT_ENUM = {
  null: "Laser-Default1",
  0: "Laser-Default1",
  1: "Laser-GH",
  2: "Laser-QVC",
  3: "Laser-HSN",
  4: "Laser-TF",
  5: "Laser-LA",
  6: "Laser-FH",
  7: "Laser-RS",
  8: "Laser-Default2",
  9: "Laser-Default2",
  10: "Laser-Default1",
};

export const TENANT_SHIPPING_LABEL_PRINTER_ENUM = {
  allpoints: "Thermal",
  unitedstatesprep: "4x6",
  tosprep: "4x6",
  prepfort: "4x6",
  proprep: "4x6",
  default: null,
};

export const TENANT_FNSKU_LABEL_PRINTER_ENUM = {
  e53: "30 up Printer",
  allpoints: "30 up Printer",
  unitedstatesprep: "FNSKU",
  prepfort: "FNSKU",
  proprep: "2.25x1.25",
  tosprep: "2x1",
  default: null,
};

export const TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM = {
  tosprep: "2x1",
  unitedstatesprep: "2.25x1.25",
  prepfort: "2.25x1.25",
  proprep: "2.25x1.25",
  weprepfba: "2x1",
  wase: "3x1",
  "fba-test": "2.25x1.25",
  default: "2x1",
};
