import _ from "lodash";
import { renderStatus } from "#utils/renderStatus";

const ListViewForSorting = ({ currentBatch }) => {
  const headers = ["SKU", "Product Name", "Qty"];

  // Consolidate items by SKU
  const consolidatedItems = _.chain(currentBatch.items)
    .groupBy("sku")
    .map((items, sku) => ({
      id: items[0].id, // use first item's id
      sku: sku,
      productName: items[0].productName, // use first item's product name
      availableQuantity: _.sumBy(items, "availableQuantity"),
    }))
    .value();

  return (
    <div className="max-w-4xl flex-1">
      <div className="mt-10 rounded-md bg-EBEBEB">
        <table className="-mt-4 min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {consolidatedItems.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {item.sku}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.productName}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.availableQuantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListViewForSorting;
