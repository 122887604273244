import Modal from "#components/utils/Modal";
import {
  DesktopComputerIcon,
  LogoutIcon,
  CogIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Dropdown from "#components/utils/Dropdown";
import { useState } from "react";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/outline";

const ManageIntegrationProvider = ({ children, ...props }) => {
  const {
    integrations,
    integrationProvider,
    setSelectedIntegrationToManage,
    selectedIntegrationToConfigure,
    setSelectedIntegrationToConfigure,
    writable,
    connectIntegration,
    logoutIntegration,
    testIntegration,
    getProducts,
    configureIntegrationSubmit,
  } = props;
  return (
    <div className="w-full">
      <ControlButtonsRow
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        writable={writable}
        connectIntegration={connectIntegration}
        integrationProvider={integrationProvider}
      />
      <ConnectedIntegrationsGrid
        integrations={integrations.filter(
          (item) => item.integrationType === integrationProvider?.name,
        )}
        writable={writable}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        integrationProvider={integrationProvider}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
      />
      {selectedIntegrationToConfigure && (
        <ConfigureIntegrationOnManageModal
          selectedIntegration={selectedIntegrationToConfigure}
          setSelectedIntegration={setSelectedIntegrationToConfigure}
          onClose={() => setSelectedIntegrationToConfigure(null)}
          onSubmit={configureIntegrationSubmit}
          integrationProvider={integrationProvider}
        />
      )}
    </div>
  );
};

const ConfigureIntegrationOnManageModal = ({ ...props }) => {
  const {
    selectedIntegration,
    setSelectedIntegration,
    onClose,
    onSubmit,
    integrationProvider,
  } = props;
  return (
    <Modal
      title={`Configure ${integrationProvider.name} Integration - ${selectedIntegration.sellerId}`}
      negativeAction={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Region
          </span>
        </div>
      </div>
      <div>
        <Dropdown
          placeholder={"Region"}
          list={integrationProvider.regions}
          labelKey="name"
          valueKey="code"
          name="region"
          setSelected={(e) => {
            setSelectedIntegration({
              ...selectedIntegration,
              region: e,
              marketplaces:
                e !== selectedIntegration.region
                  ? []
                  : selectedIntegration.marketplaces,
            });
          }}
          selectedValue={selectedIntegration.region}
        />
      </div>
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Marketplaces
          </span>
        </label>
        <MultiSelectAutoComplete
          options={integrationProvider.marketplaces.filter(
            (marketplace) => marketplace.region === selectedIntegration.region,
          )}
          labelKey="name"
          valueKey="countryCode"
          setValues={(e) =>
            setSelectedIntegration({
              ...selectedIntegration,
              marketplaces: e,
            })
          }
          values={selectedIntegration.marketplaces}
          rounded={true}
        />
      </div>
    </Modal>
  );
};

const ControlButtonsRow = ({ ...props }) => {
  const { writable } = props;
  return (
    <div className="flex w-full">
      <BackButton {...props} />
      {writable && <AddNewIntegrationButton {...props} style={"ml-auto"} />}
    </div>
  );
};

const BackButton = ({ ...props }) => {
  const { setSelectedIntegrationToManage } = props;
  return (
    <div
      onClick={() => setSelectedIntegrationToManage(null)}
      className="flex cursor-pointer items-center justify-center gap-2 text-lg font-medium text-primaryAccent">
      <ArrowLeftIcon className="h-6 w-6" />
      Back
    </div>
  );
};

const AddNewIntegrationButton = ({ ...props }) => {
  const { style } = props;
  const { connectIntegration, integrationProvider } = props;
  return (
    <div
      onClick={() => connectIntegration(integrationProvider.name)}
      className={
        style +
        " flex cursor-pointer items-center justify-center gap-2 text-lg font-medium text-primaryAccent"
      }>
      <PlusCircleIcon className="h-6 w-6" />
      Connect New Account
    </div>
  );
};

const ConnectedIntegrationsGrid = ({ ...props }) => {
  const {
    integrations,
    writable,
    logoutIntegration,
    testIntegration,
    getProducts,
    integrationProvider,
    selectedIntegrationToConfigure,
    setSelectedIntegrationToConfigure,
  } = props;
  if (integrations.length === 0) {
    return (
      <div className="flex h-32">
        <div className="m-auto">
          No {integrationProvider?.name} accounts connected.
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-3 gap-4">
      {integrations
        .sort((a, b) =>
          a?.region?.toLowerCase() > b?.region?.toLowerCase() ? -1 : 1,
        )
        .map((integration, idx) => (
          <div className="rounded-lg bg-white py-8 pt-0 shadow-lg" key={idx}>
            <div className="flex w-full justify-center rounded-t-lg bg-integrationLogoBackground py-6">
              <img
                src={integrationProvider.logo}
                className="h-20 w-32 object-contain"
              />
            </div>
            <div className="flex h-40 flex-col items-center justify-center px-4 py-6 text-center">
              {integrationProvider.name === "Amazon" && (
                <div>
                  <div className="text-xl font-medium text-primaryAccent">
                    {integrationProvider.name} -{" "}
                    {
                      integrationProvider.regions.find(
                        (item) => item.code === integration.region,
                      ).name
                    }
                  </div>
                  <div className="text-lg font-normal text-5F666B">
                    Seller ID: {integration.sellerId}
                  </div>
                </div>
              )}
              {integrationProvider.name === "Mirakl" && (
                <div>
                  {" "}
                  <div className="text-xl font-medium text-primaryAccent">
                    {integrationProvider.name} -{" "}
                    {integration.configurations.marketplace}
                  </div>
                  <div className="text-lg font-normal text-5F666B">
                    {integrationProvider.description}
                  </div>
                </div>
              )}
              {integrationProvider.name === "CommerceHub" && (
                <div>
                  <div className="text-xl font-medium text-primaryAccent">
                    {integrationProvider.name} -{`${integration.username}`}
                  </div>
                </div>
              )}
              {(integrationProvider.name === "UPS" ||
                integrationProvider.name === "FedEx" ||
                integrationProvider.name === "StampsCom") &&
                integration.name && (
                  <div>
                    <div className="text-xl font-medium text-primaryAccent">
                      {integrationProvider.name} -{`${integration.name}`}
                    </div>
                  </div>
                )}
            </div>
            <div className="mx-6 mt-4 flex space-x-2">
              <div className="w-full flex-col space-y-4">
                {integration.integrationType === "Amazon" && (
                  <div className="space-between flex w-full space-x-2">
                    <button
                      className={`text-md flex w-full px-5 py-2 text-white ${
                        writable ? "bg-2C7695" : "bg-gray-500"
                      } items-center justify-center rounded-lg text-center`}
                      onClick={() =>
                        writable &&
                        setSelectedIntegrationToConfigure(integration)
                      }>
                      <CogIcon className="mr-1 h-5 w-5" />
                      Configure Integration
                    </button>
                  </div>
                )}
                <div className="space-between flex w-full space-x-2">
                  <button
                    className={`text-md flex w-full px-5 py-2 text-white ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center rounded-lg text-center`}
                    onClick={() => writable && testIntegration(integration.id)}>
                    <DesktopComputerIcon className="mr-1 h-5 w-5" />
                    Test
                  </button>
                  {integration.tags.some((tag) =>
                    [
                      "E_COMMERCE_MARKETPLACE",
                      "MARKETPLACE_FULFILLMENT",
                      "ERP",
                    ].includes(tag),
                  ) && (
                    <button
                      className={`text-md flex w-full px-5 py-2 text-white ${
                        writable ? "bg-2C7695" : "bg-gray-500"
                      } items-center justify-center rounded-lg text-center`}
                      onClick={() => writable && getProducts(integration.id)}>
                      <RefreshIcon className="mr-1 h-5 w-5" />
                      Sync Now
                    </button>
                  )}
                </div>
                <div className="space-between flex w-full space-x-2">
                  <button
                    className={`text-md flex w-full px-5 py-2 text-white ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center rounded-lg text-center`}
                    onClick={() =>
                      writable && logoutIntegration(integration.id)
                    }>
                    <LogoutIcon className="mr-1 h-5 w-5" />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ManageIntegrationProvider;
