import { useState, useEffect, useContext } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { Tooltip, Dropdown } from "antd";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  InformationCircleIcon,
  PencilIcon,
  CheckCircleIcon,
  XCircleIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import SlideOverModal from "#components/common/SlideOverModal";
import { ConsumerNotifications } from "#newUiComponents/notificationV2/ConsumerNotifications";
import { Alert } from "antd";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { GET_NOTIFICATIONS_LIST } from "#queries";
import { GET_PRODUCT_SOURCE, SAVE_CONSUMER_NOTIFICATION } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { capitalizeLabel } from "#utils/helper-functions";
import { ALERT_VISIBILITY_IN_5000_MS } from "#newUiComponents/notificationV2/ConsumerNotifications";
import CustomBadge from "#newUiComponents/commons/CustomBadge";

const CUSTOM_TABLE_COLUMNS = [
  { title: "Notification Type", key: "eventName", isSort: true },
  { title: "Delivery Channel", key: "channelName", isSort: true },
  { title: "Conditions", key: "conditionsCount", isSort: false },
  { title: "Template", key: "templateName", isSort: true },
  { title: "Status", key: "status", isSort: true },
  { title: "Action", key: "action", stickPosition: "right" },
];
export const SEARCH_DEBOUNCE_TIME_IN_MS = 800;
export const GET_METHOD = "GET";
export const EXPIRATION_IN_SECONDS = 300;
export const CONSUMER_NOTIFICATION_ENUM = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  EDIT_NOTIFICATION: "EDIT_NOTIFICATION",
  VIEW_NOTIFICATION: "VIEW_NOTIFICATION",
};

const ConsumerNotificationsDashboard = ({ fetchNotificationsEvents }) => {
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const getNotificationListQuery = useQuery(GET_NOTIFICATIONS_LIST);
  const deleteConsumerNotificationsRulesQuery = useQuery(
    SAVE_CONSUMER_NOTIFICATION,
  );
  const productSourceQuery = useQuery(GET_PRODUCT_SOURCE);
  const [loading, setLoading] = useState(false);
  const [customeTableMetaData, setCustomeTableMetaData] = useState({
    total: 0,
    entities: null,
  });
  const [openSelectedNotification, setopenSelectedNotification] =
    useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [customerList, setcustomersList] = useState([]);
  const [productSources, setProductSources] = useState([]);
  const [notificationsFilters, setNotificationsFilters] = useState({
    currentPage: 1,
    pageSize: 20,
  });
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);

  const handleIncomingData = (data) => {
    return data.map((notification) => {
      return {
        ...notification,
        eventName:
          notification?.eventDetails && notification?.eventDetails?.length !== 0
            ? capitalizeLabel(notification?.eventDetails[0]["key"] || "")
            : "",
        templateName:
          notification?.templateDetails &&
          notification?.templateDetails?.length !== 0
            ? capitalizeLabel(
                notification?.templateDetails[0]["templateName"] || "",
              )
            : "",
        channelName: capitalizeLabel(notification?.channels),
        conditionsCount: notification?.conditions
          ? notification?.conditions?.length
          : "00",
        status: (
          <CustomBadge
            label={!notification?.isDeleted ? "Enabled" : "Disabled"}
            textColor={getStatusColor(!notification?.isDeleted)?.textColor}
            bgColor={getStatusColor(!notification?.isDeleted)?.bgColor}
          />
        ),
        action: (
          <Dropdown
            trigger={["hover"]}
            menu={{
              items: menuItems(notification),
            }}
            overlayClassName="z-5"
            dropdownRender={({ props }) => {
              return (
                <div className="z-10 h-auto w-36 overflow-auto rounded-lg border border-borderGray bg-bgWhite p-1">
                  {props?.items?.map((item, index) => (
                    <div
                      key={item.title}
                      onClick={() => item["onClick"](notification)}
                      className="cursor-pointer whitespace-nowrap p-2 text-lg font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent">
                      <div className="flex items-center space-x-2">
                        <span>{item?.icon ? item?.icon : null}</span>
                        <span>{item?.title}</span>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
            placement="topRight"
            arrow>
            <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
          </Dropdown>
        ),
      };
    });
  };

  const sortFunction = (key, direction) => {
    const columnsKey = key === "status" ? "isDeleted" : key;
    setCustomeTableMetaData((prevMetaData) => ({
      ...prevMetaData,
      total: customeTableMetaData?.entities?.length,
      entities: [...customeTableMetaData?.entities].sort((a, b) => {
        let valA = a[`${columnsKey}`];
        let valB = b[`${columnsKey}`];
        if (typeof valA === "string" && typeof valB === "string") {
          return direction === "ascending"
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA);
        } else if (typeof valA === "number" && typeof valB === "number") {
          return direction === "ascending" ? valA - valB : valB - valA;
        } else {
          return direction === "ascending"
            ? String(valA).localeCompare(String(valB))
            : String(valB).localeCompare(String(valA));
        }
      }),
    }));
  };

  const menuItems = (notification) => {
    const arr = [];
    arr.push({
      title: "Edit",
      icon: <PencilIcon className="h-5 w-5" />,
      onClick: () => {
        setSelectedNotificationType(
          CONSUMER_NOTIFICATION_ENUM?.EDIT_NOTIFICATION,
        );
        setSelectedNotification(notification);
        setopenSelectedNotification(true);
      },
      disabled: false,
    });
    arr.push({
      title: "View",
      icon: <EyeIcon className="h-5 w-5" />,
      onClick: () => {
        setSelectedNotificationType(
          CONSUMER_NOTIFICATION_ENUM?.VIEW_NOTIFICATION,
        );
        setSelectedNotification(notification);
        setopenSelectedNotification(true);
      },
      disabled: false,
    });
    arr.push({
      title: !notification?.isDeleted ? "Disable" : "Enable",
      icon: !notification?.isDeleted ? (
        <XCircleIcon className="h-5 w-5" />
      ) : (
        <CheckCircleIcon className="h-5 w-5" />
      ),
      onClick: () => enableOrDisableCustomerNotificationsRule(notification),
      disabled: false,
    });
    return arr;
  };

  useDebouncedEffect(
    () => {
      handleGetNotificationsLists();
    },
    SEARCH_DEBOUNCE_TIME_IN_MS,
    [notificationsFilters],
  );

  const handleTriggerHandleGetNotificationsLists = (pageNumber = 1) => {
    setNotificationsFilters((prevFilters) => ({
      ...prevFilters,
      currentPage: pageNumber,
    }));
  };

  const handleGetNotificationsLists = async (
    perPage,
    pageNumber,
    sort = "-createdAt",
  ) => {
    setLoading(true);
    const notificationTableListResponse =
      await getNotificationListQuery.fetchData({
        perPage: perPage ? perPage : notificationsFilters.pageSize,
        pageNumber: pageNumber ? pageNumber : notificationsFilters.currentPage,
        sort,
      });
    setLoading(false);
    if (notificationTableListResponse.data?.listNotificationRules?.entities) {
      const currentPageNumber = pageNumber
        ? pageNumber
        : notificationsFilters.currentPage;
      setCustomeTableMetaData((prevData) => ({
        ...prevData,
        total: notificationTableListResponse.data?.listNotificationRules?.total,
        entities:
          prevData && prevData.entities && currentPageNumber > 1
            ? [
                ...prevData.entities,
                ...handleIncomingData(
                  notificationTableListResponse.data?.listNotificationRules
                    ?.entities,
                ),
              ]
            : handleIncomingData(
                notificationTableListResponse.data?.listNotificationRules
                  ?.entities,
              ),
      }));
    }
  };

  const getProductSourcesList = async () => {
    appState.setLoading();
    const getProductSourceResponse = await productSourceQuery.fetchData();
    appState.removeLoading();
    if (getProductSourceResponse?.error) {
      setProductSources(null);
    } else if (getProductSourceResponse?.data) {
      setProductSources(
        getProductSourceResponse?.data?.listProductSources.map((source) => ({
          uniqueIdentifier: source,
          id: source,
          name: source,
        })) ?? [],
      );
    }
  };

  const enableOrDisableCustomerNotificationsRule = (notification) => {
    if (notification) {
      const consumerNotificationsRulesInput = {
        id: notification?.id,
        eventId: notification?.eventId,
        isDeleted: !notification?.isDeleted,
        channels: notification?.channels,
        templateId: notification?.templateId,
        conditions: notification?.conditions,
        eventType:
          notification?.eventDetails && notification?.eventDetails?.length !== 0
            ? notification?.eventDetails[0]?.key
            : null,
      };

      if (consumerNotificationsRulesInput) {
        appState.showNewConfirmation(
          "Consumer Notification",
          <span>
            Are you sure you want to{" "}
            {!notification?.isDeleted ? "disable" : "enable"}{" "}
            <span className="font-semibold text-primaryAccent">
              {notification?.eventDetails &&
                notification?.eventDetails?.length !== 0 &&
                notification?.eventDetails[0]?.key}
            </span>{" "}
            notification ?
          </span>,
          () =>
            enableOrDisableConsumerNotificationsRules(
              consumerNotificationsRulesInput,
            ),
          () => appState.hideConfirmation,
        );
      }
    }
  };

  const enableOrDisableConsumerNotificationsRules = async (
    requestNotificationRulesInput,
  ) => {
    if (!requestNotificationRulesInput) return;
    try {
      appState.setLoading();
      const deleteConsumerNotificationRulesResponse =
        await deleteConsumerNotificationsRulesQuery.fetchData({
          upsertNotificationRulesInput: requestNotificationRulesInput,
        });

      if (deleteConsumerNotificationRulesResponse?.error) {
        const errorMessage =
          deleteConsumerNotificationRulesResponse?.error?.message;
        appState.setAlert(errorMessage, "error", ALERT_VISIBILITY_IN_5000_MS);
        return false;
      }
      if (deleteConsumerNotificationRulesResponse?.data) {
        handleTriggerHandleGetNotificationsLists();
        const successMessage =
          deleteConsumerNotificationRulesResponse?.data?.upsertNotificationRules
            ?.message || "Action successful.";
        appState.setAlert(
          successMessage,
          "success",
          ALERT_VISIBILITY_IN_5000_MS,
        );
        appState.hideConfirmation();
      }
    } catch (error) {
    } finally {
      appState.removeLoading();
    }
  };

  useEffect(() => {
    getProductSourcesList();
  }, []);

  useEffect(() => {
    if (auth?.user?.customersList && auth?.user?.customersList?.length !== 0) {
      setcustomersList(
        auth?.user?.customersList
          ?.filter((user) => user?.name && user?.id)
          .map((user) => ({
            uniqueIdentifier: user.id,
            id: user.id,
            name: user.name,
          })) ?? [],
      );
    }
  }, [auth?.user]);

  return (
    <div className="h-full w-full px-8 font-inter">
      {auth?.user?.email && (
        <div className="mb-2 flex w-full content-start items-center justify-start">
          <div
            className="flex w-1/3 flex-col items-start justify-start rounded-md !bg-green-300 p-3"
            style={{ backgroundColor: "rgb(220 252 231)" }}>
            <div className="mb-1 flex items-center justify-start text-gray-700">
              <span className="text-sm">Sender Email</span>
              <Tooltip title="Sender Email">
                <InformationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            </div>
            <div className="flex items-start justify-start text-gray-700">
              no-reply-accountservices@hopstack.io
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full items-center justify-start">
        <Alert
          message={
            <div className="flex items-center font-inter text-sm">
              <InformationCircleIcon className="mr-2 h-5 w-5 text-[#224E73]" />
              <span className="text-[#224E73]">
                The email address used to send notifications to your customers
              </span>
            </div>
          }
          showIcon={false}
          className=""
          type="info"
        />
      </div>
      <div className="mb-2 flex w-full items-center justify-end">
        <button
          onClick={() => {
            setSelectedNotificationType(
              CONSUMER_NOTIFICATION_ENUM?.ADD_NOTIFICATION,
            );
            setSelectedNotification(null);
            setopenSelectedNotification(true);
          }}
          className={`cursor-pointer rounded-md border border-primaryAccent px-4 py-2 text-sm font-semibold text-primaryAccent`}>
          Create New
        </button>
      </div>

      <CustomTable
        columns={CUSTOM_TABLE_COLUMNS}
        data={customeTableMetaData?.entities || []}
        isFilters={false}
        isSearchable={false}
        isPagination={false}
        isLoadMore
        isInfiniteScroll
        loadingData={loading}
        pageSize={notificationsFilters.pageSize}
        currentPage={notificationsFilters.currentPage}
        setCurrentPage={(page) => {
          setNotificationsFilters((prevFilters) => ({
            ...prevFilters,
            currentPage: page,
          }));
        }}
        setPageSize={(pageSize) => {
          setNotificationsFilters((prevFilters) => ({
            ...prevFilters,
            pageSize: pageSize,
          }));
        }}
        totalDataCount={customeTableMetaData?.total || 0}
        onSort={sortFunction}
        noResultsText={
          <div className="flex flex-col items-center justify-center">
            <span className="!font-normal">No Notifications Created</span>
            <span className="!text-sm !font-light">
              Looks like no notifications have been created, please create
              notifications and they will be shown here.
            </span>
            <button
              onClick={() => {
                setSelectedNotification(null);
                setopenSelectedNotification(true);
              }}
              className={`mt-3 cursor-pointer rounded-md border border-primaryAccent bg-primaryAccent px-4 py-2 text-sm font-semibold text-white`}>
              Create New
            </button>
          </div>
        }
      />

      <SlideOverModal
        open={openSelectedNotification}
        onClose={() => {
          setopenSelectedNotification(false);
        }}
        fontFamilyClass="font-inter"
        title={<Titles type={selectedNotificationType} />}>
        <ConsumerNotifications
          onCancel={(action) => {
            setSelectedNotification(null);
            setopenSelectedNotification(false);
            if (action) {
              handleTriggerHandleGetNotificationsLists();
            }
          }}
          selectedNotification={selectedNotification}
          customerList={customerList}
          productSources={productSources}
          fetchNotificationsEvents={fetchNotificationsEvents}
          modalType={selectedNotificationType}
        />
      </SlideOverModal>
    </div>
  );
};

export const Titles = ({ type }) => {
  const titlesMap = {
    [CONSUMER_NOTIFICATION_ENUM?.ADD_NOTIFICATION]: "Create Notification",
    [CONSUMER_NOTIFICATION_ENUM?.EDIT_NOTIFICATION]: "Edit Notification",
    [CONSUMER_NOTIFICATION_ENUM?.VIEW_NOTIFICATION]: "View Notification",
  };

  return (
    <span className="text-2xl font-semibold">{titlesMap[type] || ""}</span>
  );
};

export const getStatusColor = (status) =>
  ({
    true: { bgColor: "#D1FAE5", textColor: "#065F46" }, // light green
    false: { bgColor: "#FECACA", textColor: "#991B1B" }, // light red
  })[status] || { bgColor: "#FFFFFF", textColor: "#000000" }; // default

export default ConsumerNotificationsDashboard;
