import { useState } from "react";
import TextField from "#components/utils/TextField";

const PickerBagCode = ({
  scanBarcode,
  text,
  subText = null,
  tabView,
  associateUsernameInputEnabled,
  showAssociateUsernameInput,
  setShowAssociateUsernameInput,
  username,
  setUsername,
  submitUsername,
  skipTote,
}) => {
  const [manualValue, setManualValue] = useState(null);

  if (associateUsernameInputEnabled && showAssociateUsernameInput) {
    return (
      <div
        className={`w-full ${
          !tabView && "-mt-20 h-screen"
        } flex items-center justify-center`}>
        <div className="flex-col">
          <div className="rounded-2xl border bg-white shadow-lg">
            <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
              <div>
                <TextField
                  type="text"
                  id="name"
                  label="Enter your name"
                  placeholder=" "
                  onChange={(e) => {
                    setUsername(e.target.value);
                    localStorage.setItem("username", e.target.value);
                  }}
                  value={username}
                  className="text-2xl"
                  autoFocus={true}
                />
              </div>
              <div
                className={`mt-4 flex-1 rounded-md bg-2C7695 py-6 text-center text-2xl text-white`}
                onClick={submitUsername}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-full ${
        !tabView && "-mt-20 h-screen"
      } flex items-center justify-center`}>
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="max-w-2xl break-words px-5 py-5 text-xl font-medium text-454A4F">
              {text || "Scan an order tray to get started"}
              {subText && (
                <div className="mt-2 text-xl text-gray-500">{subText}</div>
              )}
            </div>
          </div>
          <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setManualValue(e.target.value)}
                value={manualValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scanBarcode(manualValue);
                    setManualValue("");
                  }
                }}
                autoFocus={true}
              />
            </div>
            {skipTote && (
              <div className="mt-4 flex items-center justify-center">
                <button
                  className="mt-4 w-48 rounded-md bg-primaryAccent p-2 text-center text-lg text-white"
                  onClick={skipTote}>
                  Skip Tote
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickerBagCode;
