import React, { useState, useEffect } from "react";
import { storeInLocalStorage } from "#utils/catalogsAndAnalyticsUtils";
import { analyticsCategoriescolumns } from "#constants/analytics";
import AnalyticsDataGrid from "./AnalyticsDataGrid";

const CategoryPerformanceGrid = ({
  startEndDate,
  comparePeriodDate,
  selectedComparePeriods,
  selectedCustomer,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(
    analyticsCategoriescolumns[1],
  );
  const [toggle, setToggle] = useState(false);

  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );

  const selectColumnHandler = (column) => {
    if (column !== selectedColumn) {
      setSelectedColumn(column);
      storeInLocalStorage(column, "selectedColumn", "categoriesPerformance");
    }
  };

  const toggleButtonHandler = () => {
    setToggle(!toggle);
    storeInLocalStorage(toggle, "toggle", "categoriesPerformance");
  };

  useEffect(() => {
    if (analyticsData?.categoriesPerformance) {
      if (
        analyticsData?.categoriesPerformance?.selectedColumn &&
        analyticsData?.categoriesPerformance?.selectedColumn !== selectedColumn
      ) {
        setSelectedColumn(analyticsData?.categoriesPerformance?.selectedColumn);
      }
      if (
        analyticsData?.categoriesPerformance?.toggle &&
        analyticsData?.categoriesPerformance?.toggle !== toggle
      ) {
        setToggle(analyticsData?.categoriesPerformance?.toggle);
      }
    }
  }, []);

  return (
    <AnalyticsDataGrid
      title={"Category Level Performance"}
      data={[]}
      columns={analyticsCategoriescolumns}
      selectColumnHandler={selectColumnHandler}
      toggle={toggle}
      toggleButtonHandler={toggleButtonHandler}
      selectedComparePeriods={selectedComparePeriods}
      noDataFoundMsg={"No Categories Available"}
    />
  );
};

export default CategoryPerformanceGrid;
