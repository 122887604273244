import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_CUSTOMERS_REVENUE } from "#queries/index";
import { getUnixTimeStamp } from "#utils/catalogsAndAnalyticsUtils";
import {
  ANALYTICS_DATE_RANGES,
  getDateInDDMMYYYYFormate,
  getEndDateWithHhMmSsFormate,
  getStartDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import SalesOrderRevenueTable from "#components/catalogs/SalesOrderRevenueTable";
import CustomPagination from "#newUiComponents/commons/CustomPagination";
import {
  analyticsCustomerscolumns,
  customeDateRange,
} from "#constants/analytics";

const pageSize = 25;

const ProductRevenueByCustomers = () => {
  const getCatalogAnalyticsCustomersRevenueQuery = useQuery(
    GET_CATALOGS_ANALYTICS_CUSTOMERS_REVENUE,
  );
  const infiniteLoaderForCustomersRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [customersRevenueData, setCustomersRevenueData] = useState(null);
  const getTotalRecords = customersRevenueData?.[0]?.totalRecords || 0;

  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const defaultSelectedDate = timePeriods[3];
  const getStartEndDate = getDateInDDMMYYYYFormate(
    ANALYTICS_DATE_RANGES[defaultSelectedDate],
  );
  const salesOverviewData = JSON.parse(
    localStorage.getItem("salesOverviewData") || null,
  );
  const getStartAndEndDate = salesOverviewData?.selectedTimePeriod
    ? salesOverviewData?.selectedTimePeriod !== customeDateRange
      ? getDateInDDMMYYYYFormate(
          ANALYTICS_DATE_RANGES[salesOverviewData?.selectedTimePeriod],
        )
      : salesOverviewData?.startEndDate
    : getStartEndDate;
  const productSku = salesOverviewData?.productSku || null;
  const productId = salesOverviewData?.productId || null;

  const getRegionsRevenueData = async () => {
    const selectedStartEndDate = [];
    if (productSku) {
      if (getStartAndEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(getStartAndEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(getStartAndEndDate[1]),
        );
      }
      setIsLoading(true);
      try {
        const response =
          await getCatalogAnalyticsCustomersRevenueQuery.fetchData({
            perPage: pageSize,
            pageNumber: pageNumber,
            filters: {
              productId: productId,
              sku: productSku,
              customer: [],
              startEndDate: selectedStartEndDate,
              sortType: "top",
              performanceType: "revenue",
            },
          });
        if (
          response?.data?.catalogAnalyticsCustomerRevenue
            ?.primaryCustomerAnalytics &&
          response?.data?.catalogAnalyticsCustomerRevenue
            ?.primaryCustomerAnalytics?.length > 0
        ) {
          setCustomersRevenueData((prevData) => {
            if (prevData) {
              return [
                ...prevData,
                ...response.data.catalogAnalyticsCustomerRevenue
                  .primaryCustomerAnalytics,
              ];
            } else {
              return response.data.catalogAnalyticsCustomerRevenue
                .primaryCustomerAnalytics;
            }
          });
        } else {
          setCustomersRevenueData(null);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          getTotalRecords > pageNumber * pageSize
        ) {
          setPageNumber(pageNumber + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (infiniteLoaderForCustomersRef.current) {
      observer.observe(infiniteLoaderForCustomersRef.current);
    }

    return observer;
  };

  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoaderForCustomersRef.current) {
        observer.unobserve(infiniteLoaderForCustomersRef.current);
      }
    };
  }, [customersRevenueData, getTotalRecords]);

  useEffect(() => {
    getRegionsRevenueData();
  }, [pageNumber]);

  useEffect(() => {
    return () => {
      if (
        !window.location.pathname.includes("/productRevenueByRegions") &&
        !window.location.pathname.includes("/productAnalytics")
      ) {
        localStorage.removeItem("salesOverviewData");
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-white p-8 font-inter">
      <div className="w-full">
        <div className="mb-4 flex items-center gap-4">
          <Link
            className="flex items-center gap-1 text-gray-600 hover:text-gray-900"
            to={"/productAnalytics"}>
            <ArrowLeftIcon className="h-5 w-5" />
            {`Back to Sales Overview`}
          </Link>
          {/* <h2 className="text-2xl font-semibold">All Products</h2> */}
        </div>
        <div className="h-70v w-full">
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <h2 className="text-[17px] font-semibold">Sales by Customer</h2>
            </div>
          </div>
          <SalesOrderRevenueTable
            columns={analyticsCustomerscolumns}
            data={customersRevenueData}
            isInfiniteLoading={true}
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalDataCount={getTotalRecords}
            infiniteLoaderRef={infiniteLoaderForCustomersRef}
            isLoading={isLoading}
            noDataFoundMessage={"No Customers Available"}
          />
          {!isLoading && (
            <div className="mt-4 flex justify-end border-t border-gray-200 bg-white">
              <CustomPagination
                showDataCountOnly={true}
                currentPage={pageNumber}
                pageSize={pageSize}
                totalDataCount={getTotalRecords}
                onPageChange={(page) => {}}
                currentDataCount={customersRevenueData?.length || 0}
                showSizeChanger={false}
                onPageSizeChange={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductRevenueByCustomers;
