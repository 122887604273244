import { useEffect, useState, useContext } from "react";
import PageTitle from "#components/utils/PageTitle";
import Tabs from "#components/utils/Tabs";
import OrderStats from "#components/dashboard/OrderStats";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import { useForm } from "#hooks/useForm";
import InventoryStats from "#components/inventoryDashboard/InventoryStats";
import { makeDetailedStatQueries } from "../components/inventoryDashboard/queries";
import withDashboardLogic from "#components/HOC/withDashboardLogic.jsx";
import CustomDashboardComponent from "#components/dashboard/CustomDashboard";
import DashboardForm from "#components/dashboard/DashboardForm";
import SpaceStats from "#components/spaceManagementDashboard/SpaceStats";
import moment from "moment-timezone";
import { AuthContext } from "#contexts/auth";
import PackageVelocityMetricsOBStats from "#components/packageVelocityMetricsOBStats/PackageVelocityMetricsOBStats";
import MerchantSpecificMetricsStats from "../components/merchantSpecificMetricsDashboard/MerchantSpecificMetricsStats";
import InboundStats from "../components/packageVelocityMetricsIBDashboard/InboundStats";
const InventoryDashboardName = "Inventory";
const SpaceManagementDashboardName = "Space Management";
const PackageVelocityMetricsINDashboardName = "Inbound";
const PackageVelocityMetricsOBDashboardName = "Outbound";
const OrdersDashboardName = "Orders";
const MerchantSpecificMetricsDashboardName = "Merchant";
const VolumeMetricsDashboardName = "Volume Metrics";
const PreRecievingMetricsDashboardName = "Pre Recieving Metrics";

const Dashboard = ({
  dashboards,
  graphs,
  dashboardGraphMap,
  tabsKey,
  addDashboard,
  editDashboard,
  selectedDashboard,
  setSelectedDashboard,
  selectedGraph,
  setSelectedGraph,
  onChange: onDashboardChange,
  global,
  setGlobal,
  onGraphChange,
  cubeMeta,
  addGraph,
  deleteDashboard,
  sectionPermissions,
  tenantId,
  customers,
  warehouses,
  customersList,
  tenant,
}) => {
  const auth = useContext(AuthContext);
  const [dateRange, setDateRange] = useState("Last 7 days");
  const [selectedStatDetail, setSelectedStatDetail] = useState(null);
  const [selectedStatQuery, setSelectedStatQuery] = useState(null);
  const [dashboardComponentsMap, setDashboardComponentsMap] = useState({});
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [customDate, setCustomDate] = useState(makeDefaultCustomDateRange());

  // if (!["admin", "customer"].includes(auth?.user?.role?.toLowerCase())) {
  //   return (
  //     <div className="flex items-center justify-center text-2xl h-full mt-4">
  //       Coming Soon! Keep watching this space.
  //     </div>
  //   );
  // }

  const { onChange, onSubmit, values } = useForm(
    submitCustomDateCallback,
    makeDefaultCustomDateRange(),
  );
  const DateRangeOptions = [
    { name: "Today" },
    { name: "Yesterday" },
    { name: "This week" },
    { name: "This month" },
    { name: "Last 7 days" },
    { name: "Last 30 days" },
    { name: "Last week" },
    { name: "Last month" },
    { name: `Custom Date` },
  ];

  function submitCustomDateCallback() {
    setCustomDate(values);
  }
  useEffect(() => {
    const ret = {};
    const dashboardNames = Object.keys(dashboardGraphMap);
    dashboardNames.forEach((dashboardName, i) => {
      const dashboard = dashboardGraphMap[dashboardName];
      ret[dashboardName] = (
        <CustomDashboardComponent
          dashboard={dashboard}
          selectedGraph={selectedGraph}
          setSelectedGraph={setSelectedGraph}
          onChange={onGraphChange}
          dashboards={dashboards}
          cubeMeta={cubeMeta}
          addGraph={addGraph}
          dateRange={dateRange}
          selectedDashboard={selectedDashboard}
          setSelectedDashboard={setSelectedDashboard}
          deleteDashboard={deleteDashboard}
        />
      );

      if (i === dashboardNames.length - 1) {
        setDashboardComponentsMap(ret);
      }
    });
  }, [dashboardGraphMap, selectedGraph, dashboards, cubeMeta, dateRange]);

  useEffect(() => {
    localStorage.removeItem("analyticsData");
    localStorage.removeItem("salesOverviewData");
  }, []);

  const constructTabs = () => {
    const tabs = {
      [OrdersDashboardName]: (
        <OrderStats
          dateRange={
            dateRange === "Custom Date"
              ? [customDate.start, customDate.end]
              : dateRange
          }
          setDateRange={setDateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      ),
      // Shipments: <ShippingStats />,
      // Custom: (
      //   <MyDashboard dateRange={dateRange} setDateRange={setDateRange} />
      // ),
      // Operations: <OperationsStats />,
      // Inbound: <h1>Henlo</h1>,
      [InventoryDashboardName]: (
        <InventoryStats
          dateRange={dateRange}
          customDate={customDate}
          setDateRange={setDateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      ),
      [SpaceManagementDashboardName]: (
        <SpaceStats
          dateRange={dateRange}
          customDate={customDate}
          setDateRange={setDateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      ),
      [PackageVelocityMetricsINDashboardName]: (
        <InboundStats
          dateRange={dateRange}
          customDate={customDate}
          setDateRange={setDateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      ),
      // [PackageVelocityMetricsOBDashboardName]: (
      //   <PackageVelocityMetricsOBStats
      //     dateRange={dateRange}
      //     customDate={customDate}
      //     setDateRange={setDateRange}
      //     tenantId={tenantId}
      //     customers={customers}
      //     warehouses={warehouses}
      //   />
      // ),

      // [MerchantSpecificMetricsDashboardName]: (
      //   <MerchantSpecificMetricsStats
      //     dateRange={dateRange}
      //     customDate={customDate}
      //     setDateRange={setDateRange}
      //     tenantId={tenantId}
      //     customers={customers}
      //     warehouses={warehouses}
      //   />
      // ),
      // [VolumeMetricsDashboardName]: (
      //   <SpaceStats
      //     dateRange={dateRange}
      //     customDate={customDate}
      //     setDateRange={setDateRange}
      //     tenantId={tenantId}
      //     customers={customers}
      //     warehouses={warehouses}
      //   />
      // ),
      // [PreRecievingMetricsDashboardName]: (
      //   <SpaceStats
      //     dateRange={dateRange}
      //     customDate={customDate}
      //     setDateRange={setDateRange}
      //     tenantId={tenantId}
      //     customers={customers}
      //     warehouses={warehouses}
      //   />
      // ),
      ...dashboardComponentsMap,
    };
    if (sectionPermissions) {
      if (
        sectionPermissions.find((section) => section.route.includes("orders"))
          ?.readable === false
      ) {
        delete tabs[OrdersDashboardName];
      }
      if (
        sectionPermissions.find((section) =>
          section.route.includes("inventory"),
        )?.readable === false
      ) {
        delete tabs[InventoryDashboardName];
      }
      if (
        sectionPermissions.find((section) =>
          section.route.includes("spaceManagement"),
        )?.readable === false
      ) {
        delete tabs[SpaceManagementDashboardName];
      }
    }
    if (
      tenant?.typeOfCustomer?.includes("Prep Center") === false ||
      !["admin"].includes(auth?.user?.role?.toLowerCase())
    ) {
      delete tabs[PackageVelocityMetricsINDashboardName];
    }
    return tabs;
  };
  return (
    <div className="p-4">
      <div className="flex items-center">
        <div className="flex w-full">
          <PageTitle>Dashboard</PageTitle>
        </div>
        <div className="w-48">
          <RoundedDropdown
            placeholder={"Date Range"}
            list={DateRangeOptions}
            labelKey="name"
            valueKey="name"
            name="deviceType"
            selectedValue={dateRange}
            setSelected={(e) => {
              setDateRange(e);
              if (e === "Custom Date") {
                setShowCustomDateRange(true);
              }
              setSelectedStatQuery(
                makeDetailedStatQueries(e).find(
                  (query) => query.statKey === selectedStatDetail,
                ),
              );
            }}
          />
        </div>
      </div>
      {/* <AddButton
        text="Add Dashboard"
        onClick={() => {
          setSelectedDashboard({});
        }}
        styles={["text-sm"]}
      /> */}
      {selectedDashboard && (
        <DashboardForm
          onClose={() => {
            setSelectedDashboard(null);
          }}
          title={selectedDashboard.id ? "Edit Dashboard" : "Add Dashboard"}
          onChange={onDashboardChange}
          selectedDashboard={selectedDashboard}
          onSubmit={() => {
            selectedDashboard.id
              ? editDashboard(selectedDashboard)
              : addDashboard(selectedDashboard);
          }}
          global={global}
          setGlobal={setGlobal}
        />
      )}
      <div className="h-4"></div>
      {showCustomDateRange && (
        <Modal
          title="Set Custom Date"
          negativeAction={() => setShowCustomDateRange(false)}
          positiveAction={(e) => {
            setShowCustomDateRange(false);
            onSubmit(e);
          }}>
          <div className="space-y-4">
            <div className="flex-1">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Start Date
                </span>
              </label>
              <TextField
                type="date"
                id="start"
                label="Select Start Date"
                placeholder=" "
                onChange={onChange}
                value={values["start"]}
              />
            </div>
            <div className="flex-1">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  End Date
                </span>
              </label>
              <TextField
                type="date"
                id="end"
                label="Select End Date"
                placeholder=" "
                onChange={onChange}
                value={values["end"]}
              />
            </div>
          </div>
        </Modal>
      )}
      {tenantId && customers && warehouses && (
        <Tabs key={"tabs-" + tabsKey.toString()} tabs={constructTabs()} />
      )}
    </div>
  );
};

export default withDashboardLogic(Dashboard);

const makeDefaultCustomDateRange = () => ({
  start: moment().subtract(7, "d").format("YYYY-MM-DD"),
  end: moment().format("YYYY-MM-DD"),
});
