import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  FETCH_OR_ASSIGN_BUNDLE_PICKING_TASK,
  SCAN_BUNDLE_PICKING_ITEM,
  CONFIRM_BUNDLE_PICKING_ITEM,
  CONFIRM_BUNDLE_PICKING_DROPOFF,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withBundlePickerLogic = (WrappedComponent) => {
  return (props) => {
    // state declarations
    const [customer, setCustomer] = useState(undefined); // TO DO: set customer from batch details.
    const [currentBatch, setCurrentBatch] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [batchfilters, setBatchfilters] = useState({});

    // context declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // query declarations
    const confirmBundlePickingItemQuery = useQuery(CONFIRM_BUNDLE_PICKING_ITEM);
    const confirmBundlePickingDropoffQuery = useQuery(
      CONFIRM_BUNDLE_PICKING_DROPOFF,
    );
    const scanBundlePickingItemQuery = useQuery(SCAN_BUNDLE_PICKING_ITEM);
    const fetchBundlePickingTaskQuery = useQuery(
      FETCH_OR_ASSIGN_BUNDLE_PICKING_TASK,
    );
    const assignBundlePickingTaskQuery = useQuery(
      FETCH_OR_ASSIGN_BUNDLE_PICKING_TASK,
    );

    useEffect(() => {
      if (auth && auth.user && auth.user.warehousesList) {
        const warehouses = auth.user.warehousesList;
        if (warehouses.length === 1) {
          setBatchfilters({
            warehouse: { value: warehouses.map((i) => i.id)[0] },
          });
        }
      }
      fetchBundlePickingTaskQuery.fetchData({});
    }, []);

    // fetch bundle picking task query
    useEffect(() => {
      fetchBundlePickingTaskQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (fetchBundlePickingTaskQuery.data) {
        const batch =
          fetchBundlePickingTaskQuery.data.fetchOrAssignBundlePickingTask;

        if (batch) {
          setCurrentBatch(batch);
          setCurrentItem(batch.currentItem);
        } else {
          setCurrentBatch(null);
          setCurrentItem(null);
        }
      }

      if (fetchBundlePickingTaskQuery.error) {
        appState.setAlert(
          fetchBundlePickingTaskQuery.error.message,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      fetchBundlePickingTaskQuery.loading,
      fetchBundlePickingTaskQuery.data,
      fetchBundlePickingTaskQuery.error,
    ]);

    // assign bundle picking task query
    useEffect(() => {
      assignBundlePickingTaskQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (assignBundlePickingTaskQuery.data) {
        const batch =
          assignBundlePickingTaskQuery.data.fetchOrAssignBundlePickingTask;

        if (batch) {
          setCurrentBatch(batch);
          setCurrentItem(batch.currentItem);
        } else {
          appState.setAlert("No Bundle Available to Pick", "error", 5000);
          setCurrentBatch(null);
          setCurrentItem(null);
        }
      }

      if (assignBundlePickingTaskQuery.error) {
        appState.setAlert(
          assignBundlePickingTaskQuery.error.message,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      assignBundlePickingTaskQuery.loading,
      assignBundlePickingTaskQuery.data,
      assignBundlePickingTaskQuery.error,
    ]);

    // scan barcode
    useEffect(() => {
      scanBundlePickingItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (scanBundlePickingItemQuery.data) {
        appState.setAlert(
          scanBundlePickingItemQuery.data.scanBundlePickingItem.message,
          "success",
          4000,
        );
        fetchBundlePickingTaskQuery.fetchData({});
      }

      if (scanBundlePickingItemQuery.error) {
        appState.setAlert(
          scanBundlePickingItemQuery.error.message,
          "error",
          5000,
        );
        fetchBundlePickingTaskQuery.fetchData({});
      }
    }, [
      scanBundlePickingItemQuery.loading,
      scanBundlePickingItemQuery.data,
      scanBundlePickingItemQuery.error,
    ]);

    // confirm pick item
    useEffect(() => {
      confirmBundlePickingItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (
        confirmBundlePickingItemQuery.data?.confirmBundlePickingItem?.message
      ) {
        appState.setAlert(
          confirmBundlePickingItemQuery.data.confirmBundlePickingItem.message,
          "success",
          5000,
        );

        fetchBundlePickingTaskQuery.fetchData({});
      }

      if (confirmBundlePickingItemQuery.error) {
        appState.setAlert(
          confirmBundlePickingItemQuery.error.message,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      confirmBundlePickingItemQuery.loading,
      confirmBundlePickingItemQuery.data,
      confirmBundlePickingItemQuery.error,
    ]);

    // confirm drop off
    useEffect(() => {
      confirmBundlePickingDropoffQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (
        confirmBundlePickingDropoffQuery.data?.confirmBundlePickingDropoff
          ?.message
      ) {
        appState.setAlert(
          confirmBundlePickingDropoffQuery.data.confirmBundlePickingDropoff
            .message,
          "success",
          5000,
        );
        assignBundlePickingTaskQuery.fetchData({});
      }

      if (confirmBundlePickingDropoffQuery.error) {
        appState.setAlert(
          confirmBundlePickingDropoffQuery.error.message,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      confirmBundlePickingDropoffQuery.loading,
      confirmBundlePickingDropoffQuery.data,
      confirmBundlePickingDropoffQuery.error,
    ]);

    /**
     * Updates the batch filters with a given field and value.
     *
     * @function
     * @param {string} field - The name of the filter to change.
     * @param {string|number|boolean|Object} value - The value to set for the specified filter.
     */
    const onChangeDropdown = (field, value) => {
      const filters = {
        ...batchfilters,
      };

      filters[field] = value;
      setBatchfilters({ ...filters });
    };

    const onChangeScannedQuantity = (qty) => {
      if (qty <= currentItem.quantity && qty >= 0) {
        setCurrentItem({ ...currentItem, scannedQuantity: parseInt(qty) });
      } else {
        appState.setAlert("Please enter correct quantity", "error", 5000);
      }
    };

    /**
     * Gets a new batch
     * If the warehouse is not selected, an error alert is shown.
     */
    const getNewBatch = () => {
      if (!batchfilters?.warehouse?.value) {
        appState.setAlert("Please select the Warehouse", "error", 5000);
        return;
      }

      assignBundlePickingTaskQuery.fetchData({
        warehouse: batchfilters.warehouse.value,
      });
    };

    const scanBundlePickingItem = (code) => {
      scanBundlePickingItemQuery.fetchData({
        task: currentBatch.id,
        barcode: code,
      });
    };

    const confirmPickItem = () => {
      confirmBundlePickingItemQuery.fetchData({
        task: currentBatch.id,
        quantity: currentItem.scannedQuantity,
      });
    };

    const confirmDropoff = () => {
      confirmBundlePickingDropoffQuery.fetchData({
        task: currentBatch.id,
        station: currentBatch.station,
      });
    };

    return (
      <WrappedComponent
        currentItem={currentItem}
        currentBatch={currentBatch}
        setCurrentBatch={setCurrentBatch}
        getNewBatch={getNewBatch}
        scanBarcode={(code) => scanBundlePickingItem(code)}
        confirmPickItem={confirmPickItem}
        confirmDropoff={confirmDropoff}
        loading={
          fetchBundlePickingTaskQuery.loading ||
          assignBundlePickingTaskQuery.loading
        }
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        onChangeScannedQuantity={onChangeScannedQuantity}
        batchfilters={batchfilters}
        onChangeDropdown={onChangeDropdown}
      />
    );
  };
};

export default withBundlePickerLogic;
