import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import CheckBox from "./CheckBox";
import { twMerge } from "tailwind-merge";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";

const AutoCompleteMultiSelect = ({
  label,
  options,
  selectedValues,
  setSelectedValues,
  placeholder,
  labelClasses,
  selectClasses,
  optionClasses,
  multiSelect = "tag",
  maxTagCount = 1,
  dropdownOpen,
  setDropdownOpen,
  onChange,
}) => {
  const dropdownRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((option) =>
        option.name?.toLowerCase().includes(searchValue?.toLowerCase()),
      );
    }
    return options;
  }, [searchValue, options]);

  const handleOptionClick = useCallback(
    (option) => {
      if (onChange) onChange(option);
      else {
        const exists = selectedValues.find((item) => item.id === option.id);
        if (exists) {
          setSelectedValues((prev) =>
            prev.filter((item) => item.id !== option.id),
          );
        } else {
          setSelectedValues((prev) => [
            ...prev,
            { name: option.name, id: option.id },
          ]);
        }
      }
    },
    [selectedValues, setSelectedValues],
  );

  const handleDeselect = useCallback(
    (id) => {
      setSelectedValues((prev) => prev.filter((item) => item.id !== id));
    },
    [setSelectedValues],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
  };

  const renderSelectedValues = () => {
    if (multiSelect === "count") {
      return (
        <span className="text-gray-600">{`${selectedValues.length} selected`}</span>
      );
    }

    if (multiSelect === "tag") {
      if (!maxTagCount || selectedValues.length <= maxTagCount) {
        return selectedValues.map((item) => (
          <span
            key={item.id}
            className="flex items-center gap-1 rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
            {item.name}
            <button
              className="text-xs"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleDeselect(item.id);
              }}>
              <XIcon className="h-5 w-5 text-white" />
            </button>
          </span>
        ));
      } else {
        const visibleTags = selectedValues.slice(0, maxTagCount);
        const hiddenTagCount = selectedValues.length - maxTagCount;
        return (
          <>
            {visibleTags.map((item) => (
              <span
                key={item.id}
                className="flex items-center gap-1 rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
                {item.name}
                <button
                  className="text-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDeselect(item.id);
                  }}>
                  <XIcon className="h-5 w-5 text-white" />
                </button>
              </span>
            ))}
            <span className="rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">{`+${hiddenTagCount}`}</span>
          </>
        );
      }
    }

    return null;
  };

  return (
    <div className="relative mb-5" ref={dropdownRef}>
      <p
        className={twMerge(
          `mb-3 text-xs font-medium text-primaryAccent ${labelClasses}`,
        )}>
        {label}
      </p>

      <div
        className={twMerge(
          "relative flex cursor-pointer items-center justify-between rounded border border-gray-300 px-3 py-2 focus:border-primaryAccent focus:outline-none focus:ring-primaryAccent",
          selectClasses,
        )}
        tabIndex={0}
        onClick={toggleDropdown}>
        <div className="flex flex-wrap items-center gap-2">
          {selectedValues.length > 0 ? (
            renderSelectedValues()
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>

        <span>
          {dropdownOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          )}
        </span>
      </div>

      <Transition
        show={dropdownOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="absolute z-10 mt-2 w-full rounded border border-gray-300 bg-white shadow-lg">
        <div className="px-2 py-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="w-full rounded border border-gray-300 p-2"
          />
        </div>

        <div className="max-h-60 overflow-auto">
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option) => {
              if (!option.id || !option.name) return;

              return (
                <div
                  key={option.id}
                  className="flex cursor-pointer items-center px-4 py-2 hover:bg-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOptionClick(option);
                  }}>
                  <CheckBox
                    checked={
                      !!selectedValues.find((item) => item.id === option.id)
                    }
                    readOnly={true}
                    style={{ marginRight: "10px" }}
                  />
                  <span className={optionClasses}>{option.name}</span>
                </div>
              );
            })
          ) : (
            <div className="px-4 py-2 text-gray-500">No options found</div>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default AutoCompleteMultiSelect;

AutoCompleteMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  parentRef: PropTypes.object,
  labelClasses: PropTypes.string,
  optionClasses: PropTypes.string,
  selectClasses: PropTypes.string,
  size: PropTypes.oneOf(["large", "middle", "small"]),
  rest: PropTypes.object,
  dropdownOpen: PropTypes.bool.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};
