import withBundlePickerLogic from "#components/HOC/withBundlePickerLogic";
import PickerNewBatchRequest from "#components/picker/bundle/PickerNewBatchRequest";
import SingleLineItemBatch from "#components/picker/bundle/SingleLineItemBatch";
import PageHeader from "#components/picker/common/PageHeader";
import _ from "lodash";

const BundlePicker = ({
  getNewBatch,
  currentBatch,
  setCurrentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  loading,
  currentItem,
  customer,
  customers,
  onChangeScannedQuantity,
  warehouses,
  batchfilters,
  onChangeDropdown,
}) => {
  if (!loading && !currentBatch) {
    return (
      <>
        <PageHeader customer={customer} customers={customers} />
        <PickerNewBatchRequest
          customers={customers}
          warehouses={warehouses}
          batchfilters={batchfilters}
          onChangeDropdown={onChangeDropdown}
          getNewBatch={getNewBatch}
        />
      </>
    );
  }

  if (currentBatch) {
    return (
      <>
        <PageHeader customer={customer} customers={customers} />
        <SingleLineItemBatch
          currentBatch={currentBatch}
          setCurrentBatch={setCurrentBatch}
          scanBarcode={scanBarcode}
          confirmPickItem={confirmPickItem}
          confirmDropoff={confirmDropoff}
          currentItem={currentItem}
          onChangeScannedQuantity={onChangeScannedQuantity}
        />
      </>
    );
  }
  return null;
};

export default withBundlePickerLogic(BundlePicker);
