import { Link } from "react-router-dom";
import { Skeleton, Tooltip } from "antd";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { analyticsProductsColumns } from "#constants/analytics";
import { getValue } from "#utils/catalogsAndAnalyticsUtils";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import noFilterIcon from "#static/images/nofilter.png";

const ProductsAnalyticsTable = ({
  data,
  selectColumnHandler = () => {},
  redirect = "",
  showTableAsChild = false,
  isLoading = false,
  selectedColumn = "",
  toggle = false,
  toggleButtonHandler = () => {},
  isInfiniteLoading = false,
  infiniteLoaderForAnalyticsProducts = null,
  pageNumber = 1,
  pageSize = 5,
  totalDataCount = 0,
  skuClickHandler = () => {},
}) => {
  return (
    <div
      style={{ height: "calc(70vh - 56px)" }}
      className="relative w-full overflow-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="sticky top-0 z-5 bg-gray-50 shadow-sm">
          <tr>
            {analyticsProductsColumns?.map((column, index) => {
              return (
                <th
                  className={`px-6 py-6 text-left text-base font-normal capitalize tracking-wider ${selectedColumn === column ? "font-semibold text-primaryAccent" : "text-[#717679]"}`}
                  key={`more-product-${index}`}>
                  {column === "product" ? (
                    column
                  ) : (
                    <div className="flex">
                      <button
                        className={`group inline-flex items-center space-x-1 ${!showTableAsChild ? "capitalize" : "uppercase"}`}
                        key={`more-product-${index}`}
                        onClick={() => selectColumnHandler(column)}>
                        {column}
                      </button>
                      <div className="">
                        <ChevronUpIcon
                          className={`ml-1 h-3 w-4 cursor-pointer ${selectedColumn === column && toggle === true ? "text-primaryAccent" : "text-[#717679]"}`}
                          onClick={() => toggleButtonHandler(column, true)}
                          style={{
                            strokeWidth:
                              selectedColumn === column && toggle === true
                                ? 3
                                : 2,
                          }}
                        />
                        <ChevronDownIcon
                          className={`ml-1 h-3 w-4 cursor-pointer ${selectedColumn === column && toggle === false ? "text-primaryAccent" : "text-[#717679]"}`}
                          onClick={() => toggleButtonHandler(column, false)}
                          style={{
                            strokeWidth:
                              selectedColumn === column && toggle === false
                                ? 3
                                : 2,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full divide-y divide-gray-200 bg-white">
          {data.length > 0 ? (
            <>
              {data.map((dataObject, index) => {
                const totalRevenue = getValue(dataObject?.totalRevenue);
                const orderVolume = getValue(dataObject?.orderVolume);
                const orderFrequency = getValue(dataObject?.orderFrequency);
                return (
                  <tr
                    className="relative hover:bg-gray-50"
                    key={`product-performance-${dataObject?.productId}-${index}`}>
                    <td className="whitespace-nowrap px-6 py-6 text-base text-gray-600">
                      <div className="max-w-sm truncate text-base font-semibold text-gray-600">
                        <Tooltip
                          placement="topLeft"
                          title={dataObject?.productName}>
                          {dataObject?.productName}
                        </Tooltip>
                      </div>
                      {dataObject?.sku &&
                        (!showTableAsChild ? (
                          <Link
                            className={
                              "mt-1 block max-w-sm truncate text-xs font-semibold text-primaryAccent hover:text-primaryAccent"
                            }
                            to={skuClickHandler(dataObject?.sku)}>
                            {`SKU: ${dataObject?.sku}`}
                          </Link>
                        ) : (
                          <div className="mt-1 max-w-sm truncate text-xs text-gray-600">{`SKU: ${dataObject?.sku}`}</div>
                        ))}
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`$${totalRevenue}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`${orderVolume}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-6 text-base">
                      <div className="text-gray-600">{`${orderFrequency}`}</div>
                    </td>
                  </tr>
                );
              })}
              {isInfiniteLoading &&
                data &&
                totalDataCount > pageNumber * pageSize && (
                  <div
                    ref={infiniteLoaderForAnalyticsProducts}
                    className="absolute left-0 right-0 items-center justify-center">
                    <LoadingIndicator shouldShowOnPage={false} />
                  </div>
                )}
            </>
          ) : !isLoading ? (
            <tr>
              <td colSpan={"100%"}>
                <div className="flex-col items-center justify-center p-2 text-center">
                  <img src={noFilterIcon} className="mx-auto w-32" />
                  <div className="mt-4 text-center text-xl font-semibold text-gray-600">
                    No Products Available
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            isLoading &&
            data.length === 0 &&
            Array.from({ length: pageSize }).map((_, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {analyticsProductsColumns.map((column) => (
                  <td
                    key={column.key}
                    className={`whitespace-nowrap px-6 py-6 text-base text-gray-900`}>
                    <Skeleton.Input active />
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsAnalyticsTable;
