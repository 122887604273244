import moment from "moment-timezone";

export const getUnixTimeStamp = (date) =>
  moment.tz(moment(date), "YYYY-MM-DD HH:mm:ss", "UTC").valueOf();

export const storeInLocalStorage = (value, key, childkey = "") => {
  let setValue = {};
  if (!childkey) {
    setValue = {
      [key]: value,
    };
  } else {
    setValue = {
      [childkey]: {
        [key]: value,
      },
    };
  }
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  if (analyticsData) {
    if (analyticsData[childkey]) {
      setValue = {
        ...analyticsData,
        [childkey]: {
          ...analyticsData[childkey],
          ...setValue[childkey],
        },
      };
    } else {
      setValue = {
        ...analyticsData,
        ...setValue,
      };
    }
  }
  localStorage.setItem("analyticsData", JSON.stringify(setValue));
};

export const storeInLocalStorageForSalesOverview = (
  value,
  key,
  childkey = "",
) => {
  let setValue = {};
  if (!childkey) {
    setValue = {
      [key]: value,
    };
  } else {
    setValue = {
      [childkey]: {
        [key]: value,
      },
    };
  }
  const analyticsData = JSON.parse(
    localStorage.getItem("salesOverviewData") || null,
  );
  if (analyticsData) {
    if (analyticsData[childkey]) {
      setValue = {
        ...analyticsData,
        [childkey]: {
          ...analyticsData[childkey],
          ...setValue[childkey],
        },
      };
    } else {
      setValue = {
        ...analyticsData,
        ...setValue,
      };
    }
  }
  localStorage.setItem("salesOverviewData", JSON.stringify(setValue));
};

export const getPercentageDetail = (
  total,
  previousTotal,
  selectedComparePeriods,
) => {
  if (selectedComparePeriods && !isNaN(total) && !isNaN(previousTotal)) {
    let isLoss = false;
    let profit = "NA";
    if (Number(previousTotal) > 0) {
      profit =
        ((Number(total) - Number(previousTotal)) / Number(previousTotal)) * 100;
    }
    if (!isNaN(profit) && profit < 0) isLoss = true;
    return {
      profit: !isNaN(profit) ? profit.toFixed(1) : profit,
      isLoss,
    };
  }
  return null;
};

export const getValue = (value) => {
  if (value !== undefined && value !== null) {
    if (!Number.isInteger(value)) {
      if (value.toString().split(".")[1].length > 1) {
        return (Math.floor(value * 100) / 100).toFixed(2);
      }
      return value.toFixed(2);
    }
    return value;
  }
  return 0;
};
