const OrderCards = ({
  title = "",
  total = "",
  previousTotal = null,
  previousTotalDetail = null,
  selectedComparePeriods,
  isCardDataLoading = false,
}) => {
  return (
    <div
      className={`rounded-lg border border-gray-100 bg-white px-4 py-5 shadow sm:p-6`}>
      <div className="flex items-center justify-center">
        <h3 className="text-xl font-medium text-gray-600">{title}</h3>
      </div>
      <div className="mt-1">
        <p className="text-center text-2xl font-semibold text-gray-900">
          {!isCardDataLoading ? `${total}` : "Loading..."}
        </p>
        {!isCardDataLoading && selectedComparePeriods && (
          <div className="mt-1 flex items-center justify-center gap-2">
            <span className="text-sm text-gray-600">
              {`vs ${previousTotal}`}
            </span>
            {previousTotalDetail && (
              <span
                className={`text-sm font-medium ${!previousTotalDetail?.isLoss ? (previousTotalDetail?.profit === "NA" ? "text-gray-600" : "text-green-600") : "text-red-600"}`}>
                {`${previousTotalDetail?.isLoss || previousTotalDetail?.profit === "NA" ? "" : "+"}${previousTotalDetail?.profit}${previousTotalDetail?.profit !== "NA" ? "%" : ""}`}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCards;
