import { useState } from "react";
import { analyticsCategoriescolumns } from "#constants/analytics";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import ProductsAnalyticsTable from "../ProductsAnalyticsTable";

const CategoriesAnalyticsTable = ({
  data = [],
  selectColumnHandler = () => {},
  daysDifference = 0,
}) => {
  const [selectedCategories, setSelectedCategories] = useState(null);
  const getCategoriesProduct = (index) => {
    if (selectedCategories !== index) setSelectedCategories(index);
    else setSelectedCategories(null);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            {analyticsCategoriescolumns.length > 0 && (
              <>
                <th className="w-8 px-6 py-3"></th>
                {analyticsCategoriescolumns?.map((column, index) => {
                  const isCategoryColumn = column === "category";
                  return (
                    <th
                      key={`analytics-grid-${index}`}
                      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 ${isCategoryColumn ? "uppercase" : ""} tracking-wider`}>
                      {isCategoryColumn ? (
                        column
                      ) : (
                        <button
                          key={`analytics-grid-${index}`}
                          className="group inline-flex items-center space-x-1 capitalize"
                          onClick={() => selectColumnHandler(column)}>
                          {column}
                        </button>
                      )}
                    </th>
                  );
                })}
              </>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.length > 0 &&
            data.map((dataObject, index) => {
              let orderFrequency = "-";
              if (daysDifference > 0) {
                orderFrequency =
                  Number(dataObject?.orderVolume) / daysDifference;
                if (!Number.isInteger(orderFrequency)) {
                  orderFrequency = orderFrequency.toFixed(1);
                }
              }
              return (
                <>
                  <tr
                    key={`categories-data-${index}`}
                    className={`${selectedCategories === index ? "bg-blue-50" : ""}`}>
                    <td className="px-6 py-4">
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={() => getCategoriesProduct(index)}>
                        {selectedCategories !== index ? (
                          <ChevronRightIcon className="h-4 w-4" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" />
                        )}
                      </button>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                      {dataObject?.name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm">
                      <div className="text-gray-900">{`$${dataObject?.revenue}`}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm">
                      <div className="text-gray-900">
                        {dataObject?.orderVolume}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm">
                      <div className="text-gray-900">{orderFrequency}</div>
                    </td>
                  </tr>
                  {selectedCategories === index &&
                    dataObject?.products?.length > 0 && (
                      <tr>
                        <td colSpan="5" className="bg-gray-50 px-6 py-4">
                          <div className="rounded-lg border border-gray-200">
                            <ProductsAnalyticsTable
                              data={dataObject?.products}
                              showTableAsChild={true}
                              daysDifference={daysDifference}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriesAnalyticsTable;
