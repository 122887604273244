import React, { useState, useEffect } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
const FbaProductTableReadOnly = ({ inventoryData }) => {
  // Transform the inventory data if necessary
  const transformedData = inventoryData.map((item) => ({
    id: item.productId,
    productId: item.productId,
    image: item?.image,
    productName: item?.name || `SKU: ${item.sku} ASIN: ${item.asin}`,
    expiryDate: item.expiryDate || "-",
    unitsAvailable: item.QuantityReceived || "-",
    unitsEligibleToShip: item.quantity || "-",
    fnsku: item.fnsku,
    sellerSku: item.SellerSKU || "-",
  }));

  const columns = [
    {
      key: "productName",
      title: "Product Name",
      render: (value, row) => (
        <div className="flex items-center">
          <img
            src={row?.image || FALLBACK_IMAGE_URL}
            alt="Product"
            className="mr-3 h-12 w-12"
          />
          <div>
            <p className="text-base font-semibold">{value}</p>
            <p className="text-sm text-gray-500">{`FNSKU: ${row.fnsku}`}</p>
            <p className="text-sm text-gray-500">{row.productName}</p>
          </div>
        </div>
      ),
    },
    // {
    //   key: "expiryDate",
    //   title: "Expiry Date",
    //   render: (value) => (
    //     <span className="text-sm text-gray-700">{value}</span>
    //   ),
    // },
    // {
    //   key: "unitsAvailable",
    //   title: "Units Available",
    //   render: (value) => (
    //     <span className="px-2 py-1 bg-blue-100 rounded-full text-blue-600 text-sm">
    //       {value}
    //     </span>
    //   ),
    // },
    {
      key: "unitsEligibleToShip",
      title: "Units Eligible to Ship",
      render: (value) => (
        <span className="rounded-full bg-green-100 px-2 py-1 text-sm text-green-600">
          {value}
        </span>
      ),
    },
  ];

  return (
    <div className="flex h-100v flex-col justify-between px-8 font-inter">
      {/* <h2 className="mb-4 text-xl font-semibold">Inventory Details</h2> */}
      <CustomTable
        columns={columns}
        data={transformedData}
        isSearchable={true}
        isFilters={false}
        isPagination={false}
        isInfiniteScroll={false}
        pageSizeOptions={["5", "10", "20"]}
        totalDataCountLable="Total Products: "
        totalDataCount={transformedData.length}
      />
    </div>
  );
};

export default FbaProductTableReadOnly;
