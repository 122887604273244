import { useState, useEffect } from "react";
import SlideOverPanel from "#components/common/SlideOverPanel";
import _, { find } from "lodash";
import { CheckIcon } from "@heroicons/react/outline";

const SlideWizard = ({
  onClose,
  title,
  onSubmit,
  steps: _steps,
  children,
  addOverlay = false,
  open,
  setOpen,
}) => {
  const [steps, setSteps] = useState(_steps);
  const [selectedStep, setSelectedStep] = useState(null);

  useEffect(() => {
    setSteps([
      ..._steps.map((step, i) =>
        i === 0
          ? { ...step, id: i, status: "current" }
          : { ...step, id: i, status: "upcoming" },
      ),
    ]);
    setSelectedStep(steps[0]);
  }, []);

  const selectStep = (idx) => {
    setSteps(
      steps.map((step, i) =>
        i === idx
          ? { ...step, status: "current" }
          : { ...step, status: "upcoming" },
      ),
    );
    setSelectedStep(steps.find((_, i) => (i === idx ? true : false)));
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
        skipStep: steps?.[idx]?.skipStep,
      }));

      setSteps(copiedSteps);

      setSelectedStep(steps[foundIdx + 1]);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
        skipStep: steps?.[idx]?.skipStep,
      }));

      setSteps(copiedSteps);
      console.log(foundIdx, steps);
      setSelectedStep(steps[foundIdx - 1]);
    }
  };

  const handleClose = () => {
    if (setOpen) setOpen(false);
    if (onClose) onClose();
  };

  return (
    <SlideOverPanel
      open={open}
      setOpen={setOpen}
      title={title}
      containerStyle={"max-w-6xl"}
      isCrossIconVisible={false}>
      {/* Main content area with padding at the bottom to account for sticky buttons */}
      <div className="flex h-full flex-col p-6">
        <div className="flex-grow overflow-y-auto p-4 pb-20">
          <nav aria-label="Progress">
            <ol
              role="list"
              className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
              {steps.map((step, stepIdx) => (
                <li key={step.name} className="relative md:flex md:flex-1">
                  {step.status === "complete" ? (
                    <div className="group flex w-full items-center">
                      <span className="text-md flex items-center px-6 py-4 font-medium">
                        <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                          <CheckIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-4 text-lg font-medium text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  ) : step.status === "current" ? (
                    <div
                      className="text-md flex items-center px-4 py-4 font-medium"
                      aria-current="step">
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                        <span className="text-indigo-600">
                          {(
                            steps.findIndex((i) => i.id === step.id) + 1
                          ).toString()}
                        </span>
                      </span>
                      <span className="text-md ml-4 font-medium text-indigo-600">
                        {step.name}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="group flex items-center"
                      onClick={() => selectStep(stepIdx)}>
                      <div className="text-md flex items-center px-4 py-4 font-medium">
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900">
                            {(
                              steps.findIndex((i) => i.id === step.id) + 1
                            ).toString()}
                          </span>
                        </span>
                        <span className="text-md ml-4 font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </div>
                    </div>
                  )}

                  {stepIdx !== steps.length - 1 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div
                        className="absolute right-0 top-0 hidden h-full w-5 md:block"
                        aria-hidden="true">
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none">
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
          <div className="mt-4">
            {children(selectedStep?.id === null ? 0 : selectedStep?.id)}
          </div>
        </div>

        {/* Sticky footer with action buttons */}
        <div className="sticky bottom-0 bg-white shadow-md">
          <nav
            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
            aria-label="Pagination">
            <div className="flex flex-1 justify-between sm:justify-end">
              <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
                <div
                  onClick={handleClose}
                  className="text-md inline-flex cursor-pointer items-center justify-center rounded-md border bg-red-600 px-3 py-2 font-montserrat text-base font-medium text-white outline-none hover:bg-red-700 focus:outline-none">
                  Cancel
                </div>
                {selectedStep?.skipStep && (
                  <div
                    onClick={() => {
                      selectedStep.skipStep({ setNextStep });
                    }}
                    className="text-md inline-flex cursor-pointer items-center justify-center rounded-md border bg-gray-500 px-3 py-2 font-montserrat text-base font-medium text-white outline-none">
                    {"Skip"}
                  </div>
                )}
                {selectedStep?.id !== steps[steps.length - 1]?.id && (
                  <div
                    onClick={onSubmit}
                    className={`text-md C2D2DF-300 inline-flex cursor-pointer items-center justify-center rounded-md border px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
                      selectedStep?.id === steps[steps.length - 1]?.id
                        ? "bg-224E73"
                        : "bg-gray-200 text-gray-400"
                    }`}>
                    Submit
                  </div>
                )}
                <div
                  onClick={setPrevStep}
                  className={`text-md inline-flex cursor-pointer items-center justify-center rounded-md border px-3 py-2 font-montserrat text-base font-medium text-white outline-none ${
                    selectedStep?.id === steps[0]?.id
                      ? "bg-gray-200 text-gray-400"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}>
                  {"< Previous"}
                </div>
                <div
                  onClick={setNextStep}
                  className={`text-md inline-flex cursor-pointer items-center justify-center rounded-md border px-3 py-2 font-montserrat text-base font-medium text-white outline-none ${
                    selectedStep?.id === steps[steps.length - 1]?.id
                      ? "bg-gray-200 text-gray-400"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}>
                  {"Next >"}
                </div>
                {selectedStep?.id === steps[steps.length - 1]?.id && (
                  <div
                    onClick={onSubmit}
                    className="text-md inline-flex cursor-pointer items-center justify-center rounded rounded-md border bg-green-600 px-3 py-2 font-montserrat text-base font-medium text-white outline-none hover:bg-green-700">
                    Submit
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
      {addOverlay === true && (
        <div className="absolute left-0 top-0 h-full w-full bg-black opacity-25" />
      )}
    </SlideOverPanel>
  );
};

export default SlideWizard;
