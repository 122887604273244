import React from "react";
import ReactDOMServer from "react-dom/server";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { isOntelTenant } from "./tenantCheck";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf.min.mjs";

const ALERT_TIMEOUT_IN_MS = 5000;

const formatTextWithEllipsis = (text, startLimit, endLimit) => {
  if (!text) return "";

  const cleanText = text.trim();

  // Return original text if it's shorter than or equal to the combined limits
  if (cleanText.length <= startLimit + endLimit) return cleanText;

  // Ensure minimum sensible limits
  const safeStartLimit = Math.max(1, startLimit);
  const safeEndLimit = Math.max(1, endLimit);

  // Create truncated text with ellipsis
  const start = cleanText.slice(0, safeStartLimit);
  const end = cleanText.slice(-safeEndLimit);

  return `${start} ... ${end}`;
};

const PackingLabel = ({
  order,
  height,
  width,
  customers,
  boxNumber,
  totalBoxes,
  appState,
}) => {
  const customer = customers.find((c) => c.id === order?.customer);

  return (
    <div>
      {order.selectedBoxes.map((selectedBox, index) => {
        return (
          <div
            id={`label-container-${index}`}
            key={index}
            style={{
              width,
              height,
              fontFamily: "Arial",
              margin: "0",
              padding: "5px",
              fontSize: "8px",
              border: "1px solid black",
            }}>
            <p>{customer?.packingSlipText}</p>

            {isOntelTenant(appState?.subdomain) && (
              <div
                style={{
                  margin: "5px 0",
                  fontWeight: "bold",
                  fontFamily: "Arial",
                }}>
                <p>Reference# {order?.salesforceCustomerReference}</p>
                <p>PO# {order?.salesforceCustomerPurchaseNumber}</p>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "5px 0",
                fontWeight: "bold",
                fontFamily: "Arial",
              }}>
              <div>
                <p>Order# {order?.orderId}</p>
                <p>
                  {customer?.name} - {customer?.code}
                </p>
              </div>
              <div className="text-right">
                {`Box ${boxNumber} of ${totalBoxes} - ${selectedBox?.totalWeight} ${selectedBox?.weightUnit}`}{" "}
                <br />
                {`${selectedBox?.boxDimension?.length} x ${selectedBox?.boxDimension?.width} x ${selectedBox?.boxDimension?.height} ${selectedBox?.boxDimension?.unit}`}
              </div>
            </div>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                fontFamily: "Arial",
                padding: "10px",
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #000",
                      padding: "2px",
                      textAlign: "left",
                      width: "1.25in",
                    }}>
                    SKU
                  </th>
                  <th
                    style={{
                      border: "1px solid #000",
                      padding: "2px",
                      textAlign: "left",
                      maxWidth: "2.25in",
                    }}>
                    Name
                  </th>
                  <th
                    style={{
                      border: "1px solid #000",
                      padding: "2px",
                      textAlign: "right",
                      width: "0.35in",
                    }}>
                    Qty
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedBox.items?.map((item) => (
                  <tr key={item.sku}>
                    <td style={{ border: "1px solid #000", padding: "2px" }}>
                      {formatTextWithEllipsis(item.sku, 13, 5)}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "2px" }}>
                      {formatTextWithEllipsis(item.name, 25, 12)}
                    </td>
                    <td
                      style={{
                        border: "1px solid #000",
                        padding: "2px",
                        textAlign: "right",
                      }}>
                      {item.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export const mergeBatchLabels = async ({
  orders,
  shippingLabelUrls,
  noOfCopies = 1,
  printer = null,
  download = true,
  appState = null,
  batchId,
  customers,
}) => {
  const convertPDFToImage = async (pdfUrl) => {
    try {
      // Fetch the PDF from AWS S3
      const response = await fetch(pdfUrl);
      if (!response.ok) throw new Error("Failed to fetch PDF");
      const pdfArrayBuffer = await response.arrayBuffer();

      // Load the PDF
      const loadingTask = pdfjsLib.getDocument({ data: pdfArrayBuffer });
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;

      // Array to store images for each page
      const pageImages = [];

      // Process each page
      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);

        // Render the page onto a <canvas>
        const scale = 2;
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;

        // Convert to an image and add to array
        pageImages.push(canvas.toDataURL("image/png"));
      }

      return pageImages;
    } catch (err) {
      appState.setAlert(
        `Error converting PDF: ${err}`,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return [];
    }
  };

  if (!orders || !Array.isArray(orders) || orders.length === 0) {
    return (
      appState &&
      appState.setAlert("Box details not found.", "error", ALERT_TIMEOUT_IN_MS)
    );
  }

  if (
    !shippingLabelUrls ||
    !Array.isArray(shippingLabelUrls) ||
    shippingLabelUrls.length === 0
  ) {
    appState.setAlert(
      "Shipping labels not found.",
      "error",
      ALERT_TIMEOUT_IN_MS,
    );
  }

  try {
    appState.setLoading();

    // Generate all packing labels first
    let packingLabelImages = [];
    let shippingLabelImages = [];

    // First, flatten all boxes from all orders into a single array
    const allBoxes = orders.reduce((acc, order) => {
      const boxesWithOrderInfo = order.selectedBoxes.map((box, boxIndex) => ({
        box,
        order,
        boxIndex,
      }));
      acc.push(...boxesWithOrderInfo);
      return acc;
    }, []);

    // Now process each box individually

    for (const { box, order, boxIndex } of allBoxes) {
      const htmlString = ReactDOMServer.renderToString(
        <PackingLabel
          appState={appState}
          order={{
            ...order,
            selectedBoxes: [box],
          }}
          width={"4in"}
          height={"2in"}
          customers={customers}
          boxNumber={boxIndex + 1}
          totalBoxes={order.selectedBoxes.length}
        />,
      );

      const tempDiv = document.createElement("div");
      document.body.appendChild(tempDiv);
      tempDiv.innerHTML = htmlString;

      const labelContainers = Array.from(
        tempDiv.querySelectorAll("[id^='label-container-']"),
      );

      // Capture each packing label as an image
      for (let i = 0; i < labelContainers.length; i++) {
        try {
          const container = labelContainers[i];
          const packingLabelDataUrl = await htmlToImage.toPng(container, {
            quality: 1,
            width: 384, // 4 inches at 96 DPI
            height: 192, // 2 inches at 96 DPI
          });

          packingLabelImages.push({
            image: packingLabelDataUrl,
            orderId: order.orderId,
          });
        } catch (error) {
          console.error(
            `Error capturing packing label for box ${i + 1}:`,
            error,
          );
          packingLabelImages.push(null);
        }
      }

      tempDiv.remove();
    }

    // Convert shipping label PDFs to images
    shippingLabelImages = shippingLabelUrls.some((label) => label?.url)
      ? await Promise.all(
          shippingLabelUrls?.flatMap(async (label, index) => {
            try {
              const images = await convertPDFToImage(label.url);
              // Convert each image in the array to an object with orderId
              return images.map((image) => ({
                image: image,
                orderId: label.orderId,
              }));
            } catch (error) {
              console.error(`Error capturing shipping label ${index}:`, error);
              return [{ orderId: label.orderId, image: "" }];
            }
          }),
        ).then((results) => results.flat())
      : shippingLabelUrls.map((label) => ({
          orderId: label.orderId,
          image: "",
        }));

    if (shippingLabelImages.length < packingLabelImages.length) {
      // Get all order IDs from packing labels, including duplicates
      const packingOrderIds = packingLabelImages.map((label) => label.orderId);

      // Create a map to track how many times each order ID appears in packing labels
      const packingOrderIdCounts = packingOrderIds.reduce((acc, orderId) => {
        acc[orderId] = (acc[orderId] || 0) + 1;
        return acc;
      }, {});

      // Create a map to track how many times each order ID appears in shipping labels
      const shippingOrderIdCounts = shippingLabelImages.reduce((acc, label) => {
        acc[label.orderId] = (acc[label.orderId] || 0) + 1;
        return acc;
      }, {});

      // For each order ID, add missing entries to match packing label count
      Object.entries(packingOrderIdCounts).forEach(([orderId, count]) => {
        const existingCount = shippingOrderIdCounts[orderId] || 0;
        const missingCount = count - existingCount;

        if (missingCount > 0) {
          // Add empty shipping labels for the missing entries
          const newEntries = Array(missingCount).fill({
            orderId: orderId,
            image: "",
          });
          shippingLabelImages = shippingLabelImages.concat(newEntries);
        }
      });
    }
    // Create final PDF with combined labels
    const finalPdf = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [4, 8], // 4x8 inches
    });
    let isFirstPage = true;

    // Get all unique orderIds from both shipping and packing labels
    const orderIds = [
      ...new Set([
        ...packingLabelImages.map((label) => label.orderId),
        ...shippingLabelImages.map((label) => label.orderId),
      ]),
    ];

    // Process all labels by orderId
    for (const orderId of orderIds) {
      // Get all labels for this orderId (there might be multiple)
      const packingLabels = packingLabelImages.filter(
        (label) => label.orderId === orderId,
      );
      const shippingLabels = shippingLabelImages.filter(
        (label) => label.orderId === orderId,
      );

      // Process each combination of labels for this orderId
      const maxLabels = Math.max(packingLabels.length, shippingLabels.length);

      for (let i = 0; i < maxLabels; i++) {
        const packingLabel = packingLabels[i];
        const shippingLabel = shippingLabels[i];

        // Add a new page (except for the first page)
        if (!isFirstPage) {
          finalPdf.addPage([4, 8]);
        }
        isFirstPage = false;

        // Add shipping label to top portion (4x6 inches)
        if (shippingLabel?.image) {
          finalPdf.addImage(shippingLabel.image, "PNG", 0, 0, 4, 6);
        } else {
          // Add placeholder text if shipping label is missing
          finalPdf.setFontSize(10);
          finalPdf.text(
            "No shipping label available for Order: " + orderId,
            2,
            3,
            { align: "center" },
          );
        }

        // Add packing label to bottom portion (4x2 inches)
        if (packingLabel?.image) {
          finalPdf.addImage(packingLabel.image, "PNG", 0, 6, 4, 2);
        } else {
          // Add placeholder text if packing label is missing
          finalPdf.setFontSize(10);
          finalPdf.text(
            "No packing label available for Order: " + orderId,
            2,
            7,
            { align: "center" },
          );
        }
      }
    }

    // Generate filename
    const filename = `${batchId}.pdf`;

    if (download) {
      // Download the PDF
      finalPdf.save(filename);
    } else {
      // Handle printing using JSPM
      const pdfOutput = finalPdf.output("datauristring");

      const cpj = new JSPM.ClientPrintJob();
      const myPrinter = printer
        ? new JSPM.InstalledPrinter(printer)
        : new JSPM.DefaultPrinter();

      cpj.clientPrinter = myPrinter;

      for (let i = 0; i < noOfCopies; i++) {
        const myFile = new JSPM.PrintFilePDF(
          pdfOutput,
          JSPM.FileSourceType.URL,
          filename,
          1,
        );
        cpj.files.push(myFile);
      }

      cpj.sendToClient();
    }
  } catch (err) {
    appState?.setAlert("Could not generate or merge labels", "error", 3000);
    console.error("Error in generating and merging labels:", err);
    throw err;
  } finally {
    appState.removeLoading();
  }
};
