import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

const WorkflowProductInfo = ({ product, additionalInfo = [] }) => {
  return (
    <div>
      <div className="flex w-full rounded-md border border-gray-400 bg-white p-4">
        <div className="flex items-center">
          <img
            src={
              product && product.images?.length > 0
                ? product.images[0].url
                : FALLBACK_IMAGE_URL
            }
            alt={product?.name}
            className="mr-4 h-36 w-36"
          />
          <div>
            <div className="text-2xl font-medium">{product?.name}</div>
            <div className="text-xl text-gray-500">SKU: {product?.sku}</div>
            {additionalInfo.map((info) => (
              <div className="text-xl text-gray-500">
                {info.label}: {info.value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowProductInfo;
