import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import QRCode from "react-qr-code";
import _ from "lodash";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import WorkflowProductInfo from "#components/products/WorkflowProductInfo";

const sampleExecutedPickingPlan4 = [
  {
    entityType: "INVENTORY_TRACKING_ID",
    status: "PENDING_BREAKDOWN",
    parentCodes: ["CASE_WITH_TRACKED_CHILDREN_2"],
    childUom: "Each",
    skuBinMappingId: "67b058d8ecf0cd3d081561b2",
    scannedCodes: ["527413048726", "548988266023"],
    pickedQuantity: 2,
  },
];

const DetailedView = ({
  currentItem,
  currentBatch,
  scanBarcode,
  confirmPickItem,
  skipItem,
  onChangeScannedQuantity,
  displayScan,
  setDisplayScan,
  currentProduct,
  setCurrentItem,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const scannedBin = currentItem.scannedBin === true;
  const scannedSubTote = currentItem.scannedSubTote === true;

  const isPlanSatisfied = () => {
    return (
      currentItem.executedPickingPlan &&
      currentItem.executedPickingPlan.every((item) => item.status === "PICKED")
    );
  };

  const totalQuantityPicked = currentItem.executedPickingPlan?.reduce(
    (acc, item) => acc + item.pickedQuantity,
    0,
  );

  let confirmItem = false;
  if (scannedBin) {
    confirmItem =
      (!currentItem.executedPickingPlan && !currentItem.pickingPlan) ||
      (currentItem.executedPickingPlan && isPlanSatisfied());
  }

  const additionalProductInfo = () => {
    const info = [];
    if (currentItem.asin) {
      info.push({ label: "ASIN", value: currentItem.asin });
    }
    if (currentItem.upc) {
      info.push({ label: "UPC", value: currentItem.upc?.join(", ") });
    }
    if (currentItem.fnSku) {
      info.push({ label: "FN SKU", value: currentItem.fnSku });
    }

    if (currentItem.customAttributes?.stockMoveSize) {
      info.push({
        label: "Size",
        value: currentItem.customAttributes.stockMoveSize,
      });
    }

    if (currentItem.productAlias) {
      info.push({
        label: "Customer ID",
        value: currentItem.productAlias,
      });
    }

    return info;
  };

  const markActionAsDone = (idx) => {
    setCurrentItem({
      ...currentItem,
      executedPickingPlan: currentItem.executedPickingPlan.map((item, index) =>
        index === idx ? { ...item, status: "PICKED" } : item,
      ),
    });
  };

  const removeAction = (idx) => {
    setCurrentItem({
      ...currentItem,
      executedPickingPlan: currentItem.executedPickingPlan.filter(
        (_, index) => index !== idx,
      ),
    });
  };

  const renderActionOnPlan = (plan, idx) => {
    return (
      <div className="flex items-center">
        {plan.status === "PENDING_BREAKDOWN" && plan.pickedQuantity > 0 && (
          <button
            className="mr-2 cursor-pointer rounded-md bg-2C7695 px-4 py-2 text-white"
            onClick={() => markActionAsDone(idx)}>
            Mark as Done
          </button>
        )}
        <button
          className="mr-2 cursor-pointer rounded-md bg-red-600 px-4 py-2 text-white"
          onClick={() => removeAction(idx)}>
          Remove
        </button>
      </div>
    );
  };

  const renderExecutedPickingPlan = () => {
    return (
      <div className="text-xl">
        <PageTitle>Executed Picking Plan</PageTitle>
        <table className="w-full border-collapse">
          <thead className="bg-primaryAccent text-white">
            <tr>
              <th className="border border-green-400 p-2 text-left">
                Scanned Codes
              </th>
              <th className="border border-green-400 p-2 text-left">
                Picked Quantity
              </th>
              <th className="border border-green-400 p-2 text-left">
                Scan Hierarchy
              </th>
              <th className="border border-green-400 p-2 text-left">Status</th>
              <th className="border border-green-400 p-2 text-left">Pending</th>
              <th className="border border-green-400 p-2 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItem.executedPickingPlan?.map((plan, index) => (
              <tr key={index} className="bg-white">
                <td className="border border-green-400 p-2">
                  {plan.scannedCodes?.map((code, i) => (
                    <div key={i}>{code}</div>
                  )) || "-"}
                </td>
                <td className="border border-green-400 p-2">
                  {plan.pickedQuantity || "-"}
                </td>
                <td className="border border-green-400 p-2">
                  {plan.parentCodes?.join("<br />&nbsp;&nbsp;") || "-"}
                </td>
                <td
                  className={`border border-green-400 p-2 ${
                    plan.status === "PENDING_BREAKDOWN"
                      ? "text-red-500"
                      : "text-green-500"
                  }`}>
                  {plan.status}
                </td>
                <td
                  className={`border border-green-400 p-2 ${
                    plan.status === "PENDING_BREAKDOWN"
                      ? "text-red-500"
                      : plan.needsBreak
                        ? "text-orange-500"
                        : "text-green-500"
                  }`}>
                  {plan.status === "PENDING_BREAKDOWN"
                    ? `Scan '${plan.childUom}' Code`
                    : plan.needsBreak
                      ? "OPEN & PICK"
                      : "-"}
                </td>
                <td
                  className={`border border-green-400 p-2 ${
                    plan.status === "PENDING_BREAKDOWN"
                      ? "text-red-500"
                      : "text-green-500"
                  }`}>
                  {renderActionOnPlan(plan, index)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPickingPlan = () => {
    const pendingItem = currentItem.pickingPlan.find(
      (item) => item.status === "PENDING",
    );

    if (!pendingItem) {
      return null;
    }

    return (
      <div className="mb-4 rounded border border-[#E3A400] bg-[#FFF9E9] p-4 text-xl">
        <div className="mb-2 flex items-center font-medium">
          <i className="fas fa-info-circle mr-2"></i> Suggested Picking Plan
        </div>
        <ul className="ml-4 list-disc">
          <li>
            {pendingItem.isPartialContainer
              ? pendingItem.isBaseUom
                ? `Scan any ${pendingItem.formFactor} from this location`
                : `Scan any unopened ${pendingItem.formFactor} or an open ${pendingItem.formFactor} with at least ${pendingItem.breakdown?.quantity} ${pendingItem.breakdown?.formFactor} from this location`
              : `Scan ${!pendingItem.isBaseUom ? "any unopened" : "any"} ${
                  pendingItem.formFactor
                } from this location`}
          </li>
        </ul>
      </div>
    );
  };

  const groupedByOrderIds = _.groupBy(
    currentBatch?.items?.filter(
      (item) =>
        item.sku === currentItem.sku &&
        item.binLocation === currentItem.binLocation,
    ) || [],
    "order",
  );

  return (
    <div className="max-w-8xl mx-auto flex-col items-center justify-center">
      {scannedBin && currentItem?.pickingPlan && renderPickingPlan()}
      <div className="pt-8">
        <WorkflowProductInfo
          product={currentProduct}
          additionalInfo={additionalProductInfo()}
        />

        <div className="grid grid-cols-3 pt-4">
          <div className="col-span-3 w-full flex-1 bg-gray-100">
            <div className="space-y-2 text-xl font-medium text-454A4F">
              <LineItemValue
                value={currentItem.binLocation}
                title="Bin Location"
                idx={2}
              />
              {currentItem.tote && (
                <LineItemValue value={currentItem.tote} title="Tote" idx={3} />
              )}
              {currentItem.bestByDate && (
                <LineItemValue
                  value={currentItem.bestByDate}
                  title="Expiry Date"
                  idx={3}
                />
              )}
              <LineItemValue
                value={`${currentItem.quantity} ${currentItem.formFactor}`}
                title="Quantity"
                idx={5}
              />
            </div>
          </div>
        </div>

        {currentBatch?.tote &&
          currentBatch?.attributes?.multipleLineItemBatch && (
            <div className="mt-4 w-full">
              <table className="w-full border-collapse">
                <thead className="bg-primaryAccent text-white">
                  <tr>
                    <th className="border border-green-400 p-2 text-left">
                      Order ID
                    </th>
                    <th className="border border-green-400 p-2 text-left">
                      Qty
                    </th>
                    {currentBatch?.attributes?.multipleLineItemBatch && (
                      <th className="border border-green-400 p-2 text-left">
                        Sub-Tote
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedByOrderIds)
                    .sort((a, b) => {
                      const subToteA =
                        currentBatch?.attributes?.orderSubTotes?.[a] || 0;
                      const subToteB =
                        currentBatch?.attributes?.orderSubTotes?.[b] || 0;
                      return subToteA - subToteB;
                    })
                    .map((orderId) => (
                      <tr key={orderId} className="bg-white">
                        <td className="border border-green-400 p-2">
                          {
                            currentBatch.attributes?.orders?.find(
                              (item) => item.id === orderId,
                            )?.orderId
                          }
                        </td>
                        <td className="border border-green-400 p-2">
                          {_.sumBy(groupedByOrderIds[orderId], "quantity")}
                        </td>
                        {currentBatch?.attributes?.multipleLineItemBatch && (
                          <td className="border border-green-400 p-2">
                            {currentBatch?.attributes?.orderSubTotes[orderId]}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
      </div>

      <div
        onClick={() => {
          if (scannedBin) {
            if (currentItem.tote && !currentItem.scannedTote) {
              scanBarcode(currentItem.tote);
              return;
            }
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              scannedBin && scannedSubTote && "border-gray-600 bg-gray-400"
            }`}>
            <QRCode
              value={qrcodeValue(scannedBin, currentItem.binLocation)}
              size="70"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(scannedBin)}
        </div>
      </div>

      {scannedBin &&
        currentItem.executedPickingPlan &&
        renderExecutedPickingPlan()}

      <div className="mt-2 flex-col items-center justify-center bg-white p-10 pb-5 pt-5">
        <div>
          <TextField
            type="text"
            id="name"
            label="Scan Bin/Tote/Item"
            placeholder=" "
            onChange={(e) => setManualValue(e.target.value)}
            value={manualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                scanBarcode(manualValue);
                setManualValue("");
              }
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {!scannedBin && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Bin Location
        </div>
      )}

      {scannedBin &&
        !currentItem?.pickingPlan &&
        currentBatch?.skuConfirmationNeeded && (
          <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
            Please Scan Item
          </div>
        )}

      {currentItem?.scannedSkus > 0 || displayScan ? (
        <div className="mt-10 grid grid-cols-2 self-center">
          <div className="justify-cente flex items-center bg-white p-6 py-2 text-3xl">
            <div className="py-2 text-3xl text-5F666B">Picked Quantity</div>
          </div>
          <div className="flex items-center justify-center bg-white p-6 py-2 text-3xl text-5F666B">
            <TextField
              type="number"
              id="scannedSkus"
              label="Picked Quantity"
              placeholder=" "
              onChange={(e) => {
                onChangeScannedQuantity(e.target.value);
                setDisplayScan(true);
              }}
              value={currentItem.scannedSkus}
            />
          </div>
        </div>
      ) : null}

      <div className="mt-20 flex items-center justify-center">
        {confirmItem && (
          <div
            className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={confirmPickItem}>
            {isPlanSatisfied() && totalQuantityPicked >= currentItem.quantity
              ? "Confirm Pick"
              : "Confirm Short Pick"}
          </div>
        )}
        {!confirmItem && (
          <div className="w-64 rounded-md bg-gray-400 py-4 text-center text-2xl text-white">
            Confirm Item
          </div>
        )}
        {skipItem && (
          <div
            className="ml-4 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
            onClick={skipItem}>
            Skip Item
          </div>
        )}
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-6xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`flex flex-1 items-center rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const qrcodeValue = (scannedBin, binLocation) => {
  if (scannedBin) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (scannedBin) => {
  if (scannedBin) {
    return `Scanned`;
  }

  if (!scannedBin) {
    return "Scan Bin";
  }
};

export default DetailedView;
