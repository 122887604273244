import { useState, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_ORDER_INFO } from "#queries/index";
import {
  ANALYTICS_DATE_RANGES,
  getComparePeriodDate,
  getDateInDDMMYYYYFormate,
  getNumberOfDaysDifference,
  getStartDateWithHhMmSsFormate,
  getEndDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import {
  getPercentageDetail,
  getUnixTimeStamp,
  getValue,
  storeInLocalStorageForSalesOverview,
} from "#utils/catalogsAndAnalyticsUtils";
import DateRangeDropDown from "./DateRangeDropDown";
import OrderCards from "./OrderCards";
import RegionsByRevenueChart from "./RegionsByRevenueChart";
import CustomersByRevenue from "./CustomersByRevenue";

const AnalyticsSalesOverview = ({
  isProductLoading = false,
  product = null,
}) => {
  const getAnalyticsOrderInfoQuery = useQuery(
    GET_CATALOGS_ANALYTICS_ORDER_INFO,
  );
  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const defaultSelectedDate = timePeriods[3];
  const getStartEndDate = getDateInDDMMYYYYFormate(
    ANALYTICS_DATE_RANGES[defaultSelectedDate],
  );
  const [startEndDate, setStartEndDate] = useState(getStartEndDate || []);
  const [selectedComparePeriods, setSelectComparePeriods] = useState(null);
  const [isCardDataLoading, setIsCardDataLoading] = useState(false);
  const [analyticsCardsData, setAnalyticsCardsData] = useState(null);
  const salesOverviewData = JSON.parse(
    localStorage.getItem("salesOverviewData") || null,
  );
  const daysDifference = getNumberOfDaysDifference(
    startEndDate[0],
    startEndDate[1],
  );
  let comparePeriodDate = [];
  if (selectedComparePeriods) {
    comparePeriodDate = getComparePeriodDate(
      startEndDate,
      selectedComparePeriods,
    );
  }

  const orderCards = [
    {
      title: "Total Sales",
      total: `$${getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalSales)}`,
      previousTotal: `$${getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalSales)}`,
      previousTotalDetail: getPercentageDetail(
        getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalSales),
        getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalSales),
        selectedComparePeriods,
      ),
    },
    {
      title: "Total Orders",
      total: getValue(
        analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalOrders,
      ),
      previousTotal: getValue(
        analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalOrders,
      ),
      previousTotalDetail: getPercentageDetail(
        getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalOrders),
        getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalOrders),
        selectedComparePeriods,
      ),
    },
    {
      title: "Average Order Value",
      total: `$${getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.averageOrderValue)}`,
      previousTotal: `$${getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.averageOrderValue)}`,
      previousTotalDetail: getPercentageDetail(
        getValue(
          analyticsCardsData?.primaryAnalyticsCard?.[0]?.averageOrderValue,
        ),
        getValue(
          analyticsCardsData?.comparedAnalyticsCard?.[0]?.averageOrderValue,
        ),
        selectedComparePeriods,
      ),
    },
  ];

  const getAnalyticsOrderInfo = async () => {
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (startEndDate?.length > 0) {
      selectedStartEndDate[0] = getUnixTimeStamp(
        getStartDateWithHhMmSsFormate(startEndDate[0]),
      );
      selectedStartEndDate[1] = getUnixTimeStamp(
        getEndDateWithHhMmSsFormate(startEndDate[1]),
      );
      if (selectedComparePeriods) {
        comparePeriodData.comparePeriod = true;
        comparePeriodData["compareStartEndDate"] = [
          getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
          getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
        ];
      }
      setIsCardDataLoading(true);
      try {
        const response = await getAnalyticsOrderInfoQuery.fetchData({
          filters: {
            productId: product?.id,
            sku: product?.sku,
            startEndDate: selectedStartEndDate,
            ...comparePeriodData,
          },
        });
        if (response?.data?.catalogAnalyticsCard) {
          setAnalyticsCardsData({ ...response.data.catalogAnalyticsCard });
        } else {
          setAnalyticsCardsData(null);
        }
      } catch (e) {
      } finally {
        setIsCardDataLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      salesOverviewData?.selectedComparePeriods &&
      salesOverviewData?.selectedComparePeriods !== selectedComparePeriods
    ) {
      setSelectComparePeriods(salesOverviewData?.selectedComparePeriods);
    }
  }, []);

  useEffect(() => {
    if (product?.sku && startEndDate?.length > 0) {
      getAnalyticsOrderInfo();
    }
  }, [product, startEndDate, selectedComparePeriods]);

  useEffect(() => {
    if (!product) {
      setAnalyticsCardsData(null);
    }
  }, [product]);

  return (
    <div>
      <div className="mb-6 grid grid-cols-1 gap-4">
        <div className="relative">
          <label className="mb-1 block text-sm font-medium text-gray-600">
            Date Range
          </label>
          <DateRangeDropDown
            startEndDate={startEndDate}
            setStartEndDate={setStartEndDate}
            defaultSelectedDate={defaultSelectedDate}
            selectedComparePeriods={selectedComparePeriods}
            setSelectComparePeriods={setSelectComparePeriods}
            daysDifference={daysDifference}
            localStorageData={salesOverviewData}
            storeInLocalStorage={storeInLocalStorageForSalesOverview}
          />
        </div>
      </div>
      <div className="mb-6 grid grid-cols-3 gap-5">
        {orderCards?.length > 0 &&
          orderCards.map((orderInfo, index) => {
            return (
              <div key={`orderInfor-${index}`}>
                <OrderCards
                  title={orderInfo?.title}
                  total={orderInfo?.total}
                  previousTotal={orderInfo?.previousTotal}
                  previousTotalDetail={orderInfo?.previousTotalDetail}
                  selectedComparePeriods={selectedComparePeriods}
                  isCardDataLoading={isCardDataLoading || isProductLoading}
                />
              </div>
            );
          })}
      </div>
      <div className="mb-6 grid grid-cols-2 gap-6">
        <RegionsByRevenueChart
          isProductLoading={isProductLoading}
          product={product}
          startEndDate={startEndDate}
          selectedComparePeriods={selectedComparePeriods}
          comparePeriodDate={comparePeriodDate}
        />
        <CustomersByRevenue
          isProductLoading={isProductLoading}
          product={product}
          startEndDate={startEndDate}
          selectedComparePeriods={selectedComparePeriods}
          comparePeriodDate={comparePeriodDate}
        />
      </div>
    </div>
  );
};

export default AnalyticsSalesOverview;
