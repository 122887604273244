import PickerBagCode from "#components/picker/common/PickerBagCode";
import DetailedView from "#components/picker/order/DetailedView";
import ListView from "#components/picker/order/ListView";
import AllListView from "#components/picker/order/AllListView";
import CompletedBatch from "#components/picker/order/CompletedBatch";
import { Tab } from "@headlessui/react";
import _ from "lodash";

const SingleLineItemBatch = ({
  currentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  simulateTote,
  currentItem = {},
  workflow,
  confirmDropoffWithConfirmation,
  subdomain,
  skipItem,
  onChangeScannedQuantity,
  displayScan,
  setDisplayScan,
  currentProduct,
  pickingScanningDisabled,
  confirmAllItems,
  onChangePickedQuantityOfItem,
}) => {
  if (
    workflow &&
    workflow.steps &&
    workflow.steps.findIndex(
      (item) => item.name === "SCAN" && item.mandatory === true,
    ) === -1
  ) {
    return (
      <div className="flex items-center justify-center pt-36">
        <AllListView
          currentBatch={currentBatch}
          confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
        />
      </div>
    );
  }

  if (!currentBatch.tote && !pickingScanningDisabled) {
    return (
      <PickerBagCode
        scanBarcode={scanBarcode}
        text={pickerBagText(currentBatch.workflow)}
        simulateTote={simulateTote}
        subdomain={subdomain}
      />
    );
  }

  if (currentBatch.status === "COMPLETED") {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
      />
    );
  }

  const tabs = {};

  if (!pickingScanningDisabled) {
    tabs["Detailed View"] = (
      <DetailedView
        currentItem={currentItem}
        currentBatch={currentBatch}
        scannedBin={currentItem.scannedBin === true}
        scanBarcode={scanBarcode}
        confirmPickItem={confirmPickItem}
        skipItem={skipItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
        displayScan={displayScan}
        setDisplayScan={setDisplayScan}
        currentProduct={currentProduct}
      />
    );
  }

  tabs["List View"] = (
    <ListView
      currentBatch={currentBatch}
      pickingScanningDisabled={pickingScanningDisabled}
      confirmAllItems={confirmAllItems}
      onChangePickedQuantityOfItem={onChangePickedQuantityOfItem}
    />
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex-col pt-4">
        <Tab.Group>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="mt-4 flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

const pickerBagText = (workflow) => {
  switch (workflow) {
    case "Pick + Sort":
      return `Please scan a multi-line picker's cart`;
    case "Pick then Sort":
      return `Please scan a picker's cart to get started`;
    default:
      return `Please scan a tote to get started`;
  }
};

export default SingleLineItemBatch;
