const OrderNotes = ({ notes = [] }) => {
  return (
    <div>
      <div className="mb-4">
        <p className="pb-4 font-montserrat text-xl font-bold">Order Notes:</p>
        <div className="w-max rounded-xl border border-gray-500 p-3">
          {notes &&
            notes.map((note, index) => (
              <div className="grid grid-cols-3 p-2">
                <span className="font-bold">Note {index + 1}: </span>
                <span className="col-span-2 ml-4 font-medium">{note}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OrderNotes;
