import { getHeaderObject } from "#utils/getHeaderObject";
import cellStyleForTable from "#components/common/CellStyleForTable";
import { useEffect, useState } from "react";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import { isVanEngelenTenant } from "#utils/tenantCheck";

const OrderDetailsTable = ({
  currentOrder,
  orderDetails,
  fulfillmentDetails = null,
  availableLineItemsQuantity = null,
  type,
  updatedOrderBatchingDetails,
  setUpdatedOrderBatchingDetails,
  setOrderQuantityModified,
  tenant,
  qtyError,
  setQtyError,
  availableQtyList,
  setAvailableQtyList,
  refetchOrderQuantity,
}) => {
  const [newOrderDetails, setNewOrderDetails] = useState([]);
  const originalQuantities = orderDetails.map((order, index) => ({
    quantity: order?.quantity,
    uniqueIdentifier: `${order.productId}_${index}`,
  }));
  const [showRequestedQty, setShowRequestedQty] = useState(true);

  const headers = [
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Quantity", "quantity"),
    getHeaderObject("Form Factor", "formFactor"),
    getHeaderObject("Lot ID", "lotId"),
    getHeaderObject("Expiry Date", "expiryDate"),
    getHeaderObject("Serial Number", "serialNumber"),
    getHeaderObject("LPN", "nestedFormFactorId"),
    getHeaderObject("Pallet ID", "palletId"),
  ];
  if (tenant?.settings?.activities?.receiving?.poEnabled) {
    headers.push(getHeaderObject("PO ID", "poId"));
  }

  if (fulfillmentDetails) {
    // This will be shown when seeing original order details modal.
    headers.push(getHeaderObject("Fulfilled Qty", "fulfilledQty"));
  }

  if (availableLineItemsQuantity || availableQtyList) {
    // Show available qty in the preview for all fulfillable, partial fulfillable, unfulfillable tabs.
    headers.push(getHeaderObject("Available Qty", "availableQty"));
  }

  if (
    (type === "fulfillableOrders" || type === "backOrders") &&
    isVanEngelenTenant(tenant?.subdomain)
  ) {
    headers.push(getHeaderObject("Requested Quantity", "requestedQty"));
  }

  useEffect(() => {
    if (refetchOrderQuantity) {
      setAvailableQtyList(
        orderDetails.map((order) => ({
          quantity: order.quantity,
          sku: order.sku,
        })),
      );
    }
  }, [refetchOrderQuantity]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const onQtyChange = (qty, item) => {
    setOrderQuantityModified(true);
    setShowRequestedQty(false);

    const orginialQty = originalQuantities?.find(
      (qty, index) => qty.uniqueIdentifier === `${qty.productId}_${index}`,
    )?.quantity;

    if (orginialQty && qty > orginialQty) {
      setQtyError(
        "Requested quantity cannot be greater than original quantity",
      );
      return; // early exit to prevent further logic from running
    } else if (qty < 0 || isNaN(qty)) {
      setQtyError("Please enter valid quantity");
      return; // early exit to prevent further logic from running
    } else {
      setQtyError("");
    }

    const currentUpdatedOrderDetails = updatedOrderBatchingDetails?.find(
      (ord) => ord.order.toString() === currentOrder.id,
    );

    let isUpdated = false;
    let updatedOrderBatch = [...updatedOrderBatchingDetails]; // Clone the state to avoid multiple setState calls

    if (currentUpdatedOrderDetails) {
      // If the order exists in updatedOrderBatchingDetails, update the details.
      updatedOrderBatch = updatedOrderBatch.map((order) => {
        if (order.order === currentOrder.id) {
          return {
            ...order,
            orderDetails: order.orderDetails.map((detail) => {
              if (
                getUniqueKeyForLineItem(detail) ===
                getUniqueKeyForLineItem(item)
              ) {
                isUpdated = true;
                return {
                  ...detail,
                  quantity: Number(qty),
                };
              }
              return detail;
            }),
          };
        }
        return order;
      });
    }

    if (currentUpdatedOrderDetails && !isUpdated) {
      // If the item isn't updated, add it to the orderDetails.
      updatedOrderBatch = updatedOrderBatch.map((o) =>
        o.order === currentOrder.id
          ? {
              ...o,
              orderDetails: [
                ...o.orderDetails,
                { ...item, quantity: Number(qty) },
              ], // Add the new detail to orderDetails
            }
          : o,
      );
    }

    if (!currentUpdatedOrderDetails && !isUpdated) {
      // If the order doesn't exist in updatedOrderBatchingDetails, create a new one.
      updatedOrderBatch = [
        ...updatedOrderBatch,
        {
          order: currentOrder.id,
          orderDetails: [{ ...item, quantity: Number(qty) }],
        },
      ];
    }

    // Finally, set the state once after processing all the logic
    setUpdatedOrderBatchingDetails(updatedOrderBatch);

    // Update new order details (this seems separate from the batching update)
    setNewOrderDetails((prev) =>
      prev.map((ord) =>
        getUniqueKeyForLineItem(ord) === getUniqueKeyForLineItem(item)
          ? { ...ord, quantity: qty }
          : ord,
      ),
    );
  };

  useEffect(() => {
    setNewOrderDetails(
      orderDetails?.map((item, index) => ({
        ...item,
        uniqueId: `${item.productId}_${index}`, // Adds unique id to each item
      })),
    );
  }, []);

  return (
    <div className="relative h-fit w-full overflow-auto border border-gray-300 text-[16px] xl:text-sm">
      {qtyError ? (
        <div>
          <CustomAlert message={qtyError} type="error" id={type} />
        </div>
      ) : null}

      <table className="relative w-full divide-y divide-gray-200 whitespace-nowrap text-[16px]">
        <thead className="sticky left-0 top-0 z-10 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {newOrderDetails.length === 0 ? (
            <tr className="bg-white">
              <td className="rounded-br rounded-tr px-1 py-2 pl-2 text-left font-medium tracking-wider text-tableText">
                {"No line items found for this order"}
              </td>
            </tr>
          ) : null}
          {newOrderDetails.map((item, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              {headers.map((header, columnIndex) => {
                let value = header.correspondingValue;
                let cellStyle = cellStyleForTable(
                  value,
                  ["orderId"],
                  columnIndex + 1,
                  rowIndex,
                );

                if (value === "sku") {
                  return (
                    <td className={classNames(cellStyle, "select-text")}>
                      {item.sku}
                    </td>
                  );
                }
                if (value === "quantity") {
                  return (
                    <td className={cellStyle}>
                      {
                        originalQuantities?.find(
                          (qty) => qty.uniqueIdentifier === item.uniqueId,
                        )?.quantity
                      }
                    </td>
                  );
                }
                if (value === "requestedQty") {
                  return (
                    <td className={cellStyle}>
                      <input
                        type="number"
                        value={
                          showRequestedQty && item.requestedQty
                            ? item.requestedQty
                            : item.quantity
                        }
                        onChange={(e) => onQtyChange(e.target.value, item)}
                        className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    </td>
                  );
                }
                if (value === "formFactor") {
                  return <td className={cellStyle}>{item.formFactor}</td>;
                }
                if (value === "lotId") {
                  return <td className={cellStyle}>{item.lotId}</td>;
                }
                if (value === "expiryDate") {
                  return <td className={cellStyle}>{item.expiryDate}</td>;
                }
                if (value === "serialNumber") {
                  return <td className={cellStyle}>{item.serialNumber}</td>;
                }
                if (
                  value === "poId" &&
                  tenant?.settings?.activities?.receiving?.poEnabled
                ) {
                  return <td className={cellStyle}>{item.poId}</td>;
                }
                if (value === "palletId") {
                  return <td className={cellStyle}>{item.palletId}</td>;
                }
                if (value === "nestedFormFactorId") {
                  return (
                    <td className={cellStyle}>{item.nestedFormFactorId}</td>
                  );
                }
                if (value === "fulfilledQty") {
                  return (
                    <td className={cellStyle}>
                      {fulfillmentDetails?.[getUniqueKeyForLineItem(item)] ?? 0}
                    </td>
                  );
                }
                if (value === "availableQty") {
                  return (
                    <td className={cellStyle}>
                      {availableLineItemsQuantity &&
                      availableLineItemsQuantity[getUniqueKeyForLineItem(item)]
                        ? Object.entries(
                            availableLineItemsQuantity[
                              getUniqueKeyForLineItem(item)
                            ],
                          ).map(([formFactor, quantity], index) => (
                            <span key={index}>
                              {quantity} {formFactor}
                              {index !==
                                Object.entries(
                                  availableLineItemsQuantity[
                                    getUniqueKeyForLineItem(item)
                                  ],
                                ).length -
                                  1 && ", "}
                            </span>
                          ))
                        : availableQtyList
                          ? availableQtyList.find((q) => q.sku === item.sku)
                              .quantity
                          : 0}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getUniqueKeyForLineItem = (orderLineItem) => {
  let identifier = `${orderLineItem.sku}`;
  if (orderLineItem.lotId && orderLineItem.lotId?.trim() !== "") {
    identifier += `-${orderLineItem.lotId}`;
  }
  if (orderLineItem.expiryDate && orderLineItem.expiryDate?.trim() !== "") {
    identifier += `-${orderLineItem.expiryDate}`;
  }
  if (orderLineItem.serialNumber && orderLineItem.serialNumber?.trim() !== "") {
    identifier += `-${orderLineItem.serialNumber}`;
  }
  if (orderLineItem.poId && orderLineItem.poId?.trim() !== "") {
    identifier += `-${orderLineItem.poId}`;
  }
  if (
    orderLineItem.nestedFormFactorId &&
    orderLineItem.nestedFormFactorId?.trim() !== ""
  ) {
    identifier += `-${orderLineItem.nestedFormFactorId}`;
  }

  return identifier;
};

export default OrderDetailsTable;
