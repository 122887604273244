import ListViewForSorting from "#components/picker/order/ListViewForSorting";

const CompletedBatchPendingSort = ({ confirmDropoff, currentBatch }) => {
  return (
    <>
      <div className="-mt-20 flex h-screen w-full items-center justify-center">
        <div>
          <div className="flex-col">
            <div className="text-xl font-semibold text-gray-800">
              Batch ID: {currentBatch?.id}
            </div>
            <div className="text-xl font-medium text-gray-600">
              Total Items: ({currentBatch?.workingList?.length})
            </div>

            <div className="w-full rounded-2xl border bg-white shadow-lg">
              <div className="flex items-center justify-center pb-5 pt-5">
                <div className="border-r border-gray-400 p-5">
                  <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                    {" "}
                    <img
                      src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                      className="w-14"
                    />
                  </div>
                </div>
                <div className="px-5 py-5 text-3xl font-medium text-454A4F">
                  <div>Batch Completed Successfully</div>
                  {currentBatch?.attributes?.dropoffStation
                    ? `Go to ${currentBatch.attributes.dropoffStation}`
                    : `Go to Pack-1`}
                  <div>
                    Ensure the items below are sorted into their respective
                    sorting locations
                  </div>
                  <div
                    className="mt-2 w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={confirmDropoff}>
                    Confirm Dropoff
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ListViewForSorting currentBatch={currentBatch} />
        </div>
      </div>
    </>
  );
};

export default CompletedBatchPendingSort;
