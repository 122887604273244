import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { useParams, useHistory } from "react-router-dom";
import {
  GET_CONSIGNMENTS,
  GET_CONSIGNMENT_MASTER_DATA,
  GET_AUDIT,
  GET_INVENTORY,
  GET_CONSIGNMENT,
  GET_CONSIGNMENT_FIELDS,
  GET_BIN_LOCATIONS,
  GET_USER,
  GET_CONSIGNMENT_DAMAGED_ITEM_DETAILS,
  GET_USERS,
  GET_CONSIGNMENT_FIELDS_V2,
} from "#queries";
import {
  SAVE_CONSIGNMENT,
  DELETE_CONSIGNMENT,
  SAVE_CONSIGNMENT_NOTES,
  BULK_UPLOAD_CONSIGNMENTS,
  RECEIVE_CONSIGNMENT,
  CHANGE_CONSIGNMENT_STATUS,
  RECEIVE_CONSIGNMENT_CURRENT,
  UNDO_CONSIGNMENT_RECEIVE,
  SAVE_USER,
  GET_PAST_BULK_UPLOADS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import ReceivingItemListPdf from "#components/consignments/ReceivingItemListPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import moment from "moment-timezone";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../../constants/feature-flags";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ModalContext } from "#newUiComponents/bulkUploadV2/useReducer";
import {
  ACTION_TYPES,
  ACTIONS_TYPE_ENUM,
  POLLING_TIMER,
  STATUS_ENUM,
  BULK_UPLOAD_KEYS,
  BULK_UPLOAD_ENTITY_TYPES,
} from "#newUiComponents/bulkUploadV2/useReducer";
import { BANNER_TYPE_ENUM } from "#newUiComponents/commons/ExportBanner";
import {
  GET_CONSIGNMENT_SEARCH_FILTERS,
  SEARCH_CONSIGNMENTS,
} from "#queries/index";

dayjs.extend(utc);
dayjs.extend(timezone);
const ALERT_TIMEOUT_IN_MS = 5000;

export const CONSIGNMENT_ACTION_TYPES = [
  {
    name: "Standard",
    value: "STANDARD",
    title: "Standard",
    subTitle: "",
  },
  {
    name: "Dropship",
    value: "DROPSHIP",
    title: "Dropship",
    subTitle: "",
  },
  {
    name: "Export Report",
    value: "EXPORT_REPORT",
    title: "Export Report",
    subTitle: "",
  },
];
export const CONSIGNMENT_ACTIONS_TYPE_ENUM = {
  STANDARD: "STANDARD",
  DROPSHIP: "DROPSHIP",
  EXPORT_REPORT: "EXPORT_REPORT",
};
export const BULK_UPLOAD_DUPLICATE_VALIDATION = [];
export const BULK_UPLOAD_DUPLICATE_ROW_VALIDATION = [];

const withConsignmentsLogic = (WrappedComponent) => {
  return (props) => {
    const ldClient = useLDClient();
    const [receivingConsignment, setReceivingConsignment] = useState(null);
    const [customersSelectedForReport, setCustomersSelectedForReport] =
      useState([]);
    const [startDateForReport, setStartDateForReport] = useState(null);
    const [endDateForReport, setEndDateForReport] = useState(null);
    const [dropshipData, setDropshipData] = useState({});
    const [selectedConsignment, setSelectedConsignment] = useState(null);
    const [selectedConsignmentNotes, setSelectedConsignmentNotes] =
      useState(null);
    const [selectedWarehouseNotes, setSelectedWarehouseNotes] = useState(null);
    const [selectedConsignmentItemNotes, setSelectedConsignmentItemNotes] =
      useState(null);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const URL_PARAMS = useParams();
    const history = useHistory();
    const [items, setItems] = useState(null);
    const appState = useContext(AppStateContext);
    const masterDataQuery = useQuery(GET_CONSIGNMENT_MASTER_DATA);
    const consignmentsQuery = useQuery(GET_CONSIGNMENTS);
    const getConsignmentQuery = useQuery(GET_CONSIGNMENT);
    const getReceivingConsignmentQuery = useQuery(GET_CONSIGNMENT);
    const getConsignmentNotesQuery = useQuery(GET_CONSIGNMENT);
    const getWarehouseNotesQuery = useQuery(GET_CONSIGNMENT);
    const saveConsignmentQuery = useQuery(SAVE_CONSIGNMENT);
    const saveConsignmentNotesQuery = useQuery(SAVE_CONSIGNMENT_NOTES);
    const deleteConsignmentQuery = useQuery(DELETE_CONSIGNMENT);
    const receiveConsignmentQuery = useQuery(RECEIVE_CONSIGNMENT);
    const [showFilters, setShowFilters] = useState(false);
    const downloadConsignmentReportQuery = useQuery(GET_CONSIGNMENT);
    const downloadConsignmentItemListQuery = useQuery(GET_CONSIGNMENT);
    const binLocationsQuery = useQuery(GET_BIN_LOCATIONS);
    const changeConsignmentStatusQuery = useQuery(CHANGE_CONSIGNMENT_STATUS);
    const receiveConsignmentCurrentQuery = useQuery(
      RECEIVE_CONSIGNMENT_CURRENT,
    );
    const undoConsignmentReceiveQuery = useQuery(UNDO_CONSIGNMENT_RECEIVE);
    const getConsignmentProducts = useQuery(GET_INVENTORY);
    const getSearchFiltersQuery = useQuery(GET_CONSIGNMENT_SEARCH_FILTERS);
    const searchConsignmentsQuery = useQuery(SEARCH_CONSIGNMENTS);

    const [searchFilters, setSearchFilters] = useState([]);
    const [searchResults, setSearchResults] = useState({});
    const [currentPage, setCurrentPage] = useState(0);

    const [auditOrderId, setAuditOrderId] = useState(null);
    const getAuditQuery = useQuery(GET_AUDIT);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [isPartiallyReceived, setIsPartiallyReceived] = useState(false);

    const saveUserQuery = useQuery(SAVE_USER);
    const getConsignmentsFields = useQuery(GET_CONSIGNMENT_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_CONSIGNMENTS);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [binLocationsOptions, setBinLocationsOptions] = useState([]);

    const [changeStatusConsignment, setChangeStatusConsignment] =
      useState(null);
    const getUserQuery = useQuery(GET_USER);
    const [fetchedUser, setFetchedUser] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Consignments" }),
    );
    const [validationResult, setValidationResult] = useState(null);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);
    const [selectedAuditUsersInfo, setSelectedAuditUsersInfo] = useState(null);

    const consignmentQueryForDetails = useQuery(GET_CONSIGNMENT);
    const [showConsignmentDetails, setShowConsignmentDetails] = useState(null);
    const [showAudit, setShowAudit] = useState(false);
    const getAuditUsersQuery = useQuery(GET_USERS);
    const [groupConsignmentOn, setGroupConsignmentOn] = useState(false);

    const bulkUploadEntity = useContext(ModalContext);
    const bulkUploadV2Enabled =
      ldClient?.variation(featureFlags.BULK_UPLOAD_V2_ENABLED, false) ?? false;
    const [consignmentActionTypes, setConsignmentActionTypes] = useState(null);
    const [consignmentActionTypesEnum, setConsignmentActionTypesEnum] =
      useState(null);
    const getConsignmentsFieldsV2 = useQuery(GET_CONSIGNMENT_FIELDS_V2);
    const listPastBulkUploadQuery = useQuery(GET_PAST_BULK_UPLOADS);
    const [consignmentDashboardFieldsV2, setConsignmentDashboardFieldsV2] =
      useState(null);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [selectedActionType, setSelectedActionType] = useState(null);
    const [showBanner, setShowBanner] = useState(false);
    const bulkUploadId = localStorage.getItem(BULK_UPLOAD_KEYS?.CONSIGNMENT_ID);
    const selectedBulkUploadFile = localStorage.getItem(
      BULK_UPLOAD_KEYS?.CONSIGNMENT_FILE,
    );
    const [typeOfBanner, setTypeOfBanner] = useState(null);

    useEffect(() => {
      if (consignmentQueryForDetails.data) {
        setShowConsignmentDetails(consignmentQueryForDetails.data.consignment);

        consignmentQueryForDetails.data?.consignment.workingList &&
        consignmentQueryForDetails.data?.consignment.workingList.length > 0
          ? setItems(
              consignmentQueryForDetails.data?.consignment.workingList.map(
                (item) => ({
                  ...item,
                  consignmentId:
                    consignmentQueryForDetails.data?.consignment.id,
                }),
              ),
            )
          : setItems(consignmentQueryForDetails.data?.consignment.workingList);
      }
      if (consignmentQueryForDetails.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (consignmentQueryForDetails.error) {
        appState.setAlert(
          consignmentQueryForDetails.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      consignmentQueryForDetails.data,
      consignmentQueryForDetails.loading,
      consignmentQueryForDetails.error,
    ]);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    const getDamagedItemDetailsQuery = useQuery(
      GET_CONSIGNMENT_DAMAGED_ITEM_DETAILS,
    );

    const [showConsignmentDamageInfo, setShowConsignmentDamageInfo] =
      useState(null);

    useEffect(() => {
      if (auth?.user?.id) getUserQuery.fetchData({ id: auth.user.id });
    }, []);

    useEffect(() => {
      if (getDamagedItemDetailsQuery.data) {
        setShowConsignmentDamageInfo(
          getDamagedItemDetailsQuery.data.getConsignmentDamagedItemDetails
            .damagedItems,
        );
      }

      if (getDamagedItemDetailsQuery.loading) {
        appState.setPageLoading();
      } else {
        appState.removePageLoading();
      }

      if (getDamagedItemDetailsQuery.error) {
        appState.setAlert(
          getDamagedItemDetailsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      getDamagedItemDetailsQuery.data,
      getDamagedItemDetailsQuery.loading,
      getDamagedItemDetailsQuery.error,
    ]);
    useEffect(() => {
      if (getUserQuery.data) {
        setFetchedUser(getUserQuery.data.user);
      }
      if (getUserQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

    const saveUserTablePreferences = (json) => {
      const tablePreferences = JSON.parse(JSON.stringify(json));
      saveUserQuery.fetchData({ ...fetchedUser, tablePreferences });
    };

    useEffect(() => {
      if (receiveConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (receiveConsignmentQuery.error) {
        appState.setAlert(
          receiveConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (receiveConsignmentQuery.data) {
        appState.setAlert(
          receiveConsignmentQuery.data.receiveConsignment.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setReceivingConsignment(null);
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [
      receiveConsignmentQuery.loading,
      receiveConsignmentQuery.error,
      receiveConsignmentQuery.data,
    ]);

    useEffect(() => {
      if (receiveConsignmentCurrentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (receiveConsignmentCurrentQuery.error) {
        appState.setAlert(
          receiveConsignmentCurrentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (receiveConsignmentCurrentQuery.data) {
        appState.setAlert(
          receiveConsignmentCurrentQuery.data.receiveConsignmentCurrent.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setReceivingConsignment(null);
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [
      receiveConsignmentCurrentQuery.loading,
      receiveConsignmentCurrentQuery.error,
      receiveConsignmentCurrentQuery.data,
    ]);

    useEffect(() => {
      if (binLocationsQuery.data) {
        setBinLocationsOptions({
          ...binLocationsQuery.data.binLocations,
        });
        appState.removeLoading();
      }
    }, [
      binLocationsQuery.loading,
      binLocationsQuery.error,
      binLocationsQuery.data,
    ]);

    useEffect(() => {
      if (changeConsignmentStatusQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (changeConsignmentStatusQuery.error) {
        appState.setAlert(
          changeConsignmentStatusQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (changeConsignmentStatusQuery.data) {
        appState.setAlert(
          changeConsignmentStatusQuery.data.changeConsignmentStatus.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
        setChangeStatusConsignment(null);
      }
    }, [
      changeConsignmentStatusQuery.loading,
      changeConsignmentStatusQuery.error,
      changeConsignmentStatusQuery.data,
    ]);

    useEffect(() => {
      if (getConsignmentsFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getConsignmentsFields.data) {
        setDashboardFields(getConsignmentsFields.data.consignmentFields);
      }
    }, [
      getConsignmentsFields.loading,
      getConsignmentsFields.data,
      getConsignmentsFields.error,
    ]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadConsignments
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadConsignments.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (getReceivingConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getReceivingConsignmentQuery.data) {
        setReceivingConsignment(getReceivingConsignmentQuery.data.consignment);
      }

      if (getReceivingConsignmentQuery.error) {
        appState.setAlert(
          getReceivingConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setReceivingConsignment(null);
      }
    }, [
      getReceivingConsignmentQuery.loading,
      getReceivingConsignmentQuery.error,
      getReceivingConsignmentQuery.data,
    ]);

    useEffect(() => {
      if (getConsignmentNotesQuery.data) {
        setSelectedConsignmentNotes(getConsignmentNotesQuery.data.consignment);
      }

      if (getConsignmentNotesQuery.error) {
        appState.setAlert(
          getConsignmentNotesQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (getConsignmentNotesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getConsignmentNotesQuery.data,
      getConsignmentNotesQuery.loading,
      getConsignmentNotesQuery.error,
    ]);

    useEffect(() => {
      if (getWarehouseNotesQuery.data) {
        setSelectedWarehouseNotes(getWarehouseNotesQuery.data.consignment);
      }

      if (getWarehouseNotesQuery.error) {
        appState.setAlert(
          getWarehouseNotesQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (getWarehouseNotesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getWarehouseNotesQuery.data,
      getWarehouseNotesQuery.loading,
      getWarehouseNotesQuery.error,
    ]);

    useEffect(() => {
      masterDataQuery.fetchData();
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      loadFirstTimeData();
      getConsignmentsFields.fetchData();
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }

        const filtersSet = {
          customers: auth.user.customersList.map((item) => item.id),
          warehouses: auth.user.warehousesList.map((item) => item.id),
        };
        entity.setFilters(filtersSet);
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        binLocationsQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            warehouses: auth.user.warehouses,
          },
          paginated: false,
        });
      }
    };

    useEffect(() => {
      if (saveConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveConsignmentQuery.error) {
        appState.setAlert(
          saveConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (saveConsignmentQuery.data) {
        appState.setAlert(saveConsignmentQuery.data.saveConsignment.message);
        setSelectedConsignment(null);
        setSelectedConsignmentNotes(null);
        setSelectedConsignmentItemNotes(null);
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [
      saveConsignmentQuery.data,
      saveConsignmentQuery.loading,
      saveConsignmentQuery.error,
    ]);

    useEffect(() => {
      if (saveConsignmentNotesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveConsignmentNotesQuery.data) {
        appState.setAlert(
          saveConsignmentNotesQuery.data.saveConsignmentNotes.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setSelectedConsignment(null);
        setSelectedConsignmentNotes(null);
        setSelectedConsignmentItemNotes(null);
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }

      if (saveConsignmentNotesQuery.error) {
        appState.setAlert(
          saveConsignmentNotesQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      saveConsignmentNotesQuery.data,
      saveConsignmentNotesQuery.loading,
      saveConsignmentNotesQuery.error,
    ]);

    useEffect(() => {
      if (consignmentsQuery.loading) appState.setLoading();
      else appState.removeLoading();

      if (consignmentsQuery.data && consignmentsQuery.data.consignments) {
        entity.setEntities({
          ...consignmentsQuery.data.consignments,
          ...consignmentsQuery.variables,
        });
      }
    }, [
      consignmentsQuery.loading,
      consignmentsQuery.error,
      consignmentsQuery.data,
    ]);

    const getAudit = (order) => {
      if (order) {
        setAuditOrderId(order.orderId);
        getAuditQuery.fetchData({ id: order.id });
      }
    };
    useEffect(() => {
      if (getAuditQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getAuditQuery.data) {
        if (getAuditQuery.data.getAudit && getAuditQuery.data.getAudit.stages) {
          setSelectedAudit(getAuditQuery.data.getAudit);
          const userIds = [];
          getAuditQuery.data.getAudit.stages?.forEach((stage) => {
            if (stage.userId) {
              userIds.push(stage.userId);
            }
          });
          if (userIds.length > 0) {
            getAuditUsersQuery.fetchData({
              filters: {
                id: userIds,
              },
            });
          } else {
            setShowAudit(true);
          }
        } else {
          appState.setAlert(
            `No audit trail exists for that consignment`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      }
      if (getAuditQuery.error) {
        alert(getAuditQuery.error.message);
        setSelectedAudit(null);
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    useEffect(() => {
      getAuditUsersQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getAuditUsersQuery.data?.users?.entities) {
        setSelectedAuditUsersInfo([...getAuditUsersQuery.data.users.entities]);
        setShowAudit(true);
      }

      if (getAuditUsersQuery.error) {
        appState.setAlert(
          getAuditUsersQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setSelectedAudit(null);
        setSelectedAuditUsersInfo(null);
      }
    }, [
      getAuditUsersQuery.loading,
      getAuditUsersQuery.data,
      getAuditUsersQuery.error,
    ]);

    useEffect(() => {
      (async () => {
        const response = await getSearchFiltersQuery.fetchData();

        if (response.data?.getConsignmentSearchFilters) {
          setSearchFilters(response.data.getConsignmentSearchFilters);
        }
      })();
    }, []);

    useEffect(() => {
      if (searchConsignmentsQuery.error) {
        appState.setAlert(
          searchConsignmentsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [searchConsignmentsQuery.error]);

    const fetchEnhancedSearchResults = async (payload) => {
      if (searchConsignmentsQuery.loading) return;

      if (!payload.keyword) {
        setSearchResults({});
        return;
      }

      const response = await searchConsignmentsQuery.fetchData(payload);
      const consignments = response.data?.searchConsignments;

      if (consignments?.entities?.length > 0) {
        setSearchResults((prev) => ({
          ...consignments,
          entities: [...(prev?.entities || []), ...consignments.entities],
        }));
      }
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return consignmentsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const addNewConsignment = (consignmentType) => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      let selectedWarehouse, selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      if (warehouses.length === 1) {
        selectedWarehouse = warehouses[0].id;
        warehouseToBeSelected = false;
      }
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
      }
      const consignment = {
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
        warehouseToBeSelected,
        customerToBeSelected,
      };
      if (consignmentType === "DROPSHIP") {
        consignment.dropship = true;
      }
      setSelectedConsignment(consignment);
    };

    const submitAddedProduct = (addedProduct) => {
      let currentSelectedConsignment = selectedConsignment;
      if (
        !currentSelectedConsignment.items ||
        currentSelectedConsignment.items.length === 0
      ) {
        currentSelectedConsignment.items = [];
      }

      if (addedProduct.idx >= 0) {
        currentSelectedConsignment.items[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
        };
      } else {
        if (
          currentSelectedConsignment.items.findIndex(
            (item) => item.sku === addedProduct.sku,
          ) !== -1 &&
          currentSelectedConsignment.dropship &&
          currentSelectedConsignment.dropshipType === "FBA" &&
          currentSelectedConsignment.isCasePack
        ) {
          return appState.setAlert(
            `You cannot add the same product multiple times.`,
          );
        }
        currentSelectedConsignment.items.unshift({ ...addedProduct });
      }
      setSelectedConsignment({ ...currentSelectedConsignment });
    };

    const removeItem = (idx) => {
      let currentSelectedConsignment = { ...selectedConsignment };
      if (
        !currentSelectedConsignment.items ||
        currentSelectedConsignment.items.length === 0
      ) {
        return;
      }

      appState.showConfirmation(
        `Remove item from consignment`,
        `Are you sure you want to remove this item from the consignment?`,
        () => {
          currentSelectedConsignment.items =
            currentSelectedConsignment.items.filter(
              (item, index) => index !== idx,
            );
          setSelectedConsignment(currentSelectedConsignment);
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        "Confirm",
        "Cancel",
      );
    };

    const onChange = (e) => {
      const consignment = {
        ...selectedConsignment,
      };

      // IF ANY HANDLING PROPERTY IS CHANGED ON THE CONSIGNMENT,  WE NEED TO RESET THE ITEMS
      if (
        ["isCasePack", "dropship", "dropshipType"].includes(e.target.name) &&
        e.target.value !== consignment[e.target.name]
      ) {
        consignment.items = [];
      }

      if (
        e.target.name === "dropshipType" &&
        [true, false].includes(consignment.isCasePack) === false
      ) {
        consignment.isCasePack = false;
      }
      if (e.target.name === "dropshipType" && e.target.value === "DEFAULT") {
        delete consignment.isCasePack; // changing from FBA to Regular. hence mark/remove case pack as false
      }

      if (e.target.name === "dropship" && e.target.value === false) {
        delete consignment.dropshipType;
        delete consignment.isCasePack;
      }

      if (e.target.type === "number") {
        consignment[e.target.name] = parseInt(e.target.value);
      } else if (e.target.name === "trackingNumber") {
        consignment[e.target.name] = e.target.value.split(",");
      } else {
        consignment[e.target.name] = e.target.value;
      }

      setSelectedConsignment(consignment);
    };

    const onChangeNotes = (e) => {
      const consignment = {
        ...selectedConsignmentNotes,
      };

      if (e.target.type === "number") {
        consignment[e.target.name] = parseInt(e.target.value);
      } else if (e.target.name === "trackingNumber") {
        consignment[e.target.name] = e.target.value.split(",");
      } else {
        consignment[e.target.name] = e.target.value;
      }

      setSelectedConsignmentNotes(consignment);
    };

    const onChangeReceivingConsignment = (field, value) => {
      const consignment = {
        ...receivingConsignment,
      };
      consignment[field] = value;
      setReceivingConsignment(consignment);
    };

    const onChangeDropdown = (field, value) => {
      const consignment = {
        ...selectedConsignment,
      };
      if (["marketplace", "sellerId", "customer"].includes(field)) {
        consignment.items = [];
      }

      consignment[field] = value;
      setSelectedConsignment({ ...consignment });
    };

    useEffect(() => {
      if (deleteConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteConsignmentQuery.error) {
        appState.setAlert(
          deleteConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (deleteConsignmentQuery.data) {
        appState.setAlert(
          deleteConsignmentQuery.data.deleteConsignment.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }
    }, [
      deleteConsignmentQuery.data,
      deleteConsignmentQuery.error,
      deleteConsignmentQuery.loading,
    ]);

    const deleteConsignment = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this consignment?",
        () => {
          deleteConsignmentQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (undoConsignmentReceiveQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (undoConsignmentReceiveQuery.error) {
        appState.setAlert(
          undoConsignmentReceiveQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
      }

      if (undoConsignmentReceiveQuery.data) {
        appState.setAlert(
          undoConsignmentReceiveQuery.data.undoConsignmentReceive.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
        consignmentsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }
    }, [
      undoConsignmentReceiveQuery.data,
      undoConsignmentReceiveQuery.error,
      undoConsignmentReceiveQuery.loading,
    ]);

    const undoConsignmentReceive = (consignmentId) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to undo the receiving of this consignment?",
        () => {
          undoConsignmentReceiveQuery.fetchData({ consignmentId });
        },
        appState.hideConfirmation,
      );
    };

    const saveConsignmentItemNotes = () => {
      const idx = entity.entities.findIndex(
        (item) => item.id === selectedConsignmentItemNotes.consignmentId,
      );
      const consignment = entity.entities[idx];

      // alert(JSON.stringify(consignment.items));
      consignment.workingList[selectedConsignmentItemNotes.index] = {
        ...selectedConsignmentItemNotes,
      };

      entity.updateEntity(idx, consignment);
      setItems(
        consignment.workingList.map((item) => ({
          ...item,
          consignmentId: consignment.id,
        })),
      );
      saveConsignmentNotesQuery.fetchData({
        id: consignment.id,
        workingList: consignment.workingList,
      });
      // alert(consignment.id);
    };

    const saveConsignment = () => {
      if (
        !selectedConsignment.items ||
        selectedConsignment.items.length === 0
      ) {
        return appState.setAlert(
          `At least 1 product is needed to save a consignment.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      // Check if all the item's formfactor quantities are valid
      for (let i = 0; i < selectedConsignment.items.length; i++) {
        const item = selectedConsignment.items[i];
        const hasInvalidData = item.formFactors.findIndex(
          (formFactor) => !formFactor.quantity || formFactor.quantity < 1,
        );
        if (hasInvalidData !== -1) {
          return appState.setAlert(
            `Please provide valid form factor quantity. Check Product ${
              i + 1
            }.`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      }

      if (
        selectedConsignment.dropship &&
        selectedConsignment.dropshipType === "FBA" &&
        selectedConsignment.isCasePack
      ) {
        // CHECK IF ANY ITEM IS MISSING numberOfCases or quantityInCase property
        const missingCasePackData = selectedConsignment.items.findIndex(
          (item) => !item.numberOfCases || !item.quantityInCase,
        );
        if (missingCasePackData !== -1) {
          return appState.setAlert(
            `Please provide number of cases and quantity in case for all items. Check Item ${
              missingCasePackData + 1
            }.`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }

        // CHECK IF ANY ITEM HAS numberOfCases or quantityInCase property as 0 or an invalid number or negative
        const invalidCasePackData = selectedConsignment.items.findIndex(
          (item) => {
            return (
              item.numberOfCases <= 0 ||
              item.quantityInCase <= 0 ||
              isNaN(item.numberOfCases) ||
              isNaN(item.quantityInCase)
            );
          },
        );
        if (invalidCasePackData !== -1) {
          return appState.setAlert(
            `Please provide valid number of cases and quantity in case for all items. Check Item ${
              invalidCasePackData + 1
            }.`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }

        // CHECK IF ANY ITEM WHOSE TOTAL QUANTITY IS NOT EQUAL TO numberOfCases * quantityInCase
        const invalidCasePackData2 = selectedConsignment.items.findIndex(
          (item) => {
            return (
              item.formFactors
                .map((i) => i.quantity)
                .reduce((a, b) => a * b, 1) !==
              item.numberOfCases * item.quantityInCase
            );
          },
        );
        if (invalidCasePackData2 !== -1) {
          return appState.setAlert(
            `Total quantity for Item ${
              invalidCasePackData2 + 1
            } is not equal to the Case Quantity.`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      }

      saveConsignmentQuery.fetchData({
        ...selectedConsignment,
        status: "FINAL",
        items: selectedConsignment.items.map((item) =>
          _.omit(item, ["addingNew", "itemDetails"]),
        ),
        dropshipData:
          selectedConsignment.dropship && dropshipData
            ? { ...dropshipData }
            : null,
      });
    };

    const saveConsignmentDraft = () => {
      saveConsignmentQuery.fetchData({
        ...selectedConsignment,
        status: "DRAFT",
        items: selectedConsignment.items
          ? selectedConsignment.items.map((item) =>
              _.omit(item, ["addingNew", "baseUom", "itemDetails"]),
            )
          : [],
        dropshipData:
          selectedConsignment.dropship && dropshipData
            ? { ...dropshipData }
            : null,
      });
    };

    const onChangeBinSearch = (keyword) => {
      binLocationsQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
        },
        paginated: false,
      });
    };
    const debouncedBinLocationSearch = _.debounce(onChangeBinSearch, 1000);

    const uploadFile = (e) => {
      if (
        ["image/jpeg", "image/png", "application/pdf"].includes(e.type) ===
        false
      ) {
        return appState.setAlert(
          `Only upload files in the following formats JPG, PNG or PDF.`,
          "error",
          8000,
        );
      }

      setDropshipData({
        ...dropshipData,
        labelData: e.base64,
        labelType: e.type,
        labelUrl: null,
      });
    };

    useEffect(() => {
      if (downloadConsignmentReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (downloadConsignmentReportQuery.data) {
        downloadCSV(downloadConsignmentReportQuery.data.consignment);
      }
    }, [
      downloadConsignmentReportQuery.loading,
      downloadConsignmentReportQuery.error,
      downloadConsignmentReportQuery.data,
    ]);

    /**
     *
     * @param {object} consignment - Consignment object
     * @returns {void} - Download CSV
     * @description - Download CSV
     */
    const downloadCSV = (consignment) => {
      const csvLink = document.createElement("a");

      const rows =
        consignment.receivingBatches && consignment.receivingBatches.length > 0
          ? consignment.receivingBatches
          : consignment.workingList;

      // Define columns
      const columns = [
        { title: "SKU", dataIndex: "sku" },
        { title: "Order Id", dataIndex: "orderId" },
        {
          title: "Consignment Number",
          dataIndex: "consignmentNumber",
        },
        {
          title: "Tracking Number",
          dataIndex: "trackingNumber",
          array: true,
        },
        {
          title: "Consignment Date",
          dataIndex: "consignmentDate",
          date: true,
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          date: true,
        },
      ];

      if (consignment.status == "RECEIVING_STARTED") {
        columns.push({
          title: "Receiving Started At",
          dataIndex: "updatedAt",
          date: true,
        });
      }
      if (consignment.status == "COMPLETED") {
        columns.push({
          title: "Receiving Completed At",
          dataIndex: "updatedAt",
          date: true,
        });
      }
      if (consignment.status == "CLOSED") {
        columns.push({
          title: "Receiving Closed At",
          dataIndex: "updatedAt",
          date: true,
        });
      }

      const finalHeaders = [];
      const columnsWithArray = {};

      // Filter out columns if no corresponding data exists in the consignment object
      const validColumns = columns.filter((col) => {
        return (
          consignment[col.dataIndex] ||
          rows.some((batch) => {
            return (
              batch[col.dataIndex] !== null &&
              batch[col.dataIndex] !== undefined
            );
          })
        );
      });

      validColumns.push(
        {
          title: "Name",
          dataIndex:
            consignment.receivingBatches &&
            consignment.receivingBatches.length > 0
              ? "name"
              : "productName",
        },
        {
          title: "Expected Qty",
          dataIndex: "quantity",
          render: (_, row) =>
            consignment.workingList
              .filter((i) => i.sku === row.find((obj) => obj.sku)?.sku)
              .map((i) => i.quantity)
              .reduce((a, b) => a + b, 0),
        },
        {
          title: "Received Qty",
          dataIndex: "availableQuantity",
          render: (_, row) => {
            return consignment.receivingBatches &&
              consignment.receivingBatches.length > 0
              ? row.find((item) => item["quantity"])?.quantity
              : isNaN(row.find((item) => item["quantity"]))?.quantity ===
                    false &&
                  isNaN(row.find((item) => item["availableQuantity"]))
                    .availableQuantity === false
                ? row.find((item) => item["quantity"])?.quantity -
                  row.find((item) => item["availableQuantity"])
                    ?.availableQuantity
                : 0;
          },
        },
        { title: "Form Factor", dataIndex: "formFactor" },
      );

      if (
        consignment.receivingBatches &&
        consignment.receivingBatches.findIndex(
          (item) => item.lotId && item.lotId !== "",
        ) !== -1
      ) {
        validColumns.push({ title: "Lot/Batch ID", dataIndex: "lotId" });
      }

      if (
        consignment.receivingBatches &&
        consignment?.receivingBatches.findIndex(
          (item) => item.bestByDate && item.bestByDate !== "",
        ) !== -1
      ) {
        validColumns.push({
          title: "Expiry",
          dataIndex: "bestByDate",
          date: true,
        });
      }

      if (
        consignment.receivingBatches &&
        consignment?.receivingBatches.findIndex(
          (item) => item.nestedFormFactorId && item.nestedFormFactorId !== "",
        ) !== -1
      ) {
        validColumns.push({ title: "LPN", dataIndex: "nestedFormFactorId" });
      }

      if (
        consignment.receivingBatches &&
        consignment.receivingBatches.findIndex(
          (item) => item.nestedFormFactor && item.nestedFormFactor !== "",
        ) !== -1
      ) {
        validColumns.push({
          title: "LPN Form Factor",
          dataIndex: "nestedFormFactor",
        });
      }

      validColumns.forEach((col) => {
        if (col.array) {
          if (consignment[col.dataIndex]) {
            for (let i = 0; i < consignment[col.dataIndex].length; i++) {
              finalHeaders.push(col.title + `${i + 1}`);
              columnsWithArray[col.dataIndex] =
                consignment[col.dataIndex].length;
            }
          } else {
            const longestArray = getLongestArraySize(rows, col.dataIndex);
            for (let i = 0; i < longestArray; i++) {
              finalHeaders.push(col.title + `${i + 1}`);
            }
            columnsWithArray[col.dataIndex] = longestArray;
          }
        } else {
          finalHeaders.push(col.title);
        }
      });

      // Transform data to match columns
      const transformedData = getTransformedData(
        consignment,
        rows,
        validColumns,
        columnsWithArray,
      );

      // Prepare CSV data
      const data = transformedData.map((row) => {
        return validColumns
          .map((col) => {
            //find the item in row that matches the col dataIndex
            const matchedItem = row.find((item) => item[col.dataIndex]);

            if (col.render) {
              return col.render(_, row);
            } else if (matchedItem) {
              //if the item is a date, format time stamp to get today's date
              if (col.date)
                return `${moment(matchedItem[col.dataIndex]).format(
                  "MMMM Do YYYY h:mm:ss a",
                )}`;
              if (col.array) return `${matchedItem[col.dataIndex].toString()}`;

              return (
                matchedItem[col.dataIndex]?.toString().replace(/,/g, " ") || ""
              );
            }
            return "-";
          })
          .join(",");
      });

      const csvData = [finalHeaders.join(","), ...data].join("\n");
      csvLink.href = URL.createObjectURL(
        new Blob([csvData], { type: "text/csv" }),
      );

      csvLink.download = `receiving-report-${
        consignment.orderId ?? "consignment"
      }-${moment().format("MM/DD/YYYY")}.csv`;
      csvLink.click();
    };

    /**
     *
     * @param {array} rows - array of objects
     * @param {string} key - key to be used to get the array
     * @returns {number} - longest array size
     * @description - get the longest array size from an array of objects
     * @example
     * const rows = [{upc: "123,456,789"}, {upc: "123,456"}]
     */

    const getLongestArraySize = (rows, key) => {
      const arrayLengths = rows.map((batch) => {
        return batch[key] ? batch[key].length : 0;
      });

      return Math.max(...arrayLengths);
    };

    /**
     * @param {object} consignment - Consignment object
     * @param {array} rows - array of objects
     * @param {array} validColumns - array of objects
     * @param {object} columnsWithArray - object with key as dataIndex and value as array length
     * @returns {array} - array of objects
     * @description - transform data to match columns
     */
    const getTransformedData = (
      consignment,
      rows,
      validColumns,
      columnsWithArray,
    ) => {
      const transformedData = rows.map((batch) => {
        return validColumns.map((col) => {
          const dataObject = {};
          if (col.array) {
            const filledArray = [];
            // if length of array is less than the longest array, fill with empty strings
            if (
              batch[col.dataIndex] &&
              batch[col.dataIndex].length <= columnsWithArray[col.dataIndex]
            ) {
              for (let i = 0; i < columnsWithArray[col.dataIndex]; i++) {
                if (batch[col.dataIndex][i]) {
                  filledArray.push(batch[col.dataIndex][i]);
                } else {
                  filledArray.push("");
                }
              }
              dataObject[col.dataIndex] = filledArray;
            } else dataObject[col.dataIndex] = consignment[col.dataIndex];
          } else if (batch[col.dataIndex])
            dataObject[col.dataIndex] = batch[col.dataIndex];
          else dataObject[col.dataIndex] = consignment[col.dataIndex];
          return dataObject;
        });
      });
      return transformedData;
    };

    const downloadConsignmentItemList = (id) => {
      downloadConsignmentItemListQuery.fetchData({
        id,
        requestType: "DOWNLOAD_REPORT",
      });
    };

    useEffect(() => {
      if (downloadConsignmentItemListQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (downloadConsignmentItemListQuery.data) {
        pdf(
          <ReceivingItemListPdf
            consignment={downloadConsignmentItemListQuery.data.consignment}
            customerName={
              auth.user?.customersList
                ? auth.user.customersList.find(
                    (customer) =>
                      customer.id ===
                      downloadConsignmentItemListQuery.data.consignment
                        .customer,
                  )?.name
                : null
            }
            tenant={appState.tenant}
          />,
        )
          .toBlob()
          .then((blob) => saveAs(blob, `receiving-item-list-${Date.now()}.pdf`))
          .catch((err) => alert(err));
      }
    }, [
      downloadConsignmentItemListQuery.loading,
      downloadConsignmentItemListQuery.error,
      downloadConsignmentItemListQuery.data,
    ]);

    const downloadConsignmentReport = (id) => {
      downloadConsignmentReportQuery.fetchData({
        id,
        requestType: "DOWNLOAD_REPORT",
      });
    };

    const receiveConsignment = (id) => {
      getReceivingConsignmentQuery.fetchData({ id });
    };

    const receiveConsignmentPartially = (id) => {
      getReceivingConsignmentQuery.fetchData({ id });
      setIsPartiallyReceived(true);
    };

    const submitReceiveConsignmentPartially = () => {
      receiveConsignmentCurrentQuery.fetchData({
        id: receivingConsignment.id,
        isPartiallyReceived: true,
      });
      setReceivingConsignment(null);
      setIsPartiallyReceived(false);
    };

    const changeStatusConsignmentHandle = (id) => {
      const changeStatus = {
        ...changeStatusConsignment,
        id,
        status: "CLOSED",
      };
      setChangeStatusConsignment(changeStatus);
    };

    const closeConsignment = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to close this consignment?",
        () => {
          changeConsignmentStatusQuery.fetchData(changeStatusConsignment);
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const onChangeStatusConsignment = (e) => {
      const changeStatus = {
        ...changeStatusConsignment,
      };

      changeStatus[e.target.name] = e.target.value;
      setChangeStatusConsignment(changeStatus);
    };

    const submitReceivingConsignment = () => {
      receiveConsignmentQuery.fetchData({
        id: receivingConsignment.id,
        bestByDate: receivingConsignment.bestByDate,
        lotId: receivingConsignment.lotId,
        palletId: receivingConsignment.palletId,
        binLocation: receivingConsignment.binLocation || null,
      });
    };

    const editConsignment = async (id) => {
      appState.setLoading();
      // Get the consignment
      const consignmentResponse = await getConsignmentQuery.fetchData({ id });
      if (consignmentResponse.error) {
        appState.setAlert(
          consignmentResponse.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.removeLoading();
        return;
      }
      const consignment = consignmentResponse.data.consignment;
      // Get the detailed products information for the consignment items
      const consignmentProductsResponse =
        await getConsignmentProducts.fetchData({
          perPage: 80,
          pageNumber: 1,
          filters: {
            id: consignment.items.map((item) => item.productId),
          },
        });
      if (consignmentProductsResponse.error) {
        setSelectedConsignment(consignment);
        appState.removeLoading();
        return;
      }
      const consignmentProducts =
        await consignmentProductsResponse.data.inventory.entities;
      const productMap = _.keyBy(consignmentProducts, "id");
      setSelectedConsignment({
        ...consignment,
        items: consignment.items?.map((item) => {
          if (productMap[item.productId]) {
            return {
              ...item,
              itemDetails: productMap[item.productId],
            };
          }
          return item;
        }),
      });
      appState.removeLoading();
    };

    const refreshConsignmentItems = async () => {
      // Get the detailed products information for the consignment items
      const consignmentProductsResponse =
        await getConsignmentProducts.fetchData({
          filters: {
            id: selectedConsignment.items.map((item) => item.productId),
          },
        });
      if (consignmentProductsResponse.error) {
        return;
      }
      const consignmentProducts =
        await consignmentProductsResponse.data.inventory.entities;
      const productMap = _.keyBy(consignmentProducts, "id");
      setSelectedConsignment({
        ...selectedConsignment,
        items: selectedConsignment.items?.map((item) => {
          if (productMap[item.productId]) {
            return {
              ...item,
              itemDetails: productMap[item.productId],
            };
          }
          return item;
        }),
      });
    };

    const handleDateRangePickerValue = (dateRange) => {
      const newDateRange =
        dateRange && dateRange.length > 0
          ? [dayjs.tz(dateRange[0], "GMT"), dayjs.tz(dateRange[1], "GMT")]
          : [];
      return newDateRange;
    };

    const handleDisplayTextForDateRangePicker = (dateRange) => {
      if (dateRange && dateRange?.length > 0) {
        let formattedStart = "";
        let formattedEnd = "";
        if (dateRange[0])
          formattedStart = dayjs.tz(dateRange[0], "GMT").format("DD-MM-YYYY");

        if (dateRange[1])
          formattedEnd = dayjs.tz(dateRange[1], "GMT").format("DD-MM-YYYY");
        return `${formattedStart} ~ ${formattedEnd}`;
      } else return "";
    };

    const fetchExpandedGroupedConsignments = (value, warehouse) => {
      if (entity.filters?.groupBy) {
        delete entity.filters.groupBy;
      }

      setGroupConsignmentOn(value);

      consignmentsQuery.fetchData({
        perPage: entity.perPage,
        pageNumber: 1,
        filters: {
          ...entity.filters,
          exactKeyword: value,
          warehouses: [warehouse],
        },
        sort: entity.sort,
      });
    };

    const getConsignmentDetails = (id) => {
      history.push(`/consignments/${id}`);
    };

    useEffect(() => {
      if (URL_PARAMS.orderId) {
        consignmentQueryForDetails.fetchData({
          id: URL_PARAMS.orderId,
        });
      }
    }, [URL_PARAMS.orderId]);

    const setConsignmentManageActionType = () => {
      bulkUploadEntity?.resetModal();
      setConsignmentActionTypes([...CONSIGNMENT_ACTION_TYPES, ...ACTION_TYPES]);
      setConsignmentActionTypesEnum({
        ...CONSIGNMENT_ACTIONS_TYPE_ENUM,
        ...ACTIONS_TYPE_ENUM,
      });
    };

    useEffect(() => {
      setConsignmentManageActionType();
      getConsignmentDashboardFieldsV2();
    }, []);

    const startConsignmentPolling = (bulkUploadId) => {
      const autoRefreshInterval = setInterval(async () => {
        try {
          const listBulkUploadInput = {
            entityType: BULK_UPLOAD_ENTITY_TYPES?.CONSIGNMENT,
            id: bulkUploadId,
          };
          const getPastBulkUploadsResponse =
            await listPastBulkUploadQuery.fetchData({
              listBulkUploadInput: listBulkUploadInput,
            });
          if (getPastBulkUploadsResponse?.data) {
            const pastUploadEntities =
              getPastBulkUploadsResponse?.data?.listBulkUpload?.entities;
            if (pastUploadEntities && pastUploadEntities?.length !== 0) {
              if (
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.FAILED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
              ) {
                clearInterval(autoRefreshInterval);
                setShowBanner(true);
                if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.COMPLETED);
                } else if (
                  pastUploadEntities[0]?.processingStatus === STATUS_ENUM.FAILED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.FAILED);
                } else if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.PARTIAL_COMPLETE);
                }
                localStorage.removeItem(BULK_UPLOAD_KEYS?.CONSIGNMENT_ID);
                localStorage.removeItem(BULK_UPLOAD_KEYS?.CONSIGNMENT_FILE);
                consignmentsQuery.fetchData({
                  perPage: entity.perPage,
                  pageNumber: entity.pageNumber,
                  filters: entity.filters,
                  paginated: false,
                  sort: entity.sort,
                });
              }
            }
          }
        } catch (error) {
          clearInterval(autoRefreshInterval);
          setTypeOfBanner(null);
          setShowBanner(false);
        }
      }, POLLING_TIMER);
    };

    useEffect(() => {
      if (bulkUploadId) {
        setShowBanner(true);
        setTypeOfBanner(BANNER_TYPE_ENUM?.INITIATED);
        startConsignmentPolling(bulkUploadId);
      }
    }, [bulkUploadId]);

    const getConsignmentDashboardFieldsV2 = async () => {
      try {
        const getConsignmentDashboardFieldsV2Response =
          await getConsignmentsFieldsV2.fetchData();
        if (getConsignmentDashboardFieldsV2Response.error) {
          console.log(getConsignmentDashboardFieldsV2Response.error);
          setConsignmentDashboardFieldsV2(null);
        } else if (getConsignmentDashboardFieldsV2Response?.data) {
          setConsignmentDashboardFieldsV2(
            getConsignmentDashboardFieldsV2Response?.data?.consignmentFieldsV2,
          );
        }
      } catch (error) {
        setConsignmentDashboardFieldsV2(null);
      }
    };

    return (
      <WrappedComponent
        {...props}
        displayConsignments={entity.displayEntities}
        masterData={masterDataQuery.data ? masterDataQuery.data.masterData : {}}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        //
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        //
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          consignmentsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          consignmentsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
          setCustomersSelectedForReport(entity.filters.customer);

          if (entity.filter?.dateRange?.length > 0) {
            setStartDateForReport(entity.filters.dateRange[0]);
            setEndDateForReport(entity.filters.dateRange[1]);
          }
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          consignmentsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        handleDateRangePickerValue={handleDateRangePickerValue}
        handleDisplayTextForDateRangePicker={
          handleDisplayTextForDateRangePicker
        }
        onChangeFilter={(field, value, autoSubmit = false) => {
          // As date range fillter needs to be converted to ISO string using GMT timezone
          const gmtTimezone = "GMT";
          if (field === "startEndDate") {
            value = value.map((date, index) => {
              date = dayjs.tz(date, "DD-MM-YYYY", gmtTimezone);
              return index === 0
                ? date.startOf("day").toISOString()
                : date.endOf("day").toISOString();
            });
            const tempFilters = {
              ...entity.filters,
              [field]: value,
            };
            if (!value || value.length === 0) {
              delete tempFilters[field];
            }
            entity.setFilters(tempFilters);
          } else {
            entity.setFilters({
              ...entity.filters,
              [field]: value,
            });
          }
          if (autoSubmit) {
            consignmentsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          consignmentsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        customersSelectedForReport={customersSelectedForReport}
        setCustomersSelectedForReport={setCustomersSelectedForReport}
        startDateForReport={startDateForReport}
        setStartDateForReport={setStartDateForReport}
        endDateForReport={endDateForReport}
        setEndDateForReport={setEndDateForReport}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        getAudit={getAudit}
        auditOrderId={auditOrderId}
        setAuditOrderId={setAuditOrderId}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        selectedAuditUsersInfo={selectedAuditUsersInfo}
        setSelectedAuditUsersInfo={setSelectedAuditUsersInfo}
        showAudit={showAudit}
        setShowAudit={setShowAudit}
        setItems={setItems}
        items={items}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        addNewConsignment={addNewConsignment}
        selectedConsignment={selectedConsignment}
        setSelectedConsignment={setSelectedConsignment}
        selectedConsignmentNotes={selectedConsignmentNotes}
        setSelectedConsignmentNotes={setSelectedConsignmentNotes}
        removeItem={removeItem}
        onChange={onChange}
        onChangeNotes={onChangeNotes}
        onChangeDropdown={onChangeDropdown}
        saveConsignment={saveConsignment}
        saveConsignmentNotes={() => {
          saveConsignmentQuery.fetchData({
            ...selectedConsignmentNotes,
          });
        }}
        saveConsignmentItemNotes={saveConsignmentItemNotes}
        editConsignment={editConsignment}
        editConsignmentNotes={(id) =>
          getConsignmentNotesQuery.fetchData({ id })
        }
        editWarehouseNotes={(id) => getWarehouseNotesQuery.fetchData({ id })}
        deleteConsignment={deleteConsignment}
        undoConsignmentReceive={undoConsignmentReceive}
        setSelectedConsignmentItemNotes={setSelectedConsignmentItemNotes}
        selectedConsignmentItemNotes={selectedConsignmentItemNotes}
        submitAddedProduct={submitAddedProduct}
        selectedWarehouseNotes={selectedWarehouseNotes}
        setSelectedWarehouseNotes={setSelectedWarehouseNotes}
        saveConsignmentDraft={saveConsignmentDraft}
        subdomain={appState.subdomain}
        saveUserTablePreferences={saveUserTablePreferences}
        dropshipData={dropshipData}
        setDropshipData={setDropshipData}
        uploadFile={uploadFile}
        downloadConsignmentReport={downloadConsignmentReport}
        downloadConsignmentItemList={downloadConsignmentItemList}
        receiveConsignment={receiveConsignment}
        receivingConsignment={receivingConsignment}
        setReceivingConsignment={setReceivingConsignment}
        submitReceivingConsignment={submitReceivingConsignment}
        tenant={appState.tenant}
        user={auth.user}
        binLocationsOptions={binLocationsOptions}
        setBinLocationsOptions={setBinLocationsOptions}
        debouncedBinLocationSearch={debouncedBinLocationSearch}
        onChangeReceivingConsignment={onChangeReceivingConsignment}
        closeConsignment={closeConsignment}
        onChangeStatusConsignment={onChangeStatusConsignment}
        changeStatusConsignmentHandle={changeStatusConsignmentHandle}
        changeStatusConsignment={changeStatusConsignment}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        setChangeStatusConsignment={setChangeStatusConsignment}
        receiveConsignmentPartially={receiveConsignmentPartially}
        isPartiallyReceived={isPartiallyReceived}
        setIsPartiallyReceived={setIsPartiallyReceived}
        submitReceiveConsignmentPartially={submitReceiveConsignmentPartially}
        casePackEnabled={ldClient.variation(
          featureFlags.FBA_CASE_PACK_ENABLED,
          false,
        )}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        refreshConsignmentItems={refreshConsignmentItems}
        getDamagedInfo={(consignmentId) => {
          getDamagedItemDetailsQuery.fetchData({
            consignmentId,
          });
        }}
        showConsignmentDamageInfo={showConsignmentDamageInfo}
        closeConsignmentDamageInfo={() => {
          setShowConsignmentDamageInfo(null);
        }}
        showConsignmentDetails={showConsignmentDetails}
        closeConsignmentDetails={() => {
          setShowConsignmentDetails(null);
          history.push("/consignments");
        }}
        getConsignmentDetails={getConsignmentDetails}
        groupedConsignments={
          consignmentsQuery?.data?.consignments?.groupedConsignments
        }
        groupConsignmentOn={groupConsignmentOn}
        fetchExpandedGroupedConsignments={fetchExpandedGroupedConsignments}
        setGroupConsignmentOn={setGroupConsignmentOn}
        bulkUploadV2Enabled={bulkUploadV2Enabled}
        actionTypes={consignmentActionTypes}
        consignmentActionTypesEnum={consignmentActionTypesEnum}
        showBulkUpload={showBulkUpload}
        setShowBulkUpload={setShowBulkUpload}
        setSelectedActionType={setSelectedActionType}
        selectedActionType={selectedActionType}
        pastUploadsList={bulkUploadEntity?.pastUploadsEntities}
        showBanner={showBanner}
        typeOfBanner={typeOfBanner}
        bulkuploadFileName={selectedBulkUploadFile}
        closeBanners={() => {
          localStorage.removeItem(BULK_UPLOAD_KEYS?.CONSIGNMENT_ID);
          localStorage.removeItem(BULK_UPLOAD_KEYS?.CONSIGNMENT_FILE);
          setShowBanner(false);
        }}
        consignmentDashboardFieldsV2={consignmentDashboardFieldsV2}
        searchFilters={searchFilters}
        fetchEnhancedSearchResults={fetchEnhancedSearchResults}
        searchConsignmentsLoading={searchConsignmentsQuery.loading}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };
};

export default withConsignmentsLogic;
