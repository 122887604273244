import { useState, useEffect, useContext, useCallback } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_INTEGRATIONS,
  GET_INTEGRATION_ORDERS,
  GET_INTEGRATION_PRODUCTS,
  TEST_INTEGRATION,
  GET_SHOPIFY_LOCATIONS,
  GET_SHIPSTATION_CARRIERS,
} from "#queries";
import { SAVE_INTEGRATION, DELETE_INTEGRATION } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
const { parse } = require("tldts");
import config from "config";
import Cookies from "universal-cookie";
import qs from "qs";
import { useHistory } from "react-router-dom";

/**
 * Sets a cookie with the necessary information for tenant and selected integration.
 *
 * @param {Object} params - The parameters for setting the cookie.
 * @param {Object} params.appState - The application state containing the tenant information.
 * @param {Object} params.selectedIntegration - The selected integration object.
 */
const setCookie = ({ appState, ...selectedIntegration }) => {
  const cookies = new Cookies();
  const expires = new Date();
  expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000); // 1 month
  cookies.set(
    "hsTenantInfo",
    encodeURI(
      `${appState.tenant.apiGateway}|${window.location.origin}|${
        appState.tenant.id
      }|${JSON.stringify(selectedIntegration)}`,
    ),
    {
      path: "/",
      expires,
      domain: ".hopstack.io",
      secure: true,
    },
  );
};

const withSoftwareIntegrationsLogic = (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const [testResults, setTestResults] = useState(null);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);
    const [orders, setOrders] = useState(null);
    const [products, setProducts] = useState(null);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const saveIntegrationQuery = useQuery(SAVE_INTEGRATION);
    const deleteIntegrationQuery = useQuery(DELETE_INTEGRATION);
    const testIntegrationQuery = useQuery(TEST_INTEGRATION);
    const getShopifyLocationsQuery = useQuery(GET_SHOPIFY_LOCATIONS);
    const getShipstationCarriersQuery = useQuery(GET_SHIPSTATION_CARRIERS);
    const [subdomain, setSubdomain] = useState(null);
    const [selectedIntegrationToManage, setSelectedIntegrationToManage] =
      useState(null);
    const [selectedIntegrationToConfigure, setSelectedIntegrationToConfigure] =
      useState(null);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);
    const [shopifyLocations, setShopifyLocations] = useState([]);
    const [shipstationCarriers, setShipstationCarriers] = useState([]);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    const onPageLoad = () => {
      const queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (queryParams?.res && queryParams?.state) {
        const splitState = decodeURIComponent(queryParams.state).split("|");
        const {
          spapi_oauth_code: spOAuthCode,
          code,
          selling_partner_id: amazonSellerId,
        } = JSON.parse(queryParams.res);

        if (!splitState || splitState.length < 4) {
          return appState.setAlert(
            "Something went wrong. Please try again.",
            "error",
            5000,
          );
        }

        const integrationBody = {
          ...JSON.parse(splitState[3]),
          spOAuthCode,
          authCode: code,
          amazonSellerId,
        };

        if (
          integrationBody.integrationType !== "Salla" &&
          integrationBody.integrationType !== "Zid" &&
          integrationBody.integrationType !== "Zoho" &&
          integrationBody.integrationType !== "UPS" &&
          integrationBody.integrationType !== "StampsCom"
        ) {
          setSelectedIntegration(integrationBody);
        }

        saveIntegrationQuery.fetchData(integrationBody);
      }
      history.replace({
        search: "",
      });
    };

    useEffect(() => {
      const parsed = parse(window.location.href);
      if (process.env.NODE_ENV === "development") {
        setSubdomain(config.DEFAULT_SUBDOMAIN);
      } else {
        setSubdomain(parsed.subdomain);
      }
    }, []);

    useEffect(() => {
      loadFirstTimeData();
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        onPageLoad();
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
        let selectedWarehouseId, selectedCustomerId;
        selectedWarehouseId = warehouses[0].id;

        selectedCustomerId = customers[0].id;

        setSelectedCustomer(selectedCustomerId);
        setSelectedWarehouse(selectedWarehouseId);
        integrationsQuery.fetchData({
          filters: {
            customer: selectedCustomerId,
            warehouse: selectedWarehouseId,
          },
        });
        setFilters({
          customer: selectedCustomerId,
          warehouse: selectedWarehouseId,
        });
      }
    };

    useEffect(() => {
      if (saveIntegrationQuery.data) {
        appState.setAlert(
          saveIntegrationQuery.data.saveIntegration.message,
          "success",
          5000,
        );
        setSelectedIntegration(null);
        setSelectedIntegrationToConfigure(null);
        integrationsQuery.fetchData({
          filters: {
            customer: selectedCustomer,
            warehouse: selectedWarehouse,
          },
        });
      }
      if (saveIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveIntegrationQuery.error) {
        console.log(saveIntegrationQuery.error);
        appState.setAlert(saveIntegrationQuery.error.message, "error", 5000);
      }
    }, [
      saveIntegrationQuery.loading,
      saveIntegrationQuery.data,
      saveIntegrationQuery.error,
    ]);

    const getProductsQuery = useQuery(GET_INTEGRATION_PRODUCTS);
    const getOrdersQuery = useQuery(GET_INTEGRATION_ORDERS);

    useEffect(() => {
      if (getProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getProductsQuery.data) {
        console.log(getProductsQuery.data);
        // setProducts(getProductsQuery.data.integrationProducts);
        appState.setAlert(getProductsQuery.data.integrationProducts.message);
      }
    }, [
      getProductsQuery.loading,
      getProductsQuery.error,
      getProductsQuery.data,
    ]);

    useEffect(() => {
      if (getOrdersQuery.data) {
        console.log(getOrdersQuery.data);
        // setOrders(getOrdersQuery.data.integrationOrders);
        appState.setAlert(getOrdersQuery.data.integrationOrders.message);
      }

      if (getOrdersQuery.error) {
        appState.setAlert(getOrdersQuery.error.message, "error", 5000);
      }
    }, [getOrdersQuery.loading, getOrdersQuery.error, getOrdersQuery.data]);

    const onChange = (e) => {
      let integration = {
        ...selectedIntegration,
      };

      const nestedkey = e?.target?.attributes?.getNamedItem("nestedkey")?.value;

      if (nestedkey != null) {
        integration = {
          ...integration,
          [nestedkey]: {
            ...integration[nestedkey],
            [e?.target?.name]: e?.target?.value,
          },
        };
      } else {
        integration[e.target.name] = e.target.value;
      }
      setSelectedIntegration(integration);
    };

    const onChangeDropdown = (field, value) => {
      const integration = {
        ...selectedIntegration,
      };

      integration[field] = value;
      setSelectedIntegration(integration);
    };

    useEffect(() => {
      if (deleteIntegrationQuery.error) {
        appState.setAlert(
          `Could not logout of integration. Please contact support.`,
          "error",
          5000,
        );
      }
      if (deleteIntegrationQuery.data) {
        appState.setAlert(`Successfully logged out`, "success", 5000);
        integrationsQuery.fetchData({
          filters: {
            customer: selectedCustomer,
            warehouse: selectedWarehouse,
          },
        });
      }

      if (deleteIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteIntegrationQuery.loading,
      deleteIntegrationQuery.error,
      deleteIntegrationQuery.data,
    ]);

    const logoutIntegration = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to logout?",
        () => {
          deleteIntegrationQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (testIntegrationQuery.data) {
        appState.setAlert(
          testIntegrationQuery.data.testIntegration.message,
          "success",
          5000,
        );
        setTestResults(testIntegrationQuery.data.testIntegration.testData);
        appState.removeLoading();
      }
      if (testIntegrationQuery.error) {
        appState.setAlert(testIntegrationQuery.error.message, "error", 5000);
        setTestResults(null);
        appState.removeLoading();
      }
    }, [
      testIntegrationQuery.loading,
      testIntegrationQuery.error,
      testIntegrationQuery.data,
    ]);

    const testIntegration = (id) => {
      appState.setLoading();
      appState.setAlert(`Testing connection`, "success", 3000);
      testIntegrationQuery.fetchData({ id });
    };

    // SHOPIFY LOCATION START
    const debouncedShopifyFetchData = useCallback(
      _.debounce((shopifyAccessToken, shopifyStoreDomain) => {
        if (shopifyStoreDomain && shopifyAccessToken) {
          getShopifyLocationsQuery.fetchData({
            integrationId: null,
            shopifyStoreDomain,
            shopifyAccessToken,
          });
        }
      }, 1000),
      [],
    );

    useEffect(() => {
      if (selectedIntegration?.password && selectedIntegration?.storeName) {
        debouncedShopifyFetchData(
          selectedIntegration?.password,
          selectedIntegration?.storeName,
        );
      }
    }, [selectedIntegration?.password, selectedIntegration?.storeName]);

    useEffect(() => {
      if (getShopifyLocationsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getShopifyLocationsQuery.data) {
        const shopifyLocations =
          getShopifyLocationsQuery.data.getShopifyLocations.activeLocations;
        if (!shopifyLocations || shopifyLocations.length === 0) {
          appState.setAlert(
            `No Shopify location for this store.`,
            "error",
            5000,
          );
        }
        const filteredShopifyLocations = shopifyLocations.filter(
          (location) => location !== null,
        );
        setShopifyLocations(filteredShopifyLocations);
      }

      if (getShopifyLocationsQuery.error) {
        setShopifyLocations([]);
        appState.setAlert(
          getShopifyLocationsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      getShopifyLocationsQuery.data,
      getShopifyLocationsQuery.loading,
      getShopifyLocationsQuery.error,
    ]);

    // SHOPIFY LOCATION END

    // SHIPSTATION CARRIERS START
    const debouncedShipstationFetchData = useCallback(
      _.debounce((shipstationApiKey, shipstationApiSecret) => {
        if (shipstationApiKey && shipstationApiSecret) {
          console.log("fetching shipstation carriers");
          getShipstationCarriersQuery.fetchData({
            shipstationApiKey,
            shipstationApiSecret,
          });
        }
      }, 1000),
      [],
    );

    useEffect(() => {
      if (
        selectedIntegration?.username &&
        selectedIntegration?.password &&
        selectedIntegration?.integrationType === "Shipstation"
      ) {
        debouncedShipstationFetchData(
          selectedIntegration?.username,
          selectedIntegration?.password,
        );
      }
    }, [
      selectedIntegration?.username,
      selectedIntegration?.password,
      selectedIntegration?.integrationType,
    ]);

    useEffect(() => {
      if (getShipstationCarriersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getShipstationCarriersQuery.data) {
        const shipstationCarriers =
          getShipstationCarriersQuery.data.getShipstationCarriers;
        if (!shipstationCarriers || shipstationCarriers.length === 0) {
          appState.setAlert(
            `No Shipstation carriers for this account.`,
            "error",
            5000,
          );
        }
        setShipstationCarriers(shipstationCarriers);
      }

      if (getShipstationCarriersQuery.error) {
        setShipstationCarriers([]);
        appState.setAlert(
          getShipstationCarriersQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      getShipstationCarriersQuery.data,
      getShipstationCarriersQuery.loading,
      getShipstationCarriersQuery.error,
    ]);

    // SHIPSTATION CARRIERS END
    const validateNewAmazonIntegration = () => {
      if (!selectedIntegration.region && multiAccountSupportEnabled) {
        appState.setAlert(`Please select a region.`, "error", 5000);
        return false;
      }
      if (
        selectedIntegration.marketplaces?.length < 1 &&
        multiAccountSupportEnabled
      ) {
        appState.setAlert(
          `Please select at least one marketplace.`,
          "error",
          5000,
        );
        return false;
      }
      return true;
    };

    const getAmazonBaseRedirectUrl = (integration) => {
      let marketplace = integration.marketplaceCountryCode;
      if (integration.marketplaces?.length > 0) {
        marketplace = integration.marketplaces[0];
      }
      const marketplaceSellerCentralUrls = {
        CA: "https://sellercentral.amazon.ca",
        US: "https://sellercentral.amazon.com",
        MX: "https://sellercentral.amazon.com.mx",
        BR: "https://sellercentral.amazon.com.br",
        ES: "https://sellercentral-europe.amazon.com",
        UK: "https://sellercentral-europe.amazon.com",
        FR: "https://sellercentral-europe.amazon.com",
        NL: "https://sellercentral.amazon.nl",
        DE: "https://sellercentral-europe.amazon.com",
        IT: "https://sellercentral-europe.amazon.com",
        SE: "https://sellercentral.amazon.se",
        ZA: "https://sellercentral-europe.amazon.com",
        PL: "https://sellercentral.amazon.pl",
        EG: "https://sellercentral.amazon.eg",
        TR: "https://sellercentral.amazon.com.tr",
        SA: "https://sellercentral.amazon.sa",
        AE: "https://sellercentral.amazon.ae",
        IN: "https://sellercentral.amazon.in",
        BE: "https://sellercentral.amazon.com.be",
        SG: "https://sellercentral.amazon.sg",
        AU: "https://sellercentral.amazon.com.au",
        JP: "https://sellercentral.amazon.co.jp",
      };
      return marketplaceSellerCentralUrls[marketplace];
    };

    const saveIntegration = () => {
      if (selectedIntegration.integrationType === "Amazon") {
        if (!validateNewAmazonIntegration()) {
          return;
        }
        setCookie({
          appState,
          ...selectedIntegration,
        });
        window.location.href = `${getAmazonBaseRedirectUrl(
          selectedIntegration,
        )}/selling-partner-appstore/dp/amzn1.sp.solution.2fa956a1-7cb7-48e4-9137-f8f11a3ea610`;
      } else if (selectedIntegration.integrationType === "UPS") {
        if (selectedIntegration) {
          if (!selectedIntegration?.configurations?.upsAccountNumber) {
            appState.setAlert(
              `UPS account number is required. Please provide a valid account number.`,
              "error",
              5000,
            );
            return false;
          }

          if (
            selectedIntegration?.configurations?.upsAccountNumber !==
            selectedIntegration?.configurations?.confirmUpsAccountNumber
          ) {
            appState.setAlert(
              `UPS account numbers do not match. Please check and try again.`,
              "error",
              5000,
            );
            return false;
          }
          if (!selectedIntegration?.name) {
            appState.setAlert(
              `Please enter account name & try again.`,
              "error",
              5000,
            );
            return false;
          }
        }
        setCookie({
          appState,
          ...selectedIntegration,
        });
        window.location.href = `https://onlinetools.ups.com/security/v1/oauth/authorize?client_id=uXt5WyXgomqAXJwSk8rZlmtJqys0RPAIpLQUTQ5A19OwDRBv&redirect_uri=https%3A%2F%2Foauth.hopstack.io&response_type=code&state=string&scope=string`;
      } else if (selectedIntegration.integrationType === "StampsCom") {
        if (!selectedIntegration?.name) {
          appState.setAlert(
            `Please enter account name & try again.`,
            "error",
            5000,
          );
          return false;
        }
        setCookie({
          appState,
          ...selectedIntegration,
        });
        window.location.href = `https://signin.testing.stampsendicia.com/authorize?response_type=code&client_id=X25KY9I6dA252b0e8zmezpCJ4N880saY&redirect_uri=https://oauth.hopstack.io&scope=offline_access`;
      } else {
        if (
          selectedIntegration.integrationType === "FedEx" &&
          !selectedIntegration?.name
        ) {
          appState.setAlert(
            `Please enter account name & try again.`,
            "error",
            5000,
          );
          return false;
        }
        saveIntegrationQuery.fetchData({ ...selectedIntegration });
      }
    };

    const validateIntegrationConfiguration = () => {
      if (selectedIntegrationToConfigure.integrationType === "Shopify") {
        if (
          selectedIntegrationToConfigure.configurations
            .enabledShopifyLocationIds.length < 1
        ) {
          appState.setAlert(
            `Please select at least one location.`,
            "error",
            5000,
          );
          return false;
        } else return true;
      } else if (selectedIntegrationToConfigure.marketplaces.length < 1) {
        appState.setAlert(
          `Please select at least one marketplace.`,
          "error",
          5000,
        );
        return false;
      }
      return true;
    };

    const configureIntegrationSubmit = () => {
      if (!validateIntegrationConfiguration()) {
        return;
      }
      saveIntegrationQuery.fetchData({
        ...selectedIntegrationToConfigure,
      });
    };

    return (
      <WrappedComponent
        integrationProviders={masterData.integrationProviders?.filter(
          (item) =>
            item.scope !== "GLOBAL" &&
            ((subdomain !== "primezeroprep" && subdomain !== "wase") ||
              (subdomain === "wase" &&
                ["ebay", "amazon", "shopify"].includes(
                  item.name.toLowerCase(),
                )) ||
              item.name === "Amazon"),
        )}
        getProducts={(id) => getProductsQuery.fetchData({ id })}
        getOrders={(id) => getOrdersQuery.fetchData({ id })}
        orders={orders}
        products={products}
        setProducts={setProducts}
        setOrders={setOrders}
        connectIntegration={(integrationType) => {
          if (integrationType === "eBay") {
            appState.setLoading();
            const state = encodeURI(
              `${appState.tenant.apiGateway}|${appState.tenant.subdomain}|${appState.tenant.id}|${selectedCustomer}|${selectedWarehouse}`,
            );
            window.location = `https://auth.ebay.com/oauth2/authorize?client_id=Hopstack-Hopstack-PRD-b0905fb5f-3697805e&response_type=code&redirect_uri=Hopstack_Inc-Hopstack-Hopsta-bskaripbh&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly&state=${state}`;
          } else if (integrationType === "Salla") {
            appState.setLoading();
            setCookie({
              appState,
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
            });
            const appId = config.SALLA_APP_ID;
            window.location = `https://s.salla.sa/apps/install/${appId}`;
          } else if (integrationType === "Zid") {
            appState.setLoading();
            setCookie({
              appState,
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
            });
            window.location = `https://web.zid.sa/account/settings/shipping-options`;
          } else if (
            integrationType === "Zoho" &&
            appState.tenant.subdomain === "delmaruat"
          ) {
            // Delmar Zoho
            appState.setLoading();
            setCookie({
              appState,
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
            });
            window.location = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.coql.READ&client_id=1000.RQFU4UXZ7IARAB3SJLMS0MMY6L0EAP&response_type=code&access_type=offline&redirect_uri=https://oauth.hopstack.io`;
          } else if (
            integrationType === "Zoho" &&
            appState.tenant.subdomain === "vetest"
          ) {
            // Vanenglen Zoho
            appState.setLoading();
            setCookie({
              appState,
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
            });
            window.location = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.coql.READ&client_id=1000.R9UACSJBUH9DK4C0C0EIBPA04ZMBKS&response_type=code&access_type=offline&redirect_uri=https://oauth.hopstack.io`;
          } else if (
            integrationType === "Zoho" &&
            appState.tenant.subdomain === "uat"
          ) {
            // Hopstack Zoho
            appState.setLoading();
            setCookie({
              appState,
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
            });
            window.location = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.coql.READ&client_id=1000.MPQNM1EBL4915OVLX55Q8IUUQCGUAO&response_type=code&access_type=offline&redirect_uri=https://oauth.hopstack.io`;
          } else {
            setSelectedIntegration({
              integrationType,
              customer: selectedCustomer,
              warehouse: selectedWarehouse,
              username: "",
              password: "",
              storeName: "",
              marketplaces: [],
              region: null,
            });
          }
        }}
        integrations={
          integrationsQuery.data ? integrationsQuery.data.integrations : []
        }
        selectedIntegration={selectedIntegration}
        setSelectedIntegration={setSelectedIntegration}
        selectedIntegrationToManage={selectedIntegrationToManage}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={(integration) => {
          if (!integration) {
            return setSelectedIntegrationToConfigure(null);
          }
          if (
            (!integration.marketplaces ||
              integration.marketplaces.length === 0) &&
            integration.marketplaceCountryCode
          ) {
            integration.marketplaces = [integration.marketplaceCountryCode];
          }
          integration.marketplaceCountryCode = null;
          return setSelectedIntegrationToConfigure(integration);
        }}
        configureIntegrationSubmit={configureIntegrationSubmit}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveIntegration={saveIntegration}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        filters={filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          let updatedFilters = { ...filters };
          if (
            (field === "warehouse" || field === "customer") &&
            value !== null
          ) {
            updatedFilters[field] = value && value.length > 0 ? value[0] : null;
            if (field === "customer") {
              setSelectedCustomer(value && value.length > 0 ? value[0] : null);
            }
            if (field === "warehouse") {
              setSelectedWarehouse(value && value.length > 0 ? value[0] : null);
            }
          }
          if (autoSubmit && value != null) {
            integrationsQuery.fetchData({ filters: updatedFilters });
          }
          setFilters(updatedFilters);
        }}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          setFilters({
            ...filters,
            customer: e,
          });
          integrationsQuery.fetchData({
            filters: {
              ...filters,
              customer: e,
            },
          });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouse: e,
          });
          setSelectedWarehouse(e);
          integrationsQuery.fetchData({
            filters: {
              ...filters,
              warehouse: e,
            },
          });
        }}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        testResults={testResults}
        setTestResults={setTestResults}
        writable={props.writable}
        shopifyLocations={shopifyLocations}
        shipstationCarriers={shipstationCarriers}
      />
    );
  };
};

export default withSoftwareIntegrationsLogic;
