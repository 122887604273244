import { XIcon } from "@heroicons/react/solid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import OrderDetailsTable from "#components/orders/batching/OrderDetailsTable";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import CheckBox from "#newUiComponents/commons/CheckBox";
import OrderHeader from "./packPlan/OrderHeader";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const BatchPrioritisationWithDetails = ({
  selectedOrderIds,
  setSelectedOrderIds,
  orders,
  expandOrder,
  fulfillableOrders,
  partiallyFulfillableOrders,
  unfulfillableOrders,
  exceptionOrders,
  selectedOrderIdsForBatching,
  setSelectedOrderIdsForBatching,
  refetchOrderDetails,
  setRefetchOrderDetails,
  updatedOrderBatchingDetails,
  setUpdatedOrderBatchingDetails,
  setOrderQuantityModified,
  tenant,
  qtyError,
  setQtyError,
  isBackOrderEnabledForTenant,
  setOrderDetailsProcessing,
  setAllOrdersAreUnfulfillable,
  handleRefreshStatus,
  warehouses,
  customers,
}) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [refetchOrderQuantity, setRefetchOrderQuantity] = useState(true);
  const [availableQtyList, setAvailableQtyList] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [hasRearranged, setHasRearranged] = useState(false);

  const deselectOrder = (orderId) => {
    setSelectedOrderIds((prev) => prev.filter((order) => order !== orderId));
  };

  const onDragEnd = (result) => {
    setIsDragging(false);

    if (!result.destination || result.destination.index === -1) return;
    const selectedProductsCopy = [...selectedOrderIds];
    const [reOrderedProducts] = selectedProductsCopy.splice(
      result.source.index,
      1,
    );
    selectedProductsCopy.splice(result.destination.index, 0, reOrderedProducts);
    setSelectedOrderIds(selectedProductsCopy);
    setHasRearranged(true);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const handleBackOrderSelection = (id) => {
    if (selectedOrderIdsForBatching.includes(id)) {
      setSelectedOrderIdsForBatching(
        selectedOrderIdsForBatching.filter((existingId) => existingId !== id),
      );
    } else {
      setSelectedOrderIdsForBatching([...selectedOrderIdsForBatching, id]);
    }
  };

  // Add orderPriority helper function
  const getOrderPriority = (orderId) => {
    if (fulfillableOrders?.find((fo) => fo.order.id === orderId)) {
      return 1; // Highest priority
    }
    if (
      partiallyFulfillableOrders?.find((po) => po.originalOrder.id === orderId)
    ) {
      return 2;
    }
    if (unfulfillableOrders?.find((uo) => uo.order.id === orderId)) {
      return 3;
    }
    if (exceptionOrders?.find((eo) => eo.order.id === orderId)) {
      return 4;
    }
    return 5; // Lowest priority for unknown status
  };

  // Sort the orders before rendering
  const sortedOrderIds = [...selectedOrderIds].sort((a, b) => {
    return getOrderPriority(a) - getOrderPriority(b);
  });

  const getOrderStatus = (orderId) => {
    if (fulfillableOrders?.find((fo) => fo.order.id === orderId)) {
      return { label: "Fulfillable", color: "bg-green-100 text-green-800" };
    }
    if (
      partiallyFulfillableOrders?.find((po) => po.originalOrder.id === orderId)
    ) {
      return {
        label: "Partially Fulfillable",
        color: "bg-yellow-100 text-yellow-800",
      };
    }
    if (unfulfillableOrders?.find((uo) => uo.order.id === orderId)) {
      return { label: "Unfulfillable", color: "bg-red-100 text-red-800" };
    }
    if (exceptionOrders?.find((eo) => eo.order.id === orderId)) {
      return { label: "Exception", color: "bg-gray-100 text-gray-800" };
    }

    return { label: "Processing", color: "bg-blue-100 text-blue-800" };
  };

  useEffect(() => {
    if (selectedOrderIds.length === 0) {
      setAllOrdersAreUnfulfillable(false);
      return;
    }

    const allUnfulfillable = selectedOrderIds.every((orderId) => {
      const isUnfulfillable = unfulfillableOrders?.some(
        (uo) => uo.order.id === orderId,
      );
      const isException = exceptionOrders?.some(
        (eo) => eo.order.id === orderId,
      );

      return isUnfulfillable || isException;
    });

    setAllOrdersAreUnfulfillable(allUnfulfillable);
  }, [
    selectedOrderIds,
    unfulfillableOrders,
    exceptionOrders,
    setAllOrdersAreUnfulfillable,
  ]);

  useEffect(() => {
    // Check if any orders are still in "Processing" status
    const hasProcessingOrders = selectedOrderIds.some((orderId) => {
      const isFulfillable = fulfillableOrders?.some(
        (fo) => fo.order.id === orderId,
      );
      const isPartiallyFulfillable = partiallyFulfillableOrders?.some(
        (po) => po.originalOrder.id === orderId,
      );
      const isUnfulfillable = unfulfillableOrders?.some(
        (uo) => uo.order.id === orderId,
      );
      const isException = exceptionOrders?.some(
        (eo) => eo.order.id === orderId,
      );
      return !(
        isFulfillable ||
        isPartiallyFulfillable ||
        isUnfulfillable ||
        isException
      );
    });

    if (hasProcessingOrders) {
      setOrderDetailsProcessing(true);
    } else {
      setOrderDetailsProcessing(false);
    }
  }, [
    selectedOrderIds,
    fulfillableOrders,
    partiallyFulfillableOrders,
    unfulfillableOrders,
    exceptionOrders,
    setOrderDetailsProcessing,
  ]);

  const renderOrderDetails = (orderId, orderDetails) => {
    if (!orderDetails) return null;

    if (orderDetails.originalOrder && !orderDetails.originalOrder.splitOrders) {
      // Back order handling - using same structure as PartialFulfillableOrders
      const fulfillableChildOrder = orderDetails.fulfillableChildOrder;
      const unfulfillableChildOrder = orderDetails.unfulfillableChildOrder;
      const availableLineItemsQuantity =
        orderDetails.availableLineItemsQuantity;
      const originalOrder = orderDetails.originalOrder;

      return (
        <div className="border-t border-gray-200 p-4">
          {/* First show any reasons if present */}
          {orderDetails.reasons && (
            <ul className="mb-4 flex flex-col rounded border border-yellow-400 bg-lightYellow p-3">
              {orderDetails.reasons.map((reason, idx) => (
                <li key={idx} className="text-sm text-lightGray">
                  {reason}
                </li>
              ))}
            </ul>
          )}

          {/* Fulfillable Section */}
          <div className="mb-4">
            <div className="mb-2 flex items-center gap-x-2">
              <p className="text-base font-semibold text-primaryAccent">
                Split {originalOrder?.childOrders?.length - 1}
              </p>
              <p className="rounded-2xl border-2 border-black p-1 text-base">
                Fulfillable
              </p>
            </div>
            <OrderDetailsTable
              currentOrder={originalOrder}
              orderDetails={fulfillableChildOrder?.orderDetails}
              availableLineItemsQuantity={availableLineItemsQuantity}
              type="fulfillableOrders"
              refetchOrderDetails={refetchOrderDetails}
              setRefetchOrderDetails={setRefetchOrderDetails}
              updatedOrderBatchingDetails={updatedOrderBatchingDetails}
              setUpdatedOrderBatchingDetails={setUpdatedOrderBatchingDetails}
              setOrderQuantityModified={setOrderQuantityModified}
              tenant={tenant}
              qtyError={qtyError}
              setQtyError={setQtyError}
              refetchOrderQuantity={refetchOrderQuantity}
              availableQtyList={availableQtyList}
              setAvailableQtyList={setAvailableQtyList}
            />
          </div>

          {/* Non-Fulfillable Section */}
          <div>
            <div className="mb-2 flex items-center gap-x-2">
              <p className="text-base font-semibold text-primaryAccent">
                Split {originalOrder?.childOrders?.length}
              </p>
              <p className="rounded-2xl border-2 border-black p-1 text-base">
                Non-Fulfillable
              </p>
            </div>
            <OrderDetailsTable
              orderDetails={unfulfillableChildOrder?.orderDetails}
              availableLineItemsQuantity={availableLineItemsQuantity}
              type="unfulfillableOrders"
              refetchOrderDetails={refetchOrderDetails}
              setRefetchOrderDetails={setRefetchOrderDetails}
              updatedOrderBatchingDetails={updatedOrderBatchingDetails}
              setUpdatedOrderBatchingDetails={setUpdatedOrderBatchingDetails}
              setOrderQuantityModified={setOrderQuantityModified}
              tenant={tenant}
              qtyError={qtyError}
              setQtyError={setQtyError}
            />
          </div>
        </div>
      );
    }

    if (orderDetails.childOrders && orderDetails.order.splitOrders) {
      // Split order handling
      return (
        <div className="border-t border-gray-200 p-4">
          {orderDetails.childOrders.map((childOrder, idx) => (
            <div key={idx} className="mb-4">
              <div className="mb-2 flex items-center gap-x-2">
                <p className="text-base font-semibold text-primaryAccent">
                  Split {idx + 1}
                </p>
                <p className="rounded-2xl border-2 border-black p-1 text-base">
                  {idx === 0 ? "Fulfillable" : "Non-Fulfillable"}
                </p>
              </div>
              <OrderDetailsTable
                orderDetails={childOrder.orderDetails}
                availableLineItemsQuantity={
                  orderDetails.availableLineItemsQuantity
                }
                type={idx === 0 ? "fulfillableOrders" : "unfulfillableOrders"}
                refetchOrderDetails={refetchOrderDetails}
                setRefetchOrderDetails={setRefetchOrderDetails}
                updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                setUpdatedOrderBatchingDetails={setUpdatedOrderBatchingDetails}
                setOrderQuantityModified={setOrderQuantityModified}
                tenant={tenant}
                qtyError={qtyError}
                setQtyError={setQtyError}
              />
            </div>
          ))}
        </div>
      );
    }

    // Regular order handling
    return (
      <div className="border-t border-gray-200 p-4">
        <OrderDetailsTable
          currentOrder={orderDetails.order}
          orderDetails={orderDetails.order.orderDetails}
          availableLineItemsQuantity={orderDetails.availableLineItemsQuantity}
          type="fulfillableOrders"
          refetchOrderDetails={refetchOrderDetails}
          setRefetchOrderDetails={setRefetchOrderDetails}
          updatedOrderBatchingDetails={updatedOrderBatchingDetails}
          setUpdatedOrderBatchingDetails={setUpdatedOrderBatchingDetails}
          setOrderQuantityModified={setOrderQuantityModified}
          tenant={tenant}
          qtyError={qtyError}
          setQtyError={setQtyError}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      {isBackOrderEnabledForTenant &&
        partiallyFulfillableOrders?.length > 0 && (
          <div className="flex items-center justify-end gap-x-2 p-2">
            <CheckBox
              checked={selectAll}
              onChange={() => {
                const newSelectAll = !selectAll;
                setSelectAll(newSelectAll);
                if (newSelectAll) {
                  const backOrderIds = partiallyFulfillableOrders.map(
                    (po) => po.originalOrder.id,
                  );
                  setSelectedOrderIdsForBatching([
                    ...fulfillableOrders.map((fo) => fo.order.id),
                    ...backOrderIds,
                  ]);
                } else {
                  setSelectedOrderIdsForBatching(
                    fulfillableOrders.map((fo) => fo.order.id),
                  );
                }
              }}
            />
            <p className="text-base font-semibold text-primaryAccent">
              Select All
            </p>
          </div>
        )}

      <div className="mt-2 flex gap-3">
        Warehouse:{" "}
        {warehouses.find((warehouse) => warehouse.id === orders?.[0].warehouse)
          ?.name || ""}
      </div>
      {hasRearranged && !isDragging && (
        <div className="flex items-center">
          <p className="mr-2 text-sm text-amber-600">
            Order status may have changed. Refresh to see updated status.
          </p>
          <PrimaryButton
            onClick={() => {
              handleRefreshStatus(() => {
                setHasRearranged(true);
              });
            }}
            icon={
              <svg
                className="mr-2 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            }
            height="2rem"
            minWidth="7rem"
            maxWidth="15rem"
            variant="primary"
            className="flex items-center text-sm font-medium">
            Refresh Status
          </PrimaryButton>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="Column">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex flex-col gap-y-4">
              {selectedOrderIds.map((orderId, index) => {
                const orderStatus = getOrderStatus(orderId);
                const orderDetails =
                  fulfillableOrders?.find((fo) => fo.order.id === orderId) ||
                  partiallyFulfillableOrders?.find(
                    (po) => po.originalOrder.id === orderId,
                  ) ||
                  unfulfillableOrders?.find((uo) => uo.order.id === orderId) ||
                  exceptionOrders?.find((eo) => eo.order.id === orderId);
                const order = orders?.find((ord) => ord?.id === orderId);
                const isBackOrder =
                  orderDetails?.originalOrder &&
                  !orderDetails?.originalOrder.splitOrders;

                return (
                  <Draggable key={orderId} draggableId={orderId} index={index}>
                    {(provided) => (
                      <div
                        className="flex flex-col rounded border-2 border-gray-300"
                        {...provided.draggableProps}
                        ref={provided.innerRef}>
                        <div
                          className="flex items-center justify-between p-3"
                          {...provided.dragHandleProps}>
                          <div className="flex items-center gap-3">
                            {isBackOrder && isBackOrderEnabledForTenant && (
                              <CheckBox
                                checked={selectedOrderIdsForBatching.includes(
                                  orderId,
                                )}
                                onChange={() =>
                                  handleBackOrderSelection(orderId)
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            )}
                            {/* <div className="text-lg font-semibold text-primaryAccent">
                              <a
                                className="cursor-pointer"
                                onClick={() => expandOrder(order)}>
                                {order?.orderId}
                              </a>
                            </div> */}
                            <OrderHeader
                              orderId={order?.orderId}
                              orderSource={order?.source}
                              orderDate={order?.orderDate}
                              warehouse={""}
                              clientName={
                                customers?.find(
                                  (customer) => customer.id === order.customer,
                                )?.name || ""
                              }
                              onClickOrderId={() => expandOrder(order?.orderId)}
                            />
                            <span
                              className={`rounded-full px-2 py-1 text-sm font-medium ${orderStatus.color}`}>
                              {orderStatus.label}
                            </span>
                          </div>

                          <div className="flex gap-4">
                            <div
                              className="cursor-pointer"
                              onClick={() => deselectOrder(orderId)}>
                              <XIcon className="h-5 w-5 text-gray-500" />
                            </div>

                            <button
                              onClick={() =>
                                setExpandedOrderId(
                                  expandedOrderId === orderId ? null : orderId,
                                )
                              }
                              className="cursor-pointer">
                              {expandedOrderId === orderId ? (
                                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                              ) : (
                                <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                              )}
                            </button>
                          </div>
                        </div>

                        {expandedOrderId === orderId &&
                          renderOrderDetails(orderId, orderDetails)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BatchPrioritisationWithDetails;
