import withOrderPickerLogic from "#components/HOC/withOrderPickerLogic";
import PickerNewBatchRequest from "#components/picker/common/PickerNewBatchRequest";
import SingleLineItemBatch from "#components/picker/order/SingleLineItemBatch";
import SelectCustomerCode from "#components/picker/common/SelectCustomerCode";
import PageHeader from "#components/picker/common/PageHeader";
import _ from "lodash";

const OrderPicker = ({
  getNewBatch,
  currentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  loading,
  simulateTote,
  currentItem,
  workflow,
  customer,
  customers,
  onSubmitCustomer,
  onSkipCustomer,
  confirmDropoffWithConfirmation,
  subdomain,
  skipItem,
  onChangeScannedQuantity,
  setDisplayScan,
  displayScan,
  warehouses,
  batchfilters,
  onChangeDropdown,
  currentProduct,
  pickingScanningDisabled,
  confirmAllItems,
  onChangePickedQuantityOfItem,
}) => {
  if (!loading && !currentBatch) {
    if (workflow) {
      if (
        workflow.entryPoints &&
        workflow.entryPoints.findIndex(
          (item) => item.name === "ENTER_CUSTOMER_CODE",
        ) !== -1
      ) {
        if (customer === undefined) {
          return (
            <SelectCustomerCode
              customers={customers}
              onSubmit={onSubmitCustomer}
              onSkip={onSkipCustomer}
            />
          );
        }
      }
    }
    return (
      <>
        <PageHeader customer={customer} customers={customers} />
        <PickerNewBatchRequest
          customers={customers}
          warehouses={warehouses}
          batchfilters={batchfilters}
          onChangeDropdown={onChangeDropdown}
          getNewBatch={getNewBatch}
        />
      </>
    );
  }

  if (currentBatch) {
    return (
      <>
        <PageHeader customer={customer} customers={customers} />
        <SingleLineItemBatch
          currentBatch={currentBatch}
          scanBarcode={scanBarcode}
          confirmPickItem={confirmPickItem}
          confirmDropoff={confirmDropoff}
          confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
          simulateTote={simulateTote}
          currentItem={currentItem}
          workflow={workflow}
          subdomain={subdomain}
          skipItem={skipItem}
          onChangeScannedQuantity={onChangeScannedQuantity}
          displayScan={displayScan}
          setDisplayScan={setDisplayScan}
          currentProduct={currentProduct}
          pickingScanningDisabled={pickingScanningDisabled}
          confirmAllItems={confirmAllItems}
          onChangePickedQuantityOfItem={onChangePickedQuantityOfItem}
        />
      </>
    );
  }

  return null;
};

export default withOrderPickerLogic(OrderPicker);
