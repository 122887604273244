import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { GET_CATALOGS } from "#queries";
import { useQuery } from "#hooks/useQuery";
import noFilterIcon from "#static/images/nofilter.png";
import CommonTable from "#components/common/CommonTable";
import { transformCostPrice } from "#utils/helper-functions";
import SectionTabs from "../catalogs/catalogTabs/SectionTabs";
import AnalyticsSalesOverview from "./AnalyticsSalesOverview";

const calculateMargin = (costPrice = 0, lockedInPrice = 0) => {
  if (costPrice && (lockedInPrice || lockedInPrice === 0)) {
    const multiplyCostPrice = Number(costPrice) * 100;
    const multiplyLockedInPrice = Number(lockedInPrice) * 100;
    if (multiplyLockedInPrice >= multiplyCostPrice) {
      return {
        margin:
          ((multiplyLockedInPrice - multiplyCostPrice) / multiplyCostPrice) *
          100,
        isProfit: true,
      };
    } else {
      return {
        margin:
          ((multiplyCostPrice - multiplyLockedInPrice) / multiplyCostPrice) *
          100,
        isProfit: false,
      };
    }
  }
  return null;
};

const AnalyticsTabs = ({
  isLoading = false,
  product = null,
  allVariantComponents = [],
  tabs = [],
  setTabs = () => {},
}) => {
  const headers = [
    "Catalogue",
    "Current Landing Price",
    "Dynamic Sales Price",
    "Locked In Price",
    "Current Margin",
    "Lock In Date",
  ];
  const [collapsed, setCollapsed] = useState(componentCollapse);
  const [catalogsWithExistProduct, setCatalogsWithExistProduct] = useState([]);
  const getCatalogsQuery = useQuery(GET_CATALOGS);

  const componentCollapse = Array.from(
    { length: product?.components?.length },
    () => false,
  );

  const costPrice = {};
  if (product?.costPrice?.length === 1) {
    const hasComponentDetails = product?.costPrice?.some((item) =>
      item.hasOwnProperty("componentDetails"),
    );
    if (hasComponentDetails) {
      const costPrice = transformCostPrice(product.costPrice, false);
      product.costPrice = costPrice;
    }
    for (const costPriceKey in product.costPrice[0]) {
      if (costPriceKey !== "currency") {
        let componentPrice;
        if (!_.isObject(product.costPrice[0][costPriceKey])) {
          componentPrice = Number(product.costPrice[0][costPriceKey]);
          if (costPriceKey !== "discount" && costPriceKey !== "total") {
            costPrice[costPriceKey] = componentPrice / 100;
          } else costPrice[costPriceKey] = componentPrice;
        } else {
          const selectedComponent = allVariantComponents.find(
            (variantObject) => variantObject.id === costPriceKey,
          );
          if (selectedComponent) {
            componentPrice =
              (Number(selectedComponent?.price || "") /
                Number(selectedComponent?.quantity || "")) *
              Number(product.costPrice[0][costPriceKey].quantity || "");
            costPrice[selectedComponent?.componentName] = componentPrice / 100;
          }
        }
        if (costPriceKey !== "discount") {
          if (costPriceKey !== "total") {
            costPrice["total"] = (costPrice["total"] || 0) + componentPrice;
          }
        }
      } else costPrice[costPriceKey] = product.costPrice[0][costPriceKey];
    }
    if (costPrice?.discount) {
      costPrice.total = (costPrice.total - (costPrice?.discount || 0)) / 100;
      costPrice.discount = costPrice?.discount / 100;
    } else costPrice.total = Number(costPrice.total || 0) / 100;
  }

  const getModifiedPrice = (salesPriceData) => {
    const {
      catalogId,
      lockedInDate,
      salesPrice,
      costPrice,
      lockedInPrice,
      ...rest
    } = salesPriceData;

    const modifiedSaleData = Object.keys(rest).reduce((acc, key) => {
      if (
        key !== "discountType" &&
        key !== "marginType" &&
        key !== "landingPrice"
      ) {
        acc[key] = rest[key] / 100;
      } else {
        acc[key] = rest[key];
      }
      return acc;
    }, {});

    let margin = modifiedSaleData?.margin || 0;
    let discount = modifiedSaleData?.discount || 0;

    // Calculate margin based on marginType
    if (modifiedSaleData?.marginType === "percentage") {
      margin =
        modifiedSaleData?.landingPrice * (modifiedSaleData?.margin / 100);
      modifiedSaleData.margin = margin;
    } else {
      margin = modifiedSaleData?.margin;
    }

    // Calculate initial total with dynamic keys
    let total = modifiedSaleData?.landingPrice + margin;
    Object.keys(modifiedSaleData).forEach((key) => {
      if (
        ![
          "margin",
          "discount",
          "discountType",
          "marginType",
          "total",
          "landingPrice",
        ].includes(key)
      ) {
        total += modifiedSaleData[key];
      }
    });

    // Apply discount based on discountType
    if (modifiedSaleData?.discountType === "percentage") {
      discount = total * (modifiedSaleData?.discount / 100);
      modifiedSaleData.discount = discount;
    } else {
      discount = modifiedSaleData?.discount;
    }

    total -= discount;

    // Add total to the modified object
    modifiedSaleData.total = isNaN(total) ? total : total > 0 ? total : 0;

    return modifiedSaleData;
  };

  const fetchAllCatalog = async () => {
    const catalogsResponse = await getCatalogsQuery.fetchData();
    if (catalogsResponse.data) {
      if (catalogsResponse?.data?.catalogs?.entities?.length > 0) {
        const catalogsWithExistProduct =
          catalogsResponse?.data?.catalogs?.entities?.filter(
            (catalogObject) => {
              return (
                catalogObject?.products?.length > 0 &&
                catalogObject?.products?.includes(product?.id)
              );
            },
          );
        setCatalogsWithExistProduct(catalogsWithExistProduct);
      }
    }
  };

  useEffect(() => {
    catalogsWithExistProduct?.length > 0 && setCatalogsWithExistProduct([]);
  }, [product]);

  useEffect(() => {
    if (product) {
      fetchAllCatalog();
    }
  }, [product]);

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    switch (currentTab) {
      case "Pricing Overview":
        return (
          <div className="pricing-overview-table">
            <CommonTable headers={headers}>
              <tbody className="divide-y divide-gray-200 bg-white font-inter">
                {catalogsWithExistProduct?.length > 0
                  ? catalogsWithExistProduct?.map((catalogObject, index) => {
                      const salesData =
                        product?.salesPrice?.find(
                          (salesPrice) =>
                            salesPrice?.catalogId === catalogObject?.id,
                        ) || null;
                      const modifiedSalesPrice = getModifiedPrice({
                        landingPrice: costPrice?.total,
                        ...salesData,
                      });

                      const lockedInPriceData =
                        product?.lockedInPrice?.find(
                          (lockedIn) =>
                            lockedIn?.catalogId === catalogObject?.id,
                        ) || null;
                      const modifiedlockedInPrice = getModifiedPrice({
                        landingPrice: lockedInPriceData?.costPrice / 100,
                        ...lockedInPriceData,
                      });

                      const lockedInData =
                        product?.lockedInPrice?.find(
                          (lockedIn) =>
                            lockedIn?.catalogId === catalogObject?.id,
                        ) || null;

                      const getMargin = calculateMargin(
                        costPrice?.total,
                        modifiedlockedInPrice?.total,
                      );
                      return (
                        <>
                          <tr
                            className="cursor-pointer hover:bg-gray-100"
                            key={`pricing-overview-${index}`}>
                            <td className="whitespace-nowrap px-6 py-4">
                              <div className="text-base font-semibold text-gray-900">
                                {catalogObject?.name}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-base text-gray-900">
                              {costPrice?.total > 0
                                ? `${costPrice?.currency} ${costPrice?.total?.toFixed(2)}`
                                : "N/A"}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-base text-gray-900">
                              {costPrice?.total > 0 &&
                              (modifiedSalesPrice?.total === 0 ||
                                modifiedSalesPrice?.total > 0)
                                ? `${costPrice?.currency} ${modifiedSalesPrice?.total?.toFixed(2)}`
                                : "N/A"}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-base font-medium text-green-600">
                              {costPrice?.total > 0 &&
                              (modifiedlockedInPrice?.total === 0 ||
                                modifiedlockedInPrice?.total > 0)
                                ? `${costPrice?.currency} ${modifiedlockedInPrice?.total?.toFixed(2)}`
                                : "N/A"}
                            </td>
                            <td
                              className={`whitespace-nowrap px-6 py-4 text-base ${getMargin && !getMargin?.isProfit ? `text-red-500` : `text-yellow-600`}`}>
                              {costPrice?.total > 0 && getMargin
                                ? `${getMargin?.margin?.toFixed(2)}%`
                                : "N/A"}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                              {lockedInData?.lockedInDate
                                ? moment(lockedInData?.lockedInDate).format(
                                    "YYYY-MM-DD",
                                  )
                                : "NA"}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
                {(!product || !catalogsWithExistProduct?.length > 0) && (
                  <tr>
                    <td colSpan={"100%"}>
                      <div className="w-full flex-col items-center justify-center p-8 text-center">
                        <img src={noFilterIcon} className="mx-auto h-[212px]" />
                        <div className="mt-4 text-center text-xl font-semibold text-gray-600">
                          No Catalogs found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>
          </div>
        );
      case "Sales Overview":
        return (
          <AnalyticsSalesOverview
            isProductLoading={isLoading}
            product={product}
          />
        );
    }
  };

  return (
    <div className="flex-col">
      <div className="my-8 rounded-lg p-6 shadow">
        <div className="flex-col">
          <div className="mb-4">
            <SectionTabs tabs={tabs} setTabs={setTabs} />
          </div>
        </div>
        <div className="customer-pricing-overview-table max-h-[420px] overflow-y-auto">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTabs;
