import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_PRODUCT_LEVEL_PERFORMANCE } from "#queries/index";
import {
  storeInLocalStorage,
  getUnixTimeStamp,
} from "#utils/catalogsAndAnalyticsUtils";
import {
  getStartDateWithHhMmSsFormate,
  getEndDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import { analyticsProductsColumns } from "#constants/analytics";
import AnalyticsDataGrid from "./AnalyticsDataGrid";

const ProductPerformanceGrid = ({
  startEndDate,
  comparePeriodDate,
  selectedComparePeriods,
  selectedCustomer,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(
    analyticsProductsColumns[1],
  );
  const [toggle, setToggle] = useState(false);
  const [analyticsProductPerformanceData, setAnalyticsProductPerformanceData] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getAnalyticsProductLevelPerformanceQuery = useQuery(
    GET_CATALOGS_ANALYTICS_PRODUCT_LEVEL_PERFORMANCE,
  );
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );

  const selectColumnHandler = (column) => {
    if (
      analyticsProductPerformanceData?.primaryProductLevelPerformance?.length >
      0
    ) {
      if (column !== selectedColumn) {
        setSelectedColumn(column);
        storeInLocalStorage(column, "selectedColumn", "productPerformance");
        setToggle(false);
        storeInLocalStorage(false, "toggle", "productPerformance");
      } else {
        setToggle(!toggle);
        storeInLocalStorage(!toggle, "toggle", "productPerformance");
      }
    }
  };

  const toggleButtonHandler = (column, toggleValue) => {
    if (
      analyticsProductPerformanceData?.primaryProductLevelPerformance?.length >
      0
    ) {
      if (column !== selectedColumn) {
        setSelectedColumn(column);
        storeInLocalStorage(column, "selectedColumn", "productPerformance");
      }
      setToggle(toggleValue);
      storeInLocalStorage(toggleValue, "toggle", "productPerformance");
    }
  };

  const getAnalyticsProductPerformanceInfo = async () => {
    const customers = [];
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (selectedCustomer) {
      if (selectedCustomer !== "all customer") {
        customers[0] = selectedCustomer;
      }
      if (startEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(startEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(startEndDate[1]),
        );
      }
      if (selectedComparePeriods) {
        comparePeriodData.comparePeriod = true;
        comparePeriodData["compareStartEndDate"] = [
          getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
          getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
        ];
      }
      if (analyticsProductPerformanceData)
        setAnalyticsProductPerformanceData(null);
      setIsLoading(true);
      try {
        const response =
          await getAnalyticsProductLevelPerformanceQuery.fetchData({
            filters: {
              customer: customers,
              startEndDate: selectedStartEndDate,
              sortType: !toggle ? "top" : "bottom",
              performanceType: selectedColumn,
              ...comparePeriodData,
            },
          });
        if (response?.data?.catalogAnalyticsProductLevelPerformance) {
          setAnalyticsProductPerformanceData(
            response?.data?.catalogAnalyticsProductLevelPerformance,
          );
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const skuClickHandler = (productSku = "") => {
    return {
      pathname: `/productAnalytics`,
      state: { backPath: "/analytics", sku: productSku },
    };
  };

  useEffect(() => {
    if (analyticsData?.productPerformance) {
      if (
        analyticsData?.productPerformance?.selectedColumn &&
        analyticsData?.productPerformance?.selectedColumn !== selectedColumn
      ) {
        setSelectedColumn(analyticsData?.productPerformance?.selectedColumn);
      }
      if (
        analyticsData?.productPerformance?.toggle &&
        analyticsData?.productPerformance?.toggle !== toggle
      ) {
        setToggle(analyticsData?.productPerformance?.toggle);
      }
    }
  }, []);

  useEffect(() => {
    getAnalyticsProductPerformanceInfo();
  }, [
    toggle,
    selectedColumn,
    startEndDate,
    selectedComparePeriods,
    selectedCustomer,
  ]);

  return (
    <AnalyticsDataGrid
      title={"Product Level Performance"}
      data={
        analyticsProductPerformanceData?.primaryProductLevelPerformance || []
      }
      compareData={
        analyticsProductPerformanceData?.comparedProductLevelPerformance || []
      }
      redirect={"/productPerformance"}
      columns={analyticsProductsColumns}
      selectColumnHandler={selectColumnHandler}
      selectedColumn={selectedColumn}
      toggle={toggle}
      toggleButtonHandler={toggleButtonHandler}
      selectedComparePeriods={selectedComparePeriods}
      isLoading={isLoading}
      noDataFoundMsg={"No Products Available"}
      skuClickHandler={skuClickHandler}
    />
  );
};

export default ProductPerformanceGrid;
