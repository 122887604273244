import PageTitle from "#components/utils/PageTitle";
import ListView from "#components/prepper/ListView";
import _ from "lodash";

const BundlePrepDetails = ({ currentBatch, currentItem, simulateItemScan }) => {
  return (
    <div className="mt-4">
      <PageTitle>
        This item is part of a bundle / multi-pack. Please scan the following
        items as well.
      </PageTitle>
      <ListView
        showStatus={true}
        currentBatch={
          currentBatch
            ? {
                ...currentBatch,
                workingList: currentBatch.workingList
                  .filter((item) =>
                    currentItem.validScans.map((i) => i.id).includes(item.id),
                  )
                  .map((item) => ({
                    ...item,
                    quantity: currentItem.validScans.find(
                      (i) => i.id === item.id,
                    ).quantityOfConstituentInBundle,
                  })),
              }
            : currentBatch
        }
        simulateItemScan={simulateItemScan}
      />
    </div>
  );
};

export default BundlePrepDetails;
