import React, { useContext, useEffect, useState } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import CheckBox from "#newUiComponents/commons/CheckBox";
import FbaProductRow from "./FbaProductRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryForRelease,
  startEligibilityCheck,
  getEligibilityCheckResults,
  saveOrder,
  activateInboundPlan,
} from "#redux/FbaInbound/fbaInboundActions";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { useHistory } from "react-router-dom";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { AppStateContext } from "#contexts/appState";
import { planStatusesEnums } from "./FbaUtils";
import FbaConfirmationModals from "./FbaConfirmationModals";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch"; // Import your CustomSwitch component
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import {
  resetEligibilityCheckResults,
  resetInventoryForRelease,
  resetSaveOrderResult,
  resetUpdateInboundPlanResult,
} from "#redux/FbaInbound/fbaInboundSlice";

const FbaConfirmationProducts = ({
  selectedPlan,
  client,
  handleResetFetchPlans,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);
  const {
    inventoryForRelease,
    eligibilityCheckResults,
    activateInboundPlanResult,
  } = useSelector((state) => state.fbaInbound);

  const [filteredInventory, setFilteredInventory] = useState([]);
  const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
  const [eligibilityModalOpen, setEligibilityModalOpen] = useState(false);
  const [proceedWithPickingModalOpen, setProceedWithPickingModalOpen] =
    useState(false);
  const [showExceptionsOnly, setShowExceptionsOnly] = useState(false); // State for the switch toggle

  const handleEligibilityCheck = (isStayOnPage) => {
    const selectedInventory = inventoryForRelease?.data?.filter((product) =>
      selectedInventoryIds.includes(product.id),
    );

    const variables = {
      input: {
        customerId: selectedPlan?.customer,
        marketPlaceCountryCodes: selectedPlan?.marketplace,
        inboundId: selectedPlan?.inboundPlanId,
        products: selectedInventory?.map(
          ({ asin, sku, selectedQuantity, id, name }) => ({
            asin,
            sku,
            quantity: selectedQuantity,
            id,
            name,
          }),
        ),
        sellerId: selectedPlan?.sellerId || "A3OMJHHTYFWGK7",
        warehouseId: selectedPlan?.warehouse,
      },
    };

    const eligibilitySuccessCallback = (data) => {
      if (data?.status === "SUCCESS") {
        notify.success(
          <span>
            Eligibility check completed successfully, click{" "}
            <span
              onClick={() => {
                history.push("/fbaInbound/" + selectedPlan.id);
              }}
              className="font-semibold underline">
              {selectedPlan?.inboundPlanId}
            </span>{" "}
            to visit the product confirmation screen for this plan.
          </span>,
        );
        handleResetFetchPlans();
      } else if (data?.status === "FAILED") {
        notify.error(
          `Eligibility check is failed for ${selectedPlan?.inboundPlanId}`,
        );
      }
    };

    const successCallback = ({ workflowId, errors }) => {
      if (workflowId)
        notify.success(
          `Successfully started workflow`,
          `WorkFlow ID: ${workflowId}`,
        );
      else if (errors?.length) {
        notify.error(errors[0].message);
      }

      const eligibilityVariables = { inboundId: selectedPlan?.inboundPlanId };
      if (isStayOnPage) {
        dispatch(
          getEligibilityCheckResults({ variables: eligibilityVariables }),
        );
      } else {
        history.push(`/fbaInbound`);
        handleResetFetchPlans();
        dispatch(
          getEligibilityCheckResults({
            variables: eligibilityVariables,
            successCallback: eligibilitySuccessCallback,
            errorCallBack: notify.error,
          }),
        );
      }
    };

    dispatch(
      startEligibilityCheck({
        variables,
        successCallback,
        errorCallBack: notify.error,
      }),
    );
  };

  const handleSaveOrder = () => {
    const saveOrderVariables = {
      orderDate: Date.now(),
      orderId: selectedPlan?.inboundPlanId,
      customer: selectedPlan?.customer,
      warehouse: selectedPlan?.warehouse,
      warehouseToBeSelected: false,
      customerToBeSelected: false,
      toValidAddress: true,
      source: "FBA_V1",
      orderLineItems: filteredInventory
        ?.map((item) => {
          if (selectedInventoryIds.includes(item.id)) {
            return {
              productId: item?.id,
              quantity: item.unitsToShip,
              marketplaceAttributes: item.marketplaceAttributes ?? null,
              attributes: item.attributes ?? null,
              fnSku: item.fnSku,
              sku: item.sku,
              name: item.name,
            };
          }
        })
        .filter(Boolean),
    };

    const successCallback = (data) => {
      notify.success(data?.message);

      const inboundPlanVariables = {
        activateInboundPlanInput: {
          id: selectedPlan.id,
          items: filteredInventory
            ?.map((item) => {
              if (selectedInventoryIds.includes(item.id)) {
                return {
                  asin: item.asin,
                  fnsku: item.fnSku,
                  lotId: item?.lotId || null,
                  price: item?.price || null,
                  productId: item?.id || null,
                  quantity: item?.unitsToShip || null,
                  sku: item?.sku || null,
                  uniqueIdentifier: null,
                  expiryDate: item.selectedExpirationDate || null,
                  images: item?.images || [],
                };
              }
            })
            .filter(Boolean),
        },
      };

      dispatch(activateInboundPlan({ variables: inboundPlanVariables }));
    };

    const errorCallback = (err) => {
      notify.error(err);
    };
    dispatch(
      saveOrder({
        variables: saveOrderVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  useEffect(() => {
    if (activateInboundPlanResult?.isRejected) {
      notify.alert(activateInboundPlanResult?.data);
    } else if (activateInboundPlanResult?.isFulfilled) {
      history.push("/fbaInbound");
      handleResetFetchPlans();
    }
  }, [activateInboundPlanResult]);

  useEffect(() => {
    if (eligibilityCheckResults.isPending) appState.setLoading();
    else appState.removeLoading();

    if (eligibilityCheckResults.isRejected) {
      notify.alert(eligibilityCheckResults?.data);
    } else if (eligibilityCheckResults.isFulfilled) {
      if (eligibilityCheckResults?.data?.status === "SUCCESS") {
        // notify.success("Eligibility check completed successfully !");
        setFilteredInventory((prevInventory) => {
          const receivedItemsIds =
            eligibilityCheckResults?.data?.itemsEligibilityAndRestrictionDetails?.map(
              (item) => item.id,
            );
          const finalInventoryToShow = prevInventory
            .map((product) => {
              if (receivedItemsIds?.includes(product.id)) {
                const restrictionDetails =
                  eligibilityCheckResults?.data?.itemsEligibilityAndRestrictionDetails?.find(
                    (item) => item.id === product.id,
                  );
                return {
                  ...product,
                  restrictionDetails,
                };
              }
            })
            .filter(Boolean);

          setSelectedInventoryIds(
            finalInventoryToShow
              .filter((product) => product.restrictionDetails?.isEligible)
              .map((product) => product.id),
          );
          return finalInventoryToShow;
        });
      } else {
        notify.error(
          eligibilityCheckResults?.data?.errors[0]?.message ||
            "Eligibility check Failed !",
        );
      }
    }
  }, [eligibilityCheckResults]);

  useEffect(() => {
    if (selectedPlan && Object.keys(selectedPlan).length) {
      const variables = {
        sellerId: selectedPlan?.sellerId || "",
        marketplace: selectedPlan?.marketplace || "",
        customer: selectedPlan?.customer || "",
        warehouse: selectedPlan?.warehouse || "",
      };

      let successCallback = () => {};

      if (
        selectedPlan?.status ===
          planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.key ||
        selectedPlan?.status === planStatusesEnums.ELIGIBILITY_CHECK_PENDING.key
      ) {
        successCallback = () => {
          const eligibilityVariables = {
            inboundId: selectedPlan?.inboundPlanId,
          };
          dispatch(
            getEligibilityCheckResults({ variables: eligibilityVariables }),
          );
        };
      }

      dispatch(getInventoryForRelease({ variables, successCallback }));
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (Array.isArray(inventoryForRelease?.data)) {
      const transformedData = inventoryForRelease?.data?.map((item) => ({
        ...item,
        selectedExpirationDate:
          item.expirationDates?.[0]?.expirationDate || null,
        selectedQuantity: item.expirationDates?.[0]?.quantity || 0,
        unitsToShip: item.expirationDates?.[0]?.quantity || 0,
      }));
      setFilteredInventory(transformedData);
    } else setFilteredInventory([]);

    if (inventoryForRelease.isPending) appState.setLoading();
    else appState.removeLoading();
  }, [inventoryForRelease]);

  useEffect(() => {
    return () => {
      dispatch(resetEligibilityCheckResults());
      dispatch(resetSaveOrderResult());
      dispatch(resetUpdateInboundPlanResult());
      dispatch(resetInventoryForRelease());
    };
  }, [dispatch]);

  // Filtered inventory based on switch state (all products or only exceptions)
  const displayedInventory = showExceptionsOnly
    ? filteredInventory.filter(
        (product) => !product.restrictionDetails?.isEligible,
      )
    : filteredInventory;

  const allSelected = Array.isArray(displayedInventory)
    ? displayedInventory
        .filter(
          (product) =>
            product.restrictionDetails?.isEligible ||
            !product?.restrictionDetails,
        )
        .every((product) => selectedInventoryIds.includes(product.id))
    : false;

  const isInventoryHavingHazmatProduct = filteredInventory.some(
    (product) => product.restrictionDetails?.isEligible === false,
  );

  const inventoryColumns = [
    {
      key: "select",
      title: showExceptionsOnly ? null : (
        <CheckBox
          checked={allSelected}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedInventoryIds(
                displayedInventory
                  ?.filter(
                    (product) =>
                      product.restrictionDetails?.isEligible ||
                      !product?.restrictionDetails,
                  )
                  .map((product) => product.id),
              );
            } else {
              setSelectedInventoryIds([]);
            }
          }}
        />
      ),
      stickPosition: "left",
    },
    { key: "product", title: "Product Name" },
    { key: "expiryDate", title: "Expiry Date" },
    { key: "unitsAvailable", title: "Units Available" },
    { key: "unitsToShip", title: "Units to Ship" },
  ];

  return (
    <div className="flex h-100v flex-col justify-between px-8 font-inter">
      <div className="h-70v">
        {isInventoryHavingHazmatProduct && (
          <CustomAlert
            id="warningForHazmatProducts"
            type="warning"
            message={`Attention: Some products require hazmat review before shipping. Red warnings may indicate ineligibility. Provide all necessary details.`}
            options={{
              defaultColors: false,
              bgColor: "bg-yellow-800",
              textColor: "text-green-700",
              titleColor: "text-gray-800",
              borderColor: "border-gray-100",
            }}
          />
        )}
        <div className="mb-2">
          <p className="text-base font-semibold">Client Name: {client?.name}</p>
        </div>
        {isInventoryHavingHazmatProduct && (
          <div className="mb-2 flex items-center">
            <CustomSwitch
              leftSideText="All Products"
              rightSideText="Products with Exceptions"
              checked={showExceptionsOnly}
              onChange={(checked) => {
                setShowExceptionsOnly(checked);
              }}
              leftColor="bg-green-500"
              rightColor="bg-red-500"
              labelClasses="text-gray-700 font-medium mr-2"
              switchClasses="w-10 h-6"
              handleClasses="w-4 h-4"
            />
          </div>
        )}
        {selectedInventoryIds.length ? (
          <div className="mb-2">
            <p className="text-sm text-gray-800">
              Note: Inventory for the selected products will be blocked, and a
              parent order will be created in Hopstack, which will later be
              split into shipment orders.
            </p>
          </div>
        ) : null}
        <CustomTable
          columns={inventoryColumns}
          data={displayedInventory}
          renderRow={(record) => (
            <FbaProductRow
              record={record}
              setFilteredInventory={setFilteredInventory}
              setSelectedInventoryIds={setSelectedInventoryIds}
              selectedInventoryIds={selectedInventoryIds}
            />
          )}
          onSort={(key, direction) => console.log("Sort by:", key, direction)}
          isSearchable={false}
          isFilters={false}
          isPagination={false}
          isInfiniteScroll
          loadingData={false}
          totalDataCount={displayedInventory?.length}
          pageSizeOptions={["5", "10", "20"]}
          totalDataCountLable="Total Products: "
        />
      </div>
      <div className="sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4">
        <div className="mb-2 mt-4 flex items-center justify-end gap-4"></div>
        <div className="flex gap-6">
          <PrimaryButton
            height="3rem"
            width="7rem"
            className="mt-2 text-base font-medium"
            onClick={() => {
              history.push("/fbaInbound");
              handleResetFetchPlans();
            }}>
            Cancel
          </PrimaryButton>
          {selectedPlan?.status ===
            planStatusesEnums.ELIGIBILITY_CHECK_COMPLETED.key ||
          (eligibilityCheckResults.isFulfilled &&
            eligibilityCheckResults?.data?.status === "SUCCESS") ? (
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              variant="primary"
              className="mt-2 text-base font-medium"
              onClick={() => {
                setProceedWithPickingModalOpen(true);
              }}>
              Proceed to Picking
            </PrimaryButton>
          ) : (
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              variant="primary"
              className="mt-2 text-base font-medium"
              disabled={
                selectedInventoryIds.length === 0 ||
                selectedPlan?.status ===
                  planStatusesEnums.ELIGIBILITY_CHECK_PENDING.key
              }
              onClick={() => setEligibilityModalOpen(true)}>
              Run Eligibility Check
            </PrimaryButton>
          )}
        </div>
      </div>
      <FbaConfirmationModals
        isOpen={eligibilityModalOpen}
        onClose={() => {
          setEligibilityModalOpen(false);
        }}
        title="Ready to Ship"
        subtitle=""
        description="Verifying your products for FBA eligibility. This may take a few
            minutes. You can stay here or leave; we’ll notify you when it’s
            complete."
        primaryButtonText="Stay on this Page"
        primaryButtonAction={() => {
          handleEligibilityCheck(true);
          setEligibilityModalOpen(false);
        }}
        secondaryButtonText="Notify Me Once Ready"
        secondaryButtonAction={() => {
          handleEligibilityCheck(false);
          setEligibilityModalOpen(false);
        }}
      />
      <FbaConfirmationModals
        isOpen={proceedWithPickingModalOpen}
        onClose={() => {
          setProceedWithPickingModalOpen(false);
        }}
        title="Would you like to proceed with picking each item, or mark all items as picked and continue with the shipment process?"
        subtitle=""
        description="This choice lets you either go through the detailed picking process
            for each item or quickly mark all items as ready, moving you forward
            in the shipment flow."
        primaryButtonText="Mark All Items as Picked"
        primaryButtonAction={() => {
          // Mark items as picked action
          handleSaveOrder();
        }}
        secondaryButtonText="Proceed with Picking"
        secondaryButtonAction={() => {
          // Proceed with picking action
          handleSaveOrder();
        }}
      />
    </div>
  );
};

export default FbaConfirmationProducts;
