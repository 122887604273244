import { useRef } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  EyeIcon,
  DotsVerticalIcon,
  TrashIcon,
  PencilAltIcon,
  ExclamationIcon,
  DownloadIcon,
  ClipboardCopyIcon,
  ClipboardCheckIcon,
  DocumentSearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import SearchSortFilter from "#components/common/SearchSortFilter";
import QuickFilters from "#components/common/QuickFilters";
import { useState } from "react";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { useEffect } from "react";
import cellStyleForTable from "../common/CellStyleForTable";
import ModalV2 from "../utils/ModalV2";
import MinimalModal from "../utils/MinimalModal";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import SearchPanel from "#components/common/SearchPanel";
import { AppStateContext } from "#contexts/appState";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import {
  getEntityStatusColor,
  getHighlightedText,
} from "#utils/searchPanelUtils";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import { twMerge } from "tailwind-merge";
const noValuesText = "No Consignments";
const CONSIGNMENT_ITEM_TABLE_NAME = "consignments";

const searchTabEnums = {
  orderId: "Order ID",
  sku: "SKU",
  trackingNumber: "Tracking Number",
  supplier: "Supplier",
  asin: "ASIN",
  upc: "UPC",
};

const payloadField = {
  trackingNumber: "trackingNumber",
  orderId: "orderId",
  sku: "items.sku",
  supplier: "supplier",
  asin: "items.asin",
  upc: "items.upc",
};

const ORDER_DEBOUNCE_TIME_IN_MS = 500;

const ConsignmentsList = ({
  writable,
  consignments,
  setItems,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  editConsignment,
  editConsignmentNotes,
  editWarehouseNotes,
  deleteConsignment,
  undoConsignmentReceive,
  downloadConsignmentReport,
  receiveConsignment,
  getAudit,
  user,
  changeStatusConsignmentHandle,
  receiveConsignmentPartially,
  downloadConsignmentItemList,
  tenant,
  getDamagedInfo,
  showConsignmentDamageInfo,
  closeConsignmentDamageInfo,
  getConsignmentDetails,
  groupedConsignments,
  fetchExpandedGroupedConsignments,
  groupConsignmentOn,
  setGroupConsignmentOn,
  perPage,
  searchFilters,
  fetchEnhancedSearchResults,
  searchConsignmentsLoading,
  searchResults,
  setSearchResults,
  currentPage,
  setCurrentPage,
}) => {
  const appState = useContext(AppStateContext);
  const [showEnhancedSearch, setShowEnhancedSearch] = useState(false);
  const searchTabs = Object.entries(searchTabEnums).map((val) => ({
    id: val[0],
    name: val[1],
  }));
  const [activeTab, setActiveTab] = useState(searchTabs[0].id);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [enhancedSearchText, setEnhancedSearchText] = useState("");

  const searchRef = useRef();

  const ldClient = useLDClient();
  const isEnhancedSearchEnabled = ldClient.variation(
    featureFlags.ENHANCED_SEARCH_UX,
    false,
  );

  useEffect(() => {
    ldClient.identify({ key: appState?.tenant?.id });
  }, []);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        !searchRef ||
        !searchRef.current ||
        !searchRef.current.contains(event.target)
      ) {
        if (!event.target.closest('[role="dialog"]')) {
          setShowEnhancedSearch(false);
        }
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Close search popup on Esc key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setShowEnhancedSearch(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const onChangeEnhancedSearchKeyword = (e) => {
    setEnhancedSearchText(e.target.value);
    setSearchResults({});
  };

  const clearEnhancedSearchKeyword = () => {
    setEnhancedSearchText("");
    setCurrentPage(0);
  };

  const getTableRow = (consignment) => {
    if (!consignment) return null;

    return (
      <div
        className="flex items-center justify-between border-b border-gray-300 pb-2"
        key={consignment?.consignmentData?.id}>
        <div className="flex items-center gap-6">
          <div>
            <p className="flex gap-2">
              <a
                className="cursor-pointer font-medium text-gray-900"
                onClick={(e) => {
                  e.stopPropagation();
                  getConsignmentDetails(consignment?.consignmentData?.id);
                }}>
                {activeTab === "orderId"
                  ? getHighlightedText({
                      text: consignment?.consignmentData?.orderId,
                      activeTab: payloadField[activeTab],
                      searchHighlights: consignment?.searchHighlights,
                    })
                  : consignment?.consignmentData?.orderId}
              </a>

              {activeTab !== "orderId" && (
                <span className="text-gray-400">|</span>
              )}

              {activeTab !== "orderId" && (
                <span className="text-gray-600">
                  {getHighlightedText({
                    text: consignment?.consignmentData[payloadField[activeTab]],
                    activeTab: payloadField[activeTab],
                    searchHighlights: consignment?.searchHighlights,
                  })}
                </span>
              )}
            </p>

            <p className="flex gap-2">
              <span className="text-gray-600">
                {customers &&
                  customers.find(
                    (customer) =>
                      customer.id === consignment?.consignmentData?.customer,
                  )?.name}
              </span>

              <span className="text-gray-400">|</span>

              <span className="text-gray-600">
                {warehouses &&
                  warehouses.find(
                    (warehouse) =>
                      warehouse.id === consignment?.consignmentData?.warehouse,
                  )?.name}
              </span>
            </p>
          </div>

          <div>
            <CustomBadge
              label={consignment?.consignmentData?.status}
              className={twMerge(
                `font-medium ${getEntityStatusColor(consignment?.consignmentData?.status).bgColor} ${getEntityStatusColor(consignment?.consignmentData?.status).color}`,
              )}
            />
          </div>
        </div>

        <div className="mr-4">
          <CustomPopover
            actions={menuItems(consignment?.consignmentData).map(
              (menuItem) => ({
                ...menuItem,
                key: menuItem.title,
                icon: null,
                name: menuItem.title,
                onClick: menuItem["onClick"],
              }),
            )}
            styles={{ right: 0 }}
            triggerElement={
              <DotsVerticalIcon className="h-6 w-6 text-gray-500" />
            }
          />
        </div>
      </div>
    );
  };

  const searchPayload = (keyword, nextCursor) => {
    return {
      keyword: keyword?.trim(),
      field: payloadField[activeTab],
      filters: {
        warehouses: selectedWarehouses?.map((warehouse) => warehouse.id),
        customers: selectedCustomers?.map((customer) => customer.id),
        status: selectedStatuses?.map((status) => status.id),
      },
      nextCursor,
    };
  };

  useDebouncedEffect(
    () => fetchEnhancedSearchResults(searchPayload(enhancedSearchText)),
    ORDER_DEBOUNCE_TIME_IN_MS,
    [enhancedSearchText],
  );

  useEffect(() => {
    // on scroll
    fetchEnhancedSearchResults(
      searchPayload(enhancedSearchText, searchResults?.nextCursor),
    );
  }, [currentPage]);

  useEffect(() => {
    setSearchResults({});
    setCurrentPage(0);
    fetchEnhancedSearchResults(searchPayload(enhancedSearchText, null));
  }, [activeTab, selectedWarehouses, selectedCustomers, selectedStatuses]);

  const validScoreResults = searchResults?.entities
    ? [...searchResults.entities]?.filter((item) => item?.score > 0)
    : [];

  const [warehouseOpen, setOpenWarehouseOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const filterProps = {
    Warehouse: {
      label: "FILTER BY WAREHOUSE",
      selectedValues: selectedWarehouses,
      setSelectedValues: setSelectedWarehouses,
      placeholder: "Select Warehouse",
      id: "warehouse",
      dropdownOpen: warehouseOpen,
      setDropdownOpen: setOpenWarehouseOpen,
      optionClasses: "text-lg",
    },
    Customer: {
      label: "FILTER BY CLIENT",
      selectedValues: selectedCustomers,
      setSelectedValues: setSelectedCustomers,
      placeholder: "Select Client",
      id: "customer",
      dropdownOpen: customerOpen,
      setDropdownOpen: setCustomerOpen,
      optionClasses: "text-lg",
    },
    Status: {
      label: "FILTER BY STATUS",
      selectedValues: selectedStatuses,
      setSelectedValues: setSelectedStatuses,
      placeholder: "Select Status",
      id: "status",
      dropdownOpen: statusOpen,
      setDropdownOpen: setStatusOpen,
      optionClasses: "text-lg",
    },
  };

  const handleSearchFilterChange = ({ filterName, filterValue }) => {
    switch (filterName) {
      case "warehouse":
        const warehouseExists = selectedWarehouses.find(
          (warehouse) => warehouse.id === filterValue.id,
        );

        if (warehouseExists) {
          setSelectedWarehouses((warehouses) =>
            warehouses.filter(
              (warehouse) => warehouse.id !== warehouseExists.id,
            ),
          );
        } else {
          setSelectedWarehouses((warehouses) => [...warehouses, filterValue]);
        }
        break;

      case "customer":
        const customerExists = selectedCustomers.find(
          (customer) => customer.id === filterValue.id,
        );

        if (customerExists) {
          setSelectedCustomers((customers) =>
            customers.filter((customer) => customer.id !== customerExists.id),
          );
        } else {
          setSelectedCustomers((customers) => [...customers, filterValue]);
        }
        break;

      case "status":
        const statusExists = selectedStatuses.find(
          (status) => status.id === filterValue.id,
        );

        if (statusExists) {
          setSelectedStatuses((statues) =>
            statues.filter((status) => status.id !== statusExists.id),
          );
        } else {
          setSelectedStatuses((statues) => [...statues, filterValue]);
        }
        break;
    }
  };

  const menuItems = (consignment) => {
    const isSTO = consignment.orderType === "STO";

    const returnArr = [
      {
        title: "View Items",
        icon: EyeIcon,
        onClick: () => getConsignmentDetails(consignment.id),
      },
      {
        title: "Audit Trail",
        icon: DocumentSearchIcon,
        onClick: () => getAudit(consignment),
      },
      {
        title: "Add Notes",
        icon: PencilAltIcon,
        onClick: () => editConsignmentNotes(consignment.id),
      },
      {
        title: "Download Report",
        icon: DownloadIcon,
        onClick: () => downloadConsignmentReport(consignment.id),
      },
      {
        title: "Download Item List",
        icon: DownloadIcon,
        onClick: () => downloadConsignmentItemList(consignment.id),
      },
    ];

    if (!isSTO) {
      returnArr.push({
        title: "Edit",
        icon: PencilAltIcon,
        onClick: () => editConsignment(consignment.id),
      });
    }

    if (!isSTO) {
      returnArr.push({
        title: "Delete",
        icon: TrashIcon,
        onClick: () => deleteConsignment(consignment.id),
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment?.status,
      ) === false
    ) {
      returnArr.push({
        title: "Mark Received",
        icon: ClipboardCheckIcon,
        onClick: () => receiveConsignment(consignment.id),
      });
    }
    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment?.status,
      ) === false &&
      !isSTO
    ) {
      returnArr.push({
        title: "Mark Closed",
        icon: ClipboardCheckIcon,
        onClick: () => changeStatusConsignmentHandle(consignment.id),
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment?.status,
      ) === false
    ) {
      returnArr.push({
        title: "Receive Current",
        icon: ClipboardCheckIcon,
        onClick: () => receiveConsignmentPartially(consignment.id),
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "RECEIVING_STARTED"].includes(consignment?.status)
    ) {
      returnArr.push({
        title: "Undo Receiving",
        icon: ClipboardCheckIcon,
        onClick: () => undoConsignmentReceive(consignment.id),
      });
    }
    return returnArr;
  };

  const initialHeaders = () => {
    const returnHeaders = [
      getHeaderObject("Action", "action", false),
      getHeaderObject("Order ID", "orderId"),
      getHeaderObject("Status", "status"),
      getHeaderObject("Warehouse Notes", "warehouseNotes", false, null, false),
      getHeaderObject("Client Notes", "clientNotes", false, null, false),
      getHeaderObject("No. of SKUs", "items", false),
      getHeaderObject("Total Qty", "totalQty", false),
      getHeaderObject("Received Qty", "receivedQty", false),
      getHeaderObject("Consignment Date", "consignmentDate"),
      getHeaderObject("Added Date", "createdAt"),
      getHeaderObject("Tracking #", "trackingNumber", false),
      getHeaderObject("Supplier", "supplier"),
      getHeaderObject("Order Type", "orderType"),
    ];
    if (warehouses?.length > 1) {
      returnHeaders.push(getHeaderObject("Warehouse", "warehouse", false));
    }
    if (customers?.length > 1) {
      returnHeaders.push(getHeaderObject("Client", "client", false));
    }
    if (
      tenant?.typeOfCustomer?.includes("Prep Center") &&
      tenant?.features?.dropship === true
    ) {
      returnHeaders.push(getHeaderObject("Dropship", "dropship", false));
      returnHeaders.push(
        getHeaderObject("Packing Method(Dropship)", "isCasePack", false),
      );
      returnHeaders.push(
        getHeaderObject("Dropship Type", "dropshipType", false),
      );
    }

    return returnHeaders;
  };

  const [headers, setHeaders] = useState(initialHeaders());

  const [showHeaders, setShowHeaders] = useState(
    headers.filter((header) => header.enabled),
  );
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  const [showImageModal, setShowImageModal] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);

  const sortConsignments = (order, column) => {
    groupedConsignments.sort((a, b) => {
      if (column === "consignments") {
        if (order === "asc") {
          return a.total - b.total;
        } else {
          return b.total - a.total;
        }
      } else if (column === "completion") {
        if (order === "asc") {
          return a.completionPercentage - b.completionPercentage;
        } else {
          return b.completionPercentage - a.completionPercentage;
        }
      }
      return 0;
    });
  };

  const handleSortClick = (column) => {
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setSortColumn(column);
    sortConsignments(newSortOrder, column);
  };

  const renderSort = (column) => {
    if (sortColumn !== column) {
      return <ChevronUpIcon className="h-6 w-6 text-gray-400" />;
    }

    if (sortOrder === "desc") {
      return <ChevronDownIcon className="h-6 w-6 text-green-400" />;
    }
    return <ChevronUpIcon className="h-6 w-6 text-green-400" />;
  };

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      CONSIGNMENT_ITEM_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  const renderGroupName = (groupName) => {
    if (groupName === null || groupName === undefined) {
      return "No Value";
    }

    if (filters?.groupBy === "warehouse") {
      return warehouses?.find((item) => item.id === groupName)?.name;
    }

    if (filters?.groupBy === "customer") {
      return customers?.find((item) => item.id === groupName)?.name;
    }
    return groupName;
  };

  const handleGroupClick = (groupId, warehouse) => {
    fetchExpandedGroupedConsignments(groupId, warehouse);
  };

  const handleClearFilters = () => {
    setGroupConsignmentOn(null);
    clearFilters();
  };

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      {showConsignmentDamageInfo && (
        <>
          {showImageModal && (
            <MinimalModal
              noPadding={true}
              onClose={() => setShowImageModal(null)}>
              <img src={showImageModal}></img>
            </MinimalModal>
          )}
          <ModalV2
            onClose={closeConsignmentDamageInfo}
            xIconClicked={closeConsignmentDamageInfo}
            title={`Damage Info`}
            contextText={`Retrieve damaged items, photos, and notes for specific products captured during receiving`}
            noPadding={true}
            scrollWithin={true}>
            <div className="p-2">
              <div className="text-black-500 flex justify-end py-2 font-medium">
                Total Damaged Qty:{" "}
                {showConsignmentDamageInfo.reduce(
                  (acc, item) => acc + item.quantity,
                  0,
                )}
              </div>
              <table className="min-w-full">
                <thead className="sticky top-0 bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Product Info
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Images
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Damaged Qty
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {showConsignmentDamageInfo?.length > 0 &&
                    showConsignmentDamageInfo?.map((item) => (
                      <tr>
                        <td className="text-black-500 whitespace-nowrap px-6 py-4 text-base font-medium">
                          {item.sku}
                        </td>
                        <td className="whitespace px-6 py-4 text-sm font-normal">
                          <div
                            className="text-gray-900"
                            style={{
                              maxWidth: "500px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>
                            {item.name}
                          </div>
                          {item.asin && (
                            <div>
                              <br />
                              <div className="text-black-500 font-medium">
                                ASIN: {item.asin}
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="flex space-x-2">
                            {item.workflowImages?.signedImageUrls?.map(
                              (image, index) => (
                                <div className="cursor-pointer">
                                  <img
                                    key={index}
                                    src={image}
                                    className="h-10 w-10 rounded-md object-cover"
                                    onClick={() => {
                                      setShowImageModal(image);
                                    }}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-center space-x-2">
                            {item.quantity}
                          </div>
                        </td>
                        <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                          {item.remarks}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </ModalV2>
        </>
      )}
      <div className="my-4">
        <QuickFilters
          warehouses={warehouses}
          customers={customers}
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          filters={filters}
          width={"3/4"}
          clearFilters={handleClearFilters}
          groupConsignmentOn={groupConsignmentOn}
          showGroupByFilter={groupConsignmentOn ? false : true}
          groupByFilterName={"groupBy"}
          groupByOptions={[{ name: "Tracking Number", id: "trackingNumber" }]}
          onChangeFilter={onChangeFilter}
          statuses={[
            { name: "DRAFT" },
            { name: "UNPROCESSED" },
            { name: "RECEIVING_STARTED" },
            { name: "COMPLETED" },
            { name: "CLOSED" },
          ]}
        />
      </div>
      <div className="relative" ref={searchRef}>
        <SearchSortFilter
          onChangeSearchKeyword={
            isEnhancedSearchEnabled
              ? onChangeEnhancedSearchKeyword
              : onChangeSearchKeyword
          }
          value={
            isEnhancedSearchEnabled ? enhancedSearchText : filters["keyword"]
          }
          filters={filters}
          submitFilters={isEnhancedSearchEnabled ? null : submitFilters}
          clearKeyword={
            isEnhancedSearchEnabled ? clearEnhancedSearchKeyword : clearKeyword
          }
          onFocus={() => setShowEnhancedSearch(true)}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          choices={choices}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={CONSIGNMENT_ITEM_TABLE_NAME}
        />
        {isEnhancedSearchEnabled && showEnhancedSearch ? (
          <div className="absolute top-14 z-10 w-full rounded-md border-2 border-gray-300 bg-[#FEFEFE] p-5 pb-0">
            <SearchPanel
              filters={[
                ...searchFilters.map((filter) => ({
                  ...filter,
                  ...filterProps[filter.displayLabel],
                })),
              ]}
              tabs={searchTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onChangeFilter={handleSearchFilterChange}
              data={validScoreResults}
              rowRender={(order) => getTableRow(order)}
              isLoading={searchConsignmentsLoading}
              totalResults={searchResults?.total}
              setCurrentPage={setCurrentPage}
              nextCursor={
                searchResults.entities?.find((item) => item?.score === 0)
                  ? null
                  : searchResults?.nextCursor
              }
              emptySearchText={
                <>
                  <p className="font-semibold">
                    {!searchConsignmentsLoading &&
                    enhancedSearchText &&
                    !validScoreResults?.length
                      ? "No results found"
                      : "Search Criteria Not Entered"}
                  </p>
                  <p className="text-sm text-gray-400">
                    Please type Order ID, SKU, Tracking Number, or more to
                    search
                  </p>
                </>
              }
            />
          </div>
        ) : null}
      </div>
      {!filters?.groupBy && (
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {consignments.length === 0 ? (
              <tr className="bg-white">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {consignments &&
              consignments.length > 0 &&
              consignments.map((consignment, rowIndex) => (
                <tr
                  key={consignment.id}
                  className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["orderId"],
                      columnIndex,
                      rowIndex,
                    );
                    if (value == "action") {
                      const isLastRow = rowIndex === perPage - 1;
                      const isLastColumn =
                        columnIndex === showHeaders.length - 1;

                      return (
                        <td className={cellStyle}>
                          {writable && (
                            <Menu as="div" className="inline-block text-right">
                              <div>
                                <Menu.Button>
                                  <TableFieldButton
                                    text={
                                      <DotsVerticalIcon className="h-6 w-6" />
                                    }
                                  />
                                </Menu.Button>
                              </div>
                              <Menu.Items
                                className={`${isLastRow && "bottom-0"} ${isLastColumn && "right-0"} absolute z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                <div className="h-96 overflow-y-scroll">
                                  {menuItems(consignment).map(
                                    (menuItem, idx) => (
                                      <Menu.Item key={idx}>
                                        {({ active }) => (
                                          <button
                                            className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                            onClick={menuItem["onClick"]}>
                                            {menuItem.icon && (
                                              <menuItem.icon className="h-8 w-8 pr-2" />
                                            )}
                                            {menuItem.title}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    ),
                                  )}
                                </div>
                              </Menu.Items>
                            </Menu>
                          )}
                        </td>
                      );
                    }
                    if (value == "orderId") {
                      return (
                        <td
                          className={cellStyle}
                          onClick={() => {
                            // consignment.workingList &&
                            // consignment.workingList.length > 0
                            //   ? setItems(
                            //       consignment.workingList.map((item) => ({
                            //         ...item,
                            //         consignmentId: consignment.id,
                            //       })),
                            //     )
                            //   : setItems(consignment.workingList);

                            getConsignmentDetails(consignment.id);
                          }}>
                          {consignment.orderId}
                        </td>
                      );
                    }

                    if (value === "warehouseNotes") {
                      return (
                        <td className={cellStyle}>
                          {consignment?.workingList?.findIndex(
                            (item) => !!item.warehouseNotes,
                          ) !== -1 ? (
                            <EyeIcon
                              className="cursor-pointer text-xs text-red-600"
                              width={35}
                              onClick={() => editWarehouseNotes(consignment.id)}
                              title="There are notes"
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    }

                    if (value === "clientNotes") {
                      return (
                        <td className={cellStyle}>
                          {consignment.notes ? (
                            <EyeIcon
                              className="cursor-pointer text-xs text-red-600"
                              width={35}
                              onClick={() =>
                                editConsignmentNotes(consignment.id)
                              }
                              title="There are notes"
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    }

                    if (value == "supplier") {
                      return (
                        <td className={cellStyle}>{consignment.supplier}</td>
                      );
                    }

                    if (value == "dropship") {
                      return (
                        <td className={cellStyle}>
                          {consignment.dropship ? "Yes" : "No"}
                        </td>
                      );
                    }

                    if (value == "isCasePack") {
                      return (
                        <td className={cellStyle}>
                          {consignment.isCasePack
                            ? "Case Pack"
                            : "Regular Pack"}
                        </td>
                      );
                    }

                    if (value == "dropshipType") {
                      return (
                        <td className={cellStyle}>
                          {consignment.dropshipType}
                        </td>
                      );
                    }
                    if (value == "trackingNumber") {
                      return (
                        <td className={cellStyle}>
                          {consignment.trackingNumber &&
                          typeof consignment.trackingNumber === "object" &&
                          consignment.trackingNumber.length > 0
                            ? consignment.trackingNumber.join(",")
                            : consignment.trackingNumber}
                        </td>
                      );
                    }
                    if (value == "items") {
                      return (
                        <td className={cellStyle}>
                          {consignment.items ? consignment.items.length : null}
                        </td>
                      );
                    }
                    if (value == "totalQty") {
                      return (
                        <td className={cellStyle}>
                          {consignment.workingList
                            ? _.sumBy(consignment.workingList, "quantity")
                            : null}
                        </td>
                      );
                    }
                    if (value == "receivedQty") {
                      let receivedQty = consignment?.workingList?.reduce(
                        (acc, item) =>
                          acc + (item.quantity - item.availableQuantity),
                        0,
                      );
                      return (
                        <td className={cellStyle}>
                          <div>{receivedQty}</div>
                          {consignment.hasDamagedItems && (
                            <div
                              className="cursor-pointer text-sm text-primaryAccent underline"
                              onClick={() => {
                                getDamagedInfo(consignment.id);
                              }}>
                              Damage Info
                            </div>
                          )}
                        </td>
                      );
                    }
                    if (value == "consignmentDate") {
                      return (
                        <td className={cellStyle}>
                          {consignment.consignmentDate
                            ? moment(consignment.consignmentDate).format(
                                "MMM Do YYYY",
                              )
                            : null}
                        </td>
                      );
                    }
                    if (value == "createdAt") {
                      return (
                        <td className={cellStyle}>
                          {consignment.createdAt
                            ? moment(consignment.createdAt).format(
                                "MMM Do YYYY, h:mm a",
                              )
                            : null}
                        </td>
                      );
                    }
                    if (value == "status") {
                      return (
                        <td className={cellStyle}>{consignment.status}</td>
                      );
                    }
                    if (value === "client") {
                      return (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          {customers &&
                            customers.find(
                              (item) => item.id === consignment.customer,
                            )?.name}
                        </td>
                      );
                    }
                    if (value === "warehouse") {
                      return (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          {warehouses &&
                            warehouses.find(
                              (item) => item.id === consignment.warehouse,
                            )?.name}
                        </td>
                      );
                    }
                    if (value == "orderType") {
                      return (
                        <td className={cellStyle}>
                          {consignment.orderType === "STO"
                            ? "Stock Transfer"
                            : consignment.orderType}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
          </tbody>
        </CustomTableWithTheme>
      )}

      {filters?.groupBy && (
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 z-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              <th
                scope="col"
                className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                Tracking Number
              </th>
              <th
                scope="col"
                className="cursor-pointer px-2 py-3 pl-6 text-left font-medium tracking-wider"
                onClick={() => handleSortClick("consignments")}
                style={{ display: "flex", alignItems: "center" }}>
                # of Consignments
                {renderSort("consignments")}
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                Warehouse
              </th>
              <th
                scope="col"
                className="cursor-pointer px-2 py-3 pl-6 text-left font-medium tracking-wider"
                onClick={() => handleSortClick("completion")}
                style={{ display: "flex", alignItems: "center" }}>
                Completion %{renderSort("completion")}
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!groupedConsignments?.length ? (
              <tr className="bg-white">
                <td>{noValuesText}</td>
                <td></td>
              </tr>
            ) : (
              groupedConsignments.map((group, idx) => {
                const groupId = Array.isArray(group._id)
                  ? group._id[0]?.groupByField?.toString()
                  : group._id?.groupByField?.toString();
                const groupName = renderGroupName(groupId);
                const groupWarehouse = Array.isArray(group._id)
                  ? group._id[0]?.warehouse?.toString()
                  : group._id?.warehouse?.toString();
                const warehouseName = warehouses?.find(
                  (item) => item.id === groupWarehouse,
                )?.name;
                const isClickable = groupName !== "No Value";
                const borderColorClass =
                  idx % 2 === 0 ? "border-F4C261" : "border-primaryAccent";

                return (
                  <tr key={idx} className="bg-white">
                    <td
                      onClick={
                        isClickable
                          ? () => handleGroupClick(groupId, groupWarehouse)
                          : null
                      }
                      className={`cursor-pointer rounded-bl rounded-tl ${borderColorClass} ${
                        isClickable ? "" : "cursor-not-allowed"
                      } border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent`}>
                      {groupName}
                    </td>
                    <td className="p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {group.total}
                    </td>
                    <td className="p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {warehouseName}
                    </td>
                    <td className="p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {Number(group.completionPercentage.toFixed(0))}%
                    </td>
                    <td className="p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {group.status.toUpperCase()}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </CustomTableWithTheme>
      )}
    </div>
  );
};

export default ConsignmentsList;
