import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/outline";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { AppStateContext } from "#contexts/appState";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_PRODUCT_REGION_REVENUE } from "#queries/index";
import { getUnixTimeStamp, getValue } from "#utils/catalogsAndAnalyticsUtils";
import {
  getEndDateWithHhMmSsFormate,
  getStartDateWithHhMmSsFormate,
} from "#utils/dateRanges";

const RegionsByRevenueChart = ({
  isProductLoading = false,
  product = null,
  startEndDate,
  selectedComparePeriods,
  comparePeriodDate,
}) => {
  const getAnalyticsProductRegionRevenueQuery = useQuery(
    GET_CATALOGS_ANALYTICS_PRODUCT_REGION_REVENUE,
  );
  const appState = useContext(AppStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [regionsByRevenue, setRegionsByRevenue] = useState(null);

  const regionsByRevenueData =
    regionsByRevenue?.primaryProductRegionRevenue?.length > 0
      ? regionsByRevenue?.primaryProductRegionRevenue?.map((revenueObject) => {
          const foundedComparedRevenueObject = (
            regionsByRevenue?.comparedProductRegionRevenue || []
          ).find((compareRevenueObject) => {
            if (compareRevenueObject?.cityName === revenueObject?.cityName)
              return compareRevenueObject;
          });
          return {
            cityName: revenueObject?.cityName || "",
            "Current Period": getValue(revenueObject?.totalRevenue),
            "Previous Period": getValue(
              foundedComparedRevenueObject?.totalRevenue,
            ),
          };
        })
      : [];

  const getAnalyticsProductRegionRevenueInfo = async () => {
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (product?.sku) {
      if (startEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(startEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(startEndDate[1]),
        );
      }
      if (selectedComparePeriods) {
        comparePeriodData.comparePeriod = true;
        comparePeriodData["compareStartEndDate"] = [
          getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
          getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
        ];
      }
      if (regionsByRevenue) setRegionsByRevenue(null);
      setIsLoading(true);
      try {
        const response = await getAnalyticsProductRegionRevenueQuery.fetchData({
          perPage: 5,
          pageNumber: 1,
          filters: {
            productId: product.id,
            sku: product.sku,
            customer: [],
            startEndDate: selectedStartEndDate,
            sortType: "top",
            performanceType: "revenue",
            ...comparePeriodData,
          },
        });
        if (response?.data?.catalogAnalyticsProductRegionRevenue) {
          setRegionsByRevenue(
            response.data.catalogAnalyticsProductRegionRevenue,
          );
        }
      } catch (e) {
        appState.setAlert(
          `error while fetching product regions revenue: ${e?.message}`,
          "error",
          5000,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (product && startEndDate?.length > 0) {
      getAnalyticsProductRegionRevenueInfo();
    }
  }, [startEndDate, selectedComparePeriods, product]);

  useEffect(() => {
    if (!product) {
      setRegionsByRevenue(null);
    }
  }, [product]);

  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-3">
          <h2 className="text-[17px] font-semibold">
            Top 5 Regions by Revenue
          </h2>
        </div>
        <Link
          className="flex items-center gap-1 text-sm font-semibold text-primaryAccent hover:text-primaryAccent"
          to={"/productRevenueByRegions"}>
          View More
          <ArrowRightIcon className="h-4 w-4" style={{ strokeWidth: 3 }} />
        </Link>
      </div>
      {!(isLoading || isProductLoading) ? (
        <div className="mt-5 w-full" style={{ height: "370px" }}>
          <ResponsiveContainer>
            <BarChart width={500} height={300} data={regionsByRevenueData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="cityName" />
              <YAxis />
              <Tooltip formatter={(value) => `$${value}`} />
              <Legend />
              <Bar dataKey="Current Period" fill="#224E73" />
              {selectedComparePeriods && (
                <Bar dataKey="Previous Period" fill="#f4c261" />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <h4>Loading...</h4>
      )}
    </div>
  );
};

export default RegionsByRevenueChart;
