import React, { useEffect, useState, Fragment } from "react";
import { Popover, Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import _ from "lodash";

const customLabels = {
  shippingHandling: "Shipping & Handling",
};
const PriceBreakdownPopOver = ({
  data,
  costPrice,
  type,
  selectedCatalog,
  showPopup = true,
}) => {
  const [selectedBreakdownDetails, setSelectedBreakdownDetails] =
    useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (type === "sales" && costPrice && selectedCatalog?.id) {
      const salesData =
        data?.find(
          (salesPrice) => salesPrice?.catalogId === selectedCatalog?.id,
        ) || null;
      const modifiedSalesPrice = getModifiedPrice({
        landingPrice: costPrice?.total,
        ...salesData,
      });
      setSelectedBreakdownDetails(modifiedSalesPrice);
    } else if (type === "lockedIn" && selectedCatalog?.id) {
      const lockedInData =
        data?.find((lockedIn) => lockedIn?.catalogId === selectedCatalog?.id) ||
        null;
      const modifiedlockedInPrice = getModifiedPrice({
        landingPrice: lockedInData?.costPrice / 100,
        ...lockedInData,
      });
      setSelectedBreakdownDetails(modifiedlockedInPrice);
    } else if (type === "costPrice") {
      if (costPrice && !_.isEmpty(costPrice)) {
        let newCostPriceData = { ...costPrice };
        if (
          _.isEmpty(_.omit(newCostPriceData, ["currency", "discount", "total"]))
        ) {
          newCostPriceData["Cost Price"] =
            Number(newCostPriceData?.total || 0) +
            Number(newCostPriceData?.discount || 0);
        }

        if (_.has(newCostPriceData, "discount")) {
          newCostPriceData = {
            ..._.omit(newCostPriceData, ["discount"]),
            discount: newCostPriceData.discount,
          };
        }
        const { currency, ...rest } = newCostPriceData;
        setSelectedBreakdownDetails({
          ...rest,
        });
      }
    } else {
      const lockedInData =
        data?.find((lockedIn) => lockedIn?.catalogId === selectedCatalog?.id) ||
        null;
      setSelectedBreakdownDetails(lockedInData);
    }
  }, [selectedCatalog, data, costPrice]);

  const getModifiedPrice = (salesPriceData) => {
    const {
      catalogId,
      lockedInDate,
      salesPrice,
      costPrice,
      lockedInPrice,
      ...rest
    } = salesPriceData;

    const modifiedSaleData = Object.keys(rest).reduce((acc, key) => {
      if (
        key !== "discountType" &&
        key !== "marginType" &&
        key !== "landingPrice"
      ) {
        acc[key] = rest[key] / 100;
      } else {
        acc[key] = rest[key];
      }
      return acc;
    }, {});

    let margin = modifiedSaleData?.margin || 0;
    let discount = modifiedSaleData?.discount || 0;

    // Calculate margin based on marginType
    if (modifiedSaleData?.marginType === "percentage") {
      margin =
        modifiedSaleData?.landingPrice * (modifiedSaleData?.margin / 100);
      modifiedSaleData.margin = margin;
    } else {
      margin = modifiedSaleData?.margin;
    }

    // Calculate initial total with dynamic keys
    let total = modifiedSaleData?.landingPrice + margin;
    Object.keys(modifiedSaleData).forEach((key) => {
      if (
        ![
          "margin",
          "discount",
          "discountType",
          "marginType",
          "total",
          "landingPrice",
        ].includes(key)
      ) {
        total += modifiedSaleData[key];
      }
    });

    // Apply discount based on discountType
    if (modifiedSaleData?.discountType === "percentage") {
      discount = total * (modifiedSaleData?.discount / 100);
      modifiedSaleData.discount = discount;
    } else {
      discount = modifiedSaleData?.discount;
    }

    total -= discount;

    // Add total to the modified object
    modifiedSaleData.total = isNaN(total) ? total : total > 0 ? total : 0;

    return modifiedSaleData;
  };

  const calculatePercentage = (key, value, breakdownDetails) => {
    if (key === "margin" && breakdownDetails && value) {
      return ((value * 100) / breakdownDetails.landingPrice).toFixed(2);
    } else if (key === "discount" && breakdownDetails && value) {
      const { marginType, discount, discountType, total, ...rest } =
        breakdownDetails;
      const totalCost = Object.values(rest).reduce(
        (acc, value) => acc + value,
        0,
      );
      return ((value * 100) / totalCost).toFixed(2);
    } else {
      return 0;
    }
  };

  const formatKey = (key) => {
    return (
      customLabels[key] ||
      key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())
    );
  };

  const PricingContent = (
    <>
      {type &&
        selectedBreakdownDetails &&
        Object.keys(selectedBreakdownDetails)?.length !== 0 && (
          <div className="mx-4 mb-4 mt-2">
            <div
              className={`mb-2 flex ${!showPopup ? "justify-between" : "justify-start"} text-sm font-medium`}>
              <div className="text-black">Price Breakdown</div>
              {!showPopup && (
                <button
                  type="button"
                  className="relative rounded-md text-2xl text-[#111827] hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={() => setIsOpen(false)}>
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              )}
            </div>
            <div className="flex justify-between gap-8 text-sm font-normal">
              <div>
                {selectedBreakdownDetails &&
                  Object.entries(selectedBreakdownDetails).map(
                    ([key, value], index) => {
                      if (
                        key !== "marginType" &&
                        key !== "discountType" &&
                        key !== "total"
                      ) {
                        const isPercentage =
                          (key === "margin" &&
                            selectedBreakdownDetails?.marginType ===
                              "percentage") ||
                          (key === "discount" &&
                            selectedBreakdownDetails?.discountType ===
                              "percentage");

                        return (
                          <div
                            key={`costPriceBreakdown-${index}`}
                            className={`${!showPopup ? "flex items-center justify-start" : ""} mb-2 max-w-64 overflow-hidden text-ellipsis whitespace-nowrap ${key === "discount" ? "text-green-400" : "text-[#6E7173]"}`}>
                            {isPercentage ? (
                              <>
                                {formatKey(key)} (
                                {calculatePercentage(
                                  key,
                                  value,
                                  selectedBreakdownDetails,
                                )}
                                %):
                              </>
                            ) : (
                              <>{formatKey(key)}:</>
                            )}
                          </div>
                        );
                      }
                      return null;
                    },
                  )}
              </div>
              <div>
                {selectedBreakdownDetails &&
                  Object.entries(selectedBreakdownDetails).map(
                    ([key, value], index) => {
                      if (
                        key !== "marginType" &&
                        key !== "discountType" &&
                        key !== "total"
                      ) {
                        return (
                          <div
                            key={`costPriceBreakdown-value-${index}`}
                            className={`mb-2 ${key === "discount" ? "text-green-400" : ""}`}>
                            {`$${key === "discount" ? "-" : ""}${Number(value).toFixed(2)}`}
                          </div>
                        );
                      }
                    },
                  )}
              </div>
            </div>
            <div
              className={
                _.values(
                  _.omit(selectedBreakdownDetails, ["total", "currency"]),
                )?.length > 0
                  ? "border-t border-[#DADBDC]"
                  : ""
              }></div>
            <div className="mt-2 flex justify-between gap-2 text-sm font-medium">
              <div className="text-black">Total:</div>
              <div>{`$${Number(selectedBreakdownDetails?.total).toFixed(2)}`}</div>
            </div>
          </div>
        )}
    </>
  );

  return (
    <>
      {showPopup && (
        <Popover className="relative bg-white">
          {({ open }) => (
            <div
              onMouseEnter={(e) =>
                e.currentTarget.querySelector("button")?.click()
              }
              onMouseLeave={(e) =>
                e.currentTarget.querySelector("button")?.click()
              }
              className="relative">
              {type === "sales" &&
              (selectedBreakdownDetails?.total === 0 ||
                selectedBreakdownDetails?.total > 0) ? (
                <Popover.Button>{`${costPrice?.currency} ${Number(
                  selectedBreakdownDetails?.total,
                ).toFixed(2)}`}</Popover.Button>
              ) : type === "lockedIn" &&
                (selectedBreakdownDetails?.total === 0 ||
                  selectedBreakdownDetails?.total > 0) ? (
                <Popover.Button>{`${costPrice?.currency} ${Number(
                  selectedBreakdownDetails?.total,
                ).toFixed(2)}`}</Popover.Button>
              ) : selectedBreakdownDetails &&
                selectedBreakdownDetails?.lockedInDate ? (
                moment(selectedBreakdownDetails?.lockedInDate).format(
                  "YYYY-MM-DD",
                )
              ) : (
                "NA"
              )}

              {showPopup && (
                <Popover.Panel className="absolute z-10 max-h-80 overflow-y-auto">
                  <div className="w-auto rounded-xl border border-borderGray bg-white">
                    {PricingContent}
                  </div>
                </Popover.Panel>
              )}
            </div>
          )}
        </Popover>
      )}
      {!showPopup && (
        <>
          {type === "sales" &&
          (selectedBreakdownDetails?.total === 0 ||
            selectedBreakdownDetails?.total > 0) ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}
              className="cursor-pointe text-primaryAccent underline">{`${costPrice?.currency} ${Number(
              selectedBreakdownDetails?.total,
            ).toFixed(2)}`}</span>
          ) : type === "lockedIn" &&
            (selectedBreakdownDetails?.total === 0 ||
              selectedBreakdownDetails?.total > 0) ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}
              className="cursor-pointe text-primaryAccent underline">{`${costPrice?.currency} ${Number(
              selectedBreakdownDetails?.total,
            ).toFixed(2)}`}</span>
          ) : type === "costPrice" && selectedBreakdownDetails?.total ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}
              className="cursor-pointe text-primaryAccent underline">{`${costPrice?.currency} ${Number(
              selectedBreakdownDetails?.total,
            ).toFixed(2)}`}</span>
          ) : selectedBreakdownDetails &&
            selectedBreakdownDetails?.lockedInDate ? (
            moment(selectedBreakdownDetails?.lockedInDate).format("YYYY-MM-DD")
          ) : (
            "NA"
          )}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="userFormModal fixed inset-0 z-40 overflow-y-auto"
              onClose={() => setIsOpen(false)}
              onClick={(event) => event.stopPropagation()}
              initialFocus={null}>
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
                </Transition.Child>

                <div className="flex min-h-screen items-center justify-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95">
                    <div className="w-auto rounded-xl border border-borderGray bg-white">
                      {PricingContent}
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  );
};

export default PriceBreakdownPopOver;
