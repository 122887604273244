import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import NewModal from "#newUiComponents/commons/NewModal";
import CustomTable from "#newUiComponents/commons/CustomTable";

const MarkOrdersCompleteModal = ({
  setLabelGenerationData,
  setPickingBatchAction,
  setSelectedOrders,
  setAllOrdersSelected,
  shippingLabelFilterValues,
  shippingLabelEnums,
  setShippingLabelFilter,
  shippingLabelFilter,
  partiallyPickedOrders,
  setShowPartiallyPickedOrders,
  selectedOrders,
  labelDetailsTableColumns,
  shippingLabelExists,
  filteredPackingData,
  setShowPackingCompleteModal,
  setPackingCompleteOrders,
  validateMarkOrdersComplete,
}) => {
  return (
    <NewModal
      isOpen={true}
      onClose={() => {
        setLabelGenerationData(null);
        setPickingBatchAction("");
        setSelectedOrders([]);
        setAllOrdersSelected(false);
        setShowPackingCompleteModal(false);
        setPackingCompleteOrders([]);
      }}
      title="Mark Orders Packing Complete"
      childrenStyle={{ overflow: "hidden" }}>
      <div>
        <div className="my-4 flex w-full items-center justify-between">
          <div className="flex items-center gap-4">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: shippingLabelFilterValues,
                selectable: true,
                defaultSelectedKeys: [shippingLabelEnums.ALL_ORDERS],
              }}
              dropdownRender={({ props }) => {
                return (
                  <div className="w-auto rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
                    {props.items.map((item) => (
                      <div
                        onClick={() => setShippingLabelFilter(item)}
                        key={item.key}
                        className="cursor-pointer p-2 hover:bg-hoverHighlight hover:text-primaryAccent">
                        {item.label}
                      </div>
                    ))}
                  </div>
                );
              }}>
              <div className="flex cursor-pointer gap-2">
                {shippingLabelFilter.label}
                <DownOutlined />
              </div>
            </Dropdown>

            {partiallyPickedOrders?.length > 0 && (
              <button
                className="rounded-lg bg-white px-4 py-2 text-[16px] font-bold text-primaryAccent hover:opacity-80 active:opacity-100"
                onClick={() => setShowPartiallyPickedOrders(true)}>
                View Partially Picked Orders ({partiallyPickedOrders.length})
              </button>
            )}
          </div>

          <div>
            {selectedOrders?.length > 0 ? (
              <button
                onClick={() => validateMarkOrdersComplete(selectedOrders)}
                className="rounded-lg bg-primaryAccent px-4 py-2 text-[16px] text-white hover:opacity-80 active:opacity-100">
                Mark Orders Complete
              </button>
            ) : null}
          </div>
        </div>

        <div className="my-4 text-lg font-bold text-gray-700">
          Order Details
        </div>
        <div className="max-h-96 overflow-y-scroll">
          <CustomTable
            columns={labelDetailsTableColumns}
            data={filteredPackingData?.map((order) => ({
              ...order,
              shippingLabelExists: shippingLabelExists(order),
            }))}
          />
        </div>
      </div>
    </NewModal>
  );
};

export default MarkOrdersCompleteModal;
