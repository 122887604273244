import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const StationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedStation,
  onSubmit,
  hopstackModules,
  devices,
  onChangeMultiSelect,
  customers,
  warehouses,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Station Details
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Select Module"}
          list={hopstackModules?.filter((i) =>
            [
              "Picking",
              "Prepping",
              "Bundling",
              "Packing",
              "Shipping",
              "Receiving",
              "Putaway",
              "Stock Cycle Count",
              "Stock Transfer",
              "Sorting - Outbound",
            ].includes(i.name),
          )}
          labelKey="name"
          valueKey="name"
          name="hopstackModule"
          setSelected={(e) => {
            onChangeDropdown("hopstackModule", e);
          }}
          selectedValue={selectedStation.hopstackModule}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedStation.name}
        />
      </div>
      <div>
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Warehouses
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("warehouses", selectedOptions)
            }
            values={selectedStation.warehouses || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>
      <div>
        <div id="selectingCustomers">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={getFilteredCustomerList(
              selectedStation.warehouses,
              customers,
            )}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("customers", selectedOptions)
            }
            values={selectedStation.customers || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default StationForm;
