import React, { useRef, useState, useEffect, useContext } from "react";
import moment from "moment-timezone";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_REVENUE_TREND_PERFORMANCE } from "#queries/index";
import { AppStateContext } from "#contexts/appState";
import {
  getStartDateWithHhMmSsFormate,
  getEndDateWithHhMmSsFormate,
  getDateBasedOnComparePeriod,
} from "#utils/dateRanges";
import { getUnixTimeStamp, getValue } from "#utils/catalogsAndAnalyticsUtils";

const AnalyticsReChart = ({
  startEndDate,
  comparePeriodDate,
  selectedComparePeriods,
  selectedCustomer,
  daysDifference,
}) => {
  const appState = useContext(AppStateContext);
  const chartContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [analyticsRevenueTrendInfo, setAnalyticsRevenueTrendInfo] =
    useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const getAnalyticsRevenueTrendInfoQuery = useQuery(
    GET_CATALOGS_ANALYTICS_REVENUE_TREND_PERFORMANCE,
  );

  const handleResize = () => {
    if (chartContainerRef.current) {
      setContainerWidth(chartContainerRef.current.offsetWidth);
    }
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={290}
        textAnchor="middle"
        style={{ fontSize: 14, fontWeight: 500 }}>
        {payload.value}
      </text>
    );
  };

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <text x={0} y={y} style={{ fontSize: 14, fontWeight: 500 }}>
        {payload.value}
      </text>
    );
  };

  const CurrentPeriodCustomDot = (props) => {
    const { cx, cy, stroke, payload, value, index } = props;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        className="recharts-dot stroke-tremor-background dark:stroke-dark-tremor-background !z-10 fill-[#2c7695] dark:fill-[#2c7695]"
        fill={"#2c7695"}
        stroke={"#fff"}
        strokeWidth={2}
        style={{ zIndex: 10 }}
      />
    );
  };

  const PreviousPeriodCustomDot = (props) => {
    const { cx, cy, stroke, payload, value, index } = props;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        className="recharts-dot stroke-tremor-background dark:stroke-dark-tremor-background !z-10 fill-[#6b7280] dark:fill-[#6b7280]"
        fill={"#6b7280"}
        stroke={"#fff"}
        strokeWidth={2}
        style={{ zIndex: 10 }}
      />
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { uv, mv } = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "transparent",
            border: "1px solid #ccc",
            top: label ? label.y : 0,
            left: label ? label.x : 0,
            boxSizing: "border-box",
          }}>
          <div className="border-tremor-border dark:border-dark-tremor-border border-b px-4 py-2">
            <p className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis font-medium">
              {label}
            </p>
          </div>
          <div className="space-y-1 px-4 py-2">
            <div className="flex items-center justify-between space-x-8">
              <div className="flex items-center space-x-2">
                <span className="rounded-tremor-full border-tremor-background shadow-tremor-card dark:border-dark-tremor-background dark:shadow-dark-tremor-card h-3 w-3 shrink-0 border-2 bg-[#2c7695] dark:bg-blue-500"></span>
                <p className="text-tremor-content dark:text-dark-tremor-content whitespace-nowrap text-right">
                  Current Period
                </p>
              </div>
              <p className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis whitespace-nowrap text-right font-medium tabular-nums">{`$${payload?.[0]?.payload?.totalRevenue}`}</p>
            </div>
            {selectedComparePeriods && (
              <div className="flex items-center justify-between space-x-8">
                <div className="flex items-center space-x-2">
                  <span className="rounded-tremor-full border-tremor-background shadow-tremor-card dark:border-dark-tremor-background dark:shadow-dark-tremor-card h-3 w-3 shrink-0 border-2 bg-[#6b7280] dark:bg-blue-500"></span>
                  <p className="text-tremor-content dark:text-dark-tremor-content whitespace-nowrap text-right">
                    Previous Period
                  </p>
                </div>
                <p className="text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis whitespace-nowrap text-right font-medium tabular-nums">{`$${payload?.[0]?.payload?.comparedTotalRevenue}`}</p>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const mapRevenueTrendData = (
    analyticsRevenueTrendInfo?.primaryRevenueTrend || []
  ).map((revenueTrendObject) => {
    const matchedObject = (
      (selectedComparePeriods &&
        analyticsRevenueTrendInfo?.comparedRevenueTrend) ||
      []
    ).find((compareRevenueTrendObj) => {
      return (
        compareRevenueTrendObj?.day ===
        getDateBasedOnComparePeriod(
          revenueTrendObject?.day,
          daysDifference,
          selectedComparePeriods,
        )
      );
    });
    return {
      day: revenueTrendObject?.day || "",
      totalRevenue: getValue(revenueTrendObject?.totalRevenue),
      comparedTotalRevenue: getValue(matchedObject?.totalRevenue),
    };
  });

  const getAnalyticsRevenueTrendInfo = async () => {
    const customers = [];
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (selectedCustomer !== "all customer") customers.push(selectedCustomer);
    if (startEndDate?.length > 0) {
      selectedStartEndDate[0] = getUnixTimeStamp(
        getStartDateWithHhMmSsFormate(startEndDate[0]),
      );
      selectedStartEndDate[1] = getUnixTimeStamp(
        getEndDateWithHhMmSsFormate(startEndDate[1]),
      );
    }
    if (selectedComparePeriods) {
      comparePeriodData.comparePeriod = true;
      comparePeriodData["compareStartEndDate"] = [
        getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
        getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
      ];
    }
    setIsLoading(true);
    try {
      const response = await getAnalyticsRevenueTrendInfoQuery.fetchData({
        filters: {
          customer: customers,
          startEndDate: selectedStartEndDate,
          timeZone: moment.tz.guess(),
          ...comparePeriodData,
        },
      });
      if (response?.data?.catalogAnalyticsRevenueTrend) {
        setAnalyticsRevenueTrendInfo(
          response?.data?.catalogAnalyticsRevenueTrend,
        );
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500);
  }, [appState?.sidebarOpen]);

  useEffect(() => {
    if (
      (selectedCustomer && startEndDate?.length > 0) ||
      selectedComparePeriods
    ) {
      getAnalyticsRevenueTrendInfo();
    }
  }, [startEndDate, selectedComparePeriods, selectedCustomer]);

  if (isLoading) {
    return (
      <div>
        <h4>Loading...</h4>
      </div>
    );
  }

  return (
    <>
      <div className="mb-2 flex items-center justify-end">
        <ol className="tremor-Legend-root relative overflow-hidden">
          <div tabIndex={0} className="flex h-full flex-wrap">
            <li className="tremor-Legend-legendItem rounded-tremor-small text-tremor-content dark:text-dark-tremor-content group inline-flex cursor-default items-center whitespace-nowrap px-2 py-0.5 transition">
              <svg
                className="mr-1.5 h-2 w-2 flex-none text-[#2c7695] opacity-100 dark:text-[#2c7695]"
                fill="currentColor"
                viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="4"></circle>
              </svg>
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content truncate whitespace-nowrap opacity-100">
                Current Period
              </p>
            </li>
            {selectedComparePeriods && (
              <li className="tremor-Legend-legendItem rounded-tremor-small text-tremor-content dark:text-dark-tremor-content group inline-flex cursor-default items-center whitespace-nowrap px-2 py-0.5 transition">
                <svg
                  className="mr-1.5 h-2 w-2 flex-none text-[#6b7280] opacity-100 dark:text-[#6b7280]"
                  fill="currentColor"
                  viewBox="0 0 8 8">
                  <circle cx="4" cy="4" r="4"></circle>
                </svg>
                <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content truncate whitespace-nowrap opacity-100">
                  Previous Period
                </p>
              </li>
            )}
          </div>
        </ol>
      </div>
      <div className="mt-4 h-72 w-full">
        <div
          className="h-full w-full"
          style={{ width: "100%" }}
          ref={chartContainerRef}>
          <AreaChart
            width={containerWidth}
            height={300}
            data={mapRevenueTrendData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}>
            <CartesianGrid
              strokeDasharray="0 0"
              vertical={false}
              horizontal={true}
            />
            <XAxis
              dataKey="day"
              tick={<CustomXAxisTick />}
              padding={{ left: 15, right: 15 }}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              domain={["auto", "auto"]}
              tick={<CustomYAxisTick />}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="linear"
              dataKey="totalRevenue"
              stroke="#2c7695"
              strokeWidth={2}
              fill="#2c7695"
              baseLine={2}
              activeDot={<CurrentPeriodCustomDot />}
            />
            {selectedComparePeriods && (
              <Area
                type="linear"
                dataKey="comparedTotalRevenue"
                stroke="#6b7280"
                strokeWidth={2}
                fill="#6b7280"
                baseLine={2}
                activeDot={<PreviousPeriodCustomDot />}
              />
            )}
          </AreaChart>
        </div>
      </div>
    </>
  );
};

export default AnalyticsReChart;
