import React, { useContext, useEffect, useState } from "react";
import CheckBox from "#newUiComponents/commons/CheckBox";
import { TrashIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { AppStateContext } from "#contexts/appState";
import NewRateShopping from "#newUiComponents/packer/NewRateShopping";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch";
import { getCurrencySymbol } from "#utils/getCurrencySymbol";
import OrderDetailsModal from "../outboundPlan/OrderDetailsModal";
import { AuthContext } from "#contexts/auth";
import { GET_CARRIER_DETAIL_BY_RULES } from "#queries/index";
import { useQuery } from "#hooks/useQuery";
import { getPreferredMetricsUnit } from "#utils/Metrics";
import { formatToISODate } from "#utils/helper-functions";

const ShipmentPlan = ({
  detailedOrdersForBatching = [],
  shipmentPlan = {},
  setShipmentPlan,
  orderBoxes,
}) => {
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const getCarrierDetailByRules = useQuery(GET_CARRIER_DETAIL_BY_RULES);
  const { preferredDimensionUnit, preferredWeightUnit } =
    getPreferredMetricsUnit();
  const [addressValidation, setAddressValidation] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [orderForViewing, setOrderForViewing] = useState(null);
  const [selectedOrderForRateShopping, setSelectedOrderForRateShopping] =
    useState(null);
  const [showRateShoppingSlideOver, setShowRateShoppingSlideOver] =
    useState(false);
  const [toAddress, setToAddress] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rate, setRate] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [customers, setCustomers] = useState([]);

  // Format boxes data for the selected order
  const formatBoxesData = (orderId) => {
    const orderBoxData = orderBoxes[orderId] || [];
    return orderBoxData.map((box) => ({
      length: box.length,
      height: box.height,
      width: box.width,
      weight: box.weight || box.totalWeight || 0,
      name: box.name,
      dimensionUnit: preferredDimensionUnit,
      weightUnit: preferredWeightUnit,
      items: box.items.map((item) => ({
        sku: item.sku,
        fnSku: item.fnSku,
        quantity: item.quantity,
        bestByDate: item.expiryDate,
      })),
    }));
  };

  // Effect to handle preselected rate when order changes
  useEffect(() => {
    if (selectedOrderForRateShopping) {
      const orderId = selectedOrderForRateShopping.orderId;
      const existingPlan = shipmentPlan[orderId];

      if (existingPlan?.preSelectedCarrierRate) {
        setRate({
          selectedShipmentRateId: existingPlan.preSelectedCarrierRate.id,
          selectedShipmentSource: existingPlan.preSelectedCarrierRate.source,
          transportMode: "SP",
          selectedCarrierRate: existingPlan.preSelectedCarrierRate,
          carrier: `${existingPlan.preSelectedCarrierRate.carrier} - ${existingPlan.preSelectedCarrierRate.service}`,
        });
      } else {
        setRate(null);
      }
    }
  }, [selectedOrderForRateShopping, shipmentPlan]);

  // Handle rate selection
  const handleRateSelect = (selectedRate) => {
    if (!selectedOrderForRateShopping) return;

    const orderId = selectedOrderForRateShopping.orderId;
    const rate = selectedRate?.selectedCarrierRate;

    if (!rate) return;

    // Format the selected service details
    const selectedServiceDetails = {
      carrier: rate.carrier,
      currency: rate.currency,
      estimated_delivery_date: formatToISODate(rate.estimated_delivery_date),
      estimated_delivery_days: rate.estimated_delivery_days,
      selectedServiceId: rate.id,
      price: rate.price,
      service: rate.service,
      source: rate.source,
      type: rate.type,
    };

    setShipmentPlan((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        selectedRate,
        carrierService: selectedRate?.carrier,
        preSelectedCarrierRate: rate,
        typeOfShipment: "SP",
        selectedServiceDetails,
      },
    }));
    setRate(selectedRate);
  };

  // Update address when selected order changes
  useEffect(() => {
    if (!selectedOrderForRateShopping) return;

    const formattedAddress = {
      line1: selectedOrderForRateShopping.shippingAddress?.line1 || "",
      line2: selectedOrderForRateShopping.shippingAddress?.line2 || "",
      city: selectedOrderForRateShopping.shippingAddress?.city || "",
      name: selectedOrderForRateShopping.shippingAddress?.name || "",
      country: selectedOrderForRateShopping.shippingAddress?.country || "",
      email: selectedOrderForRateShopping.shippingAddress?.email || "",
      phone: selectedOrderForRateShopping.shippingAddress?.phone || "",
      postalCode: selectedOrderForRateShopping.shippingAddress?.zip || "",
      state: selectedOrderForRateShopping.shippingAddress?.state || "",
    };
    setToAddress(formattedAddress);
  }, [selectedOrderForRateShopping]);

  useEffect(() => {
    if (auth?.user) {
      if (auth?.user?.warehousesList) setWarehouses(auth.user.warehousesList);
      if (auth?.user?.customersList) setCustomers(auth.user.customersList);
    }
  }, [auth]);

  const handleSelectAll = () => {
    if (selectedOrders.size === detailedOrdersForBatching.length) {
      setSelectedOrders(new Set());
    } else {
      setSelectedOrders(
        new Set(detailedOrdersForBatching.map((order) => order.orderId)),
      );
    }
  };

  const handleSelectOrder = (orderId) => {
    const newSelected = new Set(selectedOrders);
    if (newSelected.has(orderId)) {
      newSelected.delete(orderId);
    } else {
      newSelected.add(orderId);
    }
    setSelectedOrders(newSelected);
  };

  const handleErasePlan = () => {
    if (selectedOrders.size === 0) {
      notify.warning(
        "No Orders Selected",
        "Please select orders to reset plan",
      );
      return;
    }

    const newShipmentPlan = { ...shipmentPlan };
    selectedOrders.forEach((orderId) => {
      delete newShipmentPlan[orderId];
    });
    setShipmentPlan(newShipmentPlan);
    setSelectedOrders(new Set());
    notify.success(
      "Plan Erased",
      "Shipment plan has been reset for selected orders",
    );
  };

  const [processedOrders, setProcessedOrders] = useState(new Set());

  useEffect(() => {
    const processOrderRules = async () => {
      for (const order of detailedOrdersForBatching) {
        if (processedOrders.has(order.orderId)) continue;
        appState.setLoading();
        try {
          const boxes = formatBoxesData(order.orderId);

          const response = await getCarrierDetailByRules.fetchData({
            boxes,
            orderData: {
              carrier: order.carrier || null,
              carrierService: order.carrierService || null,
              customer: order.customer || null,
              dropship: order.dropship || null,
              estimatedBoxes: order.estimatedBoxes || null,
              source: order.source || null,
              shippingAddress: order.shippingAddress || null,
              preSelectedCarrierRate: null,
              orderValue: order.orderValue || null,
              orderId: order.orderId || null,
              warehouse: order.warehouse || null,
            },
          });

          if (response?.data?.getCarrierDetailByRules?.carrier) {
            // Case 1: Rule Engine Rate
            const ruleCarrier = response?.data?.getCarrierDetailByRules.carrier;
            const ruleBasedRate = {
              id: ruleCarrier.selectedServiceId,
              carrier: ruleCarrier.carrier,
              service: ruleCarrier.service,
              price: ruleCarrier.price,
              estimated_delivery_days: ruleCarrier.estimatedDeliveryDays,
              integration_id: ruleCarrier.integrationId,
            };

            const selectedServiceDetails = {
              carrier: ruleBasedRate.carrier,
              currency: ruleBasedRate.currency,
              estimated_delivery_date: formatToISODate(
                ruleBasedRate.estimated_delivery_date,
              ),
              estimated_delivery_days: ruleBasedRate.estimated_delivery_days,
              selectedServiceId: ruleBasedRate.id,
              price: ruleBasedRate.price,
              service: ruleBasedRate.service,
              type: ruleBasedRate.type,
            };

            setShipmentPlan((prev) => ({
              ...prev,
              [order.orderId]: {
                ...prev[order.orderId],
                preSelectedCarrierRate: ruleBasedRate,
                selectedRate: {
                  selectedShipmentRateId: ruleBasedRate.id,
                  selectedShipmentSource: "Rule Engine",
                  transportMode: "SP",
                  selectedCarrierRate: ruleBasedRate,
                  carrier: `${ruleCarrier.carrier} - ${ruleCarrier.service}`,
                },
                selectedServiceDetails, // ISO formatted date
                typeOfShipment: "SP",
              },
            }));
          } else if (order.preSelectedCarrierRate) {
            // Case 2: Pre-selected Rate
            const preSelectedRate = order.preSelectedCarrierRate;

            const selectedServiceDetails = {
              carrier: preSelectedRate.carrier,
              currency: preSelectedRate.currency,
              estimated_delivery_date: formatToISODate(
                preSelectedRate.estimated_delivery_date,
              ),
              estimated_delivery_days: preSelectedRate.estimated_delivery_days,
              selectedServiceId: preSelectedRate.id,
              price: preSelectedRate.price,
              service: preSelectedRate.service,
              source: preSelectedRate.source || null,
              type: preSelectedRate.type,
            };

            setShipmentPlan((prev) => ({
              ...prev,
              [order.orderId]: {
                ...prev[order.orderId],
                preSelectedCarrierRate: preSelectedRate,
                selectedRate: {
                  selectedShipmentRateId: preSelectedRate.id,
                  selectedShipmentSource: preSelectedRate.source || "Manual",
                  transportMode: "SP",
                  selectedCarrierRate: preSelectedRate,
                  carrier: `${preSelectedRate.carrier} - ${preSelectedRate.service}`,
                },
                selectedServiceDetails,
                typeOfShipment: "SP",
              },
            }));
          } else if (order.carrier && order.carrierService) {
            // Case 3: Order's carrier and service
            const carrierRate = {
              id: `${order.carrier}-${order.carrierService}`,
              carrier: order.carrier,
              service: order.carrierService,
              price: null,
              estimated_delivery_days: null,
            };

            const selectedServiceDetails = {
              carrier: carrierRate.carrier,
              currency: null,
              estimated_delivery_date: null,
              estimated_delivery_days: carrierRate.estimated_delivery_days,
              selectedServiceId: null,
              price: carrierRate.price,
              service: carrierRate.service,
              type: null,
            };

            setShipmentPlan((prev) => ({
              ...prev,
              [order.orderId]: {
                ...prev[order.orderId],
                preSelectedCarrierRate: carrierRate,
                selectedRate: {
                  selectedShipmentRateId: carrierRate.id,
                  selectedShipmentSource: "Order",
                  transportMode: "SP",
                  selectedCarrierRate: carrierRate,
                  carrier: `${order.carrier} - ${order.carrierService}`,
                },
                selectedServiceDetails,
                typeOfShipment: "SP",
              },
            }));
          }

          // Mark order as processed
          setProcessedOrders((prev) => new Set([...prev, order.orderId]));
        } catch (error) {
          console.error(`Error processing order ${order.orderId}:`, error);
          setProcessedOrders((prev) => new Set([...prev, order.orderId]));
        } finally {
          appState.removeLoading();
        }
      }
    };

    processOrderRules();
  }, [detailedOrdersForBatching]);

  // Remove or modify the existing rate check effect that was triggered by selectedOrderForRateShopping
  // Instead, when an order is selected for rate shopping, we'll use the existing shipment plan:

  useEffect(() => {
    if (selectedOrderForRateShopping) {
      const existingPlan = shipmentPlan[selectedOrderForRateShopping.orderId];
      if (existingPlan?.preSelectedCarrierRate) {
        setRate({
          selectedShipmentRateId: existingPlan.preSelectedCarrierRate.id,
          selectedShipmentSource: existingPlan.preSelectedCarrierRate.source,
          transportMode: "SP",
          selectedCarrierRate: existingPlan.preSelectedCarrierRate,
          carrier: `${existingPlan.preSelectedCarrierRate.carrier} - ${existingPlan.preSelectedCarrierRate.service}`,
        });
      } else {
        setRate(null);
      }
    }
  }, [selectedOrderForRateShopping, shipmentPlan]);

  const renderCarrierService = (order) => {
    const orderId = order.orderId;

    // 1. Check for rule based rate
    if (
      shipmentPlan[orderId]?.preSelectedCarrierRate?.source === "Rule Engine"
    ) {
      return (
        <p>
          <span>
            {[
              shipmentPlan[orderId].preSelectedCarrierRate.carrier,
              shipmentPlan[orderId].preSelectedCarrierRate.service,
              shipmentPlan[orderId].preSelectedCarrierRate.currency &&
                shipmentPlan[orderId].preSelectedCarrierRate.price &&
                `${getCurrencySymbol(shipmentPlan[orderId].preSelectedCarrierRate.currency)} ${
                  shipmentPlan[orderId].preSelectedCarrierRate.price
                }`,
              shipmentPlan[orderId].preSelectedCarrierRate
                .estimated_delivery_days &&
                `${shipmentPlan[orderId].preSelectedCarrierRate.estimated_delivery_days} days`,
            ]
              .filter(Boolean)
              .join(" | ")}
          </span>{" "}
          <span
            onClick={() => {
              setSelectedOrderForRateShopping(order);
              setShowRateShoppingSlideOver(true);
            }}
            className="ml-3 cursor-pointer text-sm font-semibold text-[#224E73] underline">
            Edit
          </span>
        </p>
      );
    }

    // 2. Check for preSelected rate
    if (shipmentPlan[orderId]?.preSelectedCarrierRate) {
      return (
        <p>
          <span>
            {[
              shipmentPlan[orderId].preSelectedCarrierRate.carrier,
              shipmentPlan[orderId].preSelectedCarrierRate.service,
              shipmentPlan[orderId].preSelectedCarrierRate.currency &&
                shipmentPlan[orderId].preSelectedCarrierRate.price &&
                `${getCurrencySymbol(shipmentPlan[orderId].preSelectedCarrierRate.currency)} ${
                  shipmentPlan[orderId].preSelectedCarrierRate.price
                }`,
              shipmentPlan[orderId].preSelectedCarrierRate
                .estimated_delivery_days &&
                `${shipmentPlan[orderId].preSelectedCarrierRate.estimated_delivery_days} days`,
            ]
              .filter(Boolean)
              .join(" | ")}
          </span>{" "}
          <span
            onClick={() => {
              setSelectedOrderForRateShopping(order);
              setShowRateShoppingSlideOver(true);
            }}
            className="ml-3 cursor-pointer text-sm font-semibold text-[#224E73] underline">
            Edit
          </span>
        </p>
      );
    }

    // 3. Check for order's carrier and carrierService
    if (order.carrier && order.carrierService) {
      return (
        <p>
          <span>{`${order.carrier} | ${order.carrierService}`}</span>{" "}
          <span
            onClick={() => {
              setSelectedOrderForRateShopping(order);
              setShowRateShoppingSlideOver(true);
            }}
            className="ml-3 cursor-pointer text-sm font-semibold text-[#224E73] underline">
            Edit
          </span>
        </p>
      );
    }

    // 4. Default case - Enter rate-shopping details
    return (
      <span
        onClick={() => {
          setSelectedOrderForRateShopping(order);
          setShowRateShoppingSlideOver(true);
        }}
        className="cursor-pointer text-sm font-semibold text-[#224E73] underline">
        Enter rate-shopping details
      </span>
    );
  };

  return (
    <div className="w-full pb-20 font-inter">
      {/* Header Section */}
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-end">
            <CustomSwitch
              leftSideText="Validate Address"
              rightSideText=""
              checked={addressValidation}
              onChange={setAddressValidation}
              leftColor="bg-gray-400"
              rightColor="bg-primaryAccent"
              labelClasses="text-sm font-medium text-gray-600"
              switchClasses="w-10 h-6"
              handleClasses="w-4 h-4"
            />
          </div>
        </div>

        <div className="flex gap-3">
          <PrimaryButton
            height="2.5rem"
            minWidth="13rem"
            maxWidth="20rem"
            variant="secondary"
            className="mt-2 text-base font-medium"
            onClick={handleSelectAll}>
            Select All Orders
          </PrimaryButton>
          <PrimaryButton
            height="2.5rem"
            minWidth="7rem"
            maxWidth="20rem"
            variant="primary"
            className="mt-2 text-base font-medium"
            danger
            disabled={selectedOrders.size === 0}
            onClick={handleErasePlan}>
            <TrashIcon className="mr-2 h-4 w-4" />
            <span>Reset Plan</span>
          </PrimaryButton>
        </div>
      </div>

      {/* Table Section */}
      <div className="mt-4">
        <div className="grid grid-cols-3 gap-4 bg-gray-50 p-4 font-medium">
          <div className="col-span-1">Order Id</div>
          <div className="col-span-2">Carrier Service</div>
        </div>

        {detailedOrdersForBatching.map((order) => (
          <div
            key={order.orderId}
            className="grid grid-cols-3 gap-4 border-b p-4">
            <div className="col-span-1 flex cursor-pointer items-center justify-start gap-4 font-semibold text-primaryAccent underline">
              <CheckBox
                checked={selectedOrders.has(order.orderId)}
                onChange={() => handleSelectOrder(order.orderId)}
              />
              <div
                onClick={() => {
                  setOrderForViewing([order]);
                  setIsModalOpen(true);
                }}>
                {order.orderId}
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex items-center text-sm">
                {renderCarrierService(order)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {orderForViewing && isModalOpen && (
        <OrderDetailsModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setOrderForViewing(null);
          }}
          orders={orderForViewing}
          customers={customers}
        />
      )}

      {/* Rate Shopping SlideOver */}
      <SlideOverPanel
        open={showRateShoppingSlideOver}
        setOpen={setShowRateShoppingSlideOver}
        containerStyle={"max-w-6xl"}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={"Rate Shopping for Small Parcel"}
            description=""
            arrowAction={() => setShowRateShoppingSlideOver(false)}
          />
        }>
        {selectedOrderForRateShopping && (
          <NewRateShopping
            order={selectedOrderForRateShopping}
            transportMode="SP"
            customer={selectedOrderForRateShopping.customer}
            warehouse={selectedOrderForRateShopping.warehouse}
            shippingAddress={toAddress}
            boxes={formatBoxesData(selectedOrderForRateShopping.orderId)}
            orderSource={selectedOrderForRateShopping.source}
            storedTransportMode="SP"
            shipmentReference={selectedOrderForRateShopping.orderId}
            onRateSelect={handleRateSelect}
            rate={rate}
            preSelectedCarrierRate={
              rate && Object.keys(rate).length > 0
                ? rate?.selectedCarrierRate
                : shipmentPlan[selectedOrderForRateShopping.orderId]
                    ?.preSelectedCarrierRate || null
            }
            validateAddress={addressValidation}
            carrierIntegration={selectedOrderForRateShopping.carrierIntegration}
            setShowRateShoppingSlideOver={setShowRateShoppingSlideOver}
            selectedInsurance={null}
          />
        )}
      </SlideOverPanel>
    </div>
  );
};

export default ShipmentPlan;
