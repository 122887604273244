import NewModal from "#newUiComponents/commons/NewModal";

export const MarkPackingCompleteConfirmation = ({
  ordersForConfirmation,
  setShowPackingCompleteConfirmation,
  bulkMarkCompleted,
  selectedOrders,
}) => {
  return (
    <NewModal
      isOpen={true}
      onClose={() => setShowPackingCompleteConfirmation(false)}
      title="Packing Complete Confirmation"
      maxHeight="600px"
      maxWidth="500px">
      <div className="space-y-4">
        {ordersForConfirmation.shippingLabel.length > 0 && (
          <div>
            <p className="font-semibold">
              Below order(s) do not have a shipping label:
            </p>

            {ordersForConfirmation.shippingLabel.map((order, index) => (
              <p key={order}>
                {index + 1}. {order}
              </p>
            ))}
          </div>
        )}

        {ordersForConfirmation.trackingNumber.length > 0 && (
          <div>
            <p className="font-semibold">
              Below order(s) do not have a tracking number:
            </p>

            {ordersForConfirmation.trackingNumber.map((order, index) => (
              <p key={order}>
                {index + 1}. {order}
              </p>
            ))}
          </div>
        )}

        <div>
          <p>
            Clicking on <b>OK</b> marks the orders COMPLETE and cannot be
            reversed.
          </p>
          <p>Are you sure you want to mark these orders as completed?</p>
        </div>

        <div className="mt-4 flex items-center justify-end gap-2">
          <button
            className="rounded-lg px-4 py-2 text-[16px] hover:opacity-80 active:opacity-100"
            onClick={() => setShowPackingCompleteConfirmation(false)}>
            Cancel
          </button>

          <button
            className="rounded-lg bg-[#417492] px-4 py-2 text-[16px] text-white hover:opacity-80 active:opacity-100"
            onClick={() => {
              bulkMarkCompleted(selectedOrders);
              setShowPackingCompleteConfirmation(false);
            }}>
            OK
          </button>
        </div>
      </div>
    </NewModal>
  );
};
